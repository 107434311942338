import {useAuth0} from "@auth0/auth0-react";
import {apiService} from "./ApiServiceV2";
import {useQuery} from "react-query";

export function useUserBalance() {
    const auth0Context = useAuth0();

    return useQuery("userBalance", async () => {
        const response = await apiService.getUserBalance(auth0Context);
        return response.data;
    })
}