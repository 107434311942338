export const NAV = {
    W_VERTICAL_OPENED: 250,
    W_VERTICAL_CLOSED: 150,
}

export const FILTER = {
    WIDTH: 240
}

export const HEADER = {
    H_DESKTOP: 64
}