import React from "react";
import {Grid} from "@mui/material";
import {useTranslation} from "react-i18next";
import {ValidationParametersContext} from "./ParametersContext";
import {SectionTitle} from "../../common/SectionTitle";
import {BaseCard} from "../../components/cards";
import {useResponsive} from "../../hooks/use-responsive";
import Box from "@mui/material/Box";
import {CurrencyField} from "../../components/text_fields";
import Tooltip from "@mui/material/Tooltip";
import DataField from "../../components/data-field";
import {FormatCompactCurrency} from "../../utils/format-number";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

export const InvestmentCostSection = ({showTitle = true, validation = false}) => {
    const {t} = useTranslation();
    const {validationParameters, setValidationParameters} = React.useContext(ValidationParametersContext);
    const mdUp = useResponsive('up', 'md');
    const CardOrBox = mdUp ? BaseCard : Box;


    const handlePropertyValueChange = (event) => {
        const newPropertyValue = event.target.value;
        setValidationParameters({
            ...validationParameters, property_value: newPropertyValue
        });
    }

    const handleAgencyCommissionChange = (event) => {
        const newAgencyCommission = event.target.value;
        setValidationParameters({
            ...validationParameters, agency_commission: newAgencyCommission
        });
    }

    const handleNotaryValuationChange = (event) => {
        const newNotaryValuation = event.target.value;
        setValidationParameters({
            ...validationParameters, notary_valuation: newNotaryValuation
        });
    }

    const handlePropertyRenovationChange = (event) => {
        const newPropertyRenovation = event.target.value;
        setValidationParameters({
            ...validationParameters, property_renovation: newPropertyRenovation
        });
    }

    const handleOtherCostChange = (event) => {
        const newOtherCost = event.target.value;
        setValidationParameters({
            ...validationParameters, investment_other_cost: newOtherCost
        });
    }

    return (<CardOrBox>
        {showTitle && <SectionTitle title={t("financial.profitability.investment_expenses")} variant={"h6"}/>}
        <Grid container spacing={4} alignItems="center">
            <Grid item xs={12} sm={6}>
                <CurrencyField
                    label={t("parameters.investment_expenses.property_value")}
                    value={validationParameters?.property_value}
                    onBlur={handlePropertyValueChange}
                    onChange={handlePropertyValueChange}
                    fullWidth={true}
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <CurrencyField
                    label={t("parameters.investment_expenses.agency_commission_field_label")}
                    value={validationParameters?.agency_commission}
                    onChange={handleAgencyCommissionChange}
                    fullWidth={true}

                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <CurrencyField
                    label={t("parameters.investment_expenses.notary_valuation_field_label")}
                    value={validationParameters?.notary_valuation}
                    onChange={handleNotaryValuationChange}
                    fullWidth={true}

                />
            </Grid>
            <Grid item xs={12} sm={6}>
                {validation ? (
                    <Box display="flex" alignItems="center">
                        <DataField
                            label={t("parameters.investment_expenses.property_renovation_field_label")}
                            labelVariant="subtitle1"
                            valueVariant="subtitle1"
                            labelColor="text.primary"
                            value={FormatCompactCurrency(validationParameters?.property_renovation)}
                        />
                        <Tooltip
                            title={t("parameters.investment_expenses.renewal_value")}
                            interactive
                            enterTouchDelay={0}
                            arrow
                            sx={{ml: 2}}

                        >
                            <InfoOutlinedIcon fontSize="small" color="info"/>
                        </Tooltip>
                    </Box>
                ) : (
                    <CurrencyField
                        label={t("parameters.investment_expenses.property_renovation_field_label")}
                        value={validationParameters?.property_renovation}
                        onChange={handlePropertyRenovationChange}
                        fullWidth={true}
                    />
                )}
            </Grid>
            <Grid item xs={12} sm={6}>
                <CurrencyField
                    label={t("parameters.investment_expenses.additional_expenses_to_consider_field_label")}
                    value={validationParameters?.investment_other_cost}
                    onChange={handleOtherCostChange}
                    fullWidth={true}
                />
            </Grid>
        </Grid>
    </CardOrBox>)
}
