import Box from "@mui/material/Box";
import {Dialog, IconButton, Typography} from "@mui/material";
import {WClear} from "../icons/walden-icons";
import {NumberField} from "../text_fields";
import {SecondaryButton} from "../buttons";
import React from "react";
import {useTranslation} from "react-i18next";

export function EditValueDialog({
                                    dialogOpen,
                                    handleDialogClose,
                                    dialogCustomValue,
                                    handleTextFieldChange,
                                    handleDialogSaveClick,
                                    isValidation,
                                    isChecklist,
                                    isOikos
                                }) {
    const {t} = useTranslation();

    return (
        <Dialog id={"EditValueDialog"} open={dialogOpen} onClose={handleDialogClose}>
            <Box sx={{padding: 3, position: 'relative', minWidth: 300}}>
                <Box sx={{position: 'absolute', top: 8, right: 8}}>
                    <IconButton onClick={handleDialogClose}>
                        <WClear/>
                    </IconButton>
                </Box>
                {(isValidation || isOikos) && (
                    <>
                        <Typography variant="h6" gutterBottom>
                            {t("edit_value_dialog.title")}
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                            {t("edit_value_dialog.description")}
                        </Typography>
                    </>
                )}
                <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'stretch', paddingTop: '25px'}}>
                    <NumberField
                        isEuro
                        value={dialogCustomValue}
                        onChange={handleTextFieldChange}
                        variant="outlined"
                        size="small"
                        fullWidth
                    />
                    <SecondaryButton
                        onClick={handleDialogSaveClick}
                        sx={{mt: 2}}
                    >
                        {t("edit_value_dialog.change")}
                    </SecondaryButton>
                </Box>
                {(isValidation || isOikos) && (
                    <Typography variant="body2" sx={{mt: 2, textAlign: 'center'}}>
                        {t("edit_value_dialog.thanks")}
                    </Typography>
                )}
            </Box>
        </Dialog>
    );
}