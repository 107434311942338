import {alpha} from '@mui/material/styles';

// ----------------------------------------------------------------------

// SETUP COLORS

export const grey = {
    0: '#FFFFFF',
    100: '#F9FAFB',
    200: '#F4F6F8',
    300: '#DFE3E8',
    400: '#C4CDD5',
    500: '#919EAB',
    600: '#637381',
    700: '#454F5B',
    800: '#212B36',
    900: '#161C24',
};

export const primary = {
    lighter: '#B7F5E9', light: '#3AE4C2', main: '#1DD2AF', dark: '#18B495', darker: '#0F6C59', contrastText: '#FFFFFF',
};

export const primaryGradient = {
    main: "linear-gradient(135deg, #4BE7C8 0%, #16A286 100%)",
    bold: "linear-gradient(135deg, #1DD2AF 0%, #0F6C59 100%)",
    lighter: "linear-gradient(135deg, #D7FDF9FF 0%, #D7FDF9FF 100%)"
};

export const secondary = {
    lighter: '#CED0F3', light: '#181C63', main: '#111547', dark: '#0C0E31', darker: '#0C0E31', contrastText: '#FFFFFF',
};

export const secondaryGradient = {
    main: "linear-gradient(135deg, #8C90E3 0%, #1C2273 100%)",
    bold: "linear-gradient(135deg, #1C2273 0%, #0C0E31 100%)"
};

export const info = {
    lighter: '#CAFDF5', light: '#61F3F3', main: '#00B8D9', dark: '#006C9C', darker: '#003768', contrastText: '#FFFFFF',
};

export const infoGradient = {
    main: "linear-gradient(135deg, #61F3F3 0%, #00B8D9 100%)",
    bold: "linear-gradient(135deg, #00B8D9 0%, #006C9C 100%)",
}

export const success = {
    lighter: '#D3FCD2', light: '#77ED8B', main: '#22C55E', dark: '#118D57', darker: '#065E49', contrastText: '#ffffff',
};

export const successGradient = {
    main: "linear-gradient(135deg, #77ED8B 0%, #22C55E 100%)",
    bold: "linear-gradient(135deg, #22C55E 0%, #118D57 100%)"
}

export const warning = {
    lighter: '#FFF5CC', light: '#FFD666', main: '#FFAB00', dark: '#B76E00', darker: '#7A4100', contrastText: grey[800],
};

export const warningGradient = {
    main: "linear-gradient(135deg, #FFD666 0%, #FFAB00 100%)",
    bold: "linear-gradient(135deg, #FFAB00 0%, #B76E00 100%)"
}

export const error = {
    lighter: '#FFE9D5', light: '#FFAC82', main: '#FF5630', dark: '#B71D18', darker: '#7A0916', contrastText: '#FFFFFF',
};

export const errorGradient = {
    main: "linear-gradient(135deg, #FFAC82 0%, #FF5630 100%)",
    bold: "linear-gradient(135deg, #FF5630 0%, #B71D18 100%)"
}

export const common = {
    black: '#000000', white: '#FFFFFF',
};

export const action = {
    hover: alpha(grey[500], 0.08),
    selected: alpha(grey[500], 0.16),
    disabled: alpha(grey[500], 0.8),
    disabledBackground: alpha(grey[500], 0.24),
    focus: alpha(grey[500], 0.24),
    hoverOpacity: 0.08,
    disabledOpacity: 0.48,
};

const base = {
    primary, secondary, info, success, warning, error, grey, common, divider: alpha(grey[500], 0.2), action,
};

const gradient = {
    primary: primaryGradient,
    secondary: secondaryGradient,
    info: infoGradient,
    success: successGradient,
    warning: warningGradient,
    error: errorGradient,
}

const custom_palette = {
    "Sapphire": "#0f52ba",
    "Orange wheel": "#f77f00",
    "Fire engine red": "#d62828",
    "Tyrian purple": "#700548",
    "Jade": "#57a773",
    "Process cyan": "#08b2e3",
    "Bittersweet": "#ee6352",
    "Dark cyan": "#048A81",
    "Lion": "#AF9164",
    "Flame": "#E75015",
    "Jungle Green": "#009E77",
    "Redwood": "#B2394D"

};

const charts = {
    chart: {
        color1: custom_palette["Sapphire"],
        color2: custom_palette["Orange wheel"],
        color3: custom_palette["Fire engine red"],
        color4: custom_palette["Tyrian purple"],
        color5: custom_palette["Jade"],
        color6: custom_palette["Process cyan"],
        color7: custom_palette["Bittersweet"],
        color8: custom_palette["Dark cyan"],
        color9: custom_palette["Lion"],
        color10: custom_palette["Flame"],
        color11: custom_palette["Jungle Green"],
        color12: custom_palette["Redwood"]
    }
}

// ----------------------------------------------------------------------

export function palette(mode) {
    const light = {
        ...base, gradient, ...charts, mode: 'light', text: {
            primary: grey[800], secondary: grey[600], disabled: grey[500],
        }, background: {
            paper: '#FFFFFF', default: '#FFFFFF', neutral: grey[200],
        }, action: {
            ...base.action, active: grey[600],
        },
    };

    const dark = {
        ...base, mode: 'dark', text: {
            primary: '#FFFFFF', secondary: grey[500], disabled: grey[600],
        }, background: {
            paper: grey[800], default: grey[900], neutral: alpha(grey[500], 0.12),
        }, action: {
            ...base.action, active: grey[500],
        },
    };

    return mode === 'light' ? light : dark;
}
