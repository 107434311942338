import {IconButton, SwipeableDrawer} from "@mui/material";
import React, {useState} from "react";
import {useResponsive} from "../../hooks/use-responsive";
import NavigationContent from "./navigation-content";
import {NAV} from "../../pages/config-layout";
import Stack from "@mui/material/Stack";
import Scrollbar from "../scrollbar";
import {WMenu} from "../icons/walden-icons";

export default function MobileNavigation() {
    const lgUp = useResponsive("up", "lg");
    const [open, setOpen] = useState(false);

    if (lgUp) return null;

    const toggleDrawer = () => {
        setOpen(prev => !prev)
    };

    return (
        <>
            <IconButton onClick={toggleDrawer}>
                <WMenu width={18}/>
            </IconButton>
            <SwipeableDrawer
                anchor="left"
                open={open}
                onClose={() => setOpen(false)}
                onOpen={() => setOpen(true)}
                PaperProps={{
                    sx: {width: NAV.W_VERTICAL_OPENED},
                }}
            >
                <Scrollbar>
                    <Stack spacing={2} p={2} sx={{}}>
                        <NavigationContent/>
                    </Stack>
                </Scrollbar>
            </SwipeableDrawer>
        </>
    );
}