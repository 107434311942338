import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import {FormControl, Switch} from "@mui/material";
import PercentageField from "../../components/text_fields/percentageField";
import React from "react";
import {useTranslation} from "react-i18next";
import Box from "@mui/material/Box";
import CurrencyField from "../../components/text_fields/currencyField";
import {InvestorProfileDataContext} from "./InvestorProfileContext";

export default function InvestmentGoals({showFullComponent}) {
    const {profileData, setProfileData, validations} = React.useContext(InvestorProfileDataContext);
    const {t} = useTranslation();

    const toggleROIEnabled = () => {
        setProfileData(prevData => ({...prevData, hasROIGoal: !prevData.hasROIGoal}));
    }

    const toggleCashflowEnabled = () => {
        setProfileData(prevData => ({...prevData, hasCashflowGoal: !prevData.hasCashflowGoal}));
    }

    const togglePatrimonyEnabled = () => {
        setProfileData(prevData => ({...prevData, hasPatrimonyGoal: !prevData.hasPatrimonyGoal}));
    }

    return <>
        {showFullComponent ? <Typography variant="h6">{t("investment_goals.title")}</Typography> : null}
        <Box sx={{display: "flex", justifyContent: "space-between", width: "100%", alignItems: "center"}}>
            <Typography variant="body2">{t("financial.profitability.gross_roi")}</Typography>
            <Switch checked={profileData?.hasROIGoal} onChange={toggleROIEnabled}/>
        </Box>
        {profileData?.hasROIGoal &&
            <Grid container spacing={2} alignContent="space-between">
                <Grid item xs={12} md={4} xl={4}>
                    <FormControl fullWidth sx={{minWidth: 120}}>
                        <PercentageField value={profileData?.optimalROIThreshold}
                                         label={t("quality_levels.ideal")}
                                         onChange={event => setProfileData({
                                             ...profileData,
                                             optimalROIThreshold: event.target.value
                                         })}
                                         helperText={t("feedback.error.optimalROI_error")}
                                         isError={validations?.optimalROIIsError(profileData)}/>
                    </FormControl>
                </Grid>
                <Grid item xs={12} md={4} xl={4}>
                    <FormControl fullWidth sx={{minWidth: 120}}>
                        <PercentageField value={profileData?.mediocreROIThreshold}
                                         label={t("quality_levels.moderate")}
                                         helperText={t("feedback.error.mediocreROI_error")}
                                         isError={validations?.mediocreROIIsError(profileData)}
                                         onChange={event => setProfileData({
                                             ...profileData,
                                             mediocreROIThreshold: event.target.value
                                         })}/>
                    </FormControl>
                </Grid>
                <Grid item xs={12} md={4} xl={4}>
                    <FormControl fullWidth sx={{minWidth: 120}}>
                        <PercentageField value={profileData?.lowROIThreshold}
                                         label={t("quality_levels.low")}
                                         onChange={event => setProfileData({
                                             ...profileData,
                                             lowROIThreshold: event.target.value
                                         })}/>
                    </FormControl>
                </Grid>
            </Grid>}

        <Box sx={{display: "flex", justifyContent: "space-between", width: "100%", alignItems: "center"}}>
            <Typography variant="body2">{t("investment_goals.cash_flow.title")}</Typography>
            <Switch checked={profileData?.hasCashflowGoal} onChange={toggleCashflowEnabled}/>
        </Box>
        {profileData?.hasCashflowGoal &&
            <Grid container spacing={2} alignContent="space-between">
                <Grid item xs={12} md={4} xl={4}>
                    <FormControl fullWidth sx={{m: 1, minWidth: 120}}>
                        <CurrencyField value={profileData?.optimalCashflowThreshold}
                                       label={t("investment_goals.cash_flow.ideal")}
                                       onChange={event => setProfileData({
                                           ...profileData,
                                           optimalCashflowThreshold: event.target.value
                                       })}
                                       helperText={t("feedback.error.optimalROI_error")}
                                       isError={validations?.optimalCashflowIsError(profileData)}/>
                    </FormControl>
                </Grid>
                <Grid item xs={12} md={4} xl={4}>
                    <FormControl fullWidth sx={{m: 1, minWidth: 120}}>
                        <CurrencyField value={profileData?.mediocreCashflowThreshold}
                                       label={t("investment_goals.cash_flow.acceptable")}
                                       helperText={t("feedback.error.mediocreROI_error")}
                                       isError={validations?.mediocreCashflowIsError(profileData)}
                                       onChange={(event) => setProfileData({
                                           ...profileData,
                                           mediocreCashflowThreshold: event.target.value
                                       })}/>
                    </FormControl>
                </Grid>
                <Grid item xs={12} md={4} xl={4}>
                    <FormControl fullWidth sx={{m: 1, minWidth: 120}}>
                        <CurrencyField value={profileData?.lowCashflowThreshold}
                                       label={t("investment_goals.cash_flow.low")}
                                       onChange={(event) => setProfileData({
                                           ...profileData,
                                           lowCashflowThreshold: event.target.value
                                       })}/>
                    </FormControl>
                </Grid>
            </Grid>}

        <Box sx={{display: "flex", justifyContent: "space-between", width: "100%", alignItems: "center"}}>
            <Typography variant="body2">{t("investment_goals.patrimony_goal_label")}</Typography>
            <Switch checked={profileData?.hasPatrimonyGoal} onChange={togglePatrimonyEnabled}/>
        </Box>
        {profileData?.hasPatrimonyGoal &&
            <Grid container spacing={2} alignContent="space-between">
                <Grid item xs={12} md={4} xl={4}>
                    <FormControl fullWidth sx={{m: 1, minWidth: 120}}>
                        <CurrencyField value={profileData?.optimalPatrimonyThreshold}
                                       label={t("quality_levels.ideal")}
                                       onChange={event => setProfileData({
                                           ...profileData,
                                           optimalPatrimonyThreshold: event.target.value
                                       })}
                                       helperText={t("feedback.error.optimalROI_error")}
                                       isError={validations?.optimalPatrimonyIsError(profileData)}/>
                    </FormControl>
                </Grid>
                <Grid item xs={12} md={4} xl={4}>
                    <FormControl fullWidth sx={{m: 1, minWidth: 120}}>
                        <CurrencyField value={profileData?.mediocrePatrimonyThreshold}
                                       label={t("quality_levels.moderate")}
                                       helperText={t("feedback.error.mediocreROI_error")}
                                       isError={validations?.mediocrePatrimonyIsError(profileData)}
                                       onChange={event => setProfileData({
                                           ...profileData,
                                           mediocrePatrimonyThreshold: event.target.value
                                       })}/>
                    </FormControl>
                </Grid>
                <Grid item xs={12} md={4} xl={4}>
                    <FormControl fullWidth sx={{m: 1, minWidth: 120}}>
                        <CurrencyField value={profileData?.lowPatrimonyThreshold}
                                       label={t("quality_levels.low")}
                                       onChange={event => setProfileData({
                                           ...profileData,
                                           lowPatrimonyThreshold: event.target.value
                                       })}/>
                    </FormControl>
                </Grid>
            </Grid>}
    </>;
}