import Typography from "@mui/material/Typography";
import {Button, useTheme} from "@mui/material";
import React from "react";
import {useLocation} from "react-router-dom";
import ChecklistItem from "./checklist-item";
import {useTranslation} from "react-i18next";
import Box from "@mui/material/Box";
import {ChecklistDetailer} from "../../services/validationRequest/checklist-detailer";
import {LoadingScreenBar} from "../../components/loading-screen";
import {GetValidationItem} from "./items-map";
import {useAuth0} from "@auth0/auth0-react";
import {apiService} from "../../services";
import toast from "react-hot-toast";
import {Mixpanel} from "../../metrics/mixpanel";


export default function ValidationChecklist({changeView, setTotalSelectedValue}) {
    const validationRequestID = useLocation().state;
    const theme = useTheme();
    const auth0Context = useAuth0();
    const {t} = useTranslation();
    const {data, isLoading} = ChecklistDetailer(validationRequestID);

    // const {mutate} = ChecklistStorer();

    if (isLoading) {
        return <LoadingScreenBar/>;
    }

    const onSelectedValueChange = (modifiedItemData) => {
        const updatedItems = data.items.map((item) => {
            if (item.check_list_item_id === modifiedItemData.check_list_item_id) {
                item.category = modifiedItemData.category;
                item.value = modifiedItemData.value;
                return item;
            }
            return item;
        });
        setTotalSelectedValue(
            updatedItems.reduce((total, item) => total + item.value, 0)
        );
        data.items = updatedItems;
    };


    const handleSubmit = () => {
        Mixpanel.track('Visual Validation: A new checklist was saved');

        toast.promise(
            apiService.saveChecklist(auth0Context, data),
            {
                loading: t("general.saving"),
                success: t("general.saved"),
                error: t("feedback.error.api_request_error"),
            }
        ).then(r => changeView(false));
    };

    const Section = ({title, items, group}) => <>
        <Typography variant="h5" sx={{marginBottom: 2}}>
            {title}
        </Typography>
        {items.map((itemData) => {
            const a = GetValidationItem(itemData.i18n_key, t, theme);
            return <React.Fragment key={`${group}-${itemData.id}`}>
                <ChecklistItem
                    icon={a.icon}
                    title={a.title}
                    text={a.text}
                    facelift={a.facelift}
                    arrangements={a.arrangements}
                    integral={a.integral}
                    itemData={itemData}
                    onSelectedValueChange={onSelectedValueChange}
                />
                <div style={{marginBottom: '1rem'}}/>
            </React.Fragment>
        })}
    </>;

    const sections = [{
        title: t('visual_validation.section_title_1'),
        group: 'exterior',
        items: data.items.filter((item) => item.renovation_item_group === 'property_exterior'),
    }, {
        title: t('visual_validation.section_title_2'),
        group: 'interior',
        items: data.items.filter((item) => item.renovation_item_group === 'property_interior'),
    }, {
        title: t('visual_validation.section_title_3'),
        group: 'services',
        items: data.items.filter((item) => item.renovation_item_group === 'services'),
    },];

    return <Box sx={{mt: 1}}>
        {sections.map(({title, items, group}, index) =>
            <Section key={`section-${index}`} title={title} items={items} group={group}/>
        )}
        <Box
            sx={{
                position: 'fixed', bottom: 20, right: 16, zIndex: 10, display: 'inline-flex',
            }}
        >
            <Button
                variant="contained"
                color="primary"
                sx={{
                    borderRadius: '20px', padding: '10px 20px',
                }}
                onClick={handleSubmit}
            >
                {t("visual_validation.complete_inspection")}
            </Button>
        </Box>
    </Box>;
}