import {selectorsFromSettings} from "../../utils/financial";

export function oikosResponseMapper(response, settings) {
    if (!response?.property) return {}
    const selectors = selectorsFromSettings(settings);
    const financial = response.financial[selectors.priceSelector];
    const roi = financial.roi;
    const cash_flow = roi.cash_flow[selectors.periodSelector];
    const property_cost = roi.property_cost[selectors.periodSelector];
    const cash_flow_contribution_by_period = roi.cash_flow_contribution[selectors.periodSelector];

    return {
        ...response, financial: {
            ...financial, mortgage: {
                ...financial.mortgage,
                payment: financial.mortgage.payment[selectors.periodSelector],
                principal_payment: financial.mortgage.principal_payment[selectors.periodSelector],
                interest_payment: financial.mortgage.interest_payment[selectors.periodSelector]
            }, roi: {
                ...roi,
                cash_flow: {
                    ...cash_flow,
                    profit_before_taxes: cash_flow.profit_before_taxes[selectors.profileSelector],
                    profit_after_taxes: cash_flow.profit_after_taxes[selectors.profileSelector],
                    value_after_debt_payment: cash_flow.value_after_debt_payment[selectors.profileSelector]
                },
                property_cost: property_cost,
                irpf_amount: roi.irpf_amount[selectors.periodSelector],
                taxable_income_base_after_bonuses: roi.taxable_income_base_after_bonuses[selectors.periodSelector],
                net_roi_before_interest_and_taxes: roi.net_roi_before_interest_and_taxes[selectors.profileSelector],
                net_roi_after_interest_and_taxes: roi.net_roi_after_interest_and_taxes[selectors.profileSelector],
                roce: roi.roce[selectors.profileSelector],
                minimum_rent_for_self_paying_property: roi.minimum_rent_for_self_paying_property[selectors.periodSelector],
                cash_flow_contribution: cash_flow_contribution_by_period[selectors.profileSelector]
            }, investment_goals: response.financial.investment_goals
        }
    }
}