import AppPage from "../../common/AppPage";
import {useProfileDetailer} from "../../services/profile-detailer";
import React from "react";
import ROUTES from "../../routes";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {PricingTables} from "../investor_profile/pricing-tables";
import {LoadingScreenBar} from "../../components/loading-screen";
import toast from "react-hot-toast";

function handleError(error, t, toast, navigate) {
    if (error.response.status === 404) {
        navigate(ROUTES.onboarding.profile);
    } else {
        toast.error(t("feedback.error.general_api_error"));
    }
}

export function SubscriptionPage() {
    const {t} = useTranslation();
    const navigate = useNavigate();

    const {data: fetchedProfileData, isLoading} = useProfileDetailer(0, (error) => {
        handleError(error, t, toast, navigate);
    });

    if (isLoading) {
        return <LoadingScreenBar/>
    }

    return <AppPage containerComponent="box" topBarTitle="Subscription">
        <PricingTables subscriptionData={fetchedProfileData?.subscription_detail}/>
    </AppPage>
}