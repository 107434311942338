import UserAvatar from "../profile/user-avatar";
import React from "react";
import Popover from "@mui/material/Popover";
import MenuItem from "@mui/material/MenuItem";
import {useNavigate} from "react-router-dom";
import ROUTES from "../../routes";
import {Box, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";

export default function UserAvatarMenu({showReload, reloadPage}) {
    const navigate = useNavigate();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const {t} = useTranslation();

    const handleClick = (event) => {
        setAnchorEl(prev => prev == null ? event.currentTarget : null);
    }

    const handleClose = () => {
        setAnchorEl(null);
    }

    return <>
        <Box sx={{position: 'relative', display: 'inline-block'}}>
            <UserAvatar onClick={handleClick} sx={{
                '&:hover': {
                    cursor: "pointer"
                },
            }}/>
            {showReload && (
                <Box sx={{
                    position: 'absolute',
                    bottom: 2,
                    right: 0,
                    width: 12,
                    height: 12,
                    backgroundColor: "success.main",
                    borderRadius: '50%',
                    border: '2px solid white'
                }}/>
            )}
        </Box>
        <Popover open={open} anchorEl={anchorEl} onClose={handleClose} anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }} transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}>
            <MenuItem onClick={() => navigate(ROUTES.profile)}>
                {t("main_menu.header_menu.investor_profile")}
            </MenuItem>
            <MenuItem onClick={() => navigate(ROUTES.logout)}>
                {t("main_menu.header_menu.log_out")}
            </MenuItem>
            {showReload && (
                <MenuItem
                    onClick={reloadPage}
                    sx={{
                        backgroundColor: "success.lighter",
                        opacity: 0.7,
                        '&:hover': {
                            backgroundColor: "success.lighter",
                            opacity: 1
                        }
                    }}
                >
                    <Box component="span" sx={{
                        backgroundColor: "success.main",
                        width: 8,
                        height: 8,
                        borderRadius: "50%",
                        display: "inline-block",
                        marginRight: "8px"
                    }}/>
                    <Typography variant="body2" sx={{
                        display: "inline-block",
                        color: "success.main",
                        fontWeight: "bold"
                    }}>
                        {t("main_menu.header_menu.new_version_available")}
                    </Typography>
                </MenuItem>
            )}
        </Popover>
    </>;
}