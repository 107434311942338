import {useAuth0} from "@auth0/auth0-react";
import Typography from "@mui/material/Typography";
import React from "react";

export default function UserName({color, variant}) {
    const {user, isAuthenticated} = useAuth0();

    return isAuthenticated && (
        <Typography variant={variant} color={color} sx={{
            whiteSpace: 'nowrap'
        }}>{user.name}</Typography>
    );
}