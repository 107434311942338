import React, {useEffect, useState} from 'react';
import {useAuth0} from "@auth0/auth0-react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    Switch,
    TextField
} from "@mui/material";
import {useFeedbackStorer} from "../../services/feedback-storer";
import {useTranslation} from "react-i18next";

export default function FeedbackDialog() {
    const {t} = useTranslation();
    const [openDialog, setOpenDialog] = useState(false);
    const [feedback, setFeedback] = useState({
        title: "",
        description: "",
        consent_contact: false
    });
    const [errors, setErrors] = useState({
        title: false,
        description: false
    });
    const [isFormValid, setIsFormValid] = useState(false);
    const {user} = useAuth0();
    const {mutate: storeFeedback, isLoading} = useFeedbackStorer();

    useEffect(() => {
        const isValid = feedback.title.trim() !== "" && feedback.description.trim() !== "";
        setIsFormValid(isValid);
    }, [feedback]);

    const handleOpenDialog = () => {
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
        setFeedback({
            title: "",
            description: "",
            consent_contact: false
        });
        setErrors({
            title: false,
            description: false
        });
    };

    const handleSubmitFeedback = () => {
        const newErrors = {
            title: feedback.title.trim() === "",
            description: feedback.description.trim() === ""
        };
        setErrors(newErrors);

        if (!newErrors.title && !newErrors.description) {
            const feedbackData = {
                ...feedback,
                email: user.email
            };
            storeFeedback(feedbackData, {
                onSuccess: () => {
                    handleCloseDialog();
                }
            });
        }
    };

    const handleInputChange = (event) => {
        const {name, value, checked} = event.target;
        setFeedback(prev => ({
            ...prev,
            [name]: name === 'consent_contact' ? checked : value
        }));
        if (errors[name]) {
            setErrors(prev => ({
                ...prev,
                [name]: false
            }));
        }
    };

    return (
        <>
            <Button
                fullWidth
                variant="outlined"
                onClick={handleOpenDialog}
                color="secondary"
                sx={{mt: 2}}
            >
                {t('feedback_dialog.open_button')}
            </Button>

            <Dialog open={openDialog} onClose={handleCloseDialog}>
                <DialogTitle>{t('feedback_dialog.dialog_title')}</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        name="title"
                        label={t('feedback_dialog.title_label')}
                        type="text"
                        fullWidth
                        variant="outlined"
                        value={feedback.title}
                        onChange={handleInputChange}
                        error={errors.title}
                        helperText={errors.title ? t('feedback_dialog.title_helper_text') : ""}
                    />
                    <TextField
                        margin="dense"
                        name="description"
                        label={t('feedback_dialog.description_label')}
                        type="text"
                        fullWidth
                        variant="outlined"
                        multiline
                        rows={4}
                        value={feedback.description}
                        onChange={handleInputChange}
                        error={errors.description}
                        helperText={errors.description ? t('feedback_dialog.description_helper_text') : ""}
                    />
                    <FormControlLabel
                        control={
                            <Switch
                                checked={feedback.consent_contact}
                                onChange={handleInputChange}
                                name="consent_contact"
                            />
                        }
                        label={t('feedback_dialog.consent_contact_label')}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog}>{t('feedback_dialog.cancel_button')}</Button>
                    <Button
                        onClick={handleSubmitFeedback}
                        color="primary"
                        variant="contained"
                        disabled={!isFormValid || isLoading}
                    >
                        {isLoading ? t('feedback_dialog.sending_button') : t('feedback_dialog.submit_button')}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}