import {useQuery} from 'react-query';
import {apiService} from "../ApiServiceV2";
import {useAuth0} from "@auth0/auth0-react";
import {useTranslation} from "react-i18next";


export function parseAdministrativeValidationJSON(json, t) {
    if (typeof json !== 'object' || json === null) {
        return json;
    }

    if (Array.isArray(json)) {
        return json.map(item => parseAdministrativeValidationJSON(item, t));
    }

    const {nota_simple_description_data, status} = json;

    if (!nota_simple_description_data) {
        return json;
    }

    return {
        ...parseAdministrativeValidationData(nota_simple_description_data, t),
        status
    };
}

function parseAdministrativeValidationData(data, t) {
    if (typeof data !== 'object' || data === null) {
        return data;
    }

    if (Array.isArray(data)) {
        return data.map(item => parseAdministrativeValidationData(item, t));
    }

    const result = {};
    for (const key in data) {
        const value = data[key];
        let parsedValue = parseAdministrativeValidationData(value, t);

        if (key === 'property_address') {
            const addressFields = [];
            if (parsedValue?.street) addressFields.push(parsedValue.street);
            if (parsedValue?.number) addressFields.push(parsedValue.number);
            if (parsedValue?.door) addressFields.push(`${t('administrative_validation.door')} ${parsedValue.door}`);
            if (parsedValue?.neighborhood) addressFields.push(parsedValue.neighborhood);
            if (parsedValue?.municipality) addressFields.push(parsedValue.municipality);
            if (parsedValue?.province) addressFields.push(parsedValue.province);

            parsedValue = Object.fromEntries(Object.entries(parsedValue).filter(([, v]) => v !== null));
            parsedValue.address = addressFields.join(', ');
        } else if (key === 'property_area') {
            parsedValue = Object.fromEntries(Object.entries(parsedValue).filter(([, v]) => v !== null));
        } else if (key === 'occupancy_certificate') {
            if (parsedValue?.issued && parsedValue?.validity) {
                parsedValue = `${parsedValue?.issued}, ${parsedValue?.validity}`;
            } else if (parsedValue?.issued) {
                parsedValue = parsedValue?.issued;
            } else if (parsedValue?.validity) {
                parsedValue = parsedValue?.validity;
            } else {
                parsedValue = null;
            }
        } else if (key === 'energy_efficiency') {
            const energy_efficiency_rating = parsedValue?.rating || null;
            if (parsedValue?.rating && parsedValue?.validity) {
                parsedValue = `${parsedValue?.rating}, ${parsedValue?.validity}`;
            } else if (parsedValue?.rating) {
                parsedValue = parsedValue?.rating;
            } else if (parsedValue?.validity) {
                parsedValue = parsedValue?.validity;
            } else {
                parsedValue = null;
            }
            result['energy_efficiency_rating'] = energy_efficiency_rating;
        }

        if (parsedValue !== null && typeof parsedValue === 'object') {
            result[key] = parsedValue;
        } else if (parsedValue !== null) {
            result[key] = parsedValue;
        }
    }

    return result;
}

export function useParsedAdministrativeValidationData(propertyID, cacheTime, onError) {
    const auth0Context = useAuth0();
    const {t} = useTranslation();
    return useQuery(
        ['administrativeValidationData', propertyID],
        async () => {
            const response = await apiService.getPropertyAnalysis(auth0Context, propertyID);
            return parseAdministrativeValidationJSON(response.data, t);
        },
        {
            retry: (failureCount, error) => {
                if (error.response.status === 404) {
                    return false;
                }
                return failureCount < 3;
            },
            cacheTime: cacheTime,
            onError: (error) => {
                if (onError) {
                    onError(error);
                }
            }
        }
    );
}