// PercentageField.js
import React from "react";
import NumberField from "./numberField";

export default function PercentageField({fullWidth, label, disabled, value, onChange, isError, helperText, variant, isRequired}) {
    return (
        <NumberField
            value={value}
            sign="%"
            less_than={100}
            fullWidth={fullWidth}
            label={label}
            disabled={disabled}
            onChange={onChange ? onChange : () => {
            }}
            isError={isError}
            isRequired={isRequired}
            helperText={helperText}
            variant={variant}
        />
    )
}
