import {EditValueButton} from "../../components/buttons";
import {useState} from "react";
import {useValidationUpdater} from "../../services/validationRequest/validation-storer";
import {buildParametersRequest} from "../parameters";
import {Mixpanel} from "../../metrics/mixpanel";
import {useLocation} from "react-router-dom";
import {useValidationDetailer} from "../../services/validationRequest/validation-detailer";

export function ValidationPriceFeedback({data}) {
    const [value, setValue] = useState(0);
    const validationRequestID = useLocation().state;
    const validationUpdater = useValidationUpdater();
    const {data: apiResponse, refetch} = useValidationDetailer(validationRequestID);

    const buildRequest = () => {
        const params = {
            ...data?.parameters,
            id: data.parameters.ID,
            custom_rent_value: value,
        };
        return buildParametersRequest(params, {
            validation_request: {
                ID: data?.validation_request?.ID,
                cadastre_id: data?.validation_request?.cadastre_id,
            }
        });
    }

    const onDialogSave = () => {
        validationUpdater.mutate(buildRequest(), {
            onSuccess: () => {
                Mixpanel.track('Financial Validation: Price feedback provided');
                refetch();
            }
        });
    }

    const onTextFieldChange = (event) => {
        setValue(event.target.value);
    }

    return <EditValueButton
        isValidation
        value={value}
        onDialogSave={onDialogSave}
        onTextFieldChange={onTextFieldChange}
    />
}