import {Tour} from "./tour";
import {useTranslation} from "react-i18next";
import {TourContent} from "./tour-content";


export function ValidationAdministrativeTour({parsedData}) {
    const {t} = useTranslation();

    const commonSteps = [{
        target: '.tour-administrative-validation-description',
        content: (
            <TourContent
                title={t("tour.administrative.title")}
                description={t("tour.administrative.description")}
                priceNote={t("tour.administrative.price_note")}
            />
        ),
        placement: 'center',
    }];

    const additionalSteps = [{
        target: '.tour-administrative-validation-first-step',
        content: (
            <TourContent
                title={t("tour.administrative.title")}
                description={t("tour.administrative.full_validation")}
            />
        ),
        placement: 'center',
    }, {
        target: '.tour-administrative-validation-second-step',
        content: (
            <TourContent
                title={t("tour.administrative.title")}
                description={t("tour.administrative.full_analysis")}
            />
        ),
        placement: 'center',
    }, {
        target: '.tour-administrative-validation-third-step',
        content: (
            <TourContent
                title={t("tour.administrative.title")}
                description={t("tour.administrative.download")}
            />
        ),
    }, {
        target: '.tour-administrative-validation-fourth-step',
        content: (
            <TourContent
                title={t("tour.administrative.title")}
                description={t("tour.administrative.considerations")}
            />
        ),
    }, {
        target: '.tour-administrative-validation-fifth-step',
        content: (
            <TourContent
                title={t("tour.administrative.title")}
                description={t("tour.administrative.property_details")}
            />
        ),
    }, {
        target: '.tour-administrative-validation-sixth-step',
        content: (
            <TourContent
                title={t("tour.administrative.title")}
                description={t("tour.administrative.mortgage")}
            />
        ),
    }, {
        target: '.tour-administrative-validation-seventh-step',
        content: (
            <TourContent
                title={t("tour.administrative.title")}
                description={t("tour.administrative.owners")}
            />
        ),
    }, {
        target: '.tour-administrative-validation-eighth-step',
        content: (
            <TourContent
                title={t("tour.administrative.title")}
                description={t("tour.administrative.comparison_cadastre")}
            />
        ),
    }];

    const tourSteps = parsedData ? additionalSteps : commonSteps;

    return <Tour steps={tourSteps} tourKey='validation_administrative'/>;
}
