import React, {useState} from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import {ValidationParametersContext} from "./ParametersContext";
import {AllParameters} from "./AllParameters";
import toast from "react-hot-toast";
import {useTranslation} from "react-i18next";
import {buildParametersRequest} from "./request";
import {useUpdateOikosParameters} from "../../services/oikos/oikos-updater";
import RentSection from "./RentSection";
import Stack from "@mui/material/Stack";
import {WEdit} from "../../components/icons/walden-icons";
import {IconButton} from "@mui/material";
import {useResponsive} from "../../hooks/use-responsive";


export default function OikosParameters({refreshParams, parameters, request}) {
    const {t} = useTranslation();
    const [dialogOpen, setDialogOpen] = useState(false);
    const [oikosParameters, setOikosParameters] = useState(parameters);

    const onUpdateSuccess = () => {
        toast.success(t("feedback.success.general_api_success"));
        refreshParams();
        setDialogOpen(false);
    }
    const onUpdateError = () => {
        toast.error(t('feedback.error.api_request_error'));
    }

    const {mutate} = useUpdateOikosParameters(onUpdateSuccess, onUpdateError);
    const mdDown = useResponsive('down', 'md');

    const retrieveParameters = () => {
        setOikosParameters({...parameters, isMortgageValid: true});
    };

    const buildRequest = () => {
        return buildParametersRequest({
            ...oikosParameters,
            id: parameters.ID
        }, request);
    };

    const handleOpenDialog = () => {
        retrieveParameters();
        setDialogOpen(true);
    };

    const handleSaveClick = () => {
        if (!oikosParameters.isMortgageValid) {
            toast.error(t("feedback.error.mortgage_params_is_not_valid"));
            return;
        }

        mutate(buildRequest());
    };


    return (<>

        {mdDown ? (
            <IconButton className="tour-properties-modify-parameters" color="secondary" onClick={handleOpenDialog}>
                <WEdit/>
            </IconButton>
        ) : (
            <Button className="tour-properties-modify-parameters" variant="outlined" color="secondary"
                    startIcon={<WEdit/>}
                    onClick={handleOpenDialog}
            >{t("parameters.modify_parameters")}</Button>
        )}

        <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)} fullWidth maxWidth="lg">
            <DialogTitle>{t("parameters.modify_parameters")}</DialogTitle>
            <DialogContent>
                <ValidationParametersContext.Provider
                    value={{validationParameters: oikosParameters, setValidationParameters: setOikosParameters}}>
                    <Stack spacing={2}>
                        <RentSection/>
                        <AllParameters/>
                    </Stack>
                </ValidationParametersContext.Provider>
            </DialogContent>
            <DialogActions style={{justifyContent: 'flex-end', padding: "20px"}}>
                <Button onClick={() => setDialogOpen(false)} color="primary">
                    {t("general.close")}
                </Button>
                <Button onClick={handleSaveClick} variant="contained" color="primary">
                    {t("general.save")}
                </Button>
            </DialogActions>
        </Dialog>
    </>);
}
