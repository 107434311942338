import {useTranslation} from "react-i18next";
import {useAuth0} from "@auth0/auth0-react";
import {useQuery} from "react-query";
import {apiService} from "../ApiServiceV2";
import toast from "react-hot-toast";

export function useSimpleNotePurchaseUrl(validationRequestData, enabled, disableCache = false) {
    const {t} = useTranslation();
    const auth0Context = useAuth0();

    return useQuery(
        ["SimpleNotePurchaseUrl", validationRequestData],
        () => apiService.simpleNotEPurchaseUrl(auth0Context, validationRequestData),
        {
            enabled: enabled,
            cacheTime: disableCache ? 0 : undefined,
            onError: () => {
                toast.error(t("feedback.error.api_request_error"));
            },
        }
    );
}