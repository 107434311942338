import {useAuth0} from "@auth0/auth0-react";
import {useMutation} from "react-query";
import {useTranslation} from "react-i18next";
import toast from "react-hot-toast";
import {apiService} from "../ApiServiceV2";

export function ValidationConverter(onSuccess = () => {
}) {
    const auth0Context = useAuth0();
    const {t} = useTranslation();

    const buildConversionRequest = (validationRequestId, propertyData, parameters) => ({
        validation_request_id: validationRequestId,
        oikos_request: {
            oikos: {
                cadastre_id: propertyData.reference
            },
            parameters: {
                type: parameters.type,
                years: parameters.years,
                euribor: parameters.euribor,
                interest: parameters.interest,
                differential: parameters.differential,
                financed_percentage: parameters.financed_percentage,
                maintenance: parameters.maintenance,
                empty_period: parameters.empty_period,
                unpaid_insurance: parameters.unpaid_insurance,
                garbage_tax: parameters.garbage_tax,
                home_insurance: parameters.home_insurance,
                life_insurance: parameters.life_insurance,
                community_fees: parameters.community_fees,
                property_tax_ibi: parameters.property_tax_ibi,
                property_other_cost: parameters.property_other_cost,
                property_value: parameters.property_value,
                agency_commission: parameters.agency_commission,
                notary_valuation: parameters.notary_valuation,
                property_renovation: parameters.property_renovation,
                investment_other_cost: parameters.investment_other_cost,
                rent_value: parseFloat(parameters.rent_value)
            }
        }
    });

    return useMutation(
        (data) => {
            const {validationRequestId, propertyData, parameters} = data;
            const requestData = buildConversionRequest(validationRequestId, propertyData, parameters);
            return toast.promise(
                apiService.convertValidationToOikos(auth0Context, requestData),
                {
                    loading: t("validation_to_oikos.convert"),
                    success: t("validation_to_oikos.converted"),
                    error: t("feedback.error.api_request_error"),
                }
            );
        },
        {
            onSuccess: (response) => {
                onSuccess(response.data);
            },
            onError: (error) => {
                if (error?.response?.status === 409) {
                    toast.error(t("feedback.error.duplicate_warning_message"));
                }
            },
        }
    );
}