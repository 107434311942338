export const buildParametersRequest = (params, request = {}) => {
    return {
        ...request,
        parameters: {
            ID: params.id,
            validation_request_id: params.validation_request_id,
            type: Number(params.type),
            years: Number(params.years),
            euribor: Number(params.euribor),
            interest: Number(params.interest),
            differential: Number(params.differential),
            financed_percentage: Number(params.financed_percentage),
            maintenance: Number(params.maintenance),
            empty_period: Number(params.empty_period),
            unpaid_insurance: Number(params.unpaid_insurance),
            garbage_tax: Number(params.garbage_tax),
            home_insurance: Number(params.home_insurance),
            life_insurance: Number(params.life_insurance),
            community_fees: Number(params.community_fees),
            property_tax_ibi: Number(params.property_tax_ibi),
            property_other_cost: Number(params.property_other_cost),
            property_value: Number(params.property_value),
            agency_commission: Number(params.agency_commission),
            notary_valuation: Number(params.notary_valuation),
            property_renovation: Number(params.property_renovation),
            investment_other_cost: Number(params.investment_other_cost),
            rent_value: Number(params.rent_value),
            custom_rent_value: Number(params.custom_rent_value),
        }
    }
};