import { StepContent, StepLabel } from '@mui/material';
import React from 'react';
import {StepIcon} from "./step-icon";

export function StepWrapper({ label, stepContent, icon }) {
    return (
        <>
            <StepLabel StepIconComponent={() => <StepIcon icon={icon}/>}>
                {label}
            </StepLabel>
            <StepContent>{stepContent}</StepContent>
        </>
    );
}