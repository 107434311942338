import React from 'react';
import {
    Box,
    Typography,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    useTheme,
    useMediaQuery,
    styled,
    Grid
} from '@mui/material';
import { useTranslation } from 'react-i18next';

const StyledTableHead = styled(TableHead)(({ theme }) => ({
    '& th': {
        color: theme.palette.secondary.main,
        fontWeight: 600,
        padding: theme.spacing(2),
        borderBottom: `2px solid ${theme.palette.primary.main}`,
        transition: 'all 0.2s ease',
        fontSize: '0.9rem',
        letterSpacing: '0.5px',
        [theme.breakpoints.down('sm')]: {
            fontSize: '0.8rem',
            padding: theme.spacing(1),
        },
    }
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    transition: 'all 0.2s ease',
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    '&:hover': {
        backgroundColor: theme.palette.action.selected,
    },
    '& td': {
        padding: theme.spacing(1.5, 2),
        fontSize: '0.9rem',
        borderBottom: `1px solid ${theme.palette.divider}`,
        [theme.breakpoints.down('sm')]: {
            fontSize: '0.8rem',
            padding: theme.spacing(1),
        },
    }
}));

const SectionTitle = styled(Typography)(({ theme }) => ({
    color: theme.palette.secondary.main,
    padding: theme.spacing(2),
    borderBottom: `2px solid ${theme.palette.primary.main}`,
    marginBottom: theme.spacing(3),
    fontWeight: 600,
    fontSize: '1.1rem',
    letterSpacing: '0.5px',
    transition: 'all 0.2s ease',
    '&:hover': {
        paddingLeft: theme.spacing(3),
    },
    [theme.breakpoints.down('sm')]: {
        fontSize: '1rem',
        padding: theme.spacing(1.5),
    },
}));

const InfoSection = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(3),
    borderRadius: '12px',
    marginBottom: theme.spacing(4),
    transition: 'all 0.2s ease',
    border: `1px solid ${theme.palette.divider}`,
    '&:hover': {
        boxShadow: theme.shadows[4],
        borderColor: theme.palette.primary.main,
    },
    [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(2),
    },
}));

const InfoRow = ({ label, value }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: isMobile ? 'column' : 'row',
            justifyContent: 'space-between',
            mb: 2,
            py: 1,
            transition: 'all 0.2s ease',
            '&:hover': {
                backgroundColor: theme.palette.action.hover,
                px: 2,
                mx: -2,
                borderRadius: '6px'
            },
            '&:last-child': {
                mb: 0
            }
        }}>
            <Typography
                variant="body2"
                sx={{
                    color: theme.palette.text.secondary,
                    fontSize: { xs: '0.8rem', sm: '0.9rem' },
                    letterSpacing: '0.3px',
                    mb: isMobile ? 0.5 : 0
                }}
            >
                {label}
            </Typography>
            <Typography
                variant="body2"
                sx={{
                    fontWeight: 500,
                    color: theme.palette.secondary.main,
                    fontSize: { xs: '0.8rem', sm: '0.9rem' },
                    textAlign: isMobile ? 'left' : 'right'
                }}
            >
                {value}
            </Typography>
        </Box>
    );
};

const OfferDocumentPreview = ({ offer }) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    if (!offer) {
        return (
            <Typography color="error" variant="h6" align="center">
                {t('offer_document.no_data_available')}
            </Typography>
        );
    }

    const formatCurrency = (value) => {
        return new Intl.NumberFormat('es-ES', { style: 'currency', currency: 'EUR' }).format(parseFloat(value));
    };

    const TableSection = ({ title, data }) => (
        <Box sx={{ mb: 4 }}>
            <SectionTitle variant="h6">{title}</SectionTitle>
            <TableContainer sx={{ borderRadius: '12px', border: `1px solid ${theme.palette.divider}` }}>
                <Table>
                    <StyledTableHead>
                        <TableRow>
                            <TableCell width={isMobile ? "40%" : "30%"}>{t('offer_document.metric')}</TableCell>
                            <TableCell width={isMobile ? "30%" : "20%"} align="right">{t('offer_document.value')}</TableCell>
                            {!isMobile && <TableCell>{t('offer_document.description')}</TableCell>}
                        </TableRow>
                    </StyledTableHead>
                    <TableBody>
                        {data.map((item, index) => (
                            <StyledTableRow key={index}>
                                <TableCell sx={{ fontWeight: 500 }}>
                                    {t(`offer_document.${item.name}`)}
                                </TableCell>
                                <TableCell
                                    align="right"
                                    sx={{
                                        fontWeight: 600,
                                        fontFamily: 'monospace',
                                        fontSize: { xs: '0.8rem', sm: '0.9rem' }
                                    }}
                                >
                                    {formatCurrency(item.value)}
                                </TableCell>
                                {!isMobile && (
                                    <TableCell sx={{ color: theme.palette.text.secondary }}>
                                        {t(`offer_document.${item.description}`)}
                                    </TableCell>
                                )}
                            </StyledTableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );

    return (
        <Paper
            elevation={0}
            sx={{
                p: { xs: 2, sm: 3, md: 4 },
                margin: 'auto',
                bgcolor: theme.palette.background.paper,
                borderRadius: '16px'
            }}
        >
            <Typography
                variant="h4"
                gutterBottom
                align="center"
                sx={{
                    mb: { xs: 3, sm: 4, md: 5 },
                    color: theme.palette.secondary.main,
                    fontWeight: 600,
                    fontSize: { xs: '1.25rem', sm: '1.5rem', md: '1.75rem' },
                    letterSpacing: '0.5px'
                }}
            >
                {t('offer_document.preview_title')}
            </Typography>

            <Grid container spacing={3} sx={{ mb: 4 }}>
                <Grid item xs={12} md={6}>
                    <InfoSection>
                        <Typography
                            variant="h6"
                            gutterBottom
                            sx={{
                                color: theme.palette.secondary.main,
                                fontWeight: 600,
                                mb: 3,
                                fontSize: { xs: '0.9rem', sm: '1rem' },
                                letterSpacing: '0.5px'
                            }}
                        >
                            {t('offer_document.general_info')}
                        </Typography>
                        <InfoRow label={t('offer_document.date')} value={offer.date} />
                        <InfoRow label={t('offer_document.reference')} value={offer.reference} />
                        <InfoRow label={t('offer_document.address')} value={offer.address} />
                    </InfoSection>
                </Grid>
                <Grid item xs={12} md={6}>
                    <InfoSection>
                        <Typography
                            variant="h6"
                            gutterBottom
                            sx={{
                                color: theme.palette.secondary.main,
                                fontWeight: 600,
                                mb: 3,
                                fontSize: { xs: '0.9rem', sm: '1rem' },
                                letterSpacing: '0.5px'
                            }}
                        >
                            {t('offer_document.offer_details')}
                        </Typography>
                        <InfoRow label={t('offer_document.offer_value')} value={formatCurrency(offer.offer_value)} />
                        <InfoRow label={t('offer_document.published_value')} value={formatCurrency(offer.published_value)} />
                        <InfoRow label={t('offer_document.difference')} value={formatCurrency(offer.difference)} />
                    </InfoSection>
                </Grid>
            </Grid>

            {offer.finance.length > 0 && (
                <TableSection
                    title={t('offer_document.financial_breakdown')}
                    data={offer.finance}
                />
            )}

            {offer.reform.length > 0 && (
                <TableSection
                    title={t('offer_document.reform_negotiations')}
                    data={offer.reform}
                />
            )}

            {offer.administrative.length > 0 && (
                <TableSection
                    title={t('offer_document.administrative_negotiations')}
                    data={offer.administrative}
                />
            )}
        </Paper>
    );
};

export default OfferDocumentPreview;