import {useQuery} from "react-query";
import {apiService} from "../ApiServiceV2";
import {useAuth0} from "@auth0/auth0-react";
import toast from "react-hot-toast";

const buildRequest = (authContext) => {
    return {
        "user_email": authContext.user.email,
    }
}

export function usePaymentAuthorizer() {
    const auth0Context = useAuth0();

    return useQuery("payment_authorizer",
        async () => {
            return apiService.requestStripeCustomerSecret(auth0Context, buildRequest(auth0Context))
                .then(response => response.data)
        }, {
            onError: (error) => {
                toast.error(error.message);
            },
        });
}