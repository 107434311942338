import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
    Box,
    Typography,
    Menu,
    MenuItem,
    ListItemIcon,
    ListItemText,
    Divider,
    Tooltip,
    styled,
    keyframes
} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';

const pulseBorder = keyframes`
    0% {
        box-shadow: 0 0 0 0 rgba(29, 210, 175, 0.7);
    }
    70% {
        box-shadow: 0 0 0 6px rgba(29, 210, 175, 0);
    }
    100% {
        box-shadow: 0 0 0 0 rgba(29, 210, 175, 0);
    }
`;

const HighlightedMenuItem = styled(MenuItem)(({ theme, highlight }) => ({
    backgroundColor: 'inherit',
    color: 'inherit',
    position: 'relative',
    overflow: 'visible',
    '&::before': {
        content: highlight ? "''" : 'none',
        position: 'absolute',
        top: -1,
        left: -1,
        right: -1,
        bottom: -1,
        borderRadius: theme.shape.borderRadius,
        border: `1px solid ${theme.palette.primary.light}`,
        animation: highlight ? `${pulseBorder} 1.5s infinite` : 'none',
    },
    '&:hover': {
        backgroundColor: theme.palette.action.hover,
    },
}));

export default function ValidationMenu({
                                           onConvertValidation,
                                           onRequestDocument,
                                           onDownloadDocument,
                                           onPreviewDocument,
                                           documentID
                                       }) {
    const [anchorEl, setAnchorEl] = useState(null);
    const { t } = useTranslation();
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleMenuItemClick = (action, disabled = false) => {
        if (!disabled) {
            action();
            handleClose();
        }
    };

    const tooltipTitle = t('validation_summary.document_required');

    return (
        <Box sx={{ mb: 1 }} className="tour-validation-summary-convert">
            <Box
                onClick={handleClick}
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '4px',
                    cursor: 'pointer',
                    '&:hover': {
                        opacity: 0.8,
                    },
                }}
            >
                <Typography variant="button" noWrap>
                    {t('validation_summary.actions')}
                </Typography>
                <KeyboardArrowDownIcon
                    fontSize="small"
                    sx={{
                        transition: 'transform 0.3s ease-in-out',
                        transform: open ? 'rotate(180deg)' : 'rotate(0)',
                    }}
                />
            </Box>
            <Menu
                id="validation-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'validation-menu-button',
                }}
            >
                <MenuItem onClick={() => handleMenuItemClick(onConvertValidation)}>
                    <ListItemIcon>
                        <HomeOutlinedIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>{t('validation_summary.convert_to_oikos')}</ListItemText>
                </MenuItem>
                <Divider />
                <HighlightedMenuItem
                    onClick={() => handleMenuItemClick(onRequestDocument)}
                    highlight={!documentID}
                >
                    <ListItemIcon>
                        <DescriptionOutlinedIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText
                        primary={t('validation_summary.request_offer_document')}
                    />
                </HighlightedMenuItem>
                <Tooltip title={!documentID ? tooltipTitle : ''} arrow>
                    <span>
                        <MenuItem
                            onClick={() => handleMenuItemClick(onDownloadDocument, !documentID)}
                            disabled={!documentID}
                        >
                            <ListItemIcon>
                                <CloudDownloadOutlinedIcon fontSize="small" />
                            </ListItemIcon>
                            <ListItemText>{t('validation_summary.download_offer_document')}</ListItemText>
                        </MenuItem>
                    </span>
                </Tooltip>
                <Tooltip title={!documentID ? tooltipTitle : ''} arrow>
                    <span>
                        <MenuItem
                            onClick={() => handleMenuItemClick(onPreviewDocument, !documentID)}
                            disabled={!documentID}
                        >
                            <ListItemIcon>
                                <VisibilityOutlinedIcon fontSize="small" />
                            </ListItemIcon>
                            <ListItemText>{t('validation_summary.preview_offer_document')}</ListItemText>
                        </MenuItem>
                    </span>
                </Tooltip>
            </Menu>
        </Box>
    );
}