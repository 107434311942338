import {Stack} from "@mui/material";
import {FilterDrawer, PropertySearch} from "../common/";
import {ValidationFilter} from "./validation-filter";
import {useFiltersContext} from "../context";
import {MIN_SEARCH_LENGTH} from "../search-filter-config";

export function ValidationsSearchFilterBar() {
    const filterContext = useFiltersContext();


    const handleSearch = (criteria) => {
        const filter = {
            id: "search",
            fn: (item) => item?.address?.toLowerCase().includes(criteria) || item?.reference?.toLowerCase().includes(criteria),
            meta: criteria
        };
        filterContext.onRemoveFilter(filter);
        if (criteria.length > MIN_SEARCH_LENGTH) {
            filterContext.onAddFilter(filter);
        }
    }

    const handleClear = () => {
        filterContext.onRemoveFilter({id: "search"});
    }

    return (
        <>
            <Stack
                direction="row"
                spacing={2}
                sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    margin: "10px 0"
                }}>
                <PropertySearch handleSearch={handleSearch} handleClear={handleClear}/>
                <FilterDrawer>
                    <ValidationFilter/>
                </FilterDrawer>
            </Stack>
        </>
    );
}