import React, {useState} from "react";
import AppPage from "../../common/AppPage";
import PageTitle from "../../components/titles";
import {useTranslation} from "react-i18next";
import {IconButton, Step, Stepper} from "@mui/material";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import {ValidationParametersContext} from "../parameters/ParametersContext";
import {
    buildParametersRequest,
    defaultParameters,
    HoldingCostStep,
    InvestmentCostStep,
    MortgageStep
} from "../parameters";
import {DescribeValidationStep, ValidationReviewStep} from "./steps";
import ROUTES from "../../routes";
import {useResponsive} from "../../hooks/use-responsive";
import {useValidationStorer} from "../../services/validationRequest/validation-storer";
import {useNavigate} from "react-router-dom";
import {show_confetti} from "../../components/confetti";
import {PondAwardedDialog} from "../../common/PondAwardedDialog";
import {WClear} from "../../components/icons/walden-icons";


export default function AddValidation() {
    const {t} = useTranslation();
    const [activeStep, setActiveStep] = useState(0);
    const [cadastre, setCadastre] = useState("");
    const navigate = useNavigate();
    const [cadastreIsValid, setCadastreIsValid] = useState(false);
    const [validationParameters, setValidationParameters] = useState(defaultParameters());
    const [showPondDialog, setShowPondDialog] = useState(false);
    const [pondAmountAwarder, setPondAmountAwarded] = useState(0)
    const lgDown = useResponsive('down', 'lg');

    const handleStepChange = (step) => {
        setActiveStep(step);
    };

    const goToValidationList = () => {
        navigate(ROUTES.validations.list);
    }

    const {mutate: storeValidationReq} = useValidationStorer((response) => {
        const awardedAmount = Number(response?.awarded_pond_amount)
        if (awardedAmount > 0) {
            show_confetti();
            setShowPondDialog(true);
            setPondAmountAwarded(awardedAmount);
        } else {
            navigate(ROUTES.validations.list);
        }
    });
    const handleSave = () => {
        const validationData = buildParametersRequest(validationParameters, {
            validation_request: {
                cadastre_id: cadastre,
            },
        });
        storeValidationReq(validationData)
    };
    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleReset = () => {
        setCadastre("");
        setCadastreIsValid(false);
        setValidationParameters(defaultParameters());
        setActiveStep(0);
    };

    const steps = [
        <DescribeValidationStep handleNext={handleNext} cadastre={cadastre} setCadastre={setCadastre}
                                cadastreIsValid={cadastreIsValid} setCadastreIsValid={setCadastreIsValid}/>,
        <InvestmentCostStep handleNext={handleNext} handleBack={handleBack}/>,
        <MortgageStep handleNext={handleNext} handleBack={handleBack}/>,
        <HoldingCostStep handleNext={handleNext} handleBack={handleBack}/>,
        <ValidationReviewStep handleNext={handleSave} handleBack={handleBack} cadastre={cadastre}
                              handleStepChange={handleStepChange}/>
    ];

    const headerContent = (
        <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%'}}>
            <PageTitle>{t("validation_list.add_validation.title")}</PageTitle>
            <IconButton onClick={() => navigate(ROUTES.validations.list)}>
                <WClear/>
            </IconButton>
        </div>
    );

    return <AppPage topBarContent={headerContent}>
        {!lgDown && <PageTitle>{t("validation_list.add_validation.title")}</PageTitle>}
        <ValidationParametersContext.Provider value={{validationParameters, setValidationParameters}}>
            <Stepper activeStep={activeStep} steps={steps} handleNext={handleNext} handleBack={handleBack}
                     orientation="vertical">
                {steps.map((step, index) => <Step key={index}> {step}</Step>)}
            </Stepper>
        </ValidationParametersContext.Provider>
        {activeStep === steps.length && <Paper square elevation={0}>
            <Typography>{t("validation_list.add_validation.success_message")}</Typography>
            <Button onClick={handleReset} sx={{mt: 1, mr: 1}}>
                {t("validation_list.add_validation.reset")}
            </Button>
        </Paper>}
        <PondAwardedDialog open={showPondDialog} amountAwarded={pondAmountAwarder}
                           tittle={t("validation_list.add_validation.pond_awarded_dialog_title")}
                           nextButtonLabel={t("validation_list.add_validation.go_to_validation_list_cta")}
                           message={t("validation_list.add_validation.ponds_awarded_message")}
                           onClick={goToValidationList}/>
    </AppPage>
}