import React, {useState} from "react";
import {useTranslation} from 'react-i18next';
import ProfitabilityView from "./profitability-view";
import MortgageView from "./mortgage-view";
import Box from "@mui/material/Box";
import {Mixpanel} from "../../metrics/mixpanel";
import {IconButton, Menu, MenuItem, useTheme} from '@mui/material';
import PageTitle from "../../components/titles";
import {WDrop} from "../../components/icons/walden-icons";
import {ValidationFinancialTour} from "../../components/tour";

function FinancialView({data = {}}) {
    const {t} = useTranslation();
    const theme = useTheme();
    const [activeView, setActiveView] = useState('profitability');
    const [anchorEl, setAnchorEl] = useState(null);
    const [currentTitle, setCurrentTitle] = useState(
        <PageTitle>{t("financial.profitability.profitability")}</PageTitle>);
    const financialData = data?.financial;

    const handleViewChange = (view) => {
        Mixpanel.track(`Financial Validation: Switched to ${view} View`, {
            'Validation Request ID': financialData.ID,
        });
        setActiveView(view);
        handleMenuClose();
        setCurrentTitle(view === 'profitability'
            ? <PageTitle>{t("financial.profitability.profitability")}</PageTitle>
            : <PageTitle>{t('financial.mortgage.mortgage')}</PageTitle>
        );
    }

    const renderFinancialView = () => {
        switch (activeView) {
            case 'profitability':
                return <ProfitabilityView data={data}/>
            case 'mortgage':
                return <MortgageView data={financialData}/>
            default:
                return <ProfitabilityView data={data}/>
        }
    }

    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };


    return <>
        <ValidationFinancialTour/>
        <Box sx={{width: "100%"}} className="tour-validation-financial-description">

            <Box sx={{display: 'flex', alignItems: 'center', mt: 2}}>
                {currentTitle}
                <IconButton
                    className="tour-validation-financial-change"
                    aria-controls="simple-menu"
                    aria-haspopup="true"
                    onClick={handleMenuOpen}
                >
                    <WDrop color={theme.palette.primary.main}/>
                </IconButton>
            </Box>
            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
            >
                <MenuItem onClick={() => handleViewChange('profitability')}>
                    {t("financial.profitability.profitability")}
                </MenuItem>
                <MenuItem onClick={() => handleViewChange('mortgage')}>
                    {t('financial.mortgage.mortgage')}
                </MenuItem>
            </Menu>
            {renderFinancialView()}
        </Box>
    </>
}

export default FinancialView;