import {WAi} from "./walden-icons";
import Tooltip from "@mui/material/Tooltip";
import React from "react";
import {useTranslation} from "react-i18next";


export function AiTooltipIcon() {
    const {t} = useTranslation();

    return <Tooltip title={t("general.ai")}
                    interactive
                    enterTouchDelay={0}
                    arrow
                    sx={{ml: 2}}> {/* */}
        <WAi color="secondary.main" width="20px"/>
    </Tooltip>
}
