import {useCallback, useMemo} from 'react';
import {ListItem, Typography, useTheme} from "@mui/material";
import {useLocation, useNavigate} from "react-router-dom";
import {useSettingsContext} from "../settings";
import {useResponsive} from "../../hooks/use-responsive";
import {alpha} from "@mui/material/styles";
import Box from "@mui/material/Box";

export function NavigationItem({icon, label, url}) {
    const settings = useSettingsContext();
    const navigate = useNavigate();
    const location = useLocation();
    const {palette} = useTheme();
    const lgDown = useResponsive("down", "lg");
    const isMenuOpen = settings.open;

    const isSelected = useMemo(() => location.pathname.startsWith(url), [location, url]);

    const color = useMemo(() => isSelected ? palette.primary.light : palette.text.secondary, [isSelected, palette]);

    const stackDirection = isMenuOpen || lgDown ? "row" : "column";

    const handleClick = useCallback(() => navigate(url), [navigate, url]);

    const listItemStyle = useMemo(() => ({
        display: 'flex',
        flexDirection: stackDirection,
        borderRadius: '8px',
        ...(stackDirection === 'column' && {
            justifyContent: 'center',
        }),
        '&:hover': {
            backgroundColor: alpha(palette.primary.main, 0.1),
            borderRadius: '8px',
            cursor: "pointer"
        },
        ...(isSelected && {
            backgroundColor: alpha(palette.primary.main, 0.1),
        }),
    }), [stackDirection, isSelected, palette]);

    return (
        <ListItem onClick={handleClick} sx={listItemStyle}>
            <Box color={color} sx={{
                marginBottom: stackDirection === 'column' ? 1 : 0,
                marginRight: stackDirection === 'column' ? 0 : 1,
                display: "flex",


            }}>
                {icon}
            </Box>
            <Typography
                variant="subtitle"
                color={color}
                sx={{
                    textAlign: stackDirection === 'column' ? 'center' : 'left',
                }}
            >
                {label}
            </Typography>
        </ListItem>
    );
}
