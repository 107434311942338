import {useAuth0} from "@auth0/auth0-react";
import Avatar from "@mui/material/Avatar";
import React from "react";

export default function UserAvatar({onClick, sx}) {
    const {user} = useAuth0();

    return (
        <Avatar
            src={user.picture}
            alt={user.name}
            sx={{
                ...sx,
            }}
            onClick={onClick}
        />
    );
}
