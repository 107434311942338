import {useTranslation} from "react-i18next";
import {Tour} from "./tour";

export function OikosDetailTour() {
    const {t} = useTranslation();

    const tourSteps = [
        {
            target: '.tour-oikos-details-details',
            content: t("tour.oikos_details.details"),
        },
        {
            target: '.tour-oikos-details-profitability',
            content: t("tour.oikos_details.profitability"),
        },
        {
            target: '.tour-oikos-details-metrics',
            content: t("tour.oikos_details.metrics"),
        },
        {
            target: '.tour-properties-patrimony',
            content: t("tour.oikos_details.patrimony"),
        },
        {
            target: '.tour-oikos-details-cashflow-chart',
            content: t("tour.oikos_details.cashflow"),
        },
        {
            target: '.tour-oikos-details-expenses-chart',
            content: t("tour.oikos_details.holding_cost"),
        },
    ];

    return <Tour steps={tourSteps} tourKey='oikos_detail'/>
}