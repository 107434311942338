import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {Divider} from "@mui/material";

export function TourContent({title, description, priceNote}) {
    return (
        <Box style={{textAlign: 'left'}}>

            <Typography variant="h5" style={{marginBottom: '5px'}}>
                {title}
            </Typography>
            <Divider variant={"middle"} sx={{marginBottom: '20px'}}/>
            <Typography variant="body1">
                {description}
            </Typography>
            {priceNote && (
                <Typography variant="caption" color="textSecondary" style={{marginTop: '10px'}}>
                    {priceNote}
                </Typography>
            )}
        </Box>
    );
}