import React, {useEffect, useState} from 'react';
import {useAuth0} from '@auth0/auth0-react';
import {Outlet, useNavigate} from 'react-router-dom';
import {jwtDecode} from "jwt-decode";
import {SplashScreen} from "./components/loading-screen";
import ROUTES from "./routes";
import {authorizationScope} from "./auth0Config";

const ProtectedRoute = () => {
    const auth0Context = useAuth0();
    const navigate = useNavigate();
    const [scopes, setScopes] = useState("");
    const [isTokenLoaded, setIsTokenLoaded] = useState(false);

    useEffect(() => {
        const fetchTokens = async () => {
            if (auth0Context.isAuthenticated) {
                try {
                    const token = await auth0Context.getAccessTokenSilently();
                    const tokenPayload = jwtDecode(token);
                    setScopes(tokenPayload.scope);
                } catch (e) {
                    console.error("error", e);
                } finally {
                    setIsTokenLoaded(true);
                }
            }
        }
        fetchTokens();
        if (!auth0Context.isLoading && !auth0Context.isAuthenticated) {
            navigate(ROUTES.onboarding.intro, {state: {skipLoginRedirect: true}})
        }
    }, [auth0Context]);

    if (auth0Context.isLoading || !isTokenLoaded) {
        return <SplashScreen/>;
    }

    const renderApp = () => {
        if (scopes && scopes.includes(authorizationScope)) {
            return <Outlet/>
        } else {
            navigate("/403");
            return null;
        }
    }

    return renderApp();
};
export default ProtectedRoute;
