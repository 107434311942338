import React from 'react';
import Box from "@mui/material/Box";
import {BaseCard, CardContainer} from "../../components/cards";
import {Button, Chip, Grid, useTheme} from "@mui/material";
import Typography from "@mui/material/Typography";
import {useTranslation} from "react-i18next";
import {format} from "date-fns";
import {es} from 'date-fns/locale';
import {useAuth0} from "@auth0/auth0-react";
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import DataField from "../../components/data-field";
import {FormatCurrency} from "../../utils/format-number";
import {useResponsive} from "../../hooks/use-responsive";
import BenefitsCard from "../../components/cards/benefits-card";

const CUSTOMER_PORTAL_URL = process.env.REACT_APP_STRIPE_CUSTOMER_PORTAL_URL

const PricingBoxes = ({boxesData, subscriptionData, t}) => {
    const theme = useTheme();
    const auth0Context = useAuth0();
    const isXs = useResponsive("only", "xs");
    const isSm = useResponsive("only", "sm");
    const isMd = useResponsive("only", "md");

    const tierToIndex = {
        'basic': 0,
        'basic_y': 0,
        'advanced': 1,
        'advanced_y': 1,
        'pro': 2,
        'pro_y': 2
    };

    const defaultSelectedBoxIndex = tierToIndex[subscriptionData?.tier_name.toLowerCase()] ?? null;


    const startDate = subscriptionData?.start_date
        ? format(new Date(subscriptionData?.start_date), 'dd MMM yyyy', {locale: es})
        : '';

    const expiresAt = subscriptionData?.expires_at
        ? format(new Date(subscriptionData?.expires_at), 'dd MMM yyyy', {locale: es})
        : '';
    const planStatusLabel = subscriptionData?.status === 'active' ? t('user_profile.current_plan') : t('user_profile.trial_plan')

    return (
        <BaseCard sx={{alignItems: 'center', justifyContent: 'center'}}>
            <CardContainer sx={{justifyContent: 'center'}}>
                {boxesData.map((box, index) => (
                    <Box
                        key={index}
                        sx={{
                            position: 'relative',
                            minWidth: '200px',
                            maxWidth: isXs ? '400px' : isSm ? '180px' : isMd ? '240px' : '250px',
                            borderRadius: 2,
                            padding: 4,
                            border: index === defaultSelectedBoxIndex ? `2px solid ${theme.palette.primary.main}` : 'none',
                            transition: 'border-color 0.3s'
                        }}
                    >
                        {index === defaultSelectedBoxIndex && (
                            <Chip label={planStatusLabel} color="primary" size="small"
                                  sx={{
                                      position: 'absolute',
                                      top: 8,
                                      left: 8,
                                      border: "1px dashed secondary.main",
                                  }}/>
                        )}
                        <Typography variant="h4" color={theme.palette.secondary.main}
                                    sx={{mt: 1}}>{t(`user_profile.${box.title.toLowerCase()}_title`)}</Typography>
                        <Typography variant="h4" sx={{mt: 2}}>{FormatCurrency(box.price)}</Typography>
                        <Box sx={{display: 'flex', flexDirection: 'column'}}>
                            <Typography variant="body2">{t(`user_profile.${subscriptionData?.period}`)}</Typography>
                            {index === defaultSelectedBoxIndex && (
                                <Typography variant="caption" color="text.secondary">
                                    {startDate} - {expiresAt}
                                </Typography>
                            )}
                        </Box>
                        <Box sx={{mt: 4}}>
                            {box.bullets.map((bullet, bulletIndex) => (
                                <DataField
                                    sx={{mt: 1}}
                                    key={bulletIndex}
                                    icon={<CheckRoundedIcon sx={{color: theme.palette.primary.main, width: "12px"}}/>}
                                    valueColor="text.primary"
                                    value={bullet}
                                    valueVariant="caption"
                                />
                            ))}
                        </Box>
                    </Box>
                ))}
            </CardContainer>
            <Button
                variant="contained"
                color="primary"
                href={genEmailPrefilledCustomerPortalURL(auth0Context)}
                target="_blank"
                rel="noreferrer"
                sx={{mt: 1}}
            >
                {t('user_profile.modify_subscription')}
            </Button>
        </BaseCard>
    );
};

function genEmailPrefilledCustomerPortalURL(auth0Context) {
    return `${CUSTOMER_PORTAL_URL}?prefilled_email=${auth0Context.user.email}`;
}

export function SubscriptionDetail({subscriptionData}) {
    const {t} = useTranslation();
    const mdDown = useResponsive("down", "md");

    const boxesData = subscriptionData?.period === 'yearly' ? [
        {
            title: 'basic',
            price: '30',
            bullets: t('user_profile.basic_bullets')
        },
        {
            title: 'advance',
            price: '80',
            bullets: t('user_profile.advance_bullets')
        },
        {
            title: 'pro',
            price: '150',
            bullets: t('user_profile.pro_bullets')
        }
    ] : [
        {
            title: 'basic',
            price: '3',
            bullets: t('user_profile.basic_bullets')
        },
        {
            title: 'advance',
            price: '8',
            bullets: t('user_profile.advance_bullets')
        },
        {
            title: 'pro',
            price: '15',
            bullets: t('user_profile.pro_bullets')
        }
    ];

    return (
        <Grid item xs={12}>
            <Box sx={{
                display: "flex",
                justifyContent: "space-between",
                flexDirection: mdDown ? 'column' : 'row',
                gap: 2
            }}>
                <Box sx={{maxHeight: "300px"}}>
                    <BenefitsCard/>
                </Box>
                <PricingBoxes boxesData={boxesData} subscriptionData={subscriptionData} t={t}/>
            </Box>
        </Grid>

    )
        ;
}
