export * from "./parameter-steps";
export * from "./request"

export const defaultParameters = () => {
    return {
        maintenance: 960,
        empty_period: 480,
        unpaid_insurance: 196,
        garbage_tax: 98,
        home_insurance: 250,
        life_insurance: 90,
        community_fees: 250,
        property_tax_ibi: 180,
        property_other_cost: 0,
        property_value: 100000,
        agency_commission: 2000,
        notary_valuation: 2000,
        property_renovation: 0,
        investment_other_cost: 0,
        years: 30,
        financed_percentage: 80,
        type: 1,
        interest: 3,
        differential: 0,
        euribor: 0,
        isMortgageValid: true
    }
}