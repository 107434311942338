import React from 'react';
import Chart from 'react-apexcharts';
import {Card, Skeleton} from "@mui/material";
import {useResponsive} from "../../hooks/use-responsive";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {useTheme} from "@mui/material/styles";
import {FormatCompactCurrency, FormatPercentage} from "../../utils/format-number";
import {useTranslation} from "react-i18next";


export function DashboardChart({data, isLoading, classname}) {
    const mdDown = useResponsive('down', 'md');
    const theme = useTheme();
    const {t} = useTranslation();



    if (isLoading) {
        return (
            <Card sx={{ padding: mdDown ? '15px' : '24px' }}>
                <Typography variant="h6" sx={{ alignSelf: "left", mt: mdDown ? -1 : 0, mb: 1 }}>
                    {t("dashboard.chart_title")}
                </Typography>
                <Box position="relative" height={300} display="flex" justifyContent="center" alignItems="center">
                    <Skeleton variant="rectangular" width="100%" height="100%" sx={{ borderRadius: '16px' }} />
                </Box>
            </Card>
        );
    }


    if (!data || !data.roi || !data.cashflow || !data.patrimony) {
        return (
            <Card sx={{ padding: mdDown ? '15px' : '24px' }}>
                <Typography variant="h6" sx={{ alignSelf: "left", mt: mdDown ? -1 : 0, mb: 1 }}>
                    {t("dashboard.chart_title")}
                </Typography>
                <Box display="flex" justifyContent="center" alignItems="center" height="350px">
                    <Typography variant="h6" color="textSecondary">
                        {t("dashboard.no_data_chart")}
                    </Typography>
                </Box>
            </Card>
        );
    }

    const series = [
        {
            name: 'ROI',
            data: data?.roi?.map(data => ({x: data.date, y: data.value}))
        },
        {
            name: 'Cashflow',
            data: data?.cashflow?.map(data => ({x: data.date, y: data.value}))
        },
        {
            name: 'Patrimony',
            data: data?.patrimony?.map(data => ({x: data.date, y: data.value}))
        },
    ];

    const options = {
        chart: {
            type: 'area',
            stacked: false,
            height: 350,
            zoom: {
                enabled: true,
                type: 'x',
                autoScaleYaxis: true,
            },
            toolbar: {
                show: true,
                tools: {
                    download: false,
                    selection: false,
                    zoom: true,
                    zoomin: true,
                    zoomout: true,
                    pan: true,
                    reset: true,
                },
            },
        },
        dataLabels: {
            enabled: false
        },
        markers: {
            size: 0,
        },
        fill: {
            type: 'gradient',
            gradient: {
                shadeIntensity: 1,
                inverseColors: false,
                opacityFrom: 0.5,
                opacityTo: 0,
                stops: [0, 90, 100]
            },
        },
        yaxis: [
            {
                show: false,
                opposite: true
            },
            {
                show: false
            },
            {
                show: false,
                opposite: true
            },
        ],
        xaxis: {
            show: false,
            type: 'datetime'
        },
        tooltip: {
            shared: true,
            intersect: false,
            y: {
                formatter: function (value, {seriesIndex, w}) {
                    const name = w.globals.seriesNames[seriesIndex];
                    return name === 'ROI'
                        ? FormatPercentage(value)
                        : FormatCompactCurrency(value);
                }
            }
        },
        annotations: {
            xaxis: isLoading ? [] : data?.event?.map(event => ({
                x: new Date(event.date).getTime(),
                strokeDashArray: 0,
                borderColor: theme.palette.secondary.main,
                label: {
                    borderColor: theme.palette.secondary.main,
                    style: {
                        color: theme.palette.secondary.contrastText,
                        background: theme.palette.secondary.main,
                    },
                    text: t(`dashboard.${event?.value}`),
                    textAnchor: 'middle',
                    offsetX: 9
                },
            })),
        },
    };

    return (
        <Card sx={{
            padding: mdDown ? '15px' : '24px',
        }}>
            <Typography variant="h6"
                        sx={{alignSelf: "left", mt: mdDown ? -1 : 0, mb: 1}}>{t("dashboard.chart_title")}</Typography>
            <Chart
                options={options}
                series={series}
                type="area"
                height={350}
            />
        </Card>
    )
        ;
}