import {QueryClient, QueryClientProvider} from "react-query";

import React from 'react';
import ReactDOM from 'react-dom/client';
import {BrowserRouter} from "react-router-dom";
import './index.css';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import './i18n/i18n';
import {CssBaseline} from "@mui/material";
import ThemeProvider from "./theme";
import {SettingsProvider} from "./components/settings";
import {Auth0ProviderWithNavigate} from "./auth0-provider-with-navigate";
import {ProfileProvider} from "./components/settings/context/ProfileContext";

const queryClient = new QueryClient();
const root = ReactDOM.createRoot(document.getElementById('root'));


root.render(
    <React.StrictMode>
        <BrowserRouter>
            <ThemeProvider>
                <QueryClientProvider client={queryClient}>
                    <Auth0ProviderWithNavigate>
                        <SettingsProvider defaultSettings={{
                            themeMode: 'light', // 'light' | 'dark'
                        }}>
                            <ProfileProvider>
                                <CssBaseline/>
                                <App/>
                            </ProfileProvider>
                        </SettingsProvider>
                    </Auth0ProviderWithNavigate>
                </QueryClientProvider>
            </ThemeProvider>
        </BrowserRouter>
    </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
