import Typography from "@mui/material/Typography";
import {useAuth0} from "@auth0/auth0-react";
import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import ROUTES from "../../routes";
import {PrimaryButton, TertiaryButton} from "../../components/buttons";
import Box from "@mui/material/Box";
import {BaseCard} from "../../components/cards";
import {useResponsive} from "../../hooks/use-responsive";
import Stack from "@mui/material/Stack";
import PageTitle from "../../components/titles";
import {OnboardingPage} from "./onboarding-page";
import {useTranslation} from "react-i18next";
import {useTheme} from "@mui/material/styles";
import Link from '@mui/material/Link';

export function OnboardingIntro() {
    const auth0Context = useAuth0();
    const navigate = useNavigate();
    const mdDown = useResponsive('down', 'md');
    const [step, setStep] = useState(0);
    const {t} = useTranslation();
    const theme = useTheme();
    const nextStep = () => {
        setStep(prev => (prev + 1 < stepsData.length ? prev + 1 : prev));
    }

    const prevStep = () => {
        setStep(prev => (prev - 1 >= 0 ? prev - 1 : prev));
    }

    const signUp = () => {
        auth0Context.loginWithRedirect({
            appState: {returnTo: ROUTES.onboarding.profile},
            authorizationParams: {
                screen_hint: "signup"
            }
        });
    }

    const stepsData = [
        {
            title: t("onboarding_intro.first_step.title"),
            description: t("onboarding_intro.first_step.description"),
            nextCTA: nextStep,
            nextCTALabel: t("onboarding_intro.next"),
            image: "https://cdn.walden.ai/media/img/app/illustrations/walden_pioneers_intro.svg"
        },
        {
            title: t("onboarding_intro.second_step.title"),
            description: t("onboarding_intro.second_step.description"),
            nextCTA: nextStep,
            nextCTALabel: t("onboarding_intro.next"),
            image: "https://cdn.walden.ai/media/img/app/illustrations/walde_validate.svg"
        },
        {
            title: t("onboarding_intro.third_step.title"),
            description: t("onboarding_intro.third_step.description"),
            nextCTA: signUp,
            nextCTALabel: t("onboarding_intro.start"),
            image: "https://cdn.walden.ai/media/img/app/illustrations/investment_tracking.svg"
        }
    ];

    const [currentStep, setCurrentStep] = useState(stepsData[step]);

    const renderTitle = (title) => {
        if (step === 0) {
            const parts = title.split("Walden");
            return (
                <PageTitle>
                    <span style={{color: theme.palette.primary.main}}>Walden</span>
                    {parts[1] ? ` ${parts[1]}` : ''}
                </PageTitle>
            );
        }
        return <PageTitle>{title}</PageTitle>;
    };

    useEffect(() => {
        setCurrentStep(stepsData[step]);
    }, [step]);

    const login = () => {
        auth0Context.loginWithRedirect();
    }

    useEffect(() => {
        if (auth0Context.isAuthenticated) {
            navigate(ROUTES.index);
        }
    }, [auth0Context, navigate]);

    const renderContent = () => {
        return (
            <OnboardingPage>
                <BaseCard
                    sx={{
                        padding: mdDown ? '1rem' : '2rem',
                        margin: "auto",
                        minHeight: mdDown ? 'auto' : '400px',
                        width: mdDown ? "350px" : "800px",
                        flexDirection: mdDown ? "column" : "row",
                        alignItems: "center",
                        justifyContent: "center",
                        display: 'flex',
                        backgroundColor: 'rgba(255, 255, 255, 0.6)'
                    }}
                >
                    <Box
                        sx={{
                            width: mdDown ? '100%' : '50%',
                            height: "auto",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <Box
                            component="img"
                            sx={{
                                maxWidth: '100%',
                                maxHeight: '100%',
                                objectFit: 'contain',
                            }}
                            src={currentStep.image}
                            alt="Onboarding step illustration"
                        />
                    </Box>
                    <Box
                        sx={{
                            width: mdDown ? '100%' : '50%',
                            height: "100%",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "start",
                            justifyContent: "center",
                            gap: "2rem",
                            padding: mdDown ? "1rem 0" : "0 0 0 2rem",

                        }}
                    >
                        <Box>
                            {renderTitle(currentStep.title)}
                            <Typography sx={{mt: 2}}>{currentStep.description}</Typography>
                        </Box>
                        <Stack direction="column" sx={{gap: '1rem', width: '100%'}}>
                            <Stack direction="row" sx={{
                                width: '100%',
                                justifyContent: step > 0 ? "space-between" : "flex-end",
                                alignItems: "center",
                            }}>
                                {step > 0 &&
                                    <TertiaryButton onClick={prevStep}>{t("onboarding_intro.back")}</TertiaryButton>}
                                <PrimaryButton onClick={currentStep.nextCTA}>
                                    {currentStep.nextCTALabel}
                                </PrimaryButton>
                            </Stack>
                        </Stack>
                        <Box id="privacy-policy-link" sx={{
                            display: 'flex',
                            width: '100%',
                            flexDirection: 'column',
                            alignItems: 'center',
                            marginTop: '1rem',
                        }}>
                            {step === 0 && (
                                <Stack direction='row' sx={{
                                    width: '100%',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    gap: '0.5rem'
                                }}>
                                    <Typography variant="caption">{t("onboarding_intro.first_step.ask")}</Typography>
                                    <TertiaryButton color="primary"
                                                    onClick={login}>{t("onboarding_intro.login")}</TertiaryButton>
                                </Stack>
                            )}
                            <Link
                                href="https://walden.ai/politica-de-privacidad/"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <Typography variant="caption" color="secondary" sx={{
                                    textDecoration: 'underline',
                                    cursor: 'pointer',
                                }}>
                                    {t("general.private_policy")}
                                </Typography>
                            </Link>
                        </Box>
                    </Box>
                </BaseCard>
            </OnboardingPage>
        );
    }

    return renderContent();
}