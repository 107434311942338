import {Box, Button, Card, ClickAwayListener, Divider, Grow, Popper, Stack} from "@mui/material";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import React, {useEffect, useState} from "react";
import {CalculationProfileOptionToggle, PeriodOptionToggle, PriceOptionToggle} from "./global-option-toggles";
import {useTranslation} from "react-i18next";
import {Mixpanel} from "../../../metrics/mixpanel";
import {useResponsive} from "../../../hooks/use-responsive";
import {useSettingsContext} from "../context";

export function OptionsBar() {
    const [visibleOptions, setVisibleOptions] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const {t} = useTranslation();
    const mdDown = useResponsive("down", "md");
    const smDown = useResponsive("down", "sm");
    const settings = useSettingsContext();


    useEffect(() => {
        const storedPriceOption = localStorage.getItem('useWaldenPrice');
        if (storedPriceOption !== null) {
            const useWaldenPrice = storedPriceOption === 'true';
            if (useWaldenPrice !== settings.useWaldenPrice) {
                useWaldenPrice ? settings.onSetWaldenPrice() : settings.onSetMarketPrice();
            }
        }

        const storedTimeOption = localStorage.getItem('useMonthlyPeriod');
        if (storedTimeOption !== null) {
            const useMonthlyPeriod = storedTimeOption === 'true';
            if (useMonthlyPeriod !== settings.useMonthlyPeriod) {
                useMonthlyPeriod ? settings.onSetMonthlyPeriod() : settings.onSetYearlyPeriod();
            }
        }

        const storedOption = localStorage.getItem('useOptimistProfile');
        if (storedOption !== null) {
            const useOptimistProfile = storedOption === 'true';
            if (useOptimistProfile !== settings.useOptimistProfile) {
                useOptimistProfile ? settings.onSetOptimistProfile() : settings.onSetPrudentProfile();
            }
        }
    }, [settings]);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
        setVisibleOptions((prevState) => {
            if (!prevState) {
                Mixpanel.track('Expanded Options Bar');
            } else {
                Mixpanel.track('Closed Options Bar');
            }
            return !prevState;
        });
    };

    const handleClose = () => {
        setAnchorEl(null);
        setVisibleOptions(false);
    };

    return (
        <Stack sx={{marginBottom: 1}}>
            <Stack direction="row" justifyContent="flex-start">
                <Button endIcon={<KeyboardArrowDownIcon/>} onClick={handleClick}>
                    {t("general.options")}
                </Button>
            </Stack>
            <Popper
                open={visibleOptions}
                anchorEl={anchorEl}
                transition
                disablePortal
                style={{zIndex: 1}}
            >
                {({TransitionProps}) => (
                    <Grow {...TransitionProps}>
                        <Card sx={{marginLeft: smDown ? 2.5 : 3.5, marginTop: 1.5}}>
                            <ClickAwayListener onClickAway={handleClose}>
                                <Box sx={{p: 2}}>
                                    <Stack
                                        direction={mdDown ? 'column' : 'row'}
                                        spacing={2}
                                        divider={
                                            <Divider
                                                orientation={mdDown ? 'horizontal' : 'vertical'}
                                                flexItem
                                                sx={{borderStyle: "dashed"}}
                                            />
                                        }
                                    >
                                        <PriceOptionToggle/>
                                        <PeriodOptionToggle/>
                                        <CalculationProfileOptionToggle/>
                                    </Stack>
                                </Box>
                            </ClickAwayListener>
                        </Card>
                    </Grow>
                )}
            </Popper>
        </Stack>
    );
}