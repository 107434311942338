import {BaseCard} from "./index";
import {CardTitle} from "../titles";
import DataField from "../data-field";
import {FormatCurrency, FormatPercentage} from "../../utils/format-number";
import {useTranslation} from "react-i18next";

export function PatrimonyCard({data, sx}) {
    const {t} = useTranslation();

    const differentialValue = data?.financial?.roi?.patrimony - data?.financial?.roi?.investment_cost?.property_value;

    return <BaseCard sx={{
        ...sx,
        maxWidth: "330px"
    }}>
        <CardTitle>{t("oikos.patrimony.patrimony")}</CardTitle>
        <DataField
            direction="column"
            label={t("oikos.patrimony.actual_property_value")}
            value={FormatCurrency(data?.financial?.roi?.investment_cost?.property_value)}
            valueVariant="h6"
            valueColor="text.primary"
        />
        <DataField
            direction="column"
            label={t("oikos.detail.estimated_property_value")}
            value={FormatCurrency(data?.financial?.roi?.patrimony)}
            valueVariant="h6"
            valueColor="text.primary"
        />
        <DataField
            direction="column"
            label={t("oikos.patrimony.differential_value")}
            value={FormatCurrency(differentialValue)}
            valueVariant="h6"
            valueColor={differentialValue > 0 ? "success.main" : differentialValue < 0 ? "error.main" : "text.primary"}
        />
        <DataField
            direction="column"
            label={t("oikos.patrimony.patrimony_contribution")}
            value={FormatPercentage(data?.financial?.roi?.patrimony_contribution)}
            valueVariant="h6"
            valueColor="text.primary"
        />
    </BaseCard>
}