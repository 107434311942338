import Box from "@mui/material/Box";
import React from "react";
import {useTranslation} from "react-i18next";
import ROUTES from "../../routes";
import {PrimaryButton} from "../../components/buttons";
import {useNavigate} from "react-router-dom";
import {BaseCard} from "../../components/cards";
import {OnboardingPage} from "../onboarding/onboarding-page";
import PageTitle from "../../components/titles";
import {Typography} from "@mui/material";
import {useResponsive} from "../../hooks/use-responsive";

export function Page403() {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const mdDown = useResponsive('down', 'md');

    const handleLogout = () => {
        navigate(ROUTES.logout);
    }

    return (
        <OnboardingPage>
            <BaseCard
                sx={{
                    width: mdDown ? "300px" : "800px",
                    justifyContent: "center",
                    alignItems: "start",
                    flexDirection: mdDown ? "column" : "row"
                }}
            >
                <Box
                    sx={{
                        width: mdDown ? '100%' : '50%',
                        height: "auto",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <Box
                        component="img"
                        alt="403"
                        src="https://cdn.walden.ai/media/img/app/403.svg"
                        sx={{
                            maxWidth: '100%',
                            maxHeight: '100%',
                            objectFit: 'contain',
                        }}
                    />
                </Box>
                <Box
                    sx={{
                        width: mdDown ? '100%' : '50%',
                        height: "100%",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "start",
                        justifyContent: "center",
                        gap: "2rem",
                        padding: mdDown ? "1rem 0" : "0 0 0 2rem",
                    }}
                >
                    <Box sx={{display: "flex", flexDirection: "row", justifyContent: "center"}}>
                        <PageTitle>
                            {t("feedback.error.access_denied")}
                        </PageTitle>
                    </Box>
                    <Typography variant="body1">
                        {t("feedback.error.403")}
                    </Typography>
                    <PrimaryButton onClick={handleLogout}>
                        {t("general.logout")}
                    </PrimaryButton>
                </Box>
            </BaseCard>
        </OnboardingPage>
    );
}