import {useCallback, useState} from "react";
import {FilterContext} from "./filter-context";

const RESENT_FIRST = 1;

export function FiltersProvider({children}) {
    const [filters, setFilters] = useState([]);
    const [dateSort, setDateSort] = useState(RESENT_FIRST);

    const resetFilters = useCallback(() => {
        setFilters([])
    }, []);

    const addFilter = useCallback((filter) => {
        setFilters(prev => [...prev, filter])
    }, []);

    const removeFilter = useCallback((filter) => {
        setFilters(prev => prev.filter(f => f.id !== filter.id))
    }, []);

    const removeAllFilters = useCallback(() => {
        setFilters([]);
    }, []);

    const value = {
        filters,
        dateSort,
        onAddFilter: addFilter,
        onSetDateSort: setDateSort,
        onRemoveFilter: removeFilter,
        onResetFilters: resetFilters,
        onRemoveAllFilters: removeAllFilters,
    }

    return <FilterContext.Provider value={value}>{children}</FilterContext.Provider>
}