import {Card, CardContent, Checkbox, FormControlLabel} from "@mui/material";
import React, {useEffect, useState} from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Collapse from "@mui/material/Collapse";
import {useTheme} from "@mui/material/styles";
import {EditValueButton} from "../../components/buttons";
import DataField from "../../components/data-field";
import {WHouseExclamation, WPaint, WPerson, WRepair, WVerified} from "../../components/icons/walden-icons";
import {FormatCompactCurrency} from "../../utils/format-number";
import {useTranslation} from "react-i18next";
import {Mixpanel} from "../../metrics/mixpanel";

const INTEGRAL = "INTEGRAL";
const FACELIFT = "FACELIFT";
const REPAIR = "REPAIR";
const CUSTOM = "CUSTOM";
const OPTIMAL = "OPTIMAL";

export const selectedOptionDescription = {
    OPTIMAL: "En condiciones",
    FACELIFT: "Lavado de cara",
    REPAIR: "Necesita arreglos",
    CUSTOM: "Personalizado",
    INTEGRAL: "Reconstrucción Integral",
}

export default function ChecklistItem({
                                          icon,
                                          title,
                                          text,
                                          itemData,
                                          facelift,
                                          arrangements,
                                          integral,
                                          onSelectedValueChange
                                      }) {
    const theme = useTheme();
    const [expanded, setExpanded] = useState(false);
    const [selectedValue, setSelectedValue] = useState({
        category: itemData.category, value: itemData.value,
    });
    const [dialogOpen, setDialogOpen] = useState(false);
    const [dialogCustomValue, setDialogCustomValue] = useState("0");
    const {t} = useTranslation();


    useEffect(() => {
        itemData.value = selectedValue.value;
        itemData.category = selectedValue.category;
        onSelectedValueChange(itemData);
    }, [selectedValue, onSelectedValueChange]);


    useEffect(() => {
        const handleClickOutside = (event) => {
            if (cardRef.current && !cardRef.current.contains(event.target)) {
                setExpanded(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);


    const cardRef = React.useRef(null);

    const doSetSelectedValue = (category, value) => {
        if (value === 0) {
            category = OPTIMAL
        }
        setSelectedValue({
            category: category, value: value
        })
    }

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    const getValueForCategory = (category) => {
        const categoryData = itemData.values_by_category.find((item) => item.category === category);
        return categoryData ? categoryData.value : 0;
    };

    const handleOptionChange = (event) => {
        const _targetCategory = event.target.value;
        if (_targetCategory === CUSTOM) {
            Mixpanel.track('Visual Validation: Opened Personalized Checklist Item Edit', {"Item Id": itemData.check_list_item_id});
            setDialogOpen(true);
        } else {
            Mixpanel.track('Visual Validation: Changed Checklist Item Option', {
                "Item Id": itemData.check_list_item_id,
                "Selected Option": _targetCategory,
                "Selected Option Value": getValueForCategory(_targetCategory),
            });
            doSetSelectedValue(_targetCategory, getValueForCategory(_targetCategory));
        }
    };

    const handleContentClick = (event) => {
        event.stopPropagation();
    };

    const handleDialogSaveClick = () => {
        Mixpanel.track('Visual Validation: User saved custom value', {
            "Item Id": itemData.check_list_item_id, "Custom Value": parseFloat(dialogCustomValue),
        });
        doSetSelectedValue(CUSTOM, parseFloat(dialogCustomValue))
        setDialogOpen(false);
        setExpanded(false);
    };

    const handleTextFieldChange = (event) => {
        setDialogCustomValue(event.target.value);
    };

    return <Card onClick={handleExpandClick} ref={cardRef}>
        <Box sx={{display: "flex", alignItems: "center", padding: 1}}>
            <Box sx={{mr: 1, display: "flex", alignItems: "center", justifyContent: "center"}}>
                {React.cloneElement(icon, {
                    style: {
                        height: "60px", width: "60px", color: theme.palette.primary.dark
                    }
                })}
            </Box>
            <Box sx={{paddingLeft: 2}}>
                <Typography variant="h5" component="h2">{title}</Typography>
                <Typography variant="body1">{text}</Typography>
            </Box>
        </Box>
        <Box sx={{display: "flex", alignItems: "center", padding: 1}}>
            <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start'}}>
                <Box sx={{display: 'flex', alignItems: 'center'}}>
                    {selectedValue.category === OPTIMAL && (
                        <WVerified color={theme.palette.primary.dark} sx={{mr: 1, height: 24, width: 24}}/>)}
                    {selectedValue.category === FACELIFT && (
                        <WPaint color={theme.palette.primary.dark} sx={{mr: 1, height: 24, width: 24}}/>)}
                    {selectedValue.category === REPAIR && (
                        <WRepair color={theme.palette.primary.dark} sx={{mr: 1, height: 24, width: 24}}/>)}
                    {selectedValue.category === INTEGRAL && (<WHouseExclamation color={theme.palette.primary.dark}
                                                                                sx={{mr: 1, height: 24, width: 24}}/>)}
                    {selectedValue.category === CUSTOM && (
                        <WPerson color={theme.palette.primary.dark} sx={{mr: 1, height: 24, width: 24}}/>)}
                    <DataField
                        label={t('visual_validation.state')}
                        labelVariant="body2"
                        valueVariant="body2"
                        labelColor="text.primary"
                        value={selectedOptionDescription[selectedValue.category]}
                    />
                </Box>
                <Box sx={{display: 'flex', alignItems: 'center', mt: 1, marginLeft: 3}}>
                    <Typography variant="subtitle1">
                        + {FormatCompactCurrency(selectedValue.value)}
                    </Typography>
                    <EditValueButton
                        isChecklist
                        value={dialogCustomValue}
                        onDialogSave={handleDialogSaveClick}
                        onTextFieldChange={handleTextFieldChange}
                    />
                </Box>
            </Box>
        </Box>

        <Collapse in={expanded} timeout="auto" unmountOnExit>
            <CardContent onClick={handleContentClick}>
                <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start'}}>
                    <FormControlLabel
                        control={<Checkbox checked={selectedValue.category === OPTIMAL} onChange={handleOptionChange}
                                           value={OPTIMAL}/>}
                        label={<Typography variant="body2">{t('visual_validation.no_needs_reforms')}</Typography>}
                    />
                    <FormControlLabel
                        control={<Checkbox checked={selectedValue.category === FACELIFT} onChange={handleOptionChange}
                                           value={FACELIFT}/>}
                        label={

                            <DataField
                                label={t('visual_validation.facelift_label')}
                                labelVariant="body2"
                                valueVariant="body2"
                                labelColor="text.primary"
                                value={t(facelift)}
                            />}
                    />
                    <FormControlLabel
                        control={<Checkbox checked={selectedValue.category === REPAIR} onChange={handleOptionChange}
                                           value={REPAIR}/>}
                        label={<DataField
                            label={t('visual_validation.arrangements_label')}
                            labelVariant="body2"
                            valueVariant="body2"
                            labelColor="text.primary"
                            value={t(arrangements)}
                        />}
                    />
                    <FormControlLabel
                        control={<Checkbox checked={selectedValue.category === INTEGRAL} onChange={handleOptionChange}
                                           value={INTEGRAL}/>}
                        label={

                            <DataField
                                label={t('visual_validation.integral_label')}
                                labelVariant="body2"
                                valueVariant="body2"
                                labelColor="text.primary"
                                value={t(integral)}
                            />

                        }
                    />

                    <FormControlLabel
                        control={<Checkbox checked={selectedValue.category === CUSTOM} onChange={handleOptionChange}
                                           value={CUSTOM}/>}
                        label={<Typography variant="body2">{t('visual_validation.personalized_label')}</Typography>}
                    />
                </Box>
            </CardContent>
        </Collapse>
    </Card>;
}