import React, {useState} from 'react';
import {Typography, useTheme} from '@mui/material';
import {BaseCard} from "../../components/cards";
import PageTitle from "../../components/titles";
import Box from "@mui/material/Box";
import {AiTooltipIcon} from "../../components/icons";
import {useTranslation} from "react-i18next";

const Charges = ({charges}) => {
    const theme = useTheme();
    const primaryColor = theme.palette.primary.main;
    const {t} = useTranslation();

    if (!charges) {
        return (
            <Box>
                <Box sx={{display: "flex", width: "100%", justifyContent: "space-between", position: "column"}}>
                    <PageTitle sx={{mb: 3}}>{t("administrative_validation.charges")}</PageTitle>
                </Box>
                <BaseCard sx={{display: 'flex', alignItems: 'center', mb: 3}}>
                    <Typography variant="body1" color="text.primary">
                        {t("administrative_validation.no_charges_in_ns")}
                    </Typography>
                </BaseCard>
            </Box>
        );
    }

    return (
        <Box>
            <Box sx={{display: "flex", width: "100%", justifyContent: "space-between", position: "column"}}>
                <PageTitle sx={{mb: 3}}>{t("administrative_validation.charges")}</PageTitle>
                <AiTooltipIcon/>
            </Box>
            {charges?.map((charge, index) => (
                <React.Fragment key={index}>
                    <BaseCard sx={{display: 'flex', alignItems: 'flex-start', mb: 3}}>
                        <Box sx={{flex: 1}}>
                            <Typography variant="body2" color="text.primary" fontWeight="bold" sx={{marginBottom: 0.5}}>
                                {t("administrative_validation.interpretation")}
                            </Typography>

                            <Typography variant="body2" color="text.primary" sx={{marginBottom: 2}}>
                                {charge?.interpretation}
                            </Typography>
                            <Typography variant="body2" color="text.primary" fontWeight="bold" sx={{marginBottom: 0.5}}>
                                {t("administrative_validation.description")}
                            </Typography>
                            <DescriptionWithEllipsis description={charge?.description} maxLength={100}
                                                     primaryColor={primaryColor}/>
                        </Box>
                    </BaseCard>
                </React.Fragment>
            ))}
        </Box>
    );
};

const DescriptionWithEllipsis = ({description, maxLength, primaryColor}) => {
    const [showFullDescription, setShowFullDescription] = useState(false);

    const truncatedDescription = description.length > maxLength ? `${description.slice(0, maxLength)}` : description;
    const shouldShowEllipsis = description.length > maxLength && !showFullDescription;

    const toggleShowFullDescription = () => {
        setShowFullDescription(!showFullDescription);
    };

    return (
        <Typography variant="body2" color="text.secondary" style={{display: 'inline'}}>
            {showFullDescription ? (
                <>
                    {description}{' '}
                    <span style={{color: primaryColor, cursor: 'pointer'}} onClick={toggleShowFullDescription}>
                        ver menos
                    </span>
                </>
            ) : (
                `${truncatedDescription} `
            )}
            {shouldShowEllipsis && (
                <span style={{color: primaryColor, cursor: 'pointer', marginRight: '0.5em'}}
                      onClick={toggleShowFullDescription}>
                    ... ver más
                </span>
            )}
        </Typography>
    );
};

export default Charges;