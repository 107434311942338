import {Stack, ToggleButton, ToggleButtonGroup} from "@mui/material";
import Typography from "@mui/material/Typography";
import React from 'react';
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import Tooltip from "@mui/material/Tooltip";

export default function ToggleOption({
                                         label = "Misconfigured ToggleOption",
                                         value,
                                         onChange,
                                         options = [],
                                         tooltipText
                                     }) {

    return <>
        <Stack direction="row" sx={{
            alignItems: "center",
        }}>

        {label && <Typography variant="subtitle2" color="text.primary" sx={{mr: 3}}
            >{label}</Typography>}
            <Stack direction="row" alignItems="center">
                <ToggleButtonGroup
                    size="large"
                    color="primary"
                    value={value}
                    exclusive
                    onChange={onChange}
                    sx={{mr: 1}}
                >
                    {options.map((op) => {
                        return <ToggleButton value={op.value} key={op.velue} sx={{padding: "0 7px", margin: "0 5px"}}>
                            {op.name}
                        </ToggleButton>
                    })}
                </ToggleButtonGroup>
                {tooltipText && <Tooltip title={tooltipText} interactive enterTouchDelay={0} arrow>
                    <InfoOutlinedIcon fontSize="small" color="info"/>
                </Tooltip>}
            </Stack>
        </Stack>
    </>
}