import Typography from "@mui/material/Typography";
import {getPerformanceTranslation} from "../../../utils/financial";
import Box from "@mui/material/Box";
import {FormatPercentage} from "../../../utils/format-number";
import {useTranslation} from "react-i18next";

export function RoiIndicatorWidget({data}) {
    const {t} = useTranslation();

    return <Box display="flex" flexDirection="column" alignItems="center">
        <Typography variant="subtitle2">
            {`${t('financial.profitability.profitability')} ${getPerformanceTranslation(data.performance, t)}`}
        </Typography>
        <Box display="flex" flexDirection="row" alignItems="center">
            <Typography variant="h3">
                {FormatPercentage(data.roi)}
            </Typography>
            <Typography variant="caption" style={{marginLeft: 8}}>
                ({t('financial.profitability.gross')})
            </Typography>
        </Box>
    </Box>
}