import React from 'react';
import {BaseCard, CardContainer} from "../../components/cards";
import {Box, Typography} from '@mui/material';
import {useTranslation} from 'react-i18next';
import DataField from "../../components/data-field";
import {FormatSquareMeters} from "../../utils/format-number";
import {WDone, WWarning} from "../../components/icons/walden-icons";
import Tooltip from "@mui/material/Tooltip";

const MatchMessage = ({match, matchLabel, noMatchLabel}) => {
    const {t} = useTranslation();

    return (
        <Box sx={{display: "flex", width: "100%", justifyContent: "space-between", alignItems: "center"}}>

            <Typography variant="subtitle2" color="text.primary">
                {t(matchLabel)}
            </Typography>
            {match ? (
                <Tooltip title={t('administrative_validation.match')}> {/**/}
                    <WDone color="success.main" width={20}/>
                </Tooltip>
            ) : (
                <Tooltip title={t('administrative_validation.no_match')}> {/**/}
                    <WWarning color="error.main" width={20}/>
                </Tooltip>
            )}
        </Box>
    );
};

const EqualDataField = ({label, value}) => (
    <DataField
        direction="column"
        label={label}
        labelVariant="body2"
        labelColor="text.primary"
        valueVariant="subtitle1"
        value={value}
        valueColor="success.main"
    />
);

const NotEqualDataField = ({label1, value1, label2, value2, t}) => (

    <>
        <DataField
            direction="column"
            label={label1}
            labelVariant="caption"
            labelColor="text.secondary"
            valueVariant="subtitle1"
            value={value1}
            valueColor="error.main"
        />
        <DataField
            direction="column"
            label={label2}
            labelVariant="caption"
            labelColor="text.secondary"
            valueVariant="subtitle1"
            value={!value2 ? t("no_ns_data") : value2}
            valueColor="error.main"
        />
    </>
);

function CadastreRegistryMatcher({
                                     catastre,
                                     catastreFromNS,
                                     area,
                                     areaFromNS,
                                     energyEfficiency,
                                     energyEfficiencyFromNS
                                 }) {
    const {t} = useTranslation();
    const catastreMatch = catastre === catastreFromNS;
    const areaMatch = area === areaFromNS?.building_area;
    const energyEfficiencyMatch = energyEfficiency === energyEfficiencyFromNS;


    return (
        <CardContainer sx={{justifyContent: "space-between"}}>
            <BaseCard sx={{minWidth: "240px"}}>
                <MatchMessage
                    match={catastreMatch}
                    matchLabel="general.cadastre"
                    noMatchLabel={t("administrative_validation.no_match")}
                />
                {catastreMatch ? (
                    <EqualDataField
                        label=""
                        value={catastreFromNS}
                    />
                ) : (
                    <NotEqualDataField
                        label1={t('administrative_validation.as_per_cadastre')}
                        value1={catastre}
                        label2={t('administrative_validation.as_per_ns')}
                        value2={!catastreFromNS ? t("administrative_validation.no_ns_data") : catastreFromNS}
                        t={t}
                    />
                )}
            </BaseCard>

            <BaseCard sx={{minWidth: "240px"}}>
                <MatchMessage
                    match={areaMatch}
                    matchLabel="property_details.area"
                    noMatchLabel={t("administrative_validation.no_match")}
                />
                {areaMatch ? (
                    <EqualDataField
                        label={""}
                        value={FormatSquareMeters(areaFromNS?.building_area)}
                    />
                ) : (
                    <NotEqualDataField
                        label1={t('administrative_validation.as_per_cadastre')}
                        value1={FormatSquareMeters(area)}
                        label2={t('administrative_validation.as_per_ns')}
                        value2={!areaFromNS?.building_area ? t("administrative_validation.no_ns_data") : FormatSquareMeters(areaFromNS?.building_area)}
                        t={t}
                    />
                )}
            </BaseCard>

            <BaseCard sx={{minWidth: "240px"}}>
                <MatchMessage
                    match={energyEfficiencyMatch}
                    matchLabel="administrative_validation.energy_efficiency"
                    noMatchLabel={t("administrative_validation.no_match")}
                />
                {energyEfficiencyMatch ? (
                    <EqualDataField
                        label=""
                        value={energyEfficiencyFromNS}
                    />
                ) : (
                    <NotEqualDataField
                        label1={t('administrative_validation.as_per_cadastre')}
                        value1={energyEfficiency}
                        label2={t('administrative_validation.as_per_ns')}
                        value2={!energyEfficiencyFromNS ? t("administrative_validation.no_ns_data") : energyEfficiencyFromNS}
                        t={t}
                    />
                )}
            </BaseCard>
        </CardContainer>
    );
}

export default CadastreRegistryMatcher;