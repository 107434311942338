import {AppBar, Stack, Toolbar, Typography, useTheme} from "@mui/material";
import React from "react";
import Box from "@mui/material/Box";
import {bgBlur} from "../../theme/css";
import {Logo} from "../logo";
import {MobileMenu} from "../navigation";
import UserAvatarMenu from "./user-avatar-menu";
import {useResponsive} from "../../hooks/use-responsive";
import LanguageToggleButton from "../buttons/language-toggle-button";

export default function Header({customContent = null, title = "", showReload, reloadPage}) {
    const theme = useTheme();
    const lgDown = useResponsive('down', 'lg');
    const mdDown = useResponsive('down', 'md');

    const defaultContent = (
        <><MobileMenu/>
            <Logo sx={{height: "24px"}}/>

            <Stack direction="row" spacing={1}>
                <LanguageToggleButton/>
                <UserAvatarMenu showReload={showReload} reloadPage={reloadPage}/>

            </Stack>
        </>
    );

    const titledContent = (
        <>
            <Stack direction="row" spacing={1}>
                <MobileMenu/>
            </Stack>

            <Typography variant={mdDown ? "subtitle1" : "h5"} noWrap>{title}</Typography>
            <Stack direction="row" spacing={1}>
                <LanguageToggleButton/>
                <UserAvatarMenu showReload={showReload} reloadPage={reloadPage}/>

            </Stack>
        </>
    );

    const customConetntBar = (
        <>
            {customContent}
        </>
    )
    const getContent = () => {
        if (!lgDown) {
            return defaultContent;
        }
        if (customContent) {
            return customConetntBar;
        } else if (title) {
            return titledContent;
        } else {
            return defaultContent;
        }
    };

    return (
        <AppBar position="fixed"
                sx={{
                    ...bgBlur({
                        color: theme.palette.background.default,
                    }),
                }}
        >
            <Toolbar>
                <Box
                    sx={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "space-between",
                    }}
                >
                    {getContent()}
                </Box>
            </Toolbar>
        </AppBar>
    );
}
