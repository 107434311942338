import React, {useEffect, useRef, useState} from 'react';
import Box from '@mui/material/Box';
import {Button, Typography} from "@mui/material";

const ScrollButtons = ({buttons, indicator, sx, activeButtonColor = 'primary', activeButtonVariant = "contained"}) => {
    const buttonRefs = useRef([]);
    const initialActiveIndex = buttons.findIndex(button => button.isActive);
    const [activeIndex, setActiveIndex] = useState(initialActiveIndex >= 0 ? initialActiveIndex : 0);

    useEffect(() => {
        const updatedActiveIndex = buttons.findIndex(button => button.isActive);
        setActiveIndex(updatedActiveIndex >= 0 ? updatedActiveIndex : -1);
    }, [buttons]);

    const handleClick = (index, onClick) => {
        setActiveIndex(index);
        onClick();
        const buttonRef = buttonRefs.current[index];
        if (buttonRef) {
            buttonRef.scrollIntoView({
                behavior: 'smooth',
                block: 'nearest',
                inline: 'start'
            });
        }
    };

    return (
        <>
            <Box sx={{
                display: 'flex',
                overflowX: 'scroll',
                gap: '20px',
                p: 0,
                flexWrap: 'nowrap',
                '& .MuiButton-root': {
                    minWidth: 130,
                    whiteSpace: 'nowrap',
                },
                '&::-webkit-scrollbar': {
                    display: 'none',
                },
                scrollbarWidth: 'none',
                msOverflowStyle: 'none',
            }}>
                {buttons.map((button, index) => {
                    const buttonVariant = (button.variant || (button.isActive ? activeButtonVariant : 'outlined'));
                    const buttonColor = (button.color || (button.isActive ? activeButtonColor : 'inherit'));
                    return (
                        <Button
                            key={index}
                            ref={el => buttonRefs.current[index] = el}
                            variant={buttonVariant}
                            color={buttonColor}
                            size="medium"
                            sx={{
                                mt: 1,
                                paddingX: 9,
                                minWidth: 120,
                                ...sx

                            }}
                            onClick={() => handleClick(index, button.onClick)}
                        >
                            <Typography color={'inherit'}>{button.label}</Typography>
                        </Button>
                    );
                })}
            </Box>
            {indicator && (
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    gap: '5px',
                    p: 0,
                }}>
                    {buttons.map((_, index) => (
                        <Box
                            key={index}
                            sx={{
                                width: 4,
                                height: 4,
                                borderRadius: '50%',
                                backgroundColor: activeIndex === index ? 'primary.main' : 'grey.400',
                                transition: 'background-color 0.5s ease',

                            }}
                        />
                    ))}
                </Box>
            )}
        </>
    );
};

export default ScrollButtons;
