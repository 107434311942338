import React from 'react';
import {useResponsive} from "../../hooks/use-responsive";
import {
    Timeline,
    TimelineConnector,
    TimelineContent,
    TimelineDot,
    TimelineItem,
    TimelineOppositeContent,
    TimelineSeparator
} from "@mui/lab";
import Typography from "@mui/material/Typography";

export function CustomTimeline({steps}) {
    const mdUp = useResponsive('up', 'md');
    const smUp = useResponsive('up', 'sm');

    return (
        <Timeline sx={{alignItems: "center", paddingRight: mdUp ? "300px" : "100px"}}>
            {steps.map((step, index) => (
                <TimelineItem key={index}>
                    {mdUp && (
                        <TimelineOppositeContent
                            sx={{minWidth: mdUp ? "500px" : "240px"}}
                            align="right"
                            variant="body2"
                            color="text.secondary"
                        >
                            {step.label}
                        </TimelineOppositeContent>
                    )}

                    <TimelineSeparator>
                        <TimelineConnector/>
                        <TimelineDot style={step.dotStyle} sx={{ cursor: 'pointer' }}>
                            {step.icon}
                        </TimelineDot>
                        <TimelineConnector/>
                    </TimelineSeparator>

                    <TimelineContent sx={{minWidth: mdUp ? "500px" : (smUp ? "400px" : "250px")}}>
                        {!mdUp && (
                            <Typography variant="subtitle1" component="h1">
                                {step.label}
                            </Typography>
                        )}
                        {Array.isArray(step.timeline_content) ?
                            step.timeline_content.map((content, lineIndex) => (
                                <div key={lineIndex}>
                                    <Typography variant="caption" component="span">
                                        {content.label}:
                                    </Typography>
                                    <Typography variant="body2" component="span" mb={2}>
                                        {` ${content.value}`}
                                    </Typography>
                                </div>
                            )) :
                            <Typography variant="body2" component="span">
                                {step.timeline_content}
                            </Typography>
                        }
                    </TimelineContent>
                </TimelineItem>
            ))}
        </Timeline>
    );
}
