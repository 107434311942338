import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@mui/material";
import {PrimaryButton} from "../components/buttons";
import React from "react";

export const PondAwardedDialog = ({open, amountAwarded, onClick, nextButtonLabel, tittle, message}) => {
    return <Dialog open={open}>
        <DialogTitle>
            {tittle}
        </DialogTitle>
        <DialogContent>
            <DialogContentText>
                {message} Como agradecimiento por tu confianza, has ganado {amountAwarded} Pond como recompensa.
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            <PrimaryButton onClick={onClick}>
                {nextButtonLabel}
            </PrimaryButton>
        </DialogActions>
    </Dialog>
}