import React from 'react';
import {Box} from '@mui/material';

export const StepIcon = ({icon}) => {
    return (
        <Box
            sx={{
                backgroundColor: 'primary.main',
                color: 'common.white',
                borderRadius: '100%',
                width: 30,
                height: 30,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            {icon}
        </Box>
    );
};