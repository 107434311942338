import {IconButton} from "@mui/material";
import React, {useState} from "react";
import {EditValueDialog} from "../dialogs";
import {WPen} from "../icons/walden-icons";
import Tooltip from "@mui/material/Tooltip";
import {useTranslation} from "react-i18next";

export function EditValueButton({value, onTextFieldChange, onDialogSave, isValidation, isChecklist, isOikos}) {
    const [open, setOpen] = useState(false);
    const {t} = useTranslation();

    const onDialogClose = () => {
        setOpen(false);
    }

    const onDialogOpen = () => {
        setOpen(true);
    }

    const onSave = () => {
        setOpen(false);
        onDialogSave();
    }

    return <>
        <Tooltip title={t("financial.custom_rent_price")}
                 interactive
                 enterTouchDelay={0}
                 arrow
        >
            <IconButton
                onClick={onDialogOpen}
                sx={{width: "25px"}}
                size="small"
                color="secondary"
            >
                <WPen/>
            </IconButton>
        </Tooltip>

        <EditValueDialog
            dialogOpen={open}
            handleTextFieldChange={onTextFieldChange}
            handleDialogSaveClick={onSave}
            dialogCustomValue={value}
            handleDialogClose={onDialogClose}
            isValidation={isValidation}
            isChecklist={isChecklist}
            isOikos={isOikos}
        />
    </>
}