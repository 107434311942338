import {apiService} from "../ApiServiceV2";
import {useMutation} from "react-query";
import {useAuth0} from "@auth0/auth0-react";

export function useUpdateOikosParameters(onSuccess, onError) {
    const auth0Context = useAuth0()

    return useMutation((params) => apiService.updateOikosParameters(auth0Context, params), {
        onSuccess: onSuccess,
        onError: onError,
    })
}