import React from "react";
import NumberField from "./numberField";

export default function CurrencyField({fullWidth, label, value, onChange, isError, helperText, onBlur, disabled, isRequired, variant}) {
    return (<NumberField
            sign={"€"}
            fullWidth={fullWidth}
            label={label}
            value={value}
            onBlur={onBlur}
            onChange={onChange}
            disabled={disabled}
            isError={isError}
            helperText={helperText}
            isRequired={isRequired}
            variant={variant}/>
    )
}