import Button from "@mui/material/Button";
import React from "react";


export const TertiaryButton = ({children, onClick, color = "secondary"}) => {
    return (
        <Button
            color={color}
            variant="text"
            onClick={onClick}
        >{children}</Button>
    )
}