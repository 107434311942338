import {useTranslation} from "react-i18next";
import Paper from "@mui/material/Paper";
import {CadastreField} from "../../components/text_fields";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import {StepWrapper} from "../../components/custom-stepper";
import React from "react";
import {useTheme} from "@mui/material";
import {FormatCurrency, FormatPercentage} from "../../utils/format-number";
import {ValidationParametersContext} from "../parameters/ParametersContext";
import {CustomTimeline} from "../../components/custom-timeline/custom-timeline";
import RentSection from "../parameters/RentSection";
import {WHome, WPen, WRented, WVerified} from "../../components/icons/walden-icons";
import DataField from "../../components/data-field";

export const DescribePropertyStep = ({
                                         handleNext,
                                         cadastre,
                                         setCadastre,
                                         cadastreIsValid,
                                         setCadastreIsValid,
                                         isViewMode = false,
                                     }) => {
    const {t} = useTranslation();

    const content = (
        <Paper
            sx={{
                padding: {xs: '10px', lg: '15px'},
                margin: 'auto',
                flexGrow: 1,
            }}
        >
            {isViewMode ? (
                <DataField
                    direction="column"
                    label={t('validation_list.add_validation.cadastre')}
                    value={cadastre}
                    valueVariant="h4"
                    valueColor="text.primary"
                />
            ) : (
                <CadastreField
                    cadastre={cadastre}
                    setCadastre={setCadastre}
                    cadastreIsValid={cadastreIsValid}
                    setCadastreIsValid={setCadastreIsValid}
                />
            )}
            <Box sx={{mb: 2, display: 'flex', justifyContent: 'flex-end'}}>
                <div>
                    <Button
                        variant="contained"
                        onClick={handleNext}
                        disabled={isViewMode ? false : !cadastreIsValid}
                        sx={{mt: 1}}
                    >
                        {t('general.continue')}
                    </Button>
                </div>
            </Box>
        </Paper>
    );

    return (<StepWrapper label={t("oikos.add_properties.step1_title")} stepContent={content} icon={<WHome/>}/>);
}

export const OikosRentStep = ({handleNext, handleBack}) => {
    const {t} = useTranslation();
    const content = <Paper sx={{
        borderRadius: '15px', padding: {xs: '1px', lg: '15px'}
    }}>
        <RentSection/>
        <Box sx={{m: 2, display: 'flex', justifyContent: 'flex-end'}}>
            <div>
                <Button
                    onClick={handleBack}
                    sx={{mt: 1, ml: 1}}
                >
                    {t("general.back")}
                </Button>
                <Button
                    variant="contained"
                    onClick={handleNext}
                    sx={{mt: 1, ml: 1}}
                >
                    {t("general.continue")}
                </Button>
            </div>
        </Box>
    </Paper>;
    return <StepWrapper label={t("oikos.add_properties.aditional_data_step_title")} stepContent={content}
                        icon={<WRented/>}/>;
}

export function OikosReviewStep({cadastre, handleBack, setActiveStep, handleAddButtonClick}) {
    const {t} = useTranslation();
    const {validationParameters} = React.useContext(ValidationParametersContext);
    const theme = useTheme();

    const catastre_timeline_content = [
        {
            label: t("validation_list.add_validation.cadastre"),
            value: cadastre
        }
    ];

    const investment_cost_timeline_content = [
        {
            label: t("parameters.investment_expenses.property_value"),
            value: FormatCurrency(validationParameters?.property_value)
        },
        {
            label: t("parameters.investment_expenses.notary_valuation_field_label"),
            value: FormatCurrency(validationParameters?.notary_valuation)
        },
        {
            label: t("parameters.investment_expenses.agency_commission_field_label"),
            value: FormatCurrency(validationParameters?.agency_commission)
        },
        {
            label: t("parameters.investment_expenses.property_renovation_field_label"),
            value: FormatCurrency(validationParameters?.property_renovation)
        },
        {
            label: t("parameters.investment_expenses.additional_expenses_to_consider_field_label"),
            value: FormatCurrency(validationParameters?.property_other_cost)
        },
    ];

    const mortgage_timeline_content = [
        {
            label: t("financial.mortgage.type"),
            value: validationParameters?.type === 1 ? t("financial.mortgage.fixed") : t("financial.mortgage.variable")
        }, {
            label: t("financial.mortgage.years"),
            value: validationParameters?.years
        }, {
            label: t("financial.mortgage.financed_amount"),
            value: FormatPercentage(validationParameters?.financed_percentage)
        }, {
            label: t("financial.mortgage.interest"),
            value: FormatPercentage(validationParameters?.interest)
        }, {
            label: t("financial.mortgage.differential"),
            value: FormatPercentage(validationParameters?.differential)
        }, {
            label: t("financial.mortgage.euribor"),
            value: FormatPercentage(validationParameters?.euribor)
        },
    ]

    const holding_cost_timeline_content = [
        {
            label: t("parameters.annual_holding_cost.maintenance_field_label"),
            value: FormatCurrency(validationParameters?.maintenance)
        }, {
            label: t("parameters.annual_holding_cost.empty_periods_field_label"),
            value: FormatCurrency(validationParameters?.empty_period)
        },
        {
            label: t("parameters.annual_holding_cost.unpaid_insurance_field_label"),
            value: FormatCurrency(validationParameters?.unpaid_insurance)
        }, {
            label: t("parameters.annual_holding_cost.garbage_tax_field_label"),
            value: FormatCurrency(validationParameters?.garbage_tax)
        },
        {
            label: t("parameters.annual_holding_cost.home_insurance_field_label"),
            value: FormatCurrency(validationParameters?.home_insurance)
        }, {
            label: t("parameters.annual_holding_cost.life_insurance_field_label"),
            value: FormatCurrency(validationParameters?.life_insurance)
        },
        {
            label: t("parameters.annual_holding_cost.community_fees_field_label"),
            value: FormatCurrency(validationParameters?.community_fees)
        }, {
            label: t("parameters.annual_holding_cost.property_tax_IBI_field_label"),
            value: FormatCurrency(validationParameters?.property_tax_ibi)
        },
        {
            label: t("parameters.annual_holding_cost.additional_expenses_to_consider_field_label"),
            value: FormatCurrency(validationParameters?.property_other_cost)
        },
    ]

    const additional_data_timeline_content = [
        {
            label: t("oikos.add_properties.is_rented_switch_label"),
            value: validationParameters?.rent_value ? t("general.yes") : t("general.no")
        }, {
            label: t("oikos.add_properties.rent_value"),
            value: FormatCurrency(validationParameters?.rent_value ? validationParameters?.rent_value : 0)
        }
    ]

    const timelineSteps = [
        {
            label: t("validation_list.add_validation.identify_the_property"),
            icon: <WPen onClick={() => setActiveStep(0)}/>,
            timeline_content: catastre_timeline_content,
            dotStyle: {backgroundColor: theme.palette.primary.main}
        },
        {
            label: t("validation_list.add_validation.investment_costs"),
            icon: <WPen onClick={() => setActiveStep(1)}/>,
            timeline_content: investment_cost_timeline_content,
            dotStyle: {backgroundColor: theme.palette.primary.main}

        }, {
            label: t("financial.mortgage.mortgage"),
            icon: <WPen onClick={() => setActiveStep(2)}/>,
            timeline_content: mortgage_timeline_content,
            dotStyle: {backgroundColor: theme.palette.primary.main}

        }, {
            label: t("parameters.annual_holding_cost.title"),
            icon: <WPen onClick={() => setActiveStep(3)}/>,
            timeline_content: holding_cost_timeline_content,
            dotStyle: {backgroundColor: theme.palette.primary.main}

        }, {
            label: t("oikos.add_properties.aditional_data_step_title"),
            icon: <WPen onClick={() => setActiveStep(4)}/>,
            timeline_content: additional_data_timeline_content,
            dotStyle: {backgroundColor: theme.palette.primary.main}

        },
    ];

    const content = <Paper sx={{
        borderRadius: '15px', padding: {xs: '1px', lg: '15px'}
    }}>
        <CustomTimeline steps={timelineSteps}/>
        <Box sx={{m: 2, display: 'flex', justifyContent: 'flex-end'}}>
            <div>
                <Button color="secondary" variant="text"
                        onClick={handleBack} sx={{mt: 1, ml: 1}}>
                    {t("general.back")}
                </Button>
                <Button
                    color="primary"
                    variant="contained"
                    onClick={handleAddButtonClick}
                    sx={{mt: 1, ml: 1}}
                >
                    {t("oikos.add_properties.add_property_button_label")}
                </Button>
            </div>
        </Box>
    </Paper>

    return <StepWrapper label={t("oikos.add_properties.oikos_review_step_title")} stepContent={content}
                        icon={<WVerified/>}/>;
}
