import {useTheme} from "@mui/material/styles";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {useLocation} from "react-router-dom";
import Divider from "@mui/material/Divider";
import React, {useEffect, useState} from "react";
import DataField from "../../components/data-field";
import {useTranslation} from "react-i18next";
import {FormatCompactCurrency, FormatCurrency} from "../../utils/format-number";
import {ChecklistDetailer} from "../../services/validationRequest/checklist-detailer";
import {LoadingScreenBar} from "../../components/loading-screen";
import {GetValidationItem} from "./items-map";
import {selectedOptionDescription} from "./checklist-item";
import {Button} from "@mui/material";
import {WPen} from "../../components/icons/walden-icons";
import {Mixpanel} from "../../metrics/mixpanel";
import ValidationChecklist from "./validation-checklist";
import {useValidationDetailer} from "../../services/validationRequest/validation-detailer";
import StickyBaseCard from "./sticky";
import {useResponsive} from "../../hooks/use-responsive";
import {BaseCard} from "../../components/cards";
import {ValidationVisualTour} from "../../components/tour";

export default function VisualValidation({}) {
    const id = useLocation().state;
    const theme = useTheme();
    const {t} = useTranslation();
    const {data, isLoading} = ChecklistDetailer(id);
    const [showValidationChecklist, setShowValidationChecklist] = useState(false);
    const {data: apiResponse, refetch: refetchValidationDetailer} = useValidationDetailer(id);
    const [totalSelectedValue, setTotalSelectedValue] = useState(0);
    const mdDown = useResponsive('down', 'md');

    useEffect(() => {
        const total = data && data?.items
            ? data?.items.reduce((acc, item) => acc + item.value, 0)
            : 0;
        setTotalSelectedValue(total);
    }, [data?.items]);


    useEffect(() => {
        if (!showValidationChecklist) {
            refetchValidationDetailer();
        }
    }, [showValidationChecklist, refetchValidationDetailer]);

    const handleEditChecklist = () => {
        Mixpanel.track('Visual Validation: The user is going to edit the checklist', {"Checklist_id": id});
        setShowValidationChecklist(true);
    };

    if (isLoading) {
        return <LoadingScreenBar/>;
    }


    const sections = [
        {
            title: t('visual_validation.section_title_1'),
            group: "exterior",
            items: data.items.filter(
                (item) => item.renovation_item_group === "property_exterior"
            ),
        },
        {
            title: t('visual_validation.section_title_2'),
            group: "interior",
            items: data.items.filter(
                (item) => item.renovation_item_group === "property_interior"
            ),
        },
        {
            title: t('visual_validation.section_title_3'),
            group: "services",
            items: data.items.filter(
                (item) => item.renovation_item_group === "services"
            ),
        },
    ];

    return (
        <Box sx={{display: "flex", flexDirection: "column"}}>
            <ValidationVisualTour/>
            {mdDown ? (
                <>
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            p: 2,
                            position: "relative",
                            zIndex: 1,
                            width: "100%"
                        }}>
                        <Box

                            sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                background: theme.palette.info.dark,
                                py: 2,
                                width: 200,
                                px: 2,
                                position: "relative",
                                borderRadius: " 16px 16px 0 0",
                                top: 16,
                            }}
                        >
                            <Typography variant="subtitle2" color="white">
                                Total Reformas
                            </Typography>
                        </Box>
                    </Box>
                    <StickyBaseCard backgroundColor={theme.palette.info.dark}>
                        <Typography className="tour_visual_cost"
                                    variant="h3" color="common.white">
                            {FormatCurrency(totalSelectedValue)}
                        </Typography>
                    </StickyBaseCard>
                </>
            ) : (
                <Box className="tour_visual_cost"
                     sx={{
                         display: "flex",
                         justifyContent: "center",
                         mt: 2,
                         width: "100%"
                     }}
                >
                    <BaseCard
                        sx={{
                            backgroundColor: theme.palette.info.dark,
                            display: "flex",
                            flexDirection: "column",
                            width: "70%",
                            justifyContent: "center",
                            alignItems: "center"
                        }}
                    >
                        <Box sx={{display: "flex", justifyContent: "center"}}>
                            <DataField
                                sx={{alignItems: "center"}}
                                labelVariant="subtitle2"
                                labelColor="white"
                                valueVariant="h3"
                                label="Total Reformas"
                                valueColor="common.white"
                                value={FormatCurrency(totalSelectedValue)}
                                direction="column"
                            />
                        </Box>
                    </BaseCard>
                </Box>
            )}
            <Box sx={{mt: 1}}>
                {!showValidationChecklist && (
                    <Box
                        sx={{
                            position: 'fixed', bottom: 20, right: 16, zIndex: 10, display: 'inline-flex',
                        }}
                    >
                        <Button color="secondary" variant="contained" className="tour_visual_edit"
                                sx={{mt: 2, maxWidth: "250px", display: "flex", justifyContent: "space-evenly"}}
                                onClick={handleEditChecklist}>
                            <WPen/>
                            <Typography noWrap
                                        variant="caption1"> {t("visual_validation.edit_visual_validation")}</Typography>
                        </Button>
                    </Box>
                )}

                {showValidationChecklist ? (
                    <ValidationChecklist changeView={setShowValidationChecklist}
                                         setTotalSelectedValue={setTotalSelectedValue}/>
                ) : (
                    <Box sx={{display: "flex", justifyContent: mdDown ? "flex-start" : "center"}}>
                        <Box
                            className='tour_visual_reform'
                            sx={{
                            display: "flex",
                            gap: mdDown ? "1rem" : "3rem",
                            flexDirection: mdDown ? "column" : "row",
                            justifyContent: mdDown ? "flex-start" : "center"
                        }}>
                            {sections.map(({title, items}, index) => (
                                <Box key={`section-${index}`}
                                     sx={{mt: 4}}>
                                    <Typography variant="h6" sx={{mb: 2}}>
                                        {title}
                                    </Typography>
                                    {items.map((item) => {
                                        const itemInfo = GetValidationItem(item?.i18n_key, t, theme);
                                        const selectedCategory = item.category;
                                        const selectedValue = item?.value;
                                        return (
                                            <React.Fragment key={item?.check_list_item_id}>
                                                <Box
                                                    sx={{
                                                        display: "flex",
                                                        alignItems: "left",
                                                        padding: 1,
                                                        flexDirection: 'column'
                                                    }}
                                                >
                                                    <Box sx={{display: "flex", alignItems: "center", marginRight: 2}}>
                                                        {itemInfo.icon}
                                                        <Typography variant="subtitle1" sx={{ml: 1}}>
                                                            {itemInfo.title}
                                                        </Typography>
                                                    </Box>
                                                    <Box
                                                        sx={{
                                                            display: "flex",
                                                            flexDirection: "column",
                                                            alignItems: "flex-start",
                                                            marginLeft: 3.5,

                                                        }}
                                                    >
                                                        <Box sx={{display: "flex", alignItems: "center"}}>
                                                            <DataField
                                                                label={t('visual_validation.state')}
                                                                labelVariant="body2"
                                                                valueVariant="body2"
                                                                labelColor="text.primary"
                                                                value={selectedOptionDescription[selectedCategory]}

                                                            />
                                                        </Box>
                                                        <Box
                                                            sx={{
                                                                display: "flex",
                                                                alignItems: "left",
                                                                mt: 1,
                                                            }}
                                                        >
                                                            <Typography variant="subtitle1">
                                                                + {FormatCompactCurrency(selectedValue)}
                                                            </Typography>
                                                        </Box>
                                                    </Box>
                                                </Box>
                                                <Divider
                                                    sx={{marginLeft: 2, marginRight: 2}}
                                                />
                                            </React.Fragment>
                                        );
                                    })}
                                </Box>
                            ))}
                        </Box>
                    </Box>
                )}
            </Box>
        </Box>
    );
}