import PropTypes from "prop-types";
import {getPerformanceColor, getPerformanceTranslation} from "../../utils/financial";
import DataField from "../data-field";
import {Stack, useTheme} from "@mui/material";
import BaseCard from "./base-card";
import {FormatCurrency, FormatPercentage} from "../../utils/format-number";
import {useTranslation} from "react-i18next";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";


function FinancialSummaryCard({data, sx}) {
    const theme = useTheme();
    const {t} = useTranslation();

    const roi = data?.roi;

    const performance_text = getPerformanceTranslation(roi?.performance, t);

    return <BaseCard sx={{
        ...sx,
        backgroundColor: getPerformanceColor(theme, roi?.performance, "dark"), maxWidth: 400,
        transition: 'background-color 0.7s ease-in-out'
    }}>
        <Box display="flex" flexDirection="column" alignItems="flex-start">
            <Typography variant="h5" color="common.white">
                {`${t('financial.profitability.profitability')} ${performance_text}`}
            </Typography>

            <Box display="flex" flexDirection="row" alignItems="center" style={{marginTop: 4}}>
                <Typography variant="h3" color="common.white">
                    {FormatPercentage(roi?.gross_roi)}
                </Typography>

                <Typography variant="caption" color="common.white" style={{marginLeft: 8}}>
                    ({t('financial.profitability.gross')})
                </Typography>
            </Box>
        </Box>
        <Stack direction="row" spacing={6}>
            <DataField direction="column"
                       label={t("financial.profitability.profit_before_taxes")} labelVariant="subtitle2"
                       labelColor="common.white"
                       value={FormatCurrency(roi?.cash_flow?.profit_before_taxes)} valueVariant="subtitle2"
                       valueColor="common.white"
            />
        </Stack>
    </BaseCard>;
}

FinancialSummaryCard.prototype = {
    roi: {
        performance: PropTypes.number,
        gross_roi: PropTypes.number,
        profit_before_taxes: PropTypes.number
    }
}

export default FinancialSummaryCard;
