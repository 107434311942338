import React, {useState} from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import {ValidationParametersContext} from "./ParametersContext";
import {AllParameters} from "./AllParameters";
import toast from "react-hot-toast";
import {useTranslation} from "react-i18next";
import {buildParametersRequest} from "./request";
import {WEdit} from "../../components/icons/walden-icons";
import {useValidationUpdater} from "../../services/validationRequest/validation-storer";
import {useResponsive} from "../../hooks/use-responsive";
import {IconButton} from "@mui/material";
import {Mixpanel} from "../../metrics/mixpanel";


export default function ValidationParametersPopup({refreshParams, parameters, request}) {
    const {t} = useTranslation();
    const [dialogOpen, setDialogOpen] = useState(false);
    const [validationParameters, setValidationParameters] = useState(parameters);
    const validationUpdater = useValidationUpdater();
    const mdDown = useResponsive('down', 'md');

    const retrieveParameters = () => {
        setValidationParameters({ ...parameters, isMortgageValid: true })
    };

    const buildRequest = () => {
        return buildParametersRequest({
            ...validationParameters,
            id: parameters.ID
        }, request);
    };

    const handleOpenDialog = () => {
        Mixpanel.track('Validation Parameters: Opened editing dialog');
        retrieveParameters();
        setDialogOpen(true);
    };

    const handleSaveClick = () => {
        if (!validationParameters.isMortgageValid) {
            toast.error(t("feedback.error.mortgage_params_is_not_valid"));
            return;
        }

        validationUpdater.mutate(buildRequest(), {
            onSuccess: () => {
                Mixpanel.track('Validation Parameters: Saved changes');
                refreshParams();
                setDialogOpen(false);
                setValidationParameters({ ...validationParameters, parametersUpdated: true });
            }
        });
    };
    const handleCloseDialog = () => {
        Mixpanel.track('Validation Parameters: Closed editing dialog');
        setDialogOpen(false);
    };


    return (<>
        {mdDown ? (
            <IconButton color="secondary" onClick={handleOpenDialog}>
                <WEdit/>
            </IconButton>
        ) : (
            <Button variant="outlined" color="secondary"
                    startIcon={<WEdit/>}
                    onClick={handleOpenDialog}
            >{t("parameters.modify_parameters")}</Button>
        )}

        <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)} fullWidth maxWidth="lg">
            <DialogTitle>{t("parameters.modify_parameters")}</DialogTitle>
            <DialogContent>
                <ValidationParametersContext.Provider value={{validationParameters, setValidationParameters}}>
                    <AllParameters validation/>
                </ValidationParametersContext.Provider>
            </DialogContent>
            <DialogActions style={{justifyContent: 'flex-end', padding: "20px"}}>
                <Button onClick={handleCloseDialog} color="primary">
                    {t("general.close")}
                </Button>
                <Button onClick={handleSaveClick} variant="contained" color="primary">
                    {t("general.save")}
                </Button>
            </DialogActions>
        </Dialog>
    </>);
}
