'use client';

import PropTypes from 'prop-types';
import isEqual from 'lodash/isEqual';
import {useCallback, useEffect, useMemo, useState} from 'react';


import {localStorageGetItem} from '../../../utils/storage-available';

import {SettingsContext} from './settings-context';
import {useLocalStorage} from "../../../hooks/use-local-storage";
import {useResponsive} from "../../../hooks/use-responsive";

// ----------------------------------------------------------------------

const STORAGE_KEY = 'settings';

export function SettingsProvider({children, defaultSettings}) {
    const {state, update, reset} = useLocalStorage(STORAGE_KEY, defaultSettings);
    const lgUp = useResponsive("up", "lg");

    const [openDrawer, setOpenDrawer] = useState(lgUp);
    const [waldenPrice, setWaldenPrice] = useState(false);
    const [monthlyPeriod, setMonthlyPeriod] = useState(false);
    const [optimistProfile, setOptimistProfile] = useState(false);
    const [isScrolling, setIsScrolling] = useState(false);

    const isArabic = localStorageGetItem('i18nextLng') === 'ar';

    useEffect(() => {
        if (isArabic) {
            onChangeDirectionByLang('ar');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isArabic]);

    // Direction by lang
    const onChangeDirectionByLang = useCallback(
        (lang) => {
            update('themeDirection', lang === 'ar' ? 'rtl' : 'ltr');
        },
        [update]
    );

    const onSetScrolling = useCallback((scrolling) => {
        setIsScrolling(scrolling);
    }, []);


    // Drawer
    const onToggleDrawer = useCallback(() => {
        setOpenDrawer((prev) => !prev);
    }, []);

    const onCloseDrawer = useCallback(() => {
        setOpenDrawer(false);
    }, []);

    const onOpenDrawer = useCallback(() => {
        setOpenDrawer(true);
    }, []);

    const canReset = !isEqual(state, defaultSettings);

    const onSetWaldenPrice = useCallback((value) => {
        setWaldenPrice(value);
    }, []);

    const memoizedValue = useMemo(
        () => ({
            ...state,
            onUpdate: update,
            isScrolling,
            onSetScrolling,
            // Direction
            onChangeDirectionByLang,
            // Reset
            canReset,
            onReset: reset,
            // Drawer
            open: openDrawer,
            onOpen: onOpenDrawer,
            onClose: onCloseDrawer,
            onToggle: onToggleDrawer,
            useWaldenPrice: waldenPrice,
            onSetWaldenPrice: () => onSetWaldenPrice(true),
            onSetMarketPrice: () => onSetWaldenPrice(false),
            useMonthlyPeriod: monthlyPeriod,
            onSetMonthlyPeriod: () => setMonthlyPeriod(true),
            onSetYearlyPeriod: () => setMonthlyPeriod(false),
            useOptimistProfile: optimistProfile,
            onSetOptimistProfile: () => setOptimistProfile(true),
            onSetPrudentProfile: () => setOptimistProfile(false),
        }),
        [
            reset,
            state,
            update,
            canReset,
            openDrawer,
            isScrolling,
            onSetScrolling,
            waldenPrice,
            onOpenDrawer,
            monthlyPeriod,
            onCloseDrawer,
            onToggleDrawer,
            optimistProfile,
            onSetWaldenPrice,
            setMonthlyPeriod,
            setOptimistProfile,
            onChangeDirectionByLang,
        ]
    );

    return <SettingsContext.Provider value={memoizedValue}>{children}</SettingsContext.Provider>;
}

SettingsProvider.propTypes = {
    children: PropTypes.node,
    defaultSettings: PropTypes.object,
};
