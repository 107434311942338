const ROUTES = {
    index: "/",
    subscription: "/subscription",
    onboarding: {
        intro: "/onboarding_intro",
        profile: "/onboarding_profile",
        profile_setup: "/onboarding_profile_setup",
        property: "/onboarding_property",
    },
    dashboard: "/dashboard",
    validations : {
        list: "/validations",
        get: "/validations",
        add: "/validations/add",
        detail: "/validations/detail",
        checklist: "/validations/checklist",
    },
    properties: {
        list: "/properties",
        add: "/properties/add",
        detail: "/properties/detail",
    },
    profile: "/investor_profile",
    faq: "/faq",
    policy: "/policy",
    logout: "/logout"
}

export default ROUTES;
