import {Tour} from "./tour";
import {useTranslation} from "react-i18next";
import {TourContent} from "./tour-content";

export function ListTour({tourKey, tourType}) {
    const {t} = useTranslation();

    const title = tourType === 'oikos'
        ? t("tour.list.title_oikos")
        : t("tour.list.title_validation");

    const searchDescription = tourType === 'oikos'
        ? t("tour.list.search_oikos")
        : t("tour.list.search_validation");

    const tourSteps = [
        {
            target: '.tour_list_options',
            content: (
                <TourContent
                    title={title}
                    description={t("tour.list.options")}
                />
            ),
        },
        {
            target: '.tour_list_search',
            content: (
                <TourContent
                    title={title}
                    description={searchDescription}
                />
            ),
        }
    ];

    return <Tour steps={tourSteps} tourKey={tourKey} disableScrolling={true}/>;
}
