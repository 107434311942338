import {audience, clientId, domain, scopesString} from "./auth0Config";
import {Auth0Provider} from "@auth0/auth0-react";
import {useNavigate} from "react-router-dom";

export const Auth0ProviderWithNavigate = ({children}) => {
    const navigate = useNavigate();
    const onRedirectCallback = (appState) => {
        navigate(appState?.returnTo || window.location.pathname);
    };

    console.debug("Auth0ProviderWithNavigate", domain, clientId, audience, scopesString());

    return <Auth0Provider
        domain={domain}
        clientId={clientId}
        authorizationParams={{
            audience: audience,
            redirect_uri: window.location.origin,
            scope: scopesString(),
        }}
        onRedirectCallback={onRedirectCallback}
    >
        {children}
    </Auth0Provider>
}