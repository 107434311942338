import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import TrendingFlatOutlinedIcon from "@mui/icons-material/TrendingFlatOutlined";
import TrendingDownIcon from "@mui/icons-material/TrendingDown";
import React from "react";
import {alpha} from "@mui/material/styles";

export const ROI_PERFORMANCE_OPTIMAL = 3;
export const ROI_PERFORMANCE_MEDIUM = 2;
export const ROI_PERFORMANCE_LOW = 1;

export const VALIDATION_STATUS_DONE = "done";
export const VALIDATION_STATUS_PENDING = "pending";
export const VALIDATION_STATUS_DEMO = "demo";

export const OIKOS_STATUS_RENTED = "rented";
export const OIKOS_STATUS_AVAILABLE = "available";

export const OIKOS_STATUS_PENDING = "pending";

export const colorByStatus = (theme, status) => {
    if (status === VALIDATION_STATUS_DONE || status === OIKOS_STATUS_RENTED || status === VALIDATION_STATUS_DEMO)
        return [theme.palette.success.lighter, theme.palette.success.dark];
    else if (status === OIKOS_STATUS_AVAILABLE)
        return [alpha(theme.palette.action.active, 0.08)];
    return [alpha(theme.palette.secondary.main, 0.16), theme.palette.text.primary];
};

export const getPerformanceColor = (theme, level, tone = "lighter") => {
    if (level === undefined) return theme.palette.background.paper;
    switch (level) {
        case ROI_PERFORMANCE_OPTIMAL:
            return theme.palette.success[tone];
        case ROI_PERFORMANCE_MEDIUM:
            return theme.palette.warning[tone];
        case ROI_PERFORMANCE_LOW:
            return theme.palette.error[tone];
        default:
            return alpha(theme.palette.secondary.main, 0.16)
    }
};

export const getPerformanceGradient = (theme, level) => {
    if (level === undefined) return {backgroundColor: theme.palette.background.paper};
    switch (level) {
        case ROI_PERFORMANCE_OPTIMAL:
            return {background: theme.palette.gradient.success.main};
        case ROI_PERFORMANCE_MEDIUM:
            return {background: theme.palette.gradient.warning.main};
        case ROI_PERFORMANCE_LOW:
            return {background: theme.palette.gradient.error.main};
        default:
            return {backgroundColor: alpha(theme.palette.secondary.main, 0.16)}
    }
};

export const getPerformanceTextColor = (theme, level) => {
    if (level === undefined) return theme.palette.text.primary;
    return getPerformanceColor(theme, level, "darker");
}

export const renderPerformanceIcon = (theme, level) => {
    if (level === undefined) return null;
    const color = getPerformanceColor(theme, level);
    switch (level) {
        case ROI_PERFORMANCE_OPTIMAL:
            return <TrendingUpIcon style={{color}}/>;
        case ROI_PERFORMANCE_MEDIUM:
            return <TrendingFlatOutlinedIcon style={{color}}/>;
        case ROI_PERFORMANCE_LOW:
            return <TrendingDownIcon style={{color}}/>;
        default:
            return null;
    }
};

export const addressAsString = (address) => {
    if (!address?.street) return undefined;
    return `${address?.street} ${address?.number} ${address?.apartment}, ${address?.municipality}, ${address?.province}`;
}

export const propertyFromParcel = (reference, parcel) => {
    const prop = parcel.properties.filter(p => p.reference === reference);
    if (prop.length === 0) return undefined;
    return prop[0];
}

export const calculateVTotal = (property) => {
    if (!property || !property?.property_structures) return 0;
    return property.property_structures.filter(p => p.typology === "V").reduce((acc, p) => acc + p.area, 0);
}

export const calculateAreaFromProperty = (property) => {
    if (!property || !property?.property_structures) return undefined;
    const total = property.area;
    const vtotal = property?.property_structures.filter(p => p.typology === "V").reduce((acc, p) => acc + p.area, 0);
    const ctotal = property?.property_structures.filter(p => p.typology === "COMMON").reduce((acc, p) => acc + p.area, 0);
    return `${total} m2 (${vtotal} / ${ctotal})`;
}

export const getPerformanceTranslation = (performance, t) => performance === ROI_PERFORMANCE_OPTIMAL ? t('quality_levels.ideal') :
    performance === ROI_PERFORMANCE_LOW ? t('quality_levels.low') : performance === ROI_PERFORMANCE_MEDIUM ? t('quality_levels.moderate') : "";

export function selectorsFromSettings(settings) {
    return {
        priceSelector: settings.useWaldenPrice ? "walden" : "market",
        periodSelector: settings.useMonthlyPeriod ? "monthly" : "yearly",
        profileSelector: settings.useOptimistProfile ? "optimistic" : "prudent"
    }
}
