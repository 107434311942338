import React from "react";
import {useTranslation} from "react-i18next";
import {BaseCard} from "./index";
import Typography from "@mui/material/Typography";
import {Grid, Stack} from "@mui/material";
import DataField from "../data-field";
import {FormatCurrency, FormatPercentage} from "../../utils/format-number";
import {useResponsive} from "../../hooks/use-responsive";
import Box from "@mui/material/Box";
import _ from "lodash";


export default function FinancialMetricsCard({data = {}}) {
    const {t} = useTranslation();
    const mdDown = useResponsive('down', 'md');
    const CardOrBox = mdDown ? BaseCard : Box;

    const roi = data?.roi;

    const cardDirection = mdDown ? 'column' : 'row';
    const dataFieldProps = mdDown ? {
        direction: 'row', labelColor: "text.primary", valueVariant: "body2", valueColor: "text.primary", sx: {
            alignSelf: "stretch", justifyContent: "space-between",
        }
    } : {
        direction: 'column', valueVariant: "h6", valueColor: "text.primary"
    };

    const dataFields = [{
        label: t("financial.profitability.roce"),
        value: FormatPercentage(roi?.roce)
    }, {
        label: t("financial.profitability.net_patrimony_created"),
        value: FormatPercentage(roi?.net_patrimony_created)
    }, {
        label: t("financial.profitability.appreciation_vs_equity"),
        value: FormatPercentage(roi?.appreciation_vs_equity)
    }, {
        label: t("financial.profitability.years_mortgage_self_paying"), value: roi?.years_mortgage_self_paying
    }, {
        label: t("financial.profitability.minimum_rent_for_self_paying_property"),
        value: FormatCurrency(roi?.minimum_rent_for_self_paying_property)
    }, {
        label: t("financial.profitability.sale_value_at_retirement"),
        value: FormatCurrency(roi?.sale_value_at_retirement)
    }, {
        label: t("financial.profitability.accumulated_amortization_at_retirement"),
        value: FormatCurrency(roi?.accumulated_amortization_at_retirement)
    }, {
        label: t("financial.profitability.capital_gain_at_retirement"),
        value: FormatCurrency(roi?.capital_gain_at_retirement)
    }, {
        label: t("financial.profitability.total_property_cost_as_percentage_of_rent"),
        value: FormatPercentage(roi?.property_cost?.total_property_cost_as_percentage_of_rent)
    },];

    const mobileView = <Grid container alignItems="center" spacing={2}> {dataFields.map((field, _) => <>
        <Grid item xs={6} sm={8}>
            <Typography variant="caption" color="text.primary">{field.label}</Typography>
        </Grid>
        <Grid item xs={6} sm={4}>
            <Typography variant="body2" color="text.primary" sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-end"
            }}>{field.value}</Typography>
        </Grid>
    </>)} </Grid>

    const desktopView = <Grid container>{_.chunk(dataFields, 3).map((fields, _) => <>
        <Grid container spacing={2}>{fields.map((field, _) => <>
            <Grid item xs={4} mt={2}>
                <BaseCard sx={{minHeight: "100px"}}>
                    <DataField
                        label={field.label}
                        value={field.value}
                        {...dataFieldProps}
                    />
                </BaseCard>
            </Grid>
        </>)
        }</Grid>
    </>)
    }</Grid>;

    return <CardOrBox>
        <Stack sx={{display: "flex", alignSelf: "stretch"}} spacing={1}>
            <Typography variant="h6">{t("financial.metrics")}</Typography>
            {mdDown ? mobileView : desktopView}
        </Stack>
    </CardOrBox>;
}