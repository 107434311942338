import Typography from "@mui/material/Typography";
import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {useTheme} from "@mui/material";
import Button from "@mui/material/Button";
import {BaseCard} from "../../components/cards";
import DataField from "../../components/data-field";
import {FormatCurrency, FormatPercentage} from "../../utils/format-number";
import {useResponsive} from "../../hooks/use-responsive";
import {WAcceptable, WIdeal, WLow} from "../../components/icons/walden-icons";
import ScrollButtons from "../../components/scrollbar/scroll-buttons";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";

export default function NewInvestorProfileRanges({handleEditClick, profileData}) {
    const {t} = useTranslation();
    const mdDown = useResponsive("down", "md");
    const theme = useTheme();
    const [activeButton, setActiveButton] = useState("gross_roi");
    const dataFieldStyleFont = {
        valueVariant: "h4", labelVariant: "body2", valueColor: "text.primary", labelColor: "text.primary",
    };
    const handleButtonClick = (buttonId) => {
        setActiveButton(buttonId);
    };

    const getProgressBarMax = () => {
        let maxVal = 0;
        switch (activeButton) {
            case "gross_roi":
                maxVal = Math.max(profileData?.optimalROIThreshold, profileData?.mediocreROIThreshold, profileData?.lowROIThreshold);
                break;
            case "cashflow":
                maxVal = Math.max(profileData?.optimalCashflowThreshold, profileData?.mediocreCashflowThreshold, profileData?.lowCashflowThreshold);
                break;
            case "patrimony_goal_label":
                maxVal = Math.max(profileData?.optimalPatrimonyThreshold, profileData?.mediocrePatrimonyThreshold, profileData?.lowPatrimonyThreshold);
                break;
            default:
                break;
        }
        return maxVal * 1.2;
    };

    const buttons = [
        {
            label: t('financial.profitability.gross_roi'),
            isActive: activeButton === "gross_roi",
            onClick: () => handleButtonClick("gross_roi"),
            color: 'inherit'

        },
        {
            label: t("investment_goals.cash_flow.title"),
            isActive: activeButton === "cashflow",
            onClick: () => handleButtonClick("cashflow"),
            color: 'inherit'
        },
        {
            label: t("investment_goals.patrimony_goal_label"),
            isActive: activeButton === "patrimony_goal_label",
            onClick: () => handleButtonClick("patrimony_goal_label"),
            color: 'inherit'

        },
    ];
    const desktopContent = (<BaseCard sx={{width: "100%"}}>
        <Typography variant="h5">{t("investment_goals.title")}</Typography>
        {profileData?.hasROIGoal &&
            <Box sx={{width: "100%", display: "flex", flexDirection: "column"}}>
                <Typography variant="subtitle1">{t("financial.profitability.gross_roi")}</Typography>
                <Box
                    sx={{width: "100%", display: "flex", flexDirection: "row", justifyContent: "space-evenly", mt: 1}}>
                    <DataField
                        icon={<WIdeal color={theme.palette.success.main}/>}
                        direction="column"
                        labeledIcon
                        label={t("quality_levels.ideal")}
                        value={FormatPercentage(`${profileData?.optimalROIThreshold}`)}
                        {...dataFieldStyleFont}
                    />
                    <DataField
                        icon={<WAcceptable color={theme.palette.primary.main}/>}
                        labeledIcon
                        direction="column"
                        label={t("quality_levels.moderate")}
                        value={FormatPercentage(`${profileData?.mediocreROIThreshold}`)}
                        {...dataFieldStyleFont}
                    />
                    <DataField
                        icon={<WLow color={theme.palette.error.main}/>}
                        labeledIcon
                        direction="column"
                        label={t("quality_levels.low")}
                        value={`${profileData?.lowROIThreshold}%`}
                        {...dataFieldStyleFont}
                    />
                </Box>
            </Box>
        }
        {profileData?.hasCashflowGoal &&
            <Box sx={{width: "100%", display: "flex", flexDirection: "column", mt: 4}}>
                <Typography variant="subtitle1">{t("investment_goals.cash_flow.title")}</Typography>
                <Box
                    sx={{width: "100%", display: "flex", flexDirection: "row", justifyContent: "space-evenly", mt: 1}}>
                    <DataField
                        icon={<WIdeal color={theme.palette.success.main}/>}
                        direction="column"
                        labeledIcon
                        label={t("quality_levels.ideal")}
                        value={FormatCurrency(profileData?.optimalCashflowThreshold)}
                        {...dataFieldStyleFont}
                    />
                    <DataField
                        icon={<WAcceptable color={theme.palette.primary.main}/>}
                        direction="column"
                        labeledIcon
                        label={t("quality_levels.moderate")}
                        value={FormatCurrency(profileData?.mediocreCashflowThreshold)}
                        {...dataFieldStyleFont}
                    />
                    <DataField
                        icon={<WLow color={theme.palette.error.main}/>}
                        labeledIcon
                        direction="column"
                        label={t("quality_levels.low")}
                        value={FormatCurrency(profileData?.lowCashflowThreshold)}
                        {...dataFieldStyleFont}
                    />
                </Box>
            </Box>
        }
        {profileData?.hasPatrimonyGoal &&
            <Box sx={{width: "100%", display: "flex", flexDirection: "column", mt: 4}}>
                <Typography
                    variant="subtitle1">{t("investment_goals.patrimony_goal_label")}</Typography>
                <Box
                    sx={{width: "100%", display: "flex", flexDirection: "row", justifyContent: "space-evenly", mt: 1}}>
                    <DataField
                        icon={<WIdeal color={theme.palette.success.main}/>}
                        iconPosition="left"
                        direction="column"
                        labeledIcon
                        label={t("quality_levels.ideal")}
                        value={FormatCurrency(profileData?.optimalPatrimonyThreshold)}
                        {...dataFieldStyleFont}
                    />

                    <DataField
                        icon={<WAcceptable color={theme.palette.primary.main}/>}
                        direction="column"
                        labeledIcon
                        label={t("quality_levels.moderate")}
                        value={FormatCurrency(profileData?.mediocrePatrimonyThreshold)}
                        {...dataFieldStyleFont}
                    />
                    <DataField
                        icon={<WLow color={theme.palette.error.main}/>}
                        direction="column"
                        labeledIcon
                        label={t("quality_levels.low")}
                        value={FormatCurrency(profileData?.lowPatrimonyThreshold)}
                        {...dataFieldStyleFont}
                    />
                </Box>
            </Box>
        }
    </BaseCard>)

    const renderMobileContent = () => {
        const progressBarMax = getProgressBarMax();

        const calculateProgress = (value) => (value / progressBarMax) * 100;

        const renderBoxWithProgress = (label, value, thresholdValue, color) => {
            const progressValue = calculateProgress(thresholdValue);
            return (
                <Box style={{marginBottom: theme.spacing(2)}}>
                    <Box style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                        <Typography variant="subtitle2" style={{flexGrow: 1, textAlign: 'left'}}>
                            {t(label)}
                        </Typography>
                        <Box style={{minWidth: '50px', textAlign: 'right'}}>
                            <Typography component="span" variant="subtitle2">{value}</Typography>
                        </Box>
                    </Box>
                    <LinearProgress
                        variant="determinate"
                        value={progressValue}
                        color={color}
                        style={{height: theme.spacing(0.5), borderRadius: 5, marginTop: theme.spacing(2)}}
                    />
                </Box>
            );
        };

        switch (activeButton) {
            case "gross_roi":
                return profileData?.hasROIGoal && (<>
                        {renderBoxWithProgress("quality_levels.ideal", FormatPercentage(`${profileData?.optimalROIThreshold}`), profileData?.optimalROIThreshold, "success")}
                        {renderBoxWithProgress("quality_levels.moderate", FormatPercentage(`${profileData?.mediocreROIThreshold}`), profileData?.mediocreROIThreshold, "primary")}
                        {renderBoxWithProgress("quality_levels.low", FormatPercentage(`${profileData?.lowROIThreshold}`), profileData?.lowROIThreshold, "error")}
                    </>
                );
            case "cashflow":
                return profileData?.hasCashflowGoal && (<>
                        {renderBoxWithProgress("quality_levels.ideal", FormatCurrency(profileData?.optimalCashflowThreshold), profileData?.optimalCashflowThreshold, "success")}
                        {renderBoxWithProgress("quality_levels.moderate", FormatCurrency(profileData?.mediocreCashflowThreshold), profileData?.mediocreCashflowThreshold, "primary")}
                        {renderBoxWithProgress("quality_levels.low", FormatCurrency(profileData?.lowCashflowThreshold), profileData?.lowCashflowThreshold, "error")}
                    </>
                );
            case "patrimony_goal_label":
                return profileData?.hasPatrimonyGoal && (<>
                        {renderBoxWithProgress("quality_levels.ideal", FormatCurrency(profileData?.optimalPatrimonyThreshold), profileData?.optimalPatrimonyThreshold, "success")}
                        {renderBoxWithProgress("quality_levels.moderate", FormatCurrency(profileData?.mediocrePatrimonyThreshold), profileData?.mediocrePatrimonyThreshold, "primary")}
                        {renderBoxWithProgress("quality_levels.low", FormatCurrency(profileData?.lowPatrimonyThreshold), profileData?.lowPatrimonyThreshold, "error")}
                    </>
                );
            default:
                return null;
        }
    };

    return (
        <Box
            sx={{
                alignItems: 'inherit !important',
                display: 'flex',
                flexDirection: 'column',
                gap: '16px',
                alignSelf: 'stretch',
            }}
        >


        {mdDown ? (
                <>
                    <ScrollButtons buttons={buttons} sx={{paddingX: 12}} indicator/>
                    {renderMobileContent()}
                </>
            ) : desktopContent}

            <Button
                variant="contained"
                color="primary"
                onClick={handleEditClick}
                style={{alignSelf: 'flex-end'}}
            >
                {t("user_profile.edit_profile")}
            </Button>
        </Box>
    );

}