import React, {useEffect, useState} from "react";
import {IconButton} from "@mui/material";
import {useTranslation} from "react-i18next";
import {WSpain, WUsa} from "../icons/walden-icons";

const LanguageToggleButton = () => {
    const {i18n} = useTranslation();

    const getInitialLanguage = () => {
        const storedLanguage = localStorage.getItem('language');
        if (storedLanguage === 'es' || storedLanguage === 'en') {
            return storedLanguage;
        }

        const browserLang = navigator.language.split('-')[0];
        if (browserLang === 'es' || browserLang === 'en') {
            return browserLang;
        }
        return 'es';
    };

    const [language, setLanguage] = useState(getInitialLanguage);

    useEffect(() => {
        i18n.changeLanguage(language);
        localStorage.setItem('language', language);
    }, [language, i18n]);

    const toggleLanguage = () => {
        setLanguage(prevLang => prevLang === 'en' ? 'es' : 'en');
    };

    return (
        <IconButton onClick={toggleLanguage} size="large">
            {language === 'en' ? <WUsa/> : <WSpain/>}
        </IconButton>
    );
};

export default LanguageToggleButton;