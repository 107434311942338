import {
    WCovers,
    WElectricity,
    WExteriorCarpentry,
    WExteriorFinish,
    WExteriorMasonry,
    WFirefighting,
    WFloorsStepsAndTiling,
    WInnerLining,
    WInteriorCarpentry,
    WInteriorFinish,
    WInteriorMasonry,
    WOuterCoating,
    WPlumbing,
    WSafetyAndHealth,
    WSanitaryAppliancesAndFaucets,
    WSanitation,
    WTelecommunications,
    WVentilationExtraction
} from "../../components/icons/walden-icons";

export const GetValidationItem = (key, t, theme) => {
    const itemMaps = {

        'exterior_masonry': {
            icon: <WExteriorMasonry color={theme.palette.primary.dark}/>,
            title: t('visual_validation.exterior_masonry_title'),
            text: t('visual_validation.exterior_masonry_desc'),
            facelift: t('visual_validation.facelift_exterior_masonry'),
            arrangements: t('visual_validation.arrangements_exterior_masonry'),
            integral: t('visual_validation.integral_exterior_masonry')
        },
        'interior_masonry': {
            icon: <WInteriorMasonry color={theme.palette.primary.dark}/>,
            title: t('visual_validation.interior_masonry_title'),
            text: t('visual_validation.interior_masonry_desc'),
            facelift: t('visual_validation.facelift_interior_masonry'),
            arrangements: t('visual_validation.arrangements_interior_masonry'),
            integral: t('visual_validation.integral_interior_masonry')
        },
        'interior_finish': {
            icon: <WInteriorFinish color={theme.palette.primary.dark}/>,
            title: t('visual_validation.interior_finish_title'),
            text: t('visual_validation.interior_finish_desc'),
            facelift: t('visual_validation.facelift_interior_finish'),
            arrangements: t('visual_validation.arrangements_interior_finish'),
            integral: t('visual_validation.integral_interior_finish')
        },
        'outer_coating': {
            icon: <WOuterCoating color={theme.palette.primary.dark}/>,
            title: t('visual_validation.outer_coating_title'),
            text: t('visual_validation.outer_coating_desc'),
            facelift: t('visual_validation.facelift_outer_coating'),
            arrangements: t('visual_validation.arrangements_outer_coating'),
            integral: t('visual_validation.integral_outer_coating')
        },
        'ventilation_extraction': {
            icon: <WVentilationExtraction color={theme.palette.primary.dark}/>,
            title: t('visual_validation.ventilation_extraction_title'),
            text: t('visual_validation.ventilation_extraction_desc'),
            facelift: t('visual_validation.facelift_ventilation_extraction'),
            arrangements: t('visual_validation.arrangements_ventilation_extraction'),
            integral: t('visual_validation.integral_ventilation_extraction')
        },
        'sanitation': {
            icon: <WSanitation color={theme.palette.primary.dark}/>,
            title: t('visual_validation.sanitation_title'),
            text: t('visual_validation.sanitation_desc'),
            facelift: t('visual_validation.facelift_sanitation'),
            arrangements: t('visual_validation.arrangements_sanitation'),
            integral: t('visual_validation.integral_sanitation')
        },
        'plumbing': {
            icon: <WPlumbing color={theme.palette.primary.dark}/>,
            title: t('visual_validation.plumbing_title'),
            text: t('visual_validation.plumbing_desc'),
            facelift: t('visual_validation.facelift_plumbing'),
            arrangements: t('visual_validation.arrangements_plumbing'),
            integral: t('visual_validation.integral_plumbing')
        },
        'telecommunications': {
            icon: <WTelecommunications color={theme.palette.primary.dark}/>,
            title: t('visual_validation.telecommunications_title'),
            text: t('visual_validation.telecommunications_desc'),
            facelift: t('visual_validation.facelift_telecommunications'),
            arrangements: t('visual_validation.arrangements_telecommunications'),
            integral: t('visual_validation.integral_telecommunications')
        },
        'safety_and_health': {
            icon: <WSafetyAndHealth color={theme.palette.primary.dark}/>,
            title: t('visual_validation.safety_and_health_title'),
            text: t('visual_validation.safety_and_health_desc'),
            facelift: t('visual_validation.facelift_safety_and_health'),
            arrangements: t('visual_validation.arrangements_safety_and_health'),
            integral: t('visual_validation.integral_safety_and_health')
        },
        'firefighting': {
            icon: <WFirefighting color={theme.palette.primary.dark}/>,
            title: t('visual_validation.firefighting_title'),
            text: t('visual_validation.firefighting_desc'),
            facelift: t('visual_validation.facelift_firefighting'),
            arrangements: t('visual_validation.arrangements_firefighting'),
            integral: t('visual_validation.integral_firefighting')
        },
        'sanitary_appliances_and_faucets': {
            icon: <WSanitaryAppliancesAndFaucets color={theme.palette.primary.dark}/>,
            title: t('visual_validation.sanitary_appliances_and_faucets_title'),
            text: t('visual_validation.sanitary_appliances_and_faucets_desc'),
            facelift: t('visual_validation.facelift_sanitary_appliances_and_faucets'),
            arrangements: t('visual_validation.arrangements_sanitary_appliances_and_faucets'),
            integral: t('visual_validation.integral_sanitary_appliances_and_faucets')
        },
        'electricity': {
            icon: <WElectricity color={theme.palette.primary.dark}/>,
            title: t('visual_validation.electricity_title'),
            text: t('visual_validation.electricity_desc'),
            facelift: t('visual_validation.facelift_electricity'),
            arrangements: t('visual_validation.arrangements_electricity'),
            integral: t('visual_validation.integral_electricity')
        },
        'exterior_finish': {
            icon: <WExteriorFinish color={theme.palette.primary.dark}/>,
            title: t('visual_validation.exterior_finish_title'),
            text: t('visual_validation.exterior_finish_desc'),
            facelift: t('visual_validation.facelift_exterior_finish'),
            arrangements: t('visual_validation.arrangements_exterior_finish'),
            integral: t('visual_validation.integral_exterior_finish')
        },
        'interior_carpentry': {
            icon: <WInteriorCarpentry color={theme.palette.primary.dark}/>,
            title: t('visual_validation.interior_carpentry_title'),
            text: t('visual_validation.interior_carpentry_desc'),
            facelift: t('visual_validation.facelift_interior_carpentry'),
            arrangements: t('visual_validation.arrangements_interior_carpentry'),
            integral: t('visual_validation.integral_interior_carpentry')
        },
        'exterior_carpentry': {
            icon: <WExteriorCarpentry color={theme.palette.primary.dark}/>,
            title: t('visual_validation.exterior_carpentry_title'),
            text: t('visual_validation.exterior_carpentry_desc'),
            facelift: t('visual_validation.facelift_exterior_carpentry'),
            arrangements: t('visual_validation.arrangements_exterior_carpentry'),
            integral: t('visual_validation.integral_exterior_carpentry')
        },
        'covers': {
            icon: <WCovers color={theme.palette.primary.dark}/>,
            title: t('visual_validation.covers_title'),
            text: t('visual_validation.covers_desc'),
            facelift: t('visual_validation.facelift_covers'),
            arrangements: t('visual_validation.arrangements_covers'),
            integral: t('visual_validation.integral_covers')
        },
        'inner_lining': {
            icon: <WInnerLining color={theme.palette.primary.dark}/>,
            title: t('visual_validation.inner_lining_title'),
            text: t('visual_validation.inner_lining_desc'),
            facelift: t('visual_validation.facelift_inner_lining'),
            arrangements: t('visual_validation.arrangements_inner_lining'),
            integral: t('visual_validation.integral_inner_lining')
        },
        'floors_steps_and_tiling': {
            icon: <WFloorsStepsAndTiling color={theme.palette.primary.dark}/>,
            title: t('visual_validation.floors_steps_and_tiling_title'),
            text: t('visual_validation.floors_steps_and_tiling_desc'),
            facelift: t('visual_validation.facelift_floors_steps_and_tiling'),
            arrangements: t('visual_validation.arrangements_floors_steps_and_tiling'),
            integral: t('visual_validation.integral_floors_steps_and_tiling')
        },
    }
    return itemMaps[key]
}