import {useSettingsContext} from "../settings";
import Box from "@mui/material/Box";
import {NAV} from "../../pages/config-layout";
import {useTheme} from "@mui/material";
import NavigationContent from "./navigation-content";
import {useResponsive} from "../../hooks/use-responsive";
import React, {useEffect} from "react";
import Scrollbar from "../scrollbar";

export default function VerticalNavigation() {
    const theme = useTheme();
    const settings = useSettingsContext();
    const lgDown = useResponsive("down", "lg");
    const xlUp = useResponsive("up", "xl");

    useEffect(() => {
        xlUp ? settings.onOpen() : settings.onClose();
    }, [xlUp]);

    // hide vertical navigation on small devices. It's not necessary for small devices.
    if (lgDown) {
        return null;
    }

    const width = settings.open ? NAV.W_VERTICAL_OPENED : NAV.W_VERTICAL_CLOSED;

    return (
        <Box sx={{
            display: "flex",
            width: width,
            height: "100%",
            backgroundColor: theme.palette.background.default,
            borderRight: (theme) => `dashed 1px ${theme.palette.divider}`,
            position: "relative"
        }}>
            <Scrollbar>
                <NavigationContent/>

            </Scrollbar>

        </Box>
    );
}