import {apiService} from "./ApiServiceV2";
import {useAuth0} from "@auth0/auth0-react";
import {useMutation} from "react-query";
import {useTranslation} from "react-i18next";
import toast from "react-hot-toast";

export function useProfileStorer(onSuccess = () => {
}) {
    const auth0Context = useAuth0();
    const {t} = useTranslation();


    return useMutation(profileData => toast.promise(apiService.storeProfile(auth0Context, profileData), {
        loading: t("general.saving"), success: t('general.saved'), error: t("feedback.error.api_request_error")
    }), {
        onSuccess: (response) => {
            onSuccess(response.data);
        }, onError: (error) => {
            if (error?.response?.status === 409) {
                toast.error(t('oikos.add_properties.already_exists_warning_message'));
            }
        },
    });
}
