import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {PrimaryButton} from "../../components/buttons";
import ROUTES from "../../routes";
import Typography from "@mui/material/Typography";
import React from "react";
import {handleAddValidation} from "../validation_add/add-validation-func";

export default function AddOikosButton({fetchedProfileData}) {
    const navigate = useNavigate();
    const {t} = useTranslation();

    const handleClick = () => {
        handleAddValidation(fetchedProfileData, navigate, ROUTES.properties.add);
    };

    return <PrimaryButton onClick={handleClick}>
        <Typography variant="button">{t("oikos.add_properties.add_button_label").toUpperCase()}</Typography>
    </PrimaryButton>

}