import {apiService} from "../ApiServiceV2";
import {fDate} from "../../utils/format-time";
import {addressAsString} from "../../utils/financial";
import {useQuery} from "react-query";
import toast from "react-hot-toast";
import {useTranslation} from "react-i18next";
import {useAuth0} from "@auth0/auth0-react";

export function useOikosLister() {
    const auth0Context = useAuth0();
    const {t} = useTranslation();

    return useQuery("oikosList", async () => {
            const response = await apiService.listOikos(auth0Context);
            return response.data.map(item => responseToItemModel(item));

        },
        {
            onError: () => {
                toast.error(t("feedback.error.general_api_error"));
            }
        });
}

function responseToItemModel(item) {
    const walden_financial = item?.financial?.walden_financial
    const market_financial = item?.financial?.market_financial
    return {
        id: item?.oikos_id,
        status: item?.status,
        creation_date: fDate(item?.oikos_date),
        address: addressAsString(item?.address),
        reference: item?.reference,
        has_patrimony_goal: item.has_patrimony_goal,
        has_cash_flow_goal: item?.has_cash_flow_goal,
        patrimony_goal: item?.patrimony_goal,
        cash_flow_goal: {
            yearly: item?.yearly_cash_flow_goal, monthly: item?.monthly_cash_flow_goal,
        },
        financial: {
            walden: {
                patrimony: walden_financial?.patrimony, cash_flow: {
                    yearly: {
                        optimistic: walden_financial?.yearly_cash_flow_optimistic,
                        prudent: walden_financial?.yearly_cash_flow_prudent
                    }, monthly: {
                        optimistic: walden_financial?.monthly_cash_flow_optimistic,
                        prudent: walden_financial?.monthly_cash_flow_prudent
                    }
                }, performance: walden_financial?.performance, gross_roi: walden_financial?.gross_roi,
            }, market: {
                patrimony: market_financial?.patrimony, cash_flow: {
                    yearly: {
                        optimistic: market_financial?.yearly_cash_flow_optimistic,
                        prudent: market_financial?.yearly_cash_flow_prudent
                    }, monthly: {
                        optimistic: market_financial?.monthly_cash_flow_optimistic,
                        prudent: market_financial?.monthly_cash_flow_prudent
                    }
                }, performance: market_financial?.performance, gross_roi: market_financial?.gross_roi,
            }
        }
    }
}