import {EditValueButton} from "../../components/buttons";
import {useState} from "react";
import {useUpdateOikosParameters} from "../../services/oikos/oikos-updater";
import {Mixpanel} from "../../metrics/mixpanel";
import {buildParametersRequest} from "../parameters";
import toast from "react-hot-toast";
import {useTranslation} from "react-i18next";
import {useOikosDetails} from "../../services/oikos/oikos-detailer";
import {useLocation} from "react-router-dom";

export function OikosPriceFeedback({data}) {
    const {t} = useTranslation();
    const [value, setValue] = useState(0);
    const oikosID = useLocation().state;

    const {mutate} = useUpdateOikosParameters(
        () => {
            toast.success(t("feedback.success.general_api_success"));
        },
        () => {
            toast.error(t('feedback.error.api_request_error'));
        }
    );
    const {data: apiResponse, isLoading, refetch} = useOikosDetails(oikosID);

    const buildRequest = () => {
        const params = {
            ...data?.oikos?.parameters,
            id: data?.oikos?.parameters?.ID,
            custom_rent_value: value
        };
        return buildParametersRequest(params, {
            oikos: {
                ID: data?.oikos?.ID,
                cadastre_id: data?.oikos?.cadastre_id,
            }
        })
    }

    const onDialogSave = () => {
        mutate(buildRequest(), {
            onSuccess: () => {
                Mixpanel.track('Oikos Detail: Price feedback provided');
                refetch();
            }
        });
    }

    const onTextFieldChange = (event) => {
        setValue(event.target.value);
    }

    return <EditValueButton
        isOikos
        value={value}
        onDialogSave={onDialogSave}
        onTextFieldChange={onTextFieldChange}
    />
}