import {Button, IconButton, keyframes, useTheme} from "@mui/material";
import {useState} from "react";
import {WRefresh} from "../components/icons/walden-icons";
import {useTranslation} from "react-i18next";
import Typography from "@mui/material/Typography";
import {useResponsive} from "../hooks/use-responsive";

const rotate = keyframes`
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
`;

const RefreshButton = ({handleRefresh}) => {
    const {t} = useTranslation();
    const theme = useTheme();
    const [isRotating, setIsRotating] = useState(false);
    const mdDown = useResponsive('down', 'md');

    const handleClick = () => {
        setIsRotating(true);
        handleRefresh();
        setTimeout(() => setIsRotating(false), 1000);
    };

    return mdDown ? (
        <IconButton
            onClick={handleClick}
            sx={{
                color: theme.palette.action.active
            }}
        >
            <WRefresh sx={{animation: `${rotate} 1s linear ${isRotating ? '' : 'infinited'}`}}/>
        </IconButton>
    ) : (
        <Button
            sx={{
                paddingY: 0.5,
                color: theme.palette.action.active
            }}
            variant="outlined"
            startIcon={<WRefresh sx={{animation: `${rotate} 1s linear ${isRotating ? '' : 'infinited'}`}}/>}
            onClick={handleClick}
        >
            <Typography variant="caption1">{t('general.refresh')}</Typography>
        </Button>
    );
};

export default RefreshButton;