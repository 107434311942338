import {useTranslation} from "react-i18next";
import React, {useEffect, useState} from "react";
import {ValidationParametersContext} from "./ParametersContext";
import {Card, FormControlLabel, Radio, Stack} from "@mui/material";
import Typography from "@mui/material/Typography";
import {CurrencyField} from "../../components/text_fields";
import Box from "@mui/material/Box";
import {useResponsive} from "../../hooks/use-responsive";

function CheckIsRented(rent_value) {
    return Boolean(rent_value)
}

export default function RentSection() {
    const {t} = useTranslation();
    const {validationParameters, setValidationParameters} = React.useContext(ValidationParametersContext);
    const [isRented, setIsRented] = useState(CheckIsRented(validationParameters?.rent_value));
    const mdUp = useResponsive('up', 'md');
    const CardOrBox = mdUp ? Card : Box;

    useEffect(() => {
        setIsRented(CheckIsRented(validationParameters?.rent_value));
    }, [validationParameters?.rent_value]);

    const handleRentedToggle = () => {
        setIsRented(prev => {
            const newValue = !prev;
            setValidationParameters((prev) => {
                const rentValue = newValue ? prev.rent_value : undefined;
                return {
                    ...prev,
                    rent_value: rentValue
                }
            })
            return newValue;
        });
    }
    const cardStyle = {
        padding: "24px"
    };
    const rentChanged = (event) => {
        const value = event.target.value;
        setValidationParameters((prev) => {
            return {
                ...prev,
                rent_value: value,
            }
        });
    }

    return (
        <CardOrBox style={mdUp ? cardStyle : {}}>
            <Stack spacing={2}>
                <Typography>{t("oikos.add_properties.is_rented_switch_label")}</Typography>
                <Stack direction="row">
                    <FormControlLabel value={true}
                                      checked={isRented}
                                      control={<Radio onClick={handleRentedToggle}/>}
                                      label={t("general.yes")}/>
                    <FormControlLabel value={false}
                                      checked={!isRented}
                                      control={<Radio onClick={handleRentedToggle}/>}
                                      label={t("general.no")}/>
                </Stack>
                {isRented ?
                    <Box>
                        <CurrencyField fullWidth={true}
                                       onChange={rentChanged}
                                       value={validationParameters?.rent_value}
                                       disabled={!isRented}
                                       label={t("oikos.add_properties.rent_value")}/>
                    </Box>
                    : null}
            </Stack>
        </CardOrBox>);
}

