import Typography from "@mui/material/Typography";
import React from "react";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {Fab} from "@mui/material";
import {useResponsive} from "../../hooks/use-responsive";
import {handleAddValidation} from "./add-validation-func";
import ROUTES from "../../routes";

export default function AddValidationFabButton({fetchedProfileData}) {
    const navigate = useNavigate();
    const {t} = useTranslation();
    const smUp = useResponsive('up', 'sm');

    if (smUp) return null;

    const handleClick = () => {
        handleAddValidation(fetchedProfileData, navigate, ROUTES.validations.add);
    };

    return (
        <Fab
            variant="extended"
            sx={{
                position: "absolute", bottom: 140, right: 16
            }}
            onClick={handleClick}
        >
            <Typography variant="button">
                {t("validation_list.add_validation.add_button_label").toUpperCase()}
            </Typography>
        </Fab>
    );
}