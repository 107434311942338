import React from "react";
import {Grid, useMediaQuery, useTheme} from "@mui/material";
import {InvestmentCostSection} from "./InvestmentCostSection";
import {HoldingCostSection} from "./HoldingCostSection";
import {MortgageSection} from "./MortgageSection";

export const AllParameters = ({validation}) => {
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));


    return (<Grid container spacing={isSmallScreen ? 4 : 6}>

        <Grid item xs={12}>
            <MortgageSection/>

        </Grid>
        <Grid item xs={12}>
            <InvestmentCostSection validation={validation}/>

        </Grid>

        <Grid item xs={12}>
            <HoldingCostSection/>
        </Grid>
    </Grid>);
}