import React from 'react';
import {useTranslation} from 'react-i18next';
import {BaseCard} from "./index";
import {Box} from "@mui/material";
import PageTitle from "../titles";
import ProductUses from "../../common/product-uses/product-uses";

const BenefitsCard = () => {
    const {t} = useTranslation();

    return (
        <BaseCard sx={{justifyContent: 'center', alignItems: 'center'}}>
            <Box sx={{display: "flex", justifyContent: 'flex-start', width: "100%"}}>
                <PageTitle>{t('dashboard.user_balance')}</PageTitle>
            </Box>
            <ProductUses/>
        </BaseCard>
    );
};

export default BenefitsCard;