import Typography from "@mui/material/Typography";
import {Box, useTheme} from "@mui/material";
import React from "react";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

export const SectionTitle = ({title, variant, level}) => {
    const theme = useTheme();

    const levels = {
        high: {
            color: theme.palette.success,
            Icon: CheckCircleOutlineIcon
        },
        medium: {
            color: theme.palette.warning,
            Icon: WarningAmberOutlinedIcon
        },
        low: {
            color: theme.palette.error,
            Icon: ErrorOutlineIcon
        }
    };

    const {color, Icon} = levels[level] || {};

    return (
        <Box display="flex" flexDirection="column" alignItems="center" padding={1} marginBottom={2}>
            <Box display="flex" alignItems="center">
                {Icon && (
                    <Box marginRight={1}>
                        <Icon style={{color: color}}/>
                    </Box>
                )}
                <Typography variant={variant} style={{color: color}}>
                    {title}
                </Typography>
            </Box>
        </Box>
    )
}
