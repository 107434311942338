import React from 'react';
import {Route, Routes} from 'react-router-dom';
import ProtectedRoute from './ProtectedRoute';
import {Page403} from "./pages/errors/Page403";
import Logout from "./Logout";
import ValidationList from "./pages/validations_list";
import ValidationDetail from "./pages/validation_detail/";
import ROUTES from "./routes";
import InvestorProfile from "./pages/investor_profile/";
import AddValidation from "./pages/validation_add/";
import OikosList from "./pages/oikos_list";
import AddOikos from "./pages/oikos_add";
import {OikosDetail} from "./pages/oikos_detail";
import {Dashboard} from "./pages/dashboard";
import {OnboardingIntro, OnboardingInvestorProfile, OnboardingOutro} from "./pages/onboarding";
import {OnboardingProperty} from "./pages/onboarding/onboarding-property";
import ValidationChecklist from "./pages/validation_checklist/validation-checklist";
import {SubscriptionPage} from "./pages/payment";


function App() {


    return <Routes>
        <Route path={ROUTES.onboarding.intro} element={<OnboardingIntro/>}/>
        <Route path={ROUTES.index} element={<ProtectedRoute/>}>
            <Route index element={<Dashboard/>}/>
            <Route path={ROUTES.subscription} element={<SubscriptionPage/>}/>
            <Route path={ROUTES.onboarding.profile} element={<OnboardingOutro/>}/>
            <Route path={ROUTES.onboarding.profile_setup} element={<OnboardingInvestorProfile/>}/>
            <Route path={ROUTES.onboarding.property} element={<OnboardingProperty/>}/>
            <Route path={ROUTES.dashboard} element={<Dashboard/>}/>
            <Route path={ROUTES.validations.list} element={<ValidationList/>}/>
            <Route path={ROUTES.validations.add} element={<AddValidation/>}/>
            <Route path={ROUTES.validations.detail} element={<ValidationDetail/>}/>
            <Route path={ROUTES.validations.checklist} element={<ValidationChecklist/>}/>
            <Route path={ROUTES.properties.list} element={<OikosList/>}/>
            <Route path={ROUTES.properties.add} element={<AddOikos/>}/>
            <Route path={ROUTES.properties.detail} element={<OikosDetail/>}/>
            <Route path={ROUTES.profile} element={<InvestorProfile/>}/>
        </Route>
        <Route path="/403" element={<Page403/>}/>
        <Route path={ROUTES.logout} element={<Logout/>}/>
    </Routes>
        ;
}

export default App;
