import {NavigationItem} from "./navItem";
import ROUTES from "../../routes";
import {useSettingsContext} from "../settings";
import {useResponsive} from "../../hooks/use-responsive";
import Typography from "@mui/material/Typography";
import {useTranslation} from "react-i18next";
import {ListItem} from "@mui/material";
import {WDashboard, WProfile, WProperties, WValidations} from "../icons/walden-icons";
import React from "react";
import Box from "@mui/material/Box";
import FeedbackDialog from "../buttons/feedback-button-dialog";

export default function NavigationContent() {
    const lgDown = useResponsive("down", "lg");
    const settings = useSettingsContext();
    const {t} = useTranslation();

    const fullVersion = lgDown || settings.open;

    return (
        <Box sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            padding: "10px",
            height: "100%"
        }}>
            {fullVersion ? <ListItem>
                <Typography variant="caption" color="text.disabled">
                    {t('navigation_menu.investments_title').toUpperCase()}
                </Typography>
            </ListItem> : null}

            <Box sx={{display: 'flex', flexDirection: 'column', gap: '15px', mt: '10px', flexGrow: 1}}>
                <Box className="menu_dashboard">
                    <NavigationItem
                        icon={<WDashboard/>}
                        label="Dashboard"
                        url={ROUTES.dashboard}
                    />
                </Box>

                <Box className="menu_properties">
                    <NavigationItem
                        icon={<WProperties/>}
                        label={fullVersion ? t('navigation_menu.my_properties') : t('navigation_menu.properties')}
                        url={ROUTES.properties.list}
                    />
                </Box>
                <Box className="menu_validations">
                    <NavigationItem
                        icon={<WValidations/>}
                        label={fullVersion ? t('navigation_menu.my_validations') : t('navigation_menu.validations')}
                        url={ROUTES.validations.list}
                    />
                </Box>
                {fullVersion ? <ListItem>
                    <Typography variant="caption" color="text.disabled">
                        {t('navigation_menu.account_title').toUpperCase()}
                    </Typography>
                </ListItem> : null}
                <Box className="menu_profile">
                    <NavigationItem
                        icon={<WProfile/>}
                        label={fullVersion ? t('navigation_menu.investor_profile') : t('navigation_menu.profile')}
                        url={ROUTES.profile}
                    />
                </Box>
                <Box sx={{position: "absolute", bottom: lgDown ? 0 : 65, left: 0, width: "100%", padding: "10px"}}>
                    <FeedbackDialog/>
                </Box>
            </Box>


        </Box>
    );
}