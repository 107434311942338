import React, {useEffect, useState} from 'react';
import {Grid} from "@mui/material";
import {useTranslation} from "react-i18next";
import Box from "@mui/material/Box";
import PropertyDescription from "./property-description";
import Owners from "./owners";
import Comments from "./comments";
import Charges from "./charges";
import AdministrativeMortgage from "./mortgage";
import CadastreRegistryMatcher from "./cadastre-refistry-matcher";
import {PrimaryButton, SecondaryButton} from "../../components/buttons";
import {
    useParsedAdministrativeValidationData
} from "../../services/validationRequest/administrative-validation-detailer";
import {WDownloadNS} from "../../components/icons/walden-icons";
import {useLocation} from "react-router-dom";
import {Mixpanel} from "../../metrics/mixpanel";
import {LoadingScreenBar} from "../../components/loading-screen";
import {StepsAdministrativeValidation} from "./steps-administrative-validation";
import {useAnalysisDownloader} from "../../services/validationRequest/analysis-downloader";
import {useAuth0} from "@auth0/auth0-react";
import {useSimpleNotePurchaseUrl} from "../../services/validationRequest/administrative-validation-requester";
import {EmptyValidationList} from "../../components/app-status";
import {ValidationAdministrativeTour} from "../../components/tour/";

const success_url = process.env.REACT_APP_STRIPE_SUCCESS_URL;
const cancel_url = process.env.REACT_APP_STRIPE_CANCEL_URL;


function AdministrativeValidation({propertyData}) {
    const validationRequestId = useLocation().state;
    const {t} = useTranslation();
    const {data: parsedData, isLoading} = useParsedAdministrativeValidationData(validationRequestId, 0);
    const auth0Context = useAuth0();

    const [downloadEnabled, setDownloadEnabled] = useState(false);
    const {
        data: DownloadLink,
        isLoading: isLoadingDownload
    } = useAnalysisDownloader(validationRequestId, downloadEnabled, true);

    const requestData = {
        "validation_request_id": validationRequestId,
        "user_email": auth0Context.user.email,
        "success_url": success_url,
        "cancel_url": cancel_url
    };
    const [requestAnalisisEnabled, setRequestAnalisisEnabled] = useState(false);
    const {
        data: purchaseUrl,
        isLoading: isLoadingPurchase
    } = useSimpleNotePurchaseUrl(requestData, requestAnalisisEnabled, true);


    const handleRequestPropertyAnalysis = async () => {
        Mixpanel.track('Administrative Validation: Request Property Analysis');
        setRequestAnalisisEnabled(true);
    };


    const handleDownloadClick = () => {
        Mixpanel.track('Administrative Validation: Download Nota Simple');

        setDownloadEnabled(true);
    };


    useEffect(() => {
        if (purchaseUrl && purchaseUrl?.data?.nota_simple_purchase_session_url) {
            window.open(purchaseUrl?.data?.nota_simple_purchase_session_url, '_blank');
            setRequestAnalisisEnabled(false);
        }
    }, [purchaseUrl]);

    useEffect(() => {
        if (DownloadLink && DownloadLink.url) {
            window.open(DownloadLink.url, '_blank');
            setDownloadEnabled(false);
        }
    }, [DownloadLink]);

    if (isLoading) {
        return <LoadingScreenBar/>;
    }


    if (parsedData?.status === "ANALYSIS_PENDING") {
        return (
            <StepsAdministrativeValidation/>
        );
    }

    if (!parsedData) {
        return (<Box className='tour-administrative-validation-description'>
                <EmptyValidationList
                    imageSrc="https://cdn.walden.ai/media/img/app/blank-state-analisis-propiedades.svg">
                    <ValidationAdministrativeTour parsedData={parsedData}/>
                    <Box className="tour-administrative-validation-request">
                        <PrimaryButton
                            sx={{maxWidth: "300px"}}
                            onClick={handleRequestPropertyAnalysis}
                            loading={isLoadingPurchase}
                        >
                            {t('administrative_validation.request_administrative_validation')}
                        </PrimaryButton>
                    </Box>

                </EmptyValidationList>
            </Box>

        );
    }

    return <>
        <ValidationAdministrativeTour parsedData={parsedData}/>
        <Box className='tour-administrative-validation-first-step'
             sx={{mt: 4, display: 'flex', flexDirection: 'column', gap: 4}}>

            <Box className="tour-administrative-validation-second-step"
                 sx={{display: "flex", justifyContent: "flex-end"}}>
                <SecondaryButton
                    sx={{maxWidth: "300px"}}
                    onClick={handleDownloadClick}
                    isLoading={isLoadingDownload}
                    className="tour-administrative-validation-third-step"
                >
                    <WDownloadNS sx={{marginRight: 1}}/>
                    {t('administrative_validation.download_ns')}
                </SecondaryButton>
            </Box>


            <Grid container spacing={4}>
                <Grid item xs={12}>
                    <div className="tour-administrative-validation-fourth-step">
                        <Comments comments={parsedData?.comments}/>
                    </div>
                </Grid>
                <Grid item xs={12} md={8}>
                    <div className="tour-administrative-validation-fifth-step">
                        <PropertyDescription parsedData={parsedData}/>
                    </div>
                </Grid>
                <Grid item xs={12} md={4}>
                    <div className="tour-administrative-validation-sixth-step">
                        <AdministrativeMortgage mortgage={parsedData?.mortgage}/>
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <div className="tour-administrative-validation-seventh-step">
                        <Owners owners={parsedData?.owners}/>
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <div className="tour-administrative-validation-eighth-step">
                        <CadastreRegistryMatcher
                            catastre={propertyData?.reference} catastreFromNS={parsedData?.cadastre_reference}
                            area={propertyData?.building_area} areaFromNS={parsedData?.property_area}
                            energyEfficiency={propertyData.energy_certificate}
                            energyEfficiencyFromNS={parsedData?.energy_efficiency_rating}
                        />
                    </div>
                </Grid>


            </Grid>


            <Charges charges={parsedData?.charges}/>
        </Box>
    </>
}

export default AdministrativeValidation;