import Typography from "@mui/material/Typography";
import {PrimaryButton} from "../../components/buttons";
import React from "react";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {handleAddValidation} from "./add-validation-func";
import ROUTES from "../../routes";
import {useRequestDemo} from "../../services/request-demo";

export default function AddValidationButton({fetchedProfileData, demo, refetch}) {
    const navigate = useNavigate();
    const {t} = useTranslation();
    const {mutate: requestDemo} = useRequestDemo();

    const handleClick = () => {
        if (demo) {
            requestDemo(null, {
                onSuccess: () => {
                    refetch();
                }
            });
        } else {
            handleAddValidation(fetchedProfileData, navigate, ROUTES.validations.add);
        }
    };

    return (
        <PrimaryButton onClick={handleClick}>
            <Typography variant="button">
                {demo
                    ? t("validation_list.add_validation.request_demo").toUpperCase()
                    : t("validation_list.add_validation.add_button_label").toUpperCase()
                }
            </Typography>
        </PrimaryButton>
    );
}