import {OnboardingPage} from "./onboarding-page";
import {BaseCard} from "../../components/cards";
import {InvestorProfileEditView} from "../investor_profile/investorProfileEditView";
import {useResponsive} from "../../hooks/use-responsive";
import React, {useEffect, useState} from 'react';
import {PrimaryButton, TertiaryButton} from "../../components/buttons";
import Stack from "@mui/material/Stack";
import PageTitle from "../../components/titles";
import Typography from "@mui/material/Typography";
import {useNavigate} from "react-router-dom";
import ROUTES from "../../routes";
import {useProfileDetailer} from "../../services/profile-detailer";
import {LoadingScreenBar} from "../../components/loading-screen";
import {useTranslation} from "react-i18next";


const defaultProfileData = {
    region: "None",
    country: "None",
    salary: 0,
    community_id: "None",
    hasROIGoal: true,
    optimalROIThreshold: 11,
    mediocreROIThreshold: 9,
    lowROIThreshold: 5,
    hasCashflowGoal: true,
    optimalCashflowThreshold: 1200,
    mediocreCashflowThreshold: 700,
    lowCashflowThreshold: 400,
    hasPatrimonyGoal: true,
    optimalPatrimonyThreshold: 500000,
    mediocrePatrimonyThreshold: 200000,
    lowPatrimonyThreshold: 100000,
}

export function OnboardingInvestorProfile() {
    const {data: fetchedProfileData, isLoading} = useProfileDetailer(0, null, null, false);
    const mdDown = useResponsive('down', 'md');
    const [profileData, setProfileData] = useState(defaultProfileData);
    const [step, setStep] = useState(0);
    const [submittable, setSubmittable] = useState(false);
    const navigate = useNavigate();
    const [readyToSave, setReadyToSave] = useState(false);
    const {t} = useTranslation();

    const handleNext = () => {
        setStep(prev => {
            if (prev + 1 === stepsData.length) {
                return prev;
            }
            return prev + 1
        })
    };

    const handleBackOnboardingOutro = () => {
        navigate(ROUTES.onboarding.profile);
    }


    const handleBack = () => {
        setStep(prev => {
            if (prev - 1 < 0) {
                return prev;
            }
            return prev - 1
        })
    };
    const handleSave = () => {
        setReadyToSave(true);
    }

    const stepsData = [
        {
            title: t('onboarding_investor_profile.step1.title'),
            description: t('onboarding_investor_profile.step1.description'),
            nextCTALabel: t('onboarding_investor_profile.step1.next_cta_label'),
            nextCTAFunc: handleNext,
            nextCheckSubmittable: () => submittable,
            backCTALabel: t('onboarding_investor_profile.step1.back_cta_label'),
            backCTAFunc: handleBackOnboardingOutro,
            showInvestmentGoals: false,
            showAboutMe: true,
        },
        {
            title: t('onboarding_investor_profile.step2.title'),
            description: t('onboarding_investor_profile.step2.description'),
            nextCTALabel: t('onboarding_investor_profile.step2.next_cta_label'),
            nextCTAFunc: handleSave,
            nextCheckSubmittable: () => submittable,
            backCTALabel: t('onboarding_investor_profile.step2.back_cta_label'),
            backCTAFunc: handleBack,
            showInvestmentGoals: true,
            showAboutMe: false,
        }
    ];

    console.log(submittable);

    const [currentStepData, setCurrentStepData] = useState(stepsData[0]);

    useEffect(() => {
        setCurrentStepData(stepsData[step]);
    }, [step]);

    function onProfileStored() {
        navigate(ROUTES.subscription);
    }

    const renderComponent = () => {
        if (isLoading) {
            return <LoadingScreenBar/>
        }

        if (fetchedProfileData && fetchedProfileData?.id !== null) {
            navigate(ROUTES.dashboard);
        }

        return <OnboardingPage>
            <BaseCard
                sx={{
                    margin: "auto",
                    minHeight: mdDown ? '320px' : '400x',
                    maxWidth: "800px",
                    flexDirection: "column",
                    display: 'flex',
                    backgroundColor: 'rgba(255, 255, 255, 0.6)'
                }}
            >
                <PageTitle>{currentStepData.title}</PageTitle>
                <Typography>{currentStepData.description}</Typography>
                <InvestorProfileEditView
                    profileData={profileData}
                    setProfileData={setProfileData}
                    showInvestmentGoals={currentStepData.showInvestmentGoals}
                    showAboutMe={currentStepData.showAboutMe}
                    readyToSave={readyToSave}
                    notifySubmittable={setSubmittable}
                    notifyInvestorProfileStoreStatus={onProfileStored}
                />

                <Stack direction="row" sx={{
                    width: '100%',
                    justifyContent: "space-between",
                    alignItems: "center",
                }}>
                    <TertiaryButton onClick={currentStepData.backCTAFunc}>
                        {currentStepData.backCTALabel}
                    </TertiaryButton>


                    <PrimaryButton disabled={!submittable}
                                   onClick={currentStepData.nextCTAFunc}>
                        {currentStepData.nextCTALabel}
                    </PrimaryButton>
                </Stack>
            </BaseCard>
        </OnboardingPage>
    }

    return renderComponent();
}