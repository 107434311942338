'use client';

import {useMemo} from 'react';
import merge from 'lodash/merge';
import PropTypes from 'prop-types';

import CssBaseline from '@mui/material/CssBaseline';
import {createTheme, ThemeProvider as MuiThemeProvider} from '@mui/material/styles';

//import { useSettingsContext } from 'src/components/settings';
// system
import {palette} from './palette';
import {shadows} from './shadows';
import {typography} from './typography';
// options
import RTL from './options/right-to-left';
import {customShadows} from './custom-shadows';
import {componentsOverrides} from './overrides';
import {createPresets} from './options/presets';
import {createContrast} from './options/contrast';
import {useSettingsContext} from "../components/settings";

// ----------------------------------------------------------------------

export default function ThemeProvider({children}) {
    const settings = useSettingsContext();

    const presets = createPresets("default");

    const contrast = createContrast(settings.themeContrast, settings.themeMode);

    const memoizedValue = useMemo(
        () => ({
            palette: {
                ...palette("light"),
                ...presets.palette,
                ...contrast.palette,
            },
            customShadows: {
                ...customShadows(settings.themeMode),
                ...presets.customShadows,
            },
            direction: settings.themeDirection,
            shadows: shadows(settings.themeMode),
            shape: {borderRadius: 8},
            typography,
        }),
        [
            settings.themeMode,
            settings.themeDirection,
            presets.palette,
            presets.customShadows,
            contrast.palette,
        ]
    );

    const theme = createTheme(memoizedValue);

    theme.components = merge(componentsOverrides(theme), contrast.components);

    return (
        <MuiThemeProvider theme={theme}>
            <RTL themeDirection={settings.themeDirection}>
                <CssBaseline/>
                {children}
            </RTL>
        </MuiThemeProvider>
    );
}

ThemeProvider.propTypes = {
    children: PropTypes.node,
};
