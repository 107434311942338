import ApiServiceV2, {apiService} from "../ApiServiceV2";
import {useAuth0} from "@auth0/auth0-react";
import {useTranslation} from "react-i18next";
import {useMutation} from "react-query";
import toast from "react-hot-toast";


export function useValidationStorer(onSuccess = () => {
}) {
    const auth0Context = useAuth0();
    const {t} = useTranslation();
    const apiService = new ApiServiceV2();

    return useMutation(validationReqData => toast.promise(apiService.postValidationRequest(auth0Context, validationReqData), {
        loading: t("general.saving"), success: t('general.saved'), error: t("feedback.error.api_request_error")
    }), {
        onSuccess: (response) => {
            onSuccess(response.data);
        }, onError: (error) => {
            if (error?.response?.status === 409) {
                toast.error(t('validation.create.already_exists_warning_message'));
            }
        },
    });
}

export function useValidationUpdater() {
    const auth0Context = useAuth0();
    const {t} = useTranslation();

    return useMutation(async (validation) => {
        return toast.promise(
            apiService.updateValidationRequest(await auth0Context, validation),
            {
                loading: t('general.saving'),
                success: t('general.saved'),
                error: t('feedback.error.api_request_error'),
            }
        );
    });
}
