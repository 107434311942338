import {BaseCard} from "./index";
import {Typography, useTheme} from "@mui/material";
import {FormatCompactCurrency} from "../../utils/format-number";

export function VisualValidationSummary({propertyRenov}) {
    const theme = useTheme();

    return <BaseCard sx={{background: theme.palette.info.dark}}>
        <Typography color="common.white" variant="h4">Renovaciones</Typography>
        <Typography color="common.white" variant="h3">{FormatCompactCurrency(propertyRenov)} </Typography>
    </BaseCard>
}