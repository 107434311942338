import React from "react";
import Box from "@mui/material/Box";
import MortgagePieChart from "./mortgage-pie-chart";
import PaymentsBarChart from "../../components/cards/mortgage-payments-char";
import MortgageMetricsCard from "../../components/cards/mortgage-metrics-card";
import {OptionsBar} from "../../components/settings";
import {CardContainer} from "../../components/cards";

export default function MortgageView({data = {}}) {

    return <>
        <OptionsBar/>
        <CardContainer>
            <Box sx={{flexGrow: 1}}><MortgageMetricsCard data={data}/></Box>
            <MortgagePieChart data={data} sx={{flexGrow: 1}}/>
            <PaymentsBarChart data={data} sx={{flexGrow: 5}}/>
        </CardContainer>
    </>;
}
