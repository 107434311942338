import Typography from "@mui/material/Typography";
import {BaseCard} from "../../components/cards";
import React from "react";
import {useTranslation} from "react-i18next";
import DataField from "../../components/data-field";
import {Stack, useTheme} from "@mui/material";
import {FormatCurrency} from "../../utils/format-number";
import {WCountry, WRegion} from "../../components/icons/walden-icons";


export default function AboutUser({profileData}) {

    const theme = useTheme();
    const {t} = useTranslation();
    const iconLocation = <WRegion sx={{marginRight: 1}} color={theme.palette.primary.main}/>;
    const iconFlag = <WCountry sx={{marginRight: 1}} color={theme.palette.primary.main}/>;

    return (
        <BaseCard>
            <Typography variant="h5">
                {t("user_profile.residence_title")}
            </Typography>


            <Stack spacing={1} sx={{textAlign: 'left'}}>
                <Typography variant="subtitle2">
                    {t("location.region")}
                </Typography>

                <Typography variant="body2" sx={{display: 'flex', alignItems: 'center'}}>
                    {iconLocation}
                    {profileData?.region === t("general.other") ? t("general.other") : profileData?.region}
                </Typography>
            </Stack>

            {profileData?.country !== "None" &&
                <>
                    <Stack spacing={1} sx={{textAlign: 'left'}}>
                        <Typography variant="subtitle2">
                            {t("location.country")}
                        </Typography>

                        <Typography variant="body2" sx={{display: 'flex', alignItems: 'center'}}>
                            {iconFlag}
                            {profileData?.country}
                        </Typography>
                    </Stack>
                    <DataField
                        direction="column"
                        label={t("user_profile.community_select_label")}
                        value={profileData?.community?.name || 0}
                        valueVariant="body2"
                        labelVariant="subtitle2"
                        valueColor="text.primary"
                        labelColor="text.primary"
                        sx={{textAlign: 'left'}}
                    />
                </>
            }

            <DataField
                direction="column"
                label={t("user_profile.salary_label")}
                value={FormatCurrency(profileData?.salary || 0)}
                valueVariant="body2"
                labelVariant="subtitle2"
                valueColor="text.primary"
                labelColor="text.primary"
                sx={{textAlign: 'left'}}
            />

            <DataField
                direction="column"
                label={t("user_profile.saved_amount_label")}
                value={FormatCurrency(profileData?.savedAmount || 0)}
                valueVariant="body2"
                labelVariant="subtitle2"
                valueColor="text.primary"
                labelColor="text.primary"
                sx={{textAlign: 'left'}}
            />

            <DataField
                direction="column"
                label={t("user_profile.age_label")}
                value={`${profileData?.age || 0}`}
                valueVariant="body2"
                labelVariant="subtitle2"
                valueColor="text.primary"
                labelColor="text.primary"
                sx={{textAlign: 'left'}}
            />
        </BaseCard>
    );
}