import AppPage from "../../common/AppPage";
import {useResponsive} from "../../hooks/use-responsive";
import PageTitle from "../../components/titles";
import React, {useMemo, useState} from "react";
import Box from "@mui/material/Box";
import {Divider, Stack, Tab, Tabs, Typography} from "@mui/material";
import ROUTES from "../../routes";
import {useLocation} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {OptionsBar, useSettingsContext} from "../../components/settings";
import {oikosResponseMapper} from "./response-mapper";
import PropertySummaryCard from "../../components/cards/property-summary-card";
import {LoadingScreenBar} from "../../components/loading-screen";
import ProfitabilityCard from "../../components/cards/profitability-card";
import FinancialMetricsCard from "../../components/cards/financial-metrics-card";
import FunnelCashFlow from "../../components/cards/funnel-cash-flow-card";
import ExpensesChart from "../../components/cards/expenses-chart";
import {CardContainer, PatrimonyCard} from "../../components/cards";
import OikosParameters from "../parameters/OikosParameters";
import {NavigateBackButton} from "../../components/buttons";
import {WPropertySummary} from "../../components/icons/walden-icons";
import {useOikosDetails} from "../../services/oikos/oikos-detailer";
import {OikosPriceFeedback} from "./oikos-price-feedback";
import {OikosDetailTour} from "../../components/tour";


export function OikosDetail() {
    const oikosID = useLocation().state;
    const {t} = useTranslation();
    const [selectedTab, setSelectedTab] = useState(1);
    const settings = useSettingsContext();
    const lgDown = useResponsive('down', 'lg');
    const {data: apiResponse, isLoading, refetch} = useOikosDetails(oikosID);

    const data = useMemo(() => {
        if (apiResponse) {
            return oikosResponseMapper(apiResponse, settings);
        }
    }, [apiResponse, settings]);

    const handleTabChange = (event, newValue) => {
        setSelectedTab(newValue);
    };

    const title = <PageTitle>{data?.property?.address}</PageTitle>;

    const oikosRequest = {
        oikos: {
            ID: data?.oikos?.ID,
            cadastre_id: data?.oikos?.cadastre_id
        }
    };

    const paramsButton = <Box className="tour_properties_modify_parameters">
        <OikosParameters refreshParams={refetch} parameters={data?.oikos?.parameters} request={oikosRequest}/>
    </Box>;

    const mobileTitleElement = <Stack spacing={2.5} direction="column">
        <Stack direction="row" justifyContent="space-between">
            {title}
            {paramsButton}
        </Stack>
    </Stack>

    const priceFeedbackComponent = settings.useWaldenPrice ? <OikosPriceFeedback data={data}/> : null;

    const pageContent = <>
        <Tabs
            value={selectedTab}
            onChange={handleTabChange}
            sx={{marginTop: 1}}
        >
            <Tab icon={<WPropertySummary sx={{marginRight: 1}}/>} value={1}
                 label={t("oikos.detail.property_summary_label")}/>
        </Tabs>
        <Divider style={{marginTop: -1}}/>
        <PageTitle sx={{mt: "1em"}}>{t("oikos.detail.property_summary_label")}</PageTitle>
        <OptionsBar/>
        <CardContainer>
            <Box className="tour-oikos-details-details" sx={{flexGrow: 2}}><PropertySummaryCard data={data?.property}/></Box>
            <Box sx={{flexGrow: 1}} className="tour-oikos-details-profitability">
                <ProfitabilityCard data={data?.financial}
                                   priceFeedbackComponent={priceFeedbackComponent}/>
            </Box>
            <Box className="tour-oikos-details-metrics">
                <FinancialMetricsCard data={data?.financial}/>
            </Box>
            <Box sx={{flexGrow: 1}} className="tour-oikos-details-patrimony"><PatrimonyCard data={data}/></Box>
            <FunnelCashFlow data={data?.financial} sx={{flexGrow: 1}}/>
            <ExpensesChart data={data?.financial} sx={{flexGrow: 1}}/>
        </CardContainer>
    </>

    const headerContent = <>
        <NavigateBackButton url={ROUTES.properties.list}/>
        <Typography variant="h6" noWrap>
            {data?.property?.address}
        </Typography>
        <OikosParameters refreshParams={refetch} parameters={data?.oikos?.parameters} request={oikosRequest}/>
    </>

    return <AppPage topBarContent={headerContent} containerComponent="box">
        <OikosDetailTour/>

        {!lgDown ? mobileTitleElement : null}
        {isLoading ? <LoadingScreenBar/> : pageContent}
    </AppPage>
}
