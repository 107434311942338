import {Button, Paper, useTheme} from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import {useTranslation} from "react-i18next";
import {WError} from "../../icons/walden-icons";

export function ValidationErrorIndicatorWidget({status, handleDelete}) {
    const theme = useTheme();
    const {t} = useTranslation();


    if (status !== "error") return null;

    const errorPaperStyle = {
        padding: theme.spacing(1),
        marginBottom: theme.spacing(1),
        backgroundColor: theme.palette.error.lighter,
        color: theme.palette.error.main,
        display: 'flex',
        alignItems: 'center',
    };

    return <Grid item xs={12}>
        <Paper style={errorPaperStyle}>
            <Box sx={{
                display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%'
            }}>
                <Box sx={{display: 'flex', alignItems: 'center'}}>
                    <WError width={24} sx={{
                        color: theme.palette.error.main, marginRight: theme.spacing(1),
                    }}/>
                    <Typography variant="body2" color={theme.palette.error.darker}>
                        {t("feedback.error.validation_item_error")}
                    </Typography>
                </Box>
                <Box>
                    <Button variant="text" onClick={handleDelete}>
                        {t("general.delete")}
                    </Button>
                </Box>
            </Box>
        </Paper>
    </Grid>
}