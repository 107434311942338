import React from 'react';
import {Typography, useTheme} from '@mui/material';
import {BaseCard} from "../../components/cards";
import {useTranslation} from "react-i18next";
import Owner, {isNudaPropiedad} from "./owner";
import Stack from '@mui/material/Stack';
import {useResponsive} from "../../hooks/use-responsive";

function Owners({owners}) {
    const theme = useTheme();
    const {t} = useTranslation();
    const mdDown = useResponsive('down', 'md');
    const smDown = useResponsive('down', 'sm');

    if (!owners) {
        return (
            <BaseCard>
                <Typography variant="body1" color="text.primary">
                    {t('administrative_validation.no_owners_in_ns')}
                </Typography>
            </BaseCard>
        );
    }


    const ownersCount = owners?.length;
    const hasNudaPropiedad = owners?.some(owner => isNudaPropiedad(owner));
    const isError = hasNudaPropiedad || ownersCount > 2;
    const isWarning = !hasNudaPropiedad && ownersCount === 2;
    const isSuccess = !hasNudaPropiedad && ownersCount === 1;

    return (
        <BaseCard sx={{alignItems: "center"}}>
            <Stack
                direction={smDown ? 'column' : 'row'}
                spacing={2}
                justifyContent="space-evenly"
                sx={{width: "100%", flexGrow: 1}}
            >
                {owners?.map((owner, index) => (
                    <Owner
                        key={index}
                        owner={owner}
                        isError={isError}
                        isWarning={isWarning}
                        isSuccess={isSuccess}
                        hasNudaPropiedad={hasNudaPropiedad}
                    />
                ))}
            </Stack>
        </BaseCard>
    );
}

export default Owners;