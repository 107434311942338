import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
    Box,
    Modal,
    CircularProgress,
    Typography,
    IconButton
} from "@mui/material";
import DownloadIcon from '@mui/icons-material/Download';
import toast from "react-hot-toast";
import ROUTES from "../../../routes";
import PropertySummaryCard from "../../../components/cards/property-summary-card";
import FinancialSummaryCard from "../../../components/cards/financial-summary-card";
import {CardContainer, MortgageSummaryCard} from "../../../components/cards";
import {OptionsBar} from "../../../components/settings";
import {useDownloadOfferDocument, useRequestOfferDocument} from "../../../services/request-offer-document";
import {ValidationSummaryTour} from "../../../components/tour";
import ValidationMenu from "./validation_menu";
import {VisualValidationSummary} from "../../../components/cards/visual-validation-summary";
import OfferDocumentPreview from "./offer-document-preview";
import Comments from "../../administrative_validation/comments";
import PropertyMap from "./property_map";

export default function ValidationSummary({
                                              propertyData,
                                              financialData,
                                              parameters,
                                              comments,
                                              propertyRenov,
                                              documentID
                                          }) {
    const validationRequestID = useLocation().state;
    const navigate = useNavigate();
    const {t} = useTranslation();
    const [offerPreviewOpen, setOfferPreviewOpen] = useState(false);

    const {
        data: requestOfferData,
        isLoading: isRequestingOffer,
        isError: isRequestError,
        refetch: requestOffer
    } = useRequestOfferDocument(validationRequestID);
    const {data: downloadUrl, refetch: downloadOffer} = useDownloadOfferDocument(validationRequestID);

    const handleConvertValidation = () => {
        navigate(`${ROUTES.properties.add}`, {
            state: {
                validationRequestID,
                parameters: {
                    ...parameters,
                    isMortgageValid: true
                },
                propertyData
            }
        });
    };

    const handleRequestDocument = async () => {
        const loadingToast = toast.loading(t('offer_document.loading_message'));
        try {
            const result = await requestOffer();
            if (result && result.data && result.data.offer) {
                setOfferPreviewOpen(true);
                toast.success(t('offer_document.request_success'));
            } else {
                toast.error(t('offer_document.error_no_data_to_preview'));
            }
        } catch (error) {
            toast.error(t('offer_document.error_request_failed'));
        } finally {
            toast.dismiss(loadingToast);
        }
    };

    const handleDownloadDocument = async () => {
        try {
            const result = await downloadOffer();
            if (result && result.data && result.data.url) {
                window.open(result.data.url, '_blank');
            } else {
                toast.error(t('offer_document.error_no_download_url'));
            }
        } catch (error) {
            toast.error(t('offer_document.error_download_failed'));
        }
    };

    const handlePreviewDocument = () => {
        if (requestOfferData?.offer) {
            setOfferPreviewOpen(true);
        } else {
            toast.error(t('offer_document.error_no_data_to_preview'));
        }
    };

    const handleCloseOfferPreview = () => {
        setOfferPreviewOpen(false);
    };

    return (
        <>
            <ValidationSummaryTour/>
            <Box sx={{display: "flex", justifyContent: "space-between", alignItems: "center", mt: 1}}
                 className="tour-validation-summary-description">
                <OptionsBar/>
                <ValidationMenu
                    onConvertValidation={handleConvertValidation}
                    onRequestDocument={handleRequestDocument}
                    onDownloadDocument={handleDownloadDocument}
                    onPreviewDocument={handlePreviewDocument}
                    documentID={documentID}
                />
            </Box>
            <CardContainer>
                <Box className="tour-validation-summary-property-summary">
                    <PropertySummaryCard data={propertyData} sx={{flexGrow: 2}}/>
                </Box>
                <Box className="tour-validation-summary-profitability">
                    <FinancialSummaryCard data={financialData} sx={{width: "250px"}}/>
                </Box>
                {financialData?.mortgage?.type && (
                    <Box sx={{flexGrow: 1}}>
                        <MortgageSummaryCard data={financialData} sx={{maxWidth: "300px"}}/>
                    </Box>
                )}
                <Box className="tour-validation-summary-renovation">
                    <VisualValidationSummary propertyRenov={propertyRenov}/>
                </Box>
                <Box sx={{maxWidth: "850px"}}>
                    <Comments comments={comments} inSummary/>
                </Box>
            </CardContainer>
            <Box sx={{mt: 3}}/>
            <Box className="tour-validation-summary-map">
                <PropertyMap latitude={propertyData?.latitude} longitude={propertyData.longitude}/>
            </Box>
            <Modal
                open={offerPreviewOpen}
                onClose={handleCloseOfferPreview}
                aria-labelledby="offer-preview-modal"
                aria-describedby="offer-preview-description"
            >
                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: '90%',
                    p: 4,
                    borderRadius: 2,
                    maxHeight: '90vh',
                    overflowY: 'auto',
                    bgcolor: 'background.paper',
                }}>
                    {isRequestingOffer ? (
                        <CircularProgress/>
                    ) : isRequestError ? (
                        <Typography color="error">{t('offer_document.error_loading')}</Typography>
                    ) : (
                        <>
                            <Box sx={{position: 'absolute', top: 16, right: 16, zIndex: 1}}>
                                <IconButton onClick={handleDownloadDocument} color="primary"
                                            aria-label="download offer">
                                    <DownloadIcon/>
                                </IconButton>
                            </Box>
                            <OfferDocumentPreview offer={requestOfferData?.offer}/>
                        </>
                    )}
                </Box>
            </Modal>
        </>
    );
}