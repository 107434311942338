import {useCallback, useEffect, useState} from 'react';
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

const useServiceWorker = () => {
    const [waitingWorker, setWaitingWorker] = useState(null);
    const [showReload, setShowReload] = useState(false);
    const [registration, setRegistration] = useState(null);

    const onSWUpdate = useCallback((registration) => {
        setShowReload(true);
        setWaitingWorker(registration.waiting);
    }, []);

    const reloadPage = useCallback(() => {
        if (waitingWorker) {
            waitingWorker.postMessage({type: 'SKIP_WAITING'});
            setShowReload(false);
            window.location.reload();
        }
    }, [waitingWorker]);

    useEffect(() => {
        const registerSW = async () => {
            try {
                const reg = await serviceWorkerRegistration.register({onUpdate: onSWUpdate});
                setRegistration(reg);
            } catch (error) {
                console.error('Service worker registration failed:', error);
            }
        };
        registerSW();
    }, [onSWUpdate]);

    useEffect(() => {
        if (!registration) return;

        const intervalId = setInterval(() => {
            console.debug('Checking for SW updates...');
            registration.update();
        }, 60 * 1000);

        return () => clearInterval(intervalId);
    }, [registration]);

    return {showReload, reloadPage};
};

export default useServiceWorker;