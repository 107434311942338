import React from 'react';
import {BaseCard} from "../../components/cards";
import DataField from "../../components/data-field";
import {useTheme} from "@mui/material";
import {useTranslation} from "react-i18next";
import {WBank, WDollar, WYear} from "../../components/icons/walden-icons";
import {FormatCurrency} from "../../utils/format-number";
import {useResponsive} from "../../hooks/use-responsive";
import PageTitle from "../../components/titles";
import Typography from '@mui/material/Typography';

const AdministrativeMortgage = ({mortgage}) => {
    const theme = useTheme();
    const {t} = useTranslation();
    const mdDown = useResponsive('down', 'sm');

    const getDataFieldStyles = (mdDown) => ({
        ...(mdDown && {
            alignItems: 'flex-start',
            '& .MuiStack-root': {
                justifyContent: 'flex-start',
            },
        }),
    });

    return (
        <BaseCard>
            <PageTitle>{t("financial.mortgage.mortgage")}</PageTitle>
            {mortgage === undefined ? (
                <Typography variant="body1" color="text.primary">
                    {t('administrative_validation.mortgage.no_financing_data')}
                </Typography>
            ) : (
                <>
                    {mortgage?.creditor && (
                        <DataField
                            icon={<WBank color={theme.palette.text.secondary} sx={{minWidth: "20px"}}/>}
                            label={t('administrative_validation.mortgage.creditor')}
                            labelColor="text.primary"
                            value={mortgage?.creditor}
                            direction={mdDown ? 'column' : 'row'}
                            labeledIcon={mdDown}
                            sx={getDataFieldStyles(mdDown)}
                        />
                    )}
                    {mortgage?.principal_amount && (
                        <DataField
                            icon={<WDollar color={theme.palette.text.secondary} sx={{minWidth: "20px"}}/>}
                            label={t('administrative_validation.mortgage.principal_amount')}
                            labelColor="text.primary"
                            value={FormatCurrency(mortgage?.principal_amount)}
                            direction={mdDown ? 'column' : 'row'}
                            labeledIcon={mdDown}
                            sx={getDataFieldStyles(mdDown)}
                        />
                    )}
                    {mortgage?.interest_rate_max && (
                        <DataField
                            icon={<WDollar color={theme.palette.text.secondary} sx={{minWidth: "20px"}}/>}
                            label={t('administrative_validation.mortgage.interest_rate_max')}
                            labelColor="text.primary"
                            value={`${mortgage?.interest_rate_max}%`}
                            direction={mdDown ? 'column' : 'row'}
                            labeledIcon={mdDown}
                            sx={getDataFieldStyles(mdDown)}
                        />
                    )}
                    {mortgage?.costs_and_expenses && (
                        <DataField
                            icon={<WDollar color={theme.palette.text.secondary} sx={{minWidth: "20px"}}/>}
                            label={t('administrative_validation.mortgage.costs_and_expenses')}
                            labelColor="text.primary"
                            value={FormatCurrency(mortgage?.costs_and_expenses)}
                            direction={mdDown ? 'column' : 'row'}
                            labeledIcon={mdDown}
                            sx={getDataFieldStyles(mdDown)}
                        />
                    )}
                    {mortgage?.maturity_date && (
                        <DataField
                            icon={<WYear color={theme.palette.text.secondary} sx={{minWidth: "20px"}}/>}
                            label={t('administrative_validation.mortgage.maturity_date')}
                            labelColor="text.primary"
                            value={mortgage?.maturity_date}
                            direction={mdDown ? 'column' : 'row'}
                            labeledIcon={mdDown}
                            sx={getDataFieldStyles(mdDown)}
                        />
                    )}
                </>
            )}
        </BaseCard>
    );
};

export default AdministrativeMortgage;