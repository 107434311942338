import React from 'react';
import ApexCharts from 'react-apexcharts';
import {useTheme} from '@mui/material/styles';
import {useTranslation} from "react-i18next";
import Grid from "@mui/material/Grid";
import {BaseCard} from "../../components/cards";

function MortgagePieChart({data = {}, sx}) {
    const theme = useTheme();
    const {t} = useTranslation();
    const mortgage = data?.mortgage
    const legendOptions = {
        position: 'bottom',
        horizontalAlign: 'center',
        formatter: function (seriesName, opts) {
            return `${seriesName}: <strong>${opts.w.globals.series[opts.seriesIndex].toLocaleString()}${t('currency_symbol')}</strong>`;
        },
        labels: {
            colors: 'gray',
        },
    };

    const options = {
        chart: {
            type: 'donut',
        },
        colors: [theme.palette.primary.main, theme.palette.secondary.main],
        labels: [t('charts.mortgage.financed_amount'), t('charts.mortgage.personal_contribution')],
        plotOptions: {
            pie: {
                donut: {
                    size: '90%',
                    labels: {
                        show: true,
                        total: {
                            show: true,
                            label: t('charts.mortgage.total'),
                            color: 'grey',
                            fontSize: '12px',
                            formatter: function (w) {
                                return w.globals.seriesTotals.reduce((a, b) => a + b, 0).toLocaleString() + ' €';
                            }
                        },
                        value: {
                            color: theme.palette.background.menu,
                            fontSize: '18px',
                            fontWeight: 600,
                        }
                    }
                }
            }
        },
        legend: legendOptions,
        dataLabels: {
            enabled: false
        },
        responsive: [{
            breakpoint: 10,
            options: {
                chart: {
                    width: 500
                },
                legend: {
                    position: 'bottom'
                }
            }
        }]
    };

    const series = [mortgage?.financed_amount, mortgage?.personal_contribution_amount];

    return (
        <BaseCard sx={sx}>
            <Grid container spacing={2} style={{width: '100%'}}>
                <Grid item xs={12}>
                    <ApexCharts options={options} series={series} height={300} type="donut"/>
                </Grid>
            </Grid>
        </BaseCard>
    );

}

export default MortgagePieChart;
