import Box from "@mui/material/Box";
import {Stack, Tab, Tabs, useTheme} from "@mui/material";
import {UserAvatar, UserName} from "../../components/profile";
import {BaseCard} from "../../components/cards";
import React from "react";
import {Mixpanel} from "../../metrics/mixpanel";
import {useResponsive} from "../../hooks/use-responsive";
import {useTranslation} from "react-i18next";
import {WCreditCard, WProfile, WSettings} from "../../components/icons/walden-icons";

export default function ProfileHeader({onValueChange}) {
    const theme = useTheme();
    const xsDown = useResponsive("down", "sm");
    const {t} = useTranslation();
    const [value, setValue] = React.useState('profile');

    const handleChange = (event, newValue) => {
        Mixpanel.track('Change profile Tab')
        setValue(newValue);
        onValueChange(newValue);
    };

    return (
        <BaseCard sx={{padding: '-24', position: 'relative', overflow: 'hidden'}}>
            <Box
                component="img"
                sx={{
                    width: '100%',
                    height: '288px',
                    objectFit: 'cover',
                    marginBottom: '50px'
                }}
                src="https://cdn.walden.ai/media/img/app/background/overlay_2.webp"
                alt="Profile background"
            />
            <Stack
                direction={xsDown ? "column" : "row"}
                alignItems="center"
                spacing={2}
                sx={{
                    position: 'absolute',
                    bottom: xsDown ? '20%' : 24,
                    left: xsDown ? '50%' : 24,
                    transform: xsDown ? 'translate(-50%, -50%)' : 'none',
                }}
            >
                <UserAvatar sx={{width: xsDown ? '70%' : '40%', height: xsDown ? '70%' : '40%', minHeight: 70}}/>
                <UserName variant="h4" color="white"/>
            </Stack>
            <Box
                sx={{
                    position: 'absolute',
                    bottom: 0,
                    right: 0,
                    left: 0,
                    pb: 0,
                    pr: 2,
                    overflowX: 'auto',
                    '&::-webkit-scrollbar': {
                        display: 'none'
                    },
                    scrollbarWidth: 'none',
                }}
            >
                <Tabs
                    value={value}
                    onChange={handleChange}
                    textColor={theme.palette.text.primary}
                    indicatorColor="primary"
                    variant="scrollable"
                    scrollButtons="auto"
                    allowScrollButtonsMobile
                    sx={{
                        '& .MuiTabs-flexContainer': {
                            justifyContent: xsDown ? 'flex-start' : 'flex-end',
                        },
                    }}
                >
                    <Tab
                        value="profile"
                        icon={<WProfile sx={{marginRight: 1}}/>}
                        iconPosition="start"
                        label={t('general.profile')}
                    />
                    <Tab
                        value="subscription"
                        icon={<WCreditCard sx={{marginRight: 1}}/>}
                        iconPosition="start"
                        label={t('user_profile.subscription')}
                    />
                    <Tab
                        value="settings"
                        icon={<WSettings sx={{marginRight: 1}}/>}
                        iconPosition="start"
                        label={t('user_profile.settings')}
                    />
                </Tabs>
            </Box>
        </BaseCard>
    );
}