import mixpanel from 'mixpanel-browser';

mixpanel.init("a81062311f8351c3bcf840a1955fe273", {
    debug: true,
    track_pageview: true,
    persistence: 'localStorage'
});



const actions = {
    identify: (id) => {
        mixpanel.identify(id);
    },
    alias: (id) => {
        mixpanel.alias(id);
    },
    track_pageview: (props) => {
        mixpanel.track_pageview(props);
    },
    track: (name, props) => {
        mixpanel.track(name, props);
    },
    reset: () => {
        mixpanel.reset();
    },
    people: {
        set: (props) => {
            mixpanel.people.set(props);
        },
    },
};

export const Mixpanel = actions;