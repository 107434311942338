import i18next from "i18next";
import {initReactI18next} from "react-i18next";
import ICU from "i18next-icu";
import LanguageDetector from 'i18next-browser-languagedetector';
import es from './es.json';
import en from './en.json';

i18next
    .use(initReactI18next)
    .use(LanguageDetector)
    .use(ICU)
    .init({
        debug: true,
        fallbackLng: 'en',
        resources: {
            en: {
                translation: en
            },
            es: {
                translation: es
            },
        }
    })
