import React from 'react';
import Box from "@mui/material/Box";

const StickyBaseCard = ({children, ...props}) => {
    return (
        <Box
            style={{
                position: 'sticky',
                top: 0,
                zIndex: 1,
                backgroundColor: props.backgroundColor,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: "center",
                alignItems: "center",
                width: 200,
                margin: 'auto',
                paddingBottom: 15,
                borderRadius: '0 0 16px 16px'

            }}
        >
            {children}
        </Box>
    );
};

export default StickyBaseCard;