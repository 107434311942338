import React from 'react';
import {SubscriptionDetail} from "./subscription-detail";
import {Grid} from "@mui/material";
import {PricingTable} from "../../components/stripe";
import Typography from "@mui/material/Typography";
import {useTranslation} from "react-i18next";

export function PricingTables({subscriptionData}) {
    const isSubscribed = subscriptionData && subscriptionData.is_active;
    const {t} = useTranslation();

    if (isSubscribed) {
        return <SubscriptionDetail subscriptionData={subscriptionData}/>;
    } else {
        return (
            <Grid item xs={12}>
                <Typography
                    variant="body1"
                    sx={{
                        mb: 4,
                        color: 'text.secondary'
                    }}
                >
                    {t("user_profile.subscription_encouragement")}
                </Typography>
                <PricingTable/>
            </Grid>
        );
    }
}