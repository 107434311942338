import React from 'react';
import ReactApexChart from 'react-apexcharts';
import {useTheme} from '@mui/material/styles';
import {useTranslation} from 'react-i18next';
import {CardTitle} from "./CardTitle";
import {BaseCard} from "./index";

const FunnelCashFlowChart = ({data = {}, sx}) => {
    const theme = useTheme();
    const {t} = useTranslation();
    const cashFlow = data?.roi?.cash_flow;


    const positiveColors = [
        theme.palette.chart.color1,
        theme.palette.chart.color5,
        theme.palette.chart.color6,
        theme.palette.chart.color8,
    ];

    const negativeColors = [
        theme.palette.chart.color2,
        theme.palette.chart.color3,
        theme.palette.chart.color4,
        theme.palette.chart.color7,
    ];

    const getFillColor = (value, index) => {
        return value >= 0 ? positiveColors[index % positiveColors.length] : negativeColors[index % negativeColors.length];
    };

    const seriesData = [
        {
            x: t('charts.cashflow_values.rental_income'),
            y: cashFlow?.rent,
        },
        {
            x: t('charts.cashflow_values.profit_before_taxes'),
            y: cashFlow?.profit_before_taxes,
        },
        {
            x: t('charts.cashflow_values.profit_after_taxes'),
            y: cashFlow?.profit_after_taxes
        },
        {
            x: t('charts.cashflow_values.value_after_debt_payment'),
            y: cashFlow?.value_after_debt_payment
        },
    ].map((item, index) => ({
        ...item,
        fillColor: getFillColor(item.y, index),
    }));


    const series = [{
        name: "",
        data: seriesData,
    }];

    const chartColors = seriesData.map(dataPoint => dataPoint.fillColor);

    const options = {
        chart: {
            type: 'bar',
            height: 350,
            toolbar: {
                show: true,
                tools: {
                    download: false,
                },
            },
        },

        plotOptions: {
            bar: {
                borderRadius: 0,
                horizontal: true,
                barHeight: '80%',
                isFunnel: true,
                distributed: true,
            },
        },
        dataLabels: {
            enabled: true,
            formatter: function (val) {
                return val.toLocaleString();
            },
            dropShadow: {
                enabled: true,
            },

        },
        xaxis: {
            categories: seriesData.map(item => item.x),
        },
        legend: {
            show: true,
            position: 'bottom',
            horizontalAlign: 'left',
            formatter: function (seriesName, opts) {
                return seriesData[opts.seriesIndex].x;
            },
        },
        colors: chartColors,
    };

    return (
        <BaseCard sx={{...sx}} className="tour-oikos-details-cashflow-chart">
            <CardTitle title={t('charts.cashflow_values.title')}/>
            <div style={{width: '100%'}}>
                <ReactApexChart
                    key={"cash_flow"}
                    options={options}
                    series={series}
                    type="bar"
                    height={300}
                />
            </div>

        </BaseCard>
    );
};

export default FunnelCashFlowChart;
