import { useAuth0 } from "@auth0/auth0-react";
import { apiService } from "./ApiServiceV2";
import { useQuery } from "react-query";

export function useRequestOfferDocument(id) {
    const auth0Context = useAuth0();

    return useQuery(
        ["requestOfferDocument", id],
        async () => {
            const response = await apiService.requestOfferDocument(auth0Context, id);
            return response.data;
        },
        {
            enabled: false,
        }
    );
}

export function useDownloadOfferDocument(id) {
    const auth0Context = useAuth0();

    return useQuery(
        ["downloadOfferDocument", id],
        async () => {
            const response = await apiService.downloadOfferDocument(auth0Context, id);
            return response.data;
        },
        {
            enabled: false,
        }
    );
}