import React, {createContext, useContext, useEffect, useState} from 'react';
import {useProfileDetailer} from "../../../services/profile-detailer";

const ProfileContext = createContext();

export const useProfile = () => {
    return useContext(ProfileContext);
};

export const ProfileProvider = ({children}) => {
    const {data: fetchedProfileData, refetch: refetchProfile, isLoading: isLoadingProfile} = useProfileDetailer(0);
    const [profileData, setProfileData] = useState(fetchedProfileData);

    useEffect(() => {
        if (fetchedProfileData) {
            setProfileData(fetchedProfileData);
        }
    }, [fetchedProfileData]);

    return (
        <ProfileContext.Provider value={{profileData, refetchProfile, isLoadingProfile}}>
            {children}
        </ProfileContext.Provider>
    );
};
