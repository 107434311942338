import {Pagination} from "@mui/lab";
import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import {useFiltersContext} from "../search_filter/context";

// CustomPaginator is a wrapper component for MUI Pagination.
// It takes an array of items and a page size as props.
// Additionally, receives a `satPageItems` function to set the page items meant to be displayed in the view for a given page
// For filter and sorting aware
export default function CustomFilterAwarePaginator({items, pageSize, setPageItems, doFilter}) {
    const [page, setPage] = useState(1);
    const [pageCount, setPageCount] = useState(1);
    const filterContext = useFiltersContext();

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    }

    useEffect(() => {
        const filteredItems = doFilter(items, filterContext.filters, filterContext.dateSort);
        setPageCount(Math.ceil(filteredItems.length / pageSize) || 1);
        setPageItems(filteredItems.slice((page - 1) * pageSize, page * pageSize));
    }, [items, page, filterContext.filters, filterContext.dateSort]);

    return (
        <Pagination
            variant="text"
            shape="rounded"
            color="primary"
            count={pageCount}
            page={page}
            onChange={handleChangePage}
            sx={{mt: 4}}
        />
    );
}


CustomFilterAwarePaginator.protoTypes = {
    items: PropTypes.array,
    pageSize: PropTypes.number,
    setPageItems: PropTypes.func,
    doFilter: PropTypes.func
}
