import ToggleOption from "./toggle-option";
import {useSettingsContext} from "../context";
import {useTranslation} from "react-i18next";
import {Mixpanel} from "../../../metrics/mixpanel";

export function PriceOptionToggle() {
    const {t} = useTranslation();
    const settings = useSettingsContext();

    const handleOptionChange = (event, newOption) => {
        if (newOption === null) {
            return;
        }
        const oldOption = settings.useWaldenPrice;
        newOption ? settings.onSetWaldenPrice() : settings.onSetMarketPrice();
        localStorage.setItem('useWaldenPrice', newOption);
        Mixpanel.track(`Option bar changed from ${oldOption ? 'Walden' : 'Market'} price to ${newOption ? 'Walden' : 'Market'} price`, {
            'Old Option': oldOption ? 'Walden' : 'Market',
            'New Option': newOption ? 'Walden' : 'Market',
        });
    }

    return <ToggleOption
        label={t("settings.price_option_label")}
        value={settings.useWaldenPrice}
        onChange={handleOptionChange}
        options={[{value: false, name: t("settings.price_market_option")}, {
            value: true,
            name: t("settings.price_walden_option")
        },]}
        tooltipText={t("settings.price_tooltip")}
    />
}


export function PeriodOptionToggle() {
    const {t} = useTranslation();
    const settings = useSettingsContext();


    const handleOptionChange = (event, newOption) => {
        if (newOption === null) {
            return;
        }
        const oldOption = settings.useMonthlyPeriod;
        newOption ? settings.onSetMonthlyPeriod() : settings.onSetYearlyPeriod();
        localStorage.setItem('useMonthlyPeriod', newOption);
        Mixpanel.track(`Option bar changed from ${oldOption ? 'Monthly' : 'Yearly'} period to ${newOption ? 'Monthly' : 'Yearly'} period`, {
            'Old Option': oldOption ? 'Monthly' : 'Yearly',
            'New Option': newOption ? 'Monthly' : 'Yearly',
        });
    }

    return <ToggleOption
        label={t("settings.period_option_label")}
        value={settings.useMonthlyPeriod}
        onChange={handleOptionChange}
        options={[{name: t("settings.period_monthly_option"), value: true}, {
            name: t("settings.period_yearly_option"),
            value: false
        },]}
        tooltipText={t("settings.period_tooltip")}
    />
}

export function CalculationProfileOptionToggle() {
    const {t} = useTranslation();
    const settings = useSettingsContext();


    const handleOptionChange = (event, newOption) => {
        if (newOption === null) {
            return;
        }
        const oldOption = settings.useOptimistProfile;
        newOption ? settings.onSetOptimistProfile() : settings.onSetPrudentProfile();
        localStorage.setItem('useOptimistProfile', newOption);

        Mixpanel.track(`Option bar changed  from ${oldOption ? 'Optimist' : 'Prudent'} to ${newOption ? 'Optimist' : 'Prudent'}`,
            {
                'Old Option': oldOption ? 'Optimist' : 'Prudent',
                'New Option': newOption ? 'Optimist' : 'Prudent',
            });

    }

    return <ToggleOption
        label={t("settings.profile_option_label")}
        onChange={handleOptionChange}
        value={settings.useOptimistProfile}
        options={[{
            name: t("settings.profile_optimist_option"),
            value: true
        }, {name: t("settings.profile_prudent_option"), value: false},]}
        tooltipText={t("settings.profile_tooltip")}
    />
}
