import Iconify, {ICONS} from "../index";
import React from "react";

function WArea({sx, color, onClick, width, key, ref, ...other}) {
    return <Iconify
        icon={ICONS.area}
        width={width}
        key={key}
        ref={ref}
        sx={sx}
        color={color}
        onClick={onClick}
        {...other}
    />
}

function WDescription({sx, color, onClick, width, key, ref, ...other}) {
    return <Iconify
        icon={ICONS.description}
        width={width}
        key={key}
        ref={ref}
        sx={sx}
        color={color}
        onClick={onClick}
        {...other}
    />
}

function WBaby({sx, color, onClick, width, key, ref, ...other}) {
    return <Iconify
        icon={ICONS.baby}
        width={width}
        key={key}
        ref={ref}
        sx={sx}
        color={color}
        onClick={onClick}
        {...other}
    />
}

function WBathrooms({sx, color, onClick, width, key, ref, ...other}) {
    return <Iconify
        icon={ICONS.bathroom}
        width={width}
        key={key}
        ref={ref}
        sx={sx}
        color={color}
        onClick={onClick}
        {...other}
    />
}

function WWarning({sx, color, onClick, width, key, ref, ...other}) {
    return <Iconify
        icon={ICONS.warning}
        width={width}
        key={key}
        ref={ref}
        sx={sx}
        color={color}
        onClick={onClick}
        {...other}
    />
}

function WDone({sx, color, onClick, width, key, ref, ...other}) {
    return <Iconify
        icon={ICONS.done}
        width={width}
        key={key}
        ref={ref}
        sx={sx}
        color={color}
        onClick={onClick}
        {...other}
    />
}

function WBedrooms({sx, color, onClick, width, key, ref, ...other}) {
    return <Iconify
        icon={ICONS.bedrooms}
        width={width}
        key={key}
        ref={ref}
        sx={sx}
        color={color}
        onClick={onClick}
        {...other}
    />
}

function WCadastre({sx, color, onClick, width, key, ref, ...other}) {
    return <Iconify
        icon={ICONS.cadastre}
        width={width}
        key={key}
        ref={ref}
        sx={sx}
        color={color}
        onClick={onClick}
        {...other}
    />
}

function WClear({sx, color, onClick, width, key, ref, ...other}) {
    return <Iconify
        icon={ICONS.clear}
        width={width}
        key={key}
        ref={ref}
        sx={sx}
        color={color}
        onClick={onClick}
        {...other}
    />
}

function WConstructiveState({sx, color, onClick, width, key, ref, ...other}) {
    return <Iconify
        icon={ICONS.constructive_state}
        width={width}
        key={key}
        ref={ref}
        sx={sx}
        color={color}
        onClick={onClick}
        {...other}
    />
}

function WDashboard({sx, color, onClick, width, key, ref, ...other}) {
    return <Iconify
        icon={ICONS.dashboard}
        width={width}
        key={key}
        ref={ref}
        sx={sx}
        color={color}
        onClick={onClick}
        {...other}
    />
}

function WDiscord({sx, color, onClick, width, key, ref, ...other}) {
    return <Iconify
        icon={ICONS.discord}
        width={width}
        key={key}
        ref={ref}
        sx={sx}
        color={color}
        onClick={onClick}
        {...other}
    />
}

function WEdit({sx, color, onClick, width, key, ref, ...other}) {
    return <Iconify
        icon={ICONS.edit}
        width={width}
        key={key}
        ref={ref}
        sx={sx}
        color={color}
        onClick={onClick}
        {...other}
    />
}

function WHome({sx, color, onClick, width, key, ref, ...other}) {
    return <Iconify
        icon={ICONS.home_plus}
        width={width}
        key={key}
        ref={ref}
        sx={sx}
        color={color}
        onClick={onClick}
        {...other}
    />
}

function WBank({sx, color, onClick, width, key, ref, ...other}) {
    return <Iconify
        icon={ICONS.bank}
        width={width}
        key={key}
        ref={ref}
        sx={sx}
        color={color}
        onClick={onClick}
        {...other}
    />
}

function WDollar({sx, color, onClick, width, key, ref, ...other}) {
    return <Iconify
        icon={ICONS.dollar}
        width={width}
        key={key}
        ref={ref}
        sx={sx}
        color={color}
        onClick={onClick}
        {...other}
    />
}

function WVerified({sx, color, onClick, width, key, ref, ...other}) {
    return <Iconify
        icon={ICONS.verified}
        width={width}
        key={key}
        ref={ref}
        sx={sx}
        color={color}
        onClick={onClick}
        {...other}
    />
}

function WDrop({sx, color, onClick, width, key, ref, ...other}) {
    return <Iconify
        icon={ICONS.drop_down}
        width={width}
        key={key}
        ref={ref}
        sx={sx}
        color={color}
        onClick={onClick}
        {...other}
    />
}

function WError({sx, color, onClick, width, key, ref, ...other}) {
    return <Iconify
        icon={ICONS.error}
        width={width}
        key={key}
        ref={ref}
        sx={sx}
        color={color}
        onClick={onClick}
        {...other}
    />
}

function WFilter({sx, color, onClick, width, key, ref, ...other}) {
    return <Iconify
        icon={ICONS.filter}
        width={width}
        key={key}
        ref={ref}
        sx={sx}
        color={color}
        onClick={onClick}
        {...other}
    />
}

function WFinancial({sx, color, onClick, width, key, ref, ...other}) {
    return <Iconify
        icon={ICONS.financial}
        width={width}
        key={key}
        ref={ref}
        sx={sx}
        color={color}
        onClick={onClick}
        {...other}
    />
}

function WLoading({sx, color, onClick, width, key, ref}) {
    return <Iconify
        icon={ICONS.loading}
        width={width}
        key={key}
        ref={ref}
        sx={sx}
        color={color}
        onClick={onClick}
    />
}

function WLocation({sx, color, onClick, width, key, ref, ...other}) {
    return <Iconify
        icon={ICONS.location}
        width={width}
        key={key}
        ref={ref}
        sx={sx}
        color={color}
        onClick={onClick}
        {...other}
    />
}

function WDownloadNS({sx, color, onClick, width, key, ref, ...other}) {
    return <Iconify
        icon={ICONS.download_ns}
        width={width}
        key={key}
        ref={ref}
        sx={sx}
        color={color}
        onClick={onClick}
        {...other}
    />
}

function WDoor({sx, color, onClick, width, key, ref, ...other}) {
    return <Iconify
        icon={ICONS.door}
        width={width}
        key={key}
        ref={ref}
        sx={sx}
        color={color}
        onClick={onClick}
        {...other}
    />
}

function WValidations({sx, color, onClick, width, key, ref, ...other}) {
    return <Iconify
        icon={ICONS.my_validations}
        width={width}
        key={key}
        ref={ref}
        sx={sx}
        color={color}
        onClick={onClick}
        {...other}
    />
}

function WNonRented({sx, color, onClick, width, key, ref}) {
    return <Iconify
        icon={ICONS.non_rented}
        width={width}
        key={key}
        ref={ref}
        sx={sx}
        color={color}
        onClick={onClick}
    />
}

function WRented({sx, color, onClick, width, key, ref}) {
    return <Iconify
        icon={ICONS.rented}
        width={width}
        key={key}
        ref={ref}
        sx={sx}
        color={color}
        onClick={onClick}

    />
}

function WProfile({sx, color, onClick, width, key, ref, ...other}) {
    return <Iconify
        icon={ICONS.profile}
        width={width}
        key={key}
        ref={ref}
        sx={sx}
        color={color}
        onClick={onClick}
        {...other}
    />
}

function WCreditCard({sx, color, onClick, width, key, ref, ...other}) {
    return <Iconify
        icon={ICONS.credit_card}
        width={width}
        key={key}
        ref={ref}
        sx={sx}
        color={color}
        onClick={onClick}
        {...other}
    />
}

function WSettings({sx, color, onClick, width, key, ref, ...other}) {
    return <Iconify
        icon={ICONS.settings}
        width={width}
        key={key}
        ref={ref}
        sx={sx}
        color={color}
        onClick={onClick}
        {...other}
    />
}

function WPropertySummary({sx, color, onClick, width, key, ref, ...other}) {
    return <Iconify
        icon={ICONS.property_summary}
        width={width}
        key={key}
        ref={ref}
        sx={sx}
        color={color}
        onClick={onClick}
        {...other}
    />
}

function WBack({sx, color, onClick, width, key, ref, ...other}) {
    return <Iconify
        icon={ICONS.back}
        width={width}
        key={key}
        ref={ref}
        sx={sx}
        color={color}
        onClick={onClick}
        {...other}
    />
}

function WProperties({sx, color, onClick, width, key, ref, ...other}) {
    return <Iconify
        icon={ICONS.my_properties}
        width={width}
        key={key}
        ref={ref}
        sx={sx}
        color={color}
        onClick={onClick}
        {...other}
    />
}

function WRefresh({sx, color, onClick, width, key, ref, ...other}) {
    return <Iconify
        icon={ICONS.refresh}
        width={width}
        key={key}
        ref={ref}
        sx={sx}
        color={color}
        onClick={onClick}
        {...other}
    />
}


function WSearch({sx, color, onClick, width, key, ref, ...other}) {
    return <Iconify
        icon={ICONS.search}
        width={width}
        key={key}
        ref={ref}
        sx={sx}
        onClick={onClick}
        color={color}
        {...other}
    />
}

function WValidated({sx, color, onClick, width, key, ref}) {
    return <Iconify
        icon={ICONS.validated}
        width={width}
        key={key}
        ref={ref}
        sx={sx}
        color={color}
        onClick={onClick}
    />
}

function WValidationSummary({sx, color, onClick, width, key, ref, ...other}) {
    return <Iconify
        icon={ICONS.validation_summary}
        width={width}
        key={key}
        ref={ref}
        sx={sx}
        color={color}
        onClick={onClick}
        {...other}
    />
}

function WYear({sx, color, onClick, width, key, ref, ...other}) {
    return <Iconify
        icon={ICONS.year}
        width={width}
        key={key}
        ref={ref}
        sx={sx}
        color={color}
        onClick={onClick}
        {...other}
    />
}

function WResidential({sx, color, onClick, width, key, ref, ...other}) {
    return <Iconify
        icon={ICONS.residential}
        width={width}
        key={key}
        ref={ref}
        sx={sx}
        color={color}
        onClick={onClick}
        {...other}
    />
}

function WCertificate({sx, color, onClick, width, key, ref, ...other}) {
    return <Iconify
        icon={ICONS.certificate}
        width={width}
        key={key}
        ref={ref}
        sx={sx}
        color={color}
        onClick={onClick}
        {...other}
    />
}

function WMenu({sx, color, onClick, width, key, ref, ...other}) {
    return <Iconify
        icon={ICONS.mobile_menu}
        width={width}
        key={key}
        ref={ref}
        sx={sx}
        color={color}
        onClick={onClick}
        {...other}
    />
}

function WRegion({sx, color, onClick, width, key, ref, ...other}) {
    return <Iconify
        icon={ICONS.region}
        width={width}
        key={key}
        ref={ref}
        sx={sx}
        color={color}
        onClick={onClick}
        {...other}
    />
}

function WNeighborhood({sx, color, onClick, width, key, ref, ...other}) {
    return <Iconify
        icon={ICONS.neighborhood}
        width={width}
        key={key}
        ref={ref}
        sx={sx}
        color={color}
        onClick={onClick}
        {...other}
    />
}

function WCountry({sx, color, onClick, width, key, ref, ...other}) {
    return <Iconify
        icon={ICONS.country}
        width={width}
        key={key}
        ref={ref}
        sx={sx}
        color={color}
        onClick={onClick} {...other}
    />
}

function WAcceptable({sx, color, onClick, width, key, ref, ...other}) {
    return <Iconify
        icon={ICONS.acceptable}
        width={width}
        key={key}
        ref={ref}
        sx={sx}
        color={color}
        onClick={onClick}
        {...other}
    />
}

function WIdeal({sx, color, onClick, width, key, ref, ...other}) {
    return <Iconify
        icon={ICONS.ideal}
        width={width}
        key={key}
        ref={ref}
        sx={sx}
        color={color}
        onClick={onClick}
        {...other}
    />
}

function WLow({sx, color, onClick, width, key, ref, ...other}) {
    return <Iconify
        icon={ICONS.low}
        width={width}
        key={key}
        ref={ref}
        sx={sx}
        color={color}
        onClick={onClick}
        {...other}
    />
}

function WPen({sx, color, onClick, width, key, ref, ...other}) {
    return <Iconify
        icon={ICONS.pen}
        width={width}
        key={key}
        ref={ref}
        sx={sx}
        color={color}
        onClick={onClick}
        {...other}
    />
}

function WExteriorMasonry({sx, color, onClick, width, key, ref, ...other}) {
    return <Iconify
        icon={ICONS.exterior_masonry}
        width={width}
        key={key}
        ref={ref}
        sx={sx}
        color={color}
        onClick={onClick}
        {...other}
    />;
}

function WOuterCoating({sx, color, onClick, width, key, ref, ...other}) {
    return <Iconify icon={ICONS.outer_coating} width={width} key={key} ref={ref} sx={sx} color={color}
                    onClick={onClick} {...other} />;
}

function WCovers({sx, color, onClick, width, key, ref, ...other}) {
    return <Iconify icon={ICONS.covers} width={width} key={key} ref={ref} sx={sx} color={color}
                    onClick={onClick} {...other} />;
}

function WExteriorCarpentry({sx, color, onClick, width, key, ref, ...other}) {
    return <Iconify icon={ICONS.carpentry} width={width} key={key} ref={ref} sx={sx} color={color}
                    onClick={onClick} {...other} />;
}

function WExteriorFinish({sx, color, onClick, width, key, ref, ...other}) {
    return <Iconify icon={ICONS.finish} width={width} key={key} ref={ref} sx={sx} color={color}
                    onClick={onClick} {...other} />;
}

function WInteriorFinish({sx, color, onClick, width, key, ref, ...other}) {
    return <Iconify icon={ICONS.finish} width={width} key={key} ref={ref} sx={sx} color={color}
                    onClick={onClick} {...other} />;
}

function WInnerLining({sx, color, onClick, width, key, ref, ...other}) {
    return <Iconify icon={ICONS.inner_lining} width={width} key={key} ref={ref} sx={sx} color={color}
                    onClick={onClick} {...other} />;
}

function WInteriorMasonry({sx, color, onClick, width, key, ref, ...other}) {
    return <Iconify icon={ICONS.interior_masonry} width={width} key={key} ref={ref} sx={sx} color={color}
                    onClick={onClick} {...other} />;
}

function WFloorsStepsAndTiling({sx, color, onClick, width, key, ref, ...other}) {
    return <Iconify icon={ICONS.floors_steps_and_tiling} width={width} key={key} ref={ref} sx={sx} color={color}
                    onClick={onClick} {...other} />;
}

function WSanitaryAppliancesAndFaucets({sx, color, onClick, width, key, ref, ...other}) {
    return <Iconify icon={ICONS.sanitary_appliances_and_faucets} width={width} key={key} ref={ref} sx={sx} color={color}
                    onClick={onClick} {...other} />;
}

function WSanitation({sx, color, onClick, width, key, ref, ...other}) {
    return <Iconify icon={ICONS.sanitation} width={width} key={key} ref={ref} sx={sx} color={color}
                    onClick={onClick} {...other} />;
}

function WPlumbing({sx, color, onClick, width, key, ref, ...other}) {
    return <Iconify icon={ICONS.plumbing} width={width} key={key} ref={ref} sx={sx} color={color}
                    onClick={onClick} {...other} />;
}

function WElectricity({sx, color, onClick, width, key, ref, ...other}) {
    return <Iconify icon={ICONS.electricity} width={width} key={key} ref={ref} sx={sx} color={color}
                    onClick={onClick} {...other} />;
}

function WTelecommunications({sx, color, onClick, width, key, ref, ...other}) {
    return <Iconify icon={ICONS.telecommunications} width={width} key={key} ref={ref} sx={sx} color={color}
                    onClick={onClick} {...other} />;
}

function WVentilationExtraction({sx, color, onClick, width, key, ref, ...other}) {
    return <Iconify icon={ICONS.ventilation_extraction} width={width} key={key} ref={ref} sx={sx} color={color}
                    onClick={onClick} {...other} />;
}

function WFirefighting({sx, color, onClick, width, key, ref, ...other}) {
    return <Iconify icon={ICONS.firefighting} width={width} key={key} ref={ref} sx={sx} color={color}
                    onClick={onClick} {...other} />;
}

function WSafetyAndHealth({sx, color, onClick, width, key, ref, ...other}) {
    return <Iconify icon={ICONS.safety_and_health} width={width} key={key} ref={ref} sx={sx} color={color}
                    onClick={onClick} {...other} />;
}

function WRepair({sx, color, onClick, width, key, ref, ...other}) {
    return (<Iconify
        icon={ICONS.repair}
        width={width}
        key={key}
        ref={ref}
        sx={sx}
        color={color}
        onClick={onClick}
        {...other}
    />);
}

function WDelete({sx, color, onClick, width, key, ref, ...other}) {
    return (<Iconify
        icon={ICONS.delete}
        width={width}
        key={key}
        ref={ref}
        sx={sx}
        color={color}
        onClick={onClick}
        {...other}
    />);
}

function WModify({sx, color, onClick, width, key, ref, ...other}) {
    return (<Iconify
        icon={ICONS.modify}
        width={width}
        key={key}
        ref={ref}
        sx={sx}
        color={color}
        onClick={onClick}
        {...other}
    />);
}

function WHouseExclamation({sx, color, onClick, width, key, ref, ...other}) {
    return (<Iconify
        icon={ICONS.house_exclamation}
        width={width}
        key={key}
        ref={ref}
        sx={sx}
        color={color}
        onClick={onClick}
        {...other}
    />);
}

function WInteriorCarpentry({sx, color, onClick, width, key, ref, ...other}) {
    return (<Iconify
        icon={ICONS.carpentry}
        width={width}
        key={key}
        ref={ref}
        sx={sx}
        color={color}
        onClick={onClick}
        {...other}
    />);
}

function WPaint({sx, color, onClick, width, key, ref, ...other}) {
    return (<Iconify
        icon={ICONS.paint}
        width={width}
        key={key}
        ref={ref}
        sx={sx}
        color={color}
        onClick={onClick}
        {...other}
    />);
}

function WPerson({sx, color, onClick, width, key, ref, ...other}) {
    return (<Iconify
        icon={ICONS.person}
        width={width}
        key={key}
        ref={ref}
        sx={sx}
        color={color}
        onClick={onClick}
        {...other}
    />);
}

function WInfo({sx, color, onClick, width, key, ref, ...other}) {
    return (<Iconify
        icon={ICONS.info}
        width={width}
        key={key}
        ref={ref}
        sx={sx}
        color={color}
        onClick={onClick}
        {...other}
    />);
}

function WEnergyEfficiency({sx, color, onClick, width, key, ref, ...other}) {
    return (<Iconify
        icon={ICONS.energy_efficiency}
        width={width}
        key={key}
        ref={ref}
        sx={sx}
        color={color}
        onClick={onClick}
        {...other}
    />);
}

function WAi({sx, color, onClick, width, key, ref, ...other}) {
    return (<Iconify
        icon={ICONS.ai}
        width={width}
        key={key}
        ref={ref}
        sx={sx}
        color={color}
        onClick={onClick}
        {...other}
    />);
}

function WSpain({sx, color, onClick, width, key, ref, ...other}) {
    return <Iconify
        icon={ICONS.spain}
        width={width}
        key={key}
        ref={ref}
        sx={sx}
        color={color}
        onClick={onClick}
        {...other}
    />;
}

function WUsa({sx, color, onClick, width, key, ref, ...other}) {
    return <Iconify
        icon={ICONS.usa}
        width={width}
        key={key}
        ref={ref}
        sx={sx}
        color={color}
        onClick={onClick}
        {...other}
    />;
}

export {
    WCountry,
    WRegion,
    WArea,
    WBedrooms,
    WClear,
    WDiscord,
    WCadastre,
    WSearch,
    WEdit,
    WError,
    WLoading,
    WFilter,
    WLocation,
    WProfile,
    WFinancial,
    WRefresh,
    WEnergyEfficiency,
    WRented,
    WNonRented,
    WYear,
    WDashboard,
    WValidated,
    WValidations,
    WProperties,
    WValidationSummary,
    WBathrooms,
    WConstructiveState,
    WResidential,
    WMenu,
    WPropertySummary,
    WBack,
    WIdeal,
    WAcceptable,
    WLow,
    WPen,
    WDollar,
    WHome,
    WVerified,
    WBank,
    WDrop,
    WCovers,
    WElectricity,
    WPlumbing,
    WSanitation,
    WExteriorMasonry,
    WInteriorMasonry,
    WTelecommunications,
    WRepair,
    WModify,
    WPaint,
    WHouseExclamation,
    WPerson,
    WFirefighting,
    WExteriorCarpentry,
    WExteriorFinish,
    WFloorsStepsAndTiling,
    WInnerLining,
    WOuterCoating,
    WSafetyAndHealth,
    WSanitaryAppliancesAndFaucets,
    WVentilationExtraction,
    WInteriorCarpentry,
    WInteriorFinish,
    WInfo,
    WDescription,
    WDoor,
    WNeighborhood,
    WCertificate,
    WBaby,
    WAi,
    WDone,
    WWarning,
    WDownloadNS,
    WCreditCard,
    WSettings,
    WDelete,
    WSpain,
    WUsa
}