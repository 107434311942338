import React, {useEffect, useState} from 'react';
import Grid from "@mui/material/Grid";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton,
    Paper,
    Stack,
    Typography,
    useTheme
} from "@mui/material";
import DataField from "../../components/data-field";
import {BaseCard} from "../../components/cards";
import {
    getPerformanceColor,
    VALIDATION_STATUS_DEMO,
    VALIDATION_STATUS_DONE,
    VALIDATION_STATUS_PENDING
} from "../../utils/financial";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import ROUTES from "../../routes";
import {alpha} from "@mui/material/styles";
import toast from "react-hot-toast";
import {ValidationDeleter} from "../../services/validationRequest/validation-deleter";
import {useAuth0} from "@auth0/auth0-react";
import {
    RoiIndicatorWidget,
    ValidationErrorIndicatorWidget,
    ValidationStatusWidget
} from "../../components/cards/widgets";
import {Mixpanel} from "../../metrics/mixpanel";
import {WDelete} from "../../components/icons/walden-icons";
import Box from "@mui/material/Box";

export default function ValidationListItem({data, onDeleted, mini}) {
    const auth0Context = useAuth0();
    const {t} = useTranslation();
    const theme = useTheme();
    const navigate = useNavigate();
    const [openDialog, setOpenDialog] = useState(false);
    const [countdown, setCountdown] = useState(3);
    const [deleteEnabled, setDeleteEnabled] = useState(false);

    const ribbonStyles = {
        width: 90,
        height: 90,
        overflow: 'hidden',
        position: 'absolute',
        top: -8,
        right: -4,
        '&::before, &::after': {
            content: '""',
            position: 'absolute',
            zIndex: -1,
            display: 'block',
            border: `4px solid ${theme.palette.primary.main}`,
        },
        '&::before': {
            top: 0,
            left: 0,
            borderTopColor: 'transparent',
            borderRightColor: 'transparent',
        },
        '&::after': {
            bottom: 0,
            right: 0,
            borderBottomColor: 'transparent',
            borderLeftColor: 'transparent',
        },
        '& span': {
            position: 'absolute',
            display: 'block',
            width: 150,
            padding: '5px 0',
            backgroundColor: theme.palette.primary.main,
            boxShadow: '0 5px 10px rgba(0,0,0,.1)',
            color: '#fff',
            fontWeight: 300,
            textAlign: 'center',
            left: -16,
            top: 19,
            transform: 'rotate(45deg)',
        },
    };

    useEffect(() => {
        let timer;
        if (openDialog && countdown > 0) {
            timer = setTimeout(() => setCountdown(countdown - 1), 1000);
        } else if (countdown === 0) {
            setDeleteEnabled(true);
        }
        return () => clearTimeout(timer);
    }, [openDialog, countdown]);

    const baseCardStyle = data.status === 'error' ? {
        backgroundColor: alpha(theme.palette.error.main, 0.08),
    } : {};

    const handleOpenDialog = () => {
        setOpenDialog(true);
        setCountdown(3);
        setDeleteEnabled(false);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const handleDelete = () => {
        handleCloseDialog();
        toast.promise(ValidationDeleter(auth0Context, data.id), {
            loading: t("feedback.validation.delete_confirmation.delete_button"),
            success: () => {
                onDeleted(data.id);
                return t("feedback.success.general_api_success");
            },
            error: (e) => {
                if (e?.response?.status === 409) {
                    return t('feedback.error.duplicate_warning_message');
                } else {
                    return t('feedback.error.api_request_error');
                }
            }
        });
    };

    const paperStyle = {
        padding: theme.spacing(3),
        backgroundColor: getPerformanceColor(theme, data.status !== VALIDATION_STATUS_PENDING ? data.performance : 'default'),
        transition: 'background-color 0.7s ease-in-out',
        color: theme.palette.text.primary,
        textAlign: 'center',
        margin: "auto",
        alignItems: "center",
        justifyContent: "center",
        display: "flex",
        minHeight: data.status === VALIDATION_STATUS_DONE ? 'auto' : '118px'
    };

    const handleOpenValidation = () => {
        if (data.status === VALIDATION_STATUS_DONE || data.status === VALIDATION_STATUS_DEMO) {
            Mixpanel.track('Validation Opened', {
                'Validation Request ID': data.id,
                'Property ID': data?.property?.ID,
            });
            navigate(`${ROUTES.validations.detail}?id=${data.id}`, {state: data.id})
        }
    }

    return (
        <>
            <BaseCard sx={baseCardStyle} onClick={handleOpenValidation}>
                {data?.status === VALIDATION_STATUS_DEMO && (
                    <Box sx={ribbonStyles}>
                        <Typography component="span">{t("validation_list.demo")}</Typography>
                    </Box>
                )}
                <Grid container spacing={1}>
                    <ValidationErrorIndicatorWidget status={data?.status} handleDelete={handleOpenDialog}/>
                    <Grid item xs={12} md={mini ? 12 : 8} lg={mini ? 7 : 8} xl={mini ? 7 : 9}>
                        <Stack sx={{flexGrow: 1}} spacing={1}>
                            <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start'}}>
                                <Stack spacing={0.5} direction="row">
                                    {data.status !== 'error' && (
                                        <>
                                            <ValidationStatusWidget status={data.status}/>
                                            <DataField sx={{mt: 0.4}} value={data.validation_date}/>
                                        </>
                                    )}
                                </Stack>
                                {data.status === 'pending' && (
                                    <IconButton onClick={handleOpenDialog} size="small">
                                        <WDelete/>
                                    </IconButton>
                                )}
                            </Box>
                            {data.address ? (
                                <>
                                    <Typography variant="caption"
                                                color="text.disabled">{t("property_details.address")}</Typography>
                                    <Typography variant="h6" color="text.primary">{data.address}</Typography>
                                </>
                            ) : null}
                            <DataField
                                label={`${t("general.cadastre")}:`}
                                labelColor="text.primary"
                                value={data.reference}
                                valueColor="text.secondary"
                            />
                        </Stack>
                    </Grid>
                    {data.status !== 'error' && (
                        <Grid item xs={12} md={mini ? 12 : 4} lg={mini ? 5 : 4} xl={mini ? 5 : 3}>
                            <Paper sx={paperStyle}>
                                {data.status !== VALIDATION_STATUS_PENDING && (data.status === VALIDATION_STATUS_DONE || data.status === VALIDATION_STATUS_DEMO) ?
                                    <RoiIndicatorWidget data={data}/> : null}

                                {data.status === VALIDATION_STATUS_PENDING ?
                                    <Typography variant="h5">{t("general.in_process_dots")}</Typography> : null}
                            </Paper>
                        </Grid>
                    )}
                </Grid>
            </BaseCard>
            <Dialog
                open={openDialog}
                onClose={handleCloseDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{t("feedback.validation.delete_confirmation.title")}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {t("feedback.validation.delete_confirmation.message")}
                        <br/><br/>
                        {t("feedback.validation.delete_confirmation.contact_message")}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleCloseDialog}>{t("feedback.validation.delete_confirmation.cancel_button")}</Button>
                    <Button
                        onClick={handleDelete}
                        disabled={!deleteEnabled}
                        color="error"
                    >
                        {deleteEnabled
                            ? t("feedback.validation.delete_confirmation.delete_button")
                            : t("feedback.validation.delete_confirmation.delete_button_with_timer", {seconds: countdown})}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}
