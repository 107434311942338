export function FormatNumber(number, isYears = false, isEuro = false, isPercentage = false) {
    const options = {
        style: 'decimal',
        minimumFractionDigits: number % 1 !== 0 ? 2 : 0,
        maximumFractionDigits: 2,
    };

    const locale = 'en-US';
    const formattedNumber = new Intl.NumberFormat(locale, options).format(number);

    if (isEuro) {
        return `€ ${formattedNumber}`;
    }

    if (isPercentage) {
        return `${formattedNumber} %`;
    }

    return formattedNumber;
}

export function FormatCompactNumber(number) {
    const format = new Intl.NumberFormat("es-ES", {
        notation: 'compact',
        maximumFractionDigits: 1
    });
    return format.format(number);
}


export function FormatCompactCurrency(number, threshold = 1000, isEuro = true) {
    if (Math.abs(number) >= threshold) {
        const formattedNumber = FormatCompactNumber(number);
        return `${isEuro ? '€ ' : ''}${formattedNumber}`;
    } else {
        return FormatCurrency(number, isEuro);
    }
}

export function FormatCurrency(number) {
    return FormatNumber(number, false, true);
}

export function FormatPercentage(number) {
    return FormatNumber(number, false, false, true);
}

export function FormatSquareMeters(number) {
    const options = {
        style: 'decimal',
        minimumFractionDigits: number % 1 !== 0 ? 2 : 0,
        maximumFractionDigits: 2,
        useGrouping: true,
        minimumIntegerDigits: 1,
    };
    const locale = 'es-ES';
    const formattedNumber = new Intl.NumberFormat(locale, options).format(number);
    return `${formattedNumber} m²`;
}