import Box from "@mui/material/Box";

export function CardContainer({children, sx}) {
    return <Box sx={{
        display: "flex",
        flexWrap: "wrap",
        alignItems: "flex-start",
        justifyContent: "flex-start",
        gap: 3,
        ...sx
    }}>
        {children}
    </Box>
}