import {apiService} from "../ApiServiceV2";
import {addressAsString, calculateAreaFromProperty, calculateVTotal, propertyFromParcel} from "../../utils/financial";
import {fDate} from "../../utils/format-time";
import {useTranslation} from "react-i18next";
import {useQuery} from "react-query";
import toast from "react-hot-toast";
import {useAuth0} from "@auth0/auth0-react";

export function useValidationDetailer(validationId) {
    const {t} = useTranslation();
    const auth0Context = useAuth0();

    return useQuery(
        ['validationDetail', validationId],
        () => fetchValidationDetail(auth0Context, validationId),
        {
            onError: () => {
                toast.error(t('feedback.error.general_api_error'));
            },
        }
    );

}

async function fetchValidationDetail(auth0Context, validationId) {

    return apiService.getPropertyValidationDetail(auth0Context, validationId).then((response) => {
        return mapResponseToValidationDetailModel(response.data);
    })
}
function mapResponseToValidationDetailModel(validationDetailResponse) {
    if (!validationDetailResponse?.validation_request) return;

    const property = propertyFromParcel(validationDetailResponse?.validation_request?.cadastre_id, validationDetailResponse?.parcel);
    const financial = validationDetailResponse?.financial_validation;
    const walden_financial = financial?.financial_estimated;
    const mortgage_walden_financial = walden_financial?.mortgage;
    const roi_walden_financial = walden_financial?.roi
    const cash_flow_roi_walden_financial = roi_walden_financial?.cash_flow
    const investment_cost_roi_walden_financial = roi_walden_financial?.investment_cost
    const property_cost_roi_walden_financial = roi_walden_financial?.property_cost
    const energyCertificateMap = {
        1: 'A',
        2: 'B',
        3: 'C',
        4: 'D',
        5: 'E',
        6: 'F',
        7: 'G'
    };
    const market_financial = financial?.financial_actual
    const mortgage_market_financial = market_financial?.mortgage
    const roi_market_financial = market_financial?.roi
    const cash_flow_roi_market_financial = roi_market_financial?.cash_flow
    const investment_cost_roi_market_financial = roi_market_financial?.investment_cost
    const property_cost_roi_market_financial = roi_market_financial?.property_cost


    return {
        validation_request: {...validationDetailResponse?.validation_request},
        property: {
            date: fDate(validationDetailResponse?.validation_request?.created_at),
            address: addressAsString(validationDetailResponse?.address),
            reference: validationDetailResponse?.validation_request?.cadastre_id,
            area: calculateAreaFromProperty(property),
            year: property?.constructed_year,
            construction_status: property?.quality,
            is_residential: property?.is_residential,
            rooms: property?.room_number,
            bathrooms: property?.bathroom_number,
            latitude: validationDetailResponse?.parcel?.latitude,
            longitude: validationDetailResponse?.parcel?.longitude,
            building_area: calculateVTotal(property),
            energy_certificate: energyCertificateMap[property?.energy_certificate]
        },
        financial: {
            walden: {
                mortgage: {
                    total_payment: mortgage_walden_financial?.total_payment,
                    total_principal_payment: mortgage_walden_financial?.total_principal_payment,
                    total_interest_payment: mortgage_walden_financial?.total_interest_payment,
                    personal_contribution_amount: mortgage_walden_financial?.personal_contribution_amount,
                    personal_contribution_percentage: mortgage_walden_financial?.personal_contribution_percentage,
                    financed_amount: mortgage_walden_financial?.financed_amount,
                    financed_percentage: mortgage_walden_financial?.financed_percentage,
                    type: mortgage_walden_financial?.type,
                    years: mortgage_walden_financial?.years,
                    euribor: mortgage_walden_financial?.euribor,
                    interest: mortgage_walden_financial?.interest,
                    differential: mortgage_walden_financial?.differential,
                    payment: {
                        monthly: mortgage_walden_financial?.monthly_payment,
                        yearly: mortgage_walden_financial?.yearly_payment
                    },
                    principal_payment: {
                        monthly: mortgage_walden_financial?.monthly_principal_payment,
                        yearly: mortgage_walden_financial?.yearly_principal_payment
                    },
                    interest_payment: {
                        monthly: mortgage_walden_financial?.monthly_interest_payment,
                        yearly: mortgage_walden_financial?.yearly_interest_payment
                    }
                },
                roi: {
                    cash_flow: {
                        yearly: {
                            rent: cash_flow_roi_walden_financial?.yearly_rent, profit_before_taxes: {
                                optimistic: cash_flow_roi_walden_financial?.yearly_profit_before_taxes_optimistic,
                                prudent: cash_flow_roi_walden_financial?.yearly_profit_before_taxes_prudent,
                            }, profit_after_taxes: {
                                optimistic: cash_flow_roi_walden_financial?.yearly_profit_after_taxes_optimistic,
                                prudent: cash_flow_roi_walden_financial?.yearly_profit_after_taxes_prudent,
                            }, value_after_debt_payment: {
                                optimistic: cash_flow_roi_walden_financial?.yearly_cash_flow_after_debt_payment_optimistic,
                                prudent: cash_flow_roi_walden_financial?.yearly_cash_flow_after_debt_payment_prudent,
                            }

                        }, monthly: {
                            rent: cash_flow_roi_walden_financial?.monthly_rent, profit_before_taxes: {
                                optimistic: cash_flow_roi_walden_financial?.monthly_profit_before_taxes_optimistic,
                                prudent: cash_flow_roi_walden_financial?.monthly_profit_before_taxes_prudent,
                            }, profit_after_taxes: {
                                optimistic: cash_flow_roi_walden_financial?.monthly_profit_after_taxes_optimistic,
                                prudent: cash_flow_roi_walden_financial?.monthly_profit_after_taxes_prudent,
                            }, value_after_debt_payment: {
                                optimistic: cash_flow_roi_walden_financial?.monthly_cash_flow_after_debt_payment_optimistic,
                                prudent: cash_flow_roi_walden_financial?.monthly_cash_flow_after_debt_payment_prudent,
                            }
                        }

                    },
                    investment_cost: {
                        itp: investment_cost_roi_walden_financial?.itp,
                        property_value: investment_cost_roi_walden_financial?.property_value,
                        agency_commission: investment_cost_roi_walden_financial?.agency_commission,
                        notary_valuation: investment_cost_roi_walden_financial?.notary_valuation,
                        property_renovation: investment_cost_roi_walden_financial?.property_renovation,
                        investment_other_cost: investment_cost_roi_walden_financial?.investment_other_cost,
                        total_investment_cost: investment_cost_roi_walden_financial?.total_investment_cost
                    },
                    property_cost: {
                        yearly: {
                            mortgage_payment: property_cost_roi_walden_financial?.yearly_mortgage_payment,
                            maintenance: property_cost_roi_walden_financial?.yearly_property_costs.maintenance,
                            empty_period: property_cost_roi_walden_financial?.yearly_property_costs.empty_period,
                            unpaid_insurance: property_cost_roi_walden_financial?.yearly_property_costs.unpaid_insurance,
                            garbage_tax: property_cost_roi_walden_financial?.yearly_property_costs.garbage_tax,
                            home_insurance: property_cost_roi_walden_financial?.yearly_property_costs.garbage_tax,
                            life_insurance: property_cost_roi_walden_financial?.yearly_property_costs.life_insurance,
                            community_fees: property_cost_roi_walden_financial?.yearly_property_costs.community_fees,
                            property_tax_ibi: property_cost_roi_walden_financial?.yearly_property_costs.property_tax_ibi,
                            property_other_cost: property_cost_roi_walden_financial?.yearly_property_costs.property_other_cost,
                            total_property_cost: property_cost_roi_walden_financial?.yearly_total_property_cost,
                            total_property_cost_as_percentage_of_rent: property_cost_roi_walden_financial?.total_property_cost_as_percentage_of_rent
                        }, monthly: {
                            mortgage_payment: property_cost_roi_walden_financial?.yearly_mortgage_payment,
                            maintenance: property_cost_roi_walden_financial?.yearly_property_costs.maintenance,
                            empty_period: property_cost_roi_walden_financial?.yearly_property_costs.empty_period,
                            unpaid_insurance: property_cost_roi_walden_financial?.yearly_property_costs.unpaid_insurance,
                            garbage_tax: property_cost_roi_walden_financial?.yearly_property_costs.garbage_tax,
                            home_insurance: property_cost_roi_walden_financial?.yearly_property_costs.garbage_tax,
                            life_insurance: property_cost_roi_walden_financial?.yearly_property_costs.life_insurance,
                            community_fees: property_cost_roi_walden_financial?.yearly_property_costs.community_fees,
                            property_tax_ibi: property_cost_roi_walden_financial?.yearly_property_costs.property_tax_ibi,
                            property_other_cost: property_cost_roi_walden_financial?.yearly_property_costs.property_other_cost,
                            total_property_cost: property_cost_roi_walden_financial?.yearly_total_property_cost,
                            total_property_cost_as_percentage_of_rent: property_cost_roi_walden_financial?.total_property_cost_as_percentage_of_rent
                        }
                    },
                    gross_roi: roi_walden_financial?.gross_roi,
                    necessary_cash: roi_walden_financial?.necessary_cash,
                    performance: roi_walden_financial?.roi_performance,
                    irpf_percentage: roi_walden_financial?.irpf_percentage,
                    irpf_amount: {
                        yearly: roi_walden_financial?.yearly_irpf_amount,
                        monthly: roi_walden_financial?.monthly_irpf_amount
                    },
                    yearly_amortization: roi_walden_financial?.yearly_amortization,
                    taxable_income_base_after_bonuses: {
                        yearly: roi_walden_financial?.yearly_taxable_income_base_after_bonuses,
                        monthly: roi_walden_financial?.monthly_taxable_income_base_after_bonuses
                    },
                    net_roi_before_interest_and_taxes: {
                        optimistic: roi_walden_financial?.net_before_interest_and_taxes_optimistic,
                        prudent: roi_walden_financial?.net_before_interest_and_taxes_prudent,
                    },
                    net_roi_after_interest_and_taxes: {
                        optimistic: roi_walden_financial?.net_after_interest_and_taxes_optimistic,
                        prudent: roi_walden_financial?.net_after_interest_and_taxes_prudent
                    },
                    roce: {
                        optimistic: roi_walden_financial?.roce_optimistic, prudent: roi_walden_financial?.roce_prudent
                    },
                    net_patrimony_created: roi_walden_financial?.net_patrimony_created,
                    appreciation_vs_equity: roi_walden_financial?.appreciation_vs_equity,
                    years_mortgage_self_paying: roi_walden_financial?.years_mortgage_self_paying,
                    minimum_rent_for_self_paying_property: {
                        yearly: roi_walden_financial?.yearly_minimum_rent_for_self_paying_property,
                        monthly: roi_walden_financial?.monthly_minimum_rent_for_self_paying_property
                    },
                    sale_value_at_retirement: roi_walden_financial?.sale_value_at_retirement,
                    accumulated_amortization_at_retirement: roi_walden_financial?.accumulated_amortization_at_retirement,
                    capital_gain_at_retirement: roi_walden_financial?.capital_gain_at_retirement,
                    roi_diff: walden_financial?.roi_diff,
                    patrimony_contribution: walden_financial?.patrimony_contribution,
                    cash_flow_contribution: {
                        monthly: {
                            optimistic: walden_financial?.cash_flow_monthly_optimistic_contribution,
                            prudent: walden_financial?.cash_flow_monthly_prudent_contribution,
                        },
                        yearly: {
                            optimistic: walden_financial?.cash_flow_yearly_optimistic_contribution,
                            prudent: walden_financial?.cash_flow_yearly_prudent_contribution,
                        }
                    }
                },
            }, market: {
                mortgage: {
                    total_payment: mortgage_market_financial?.total_payment,
                    total_principal_payment: mortgage_market_financial?.total_principal_payment,
                    total_interest_payment: mortgage_market_financial?.total_interest_payment,
                    personal_contribution_amount: mortgage_market_financial?.personal_contribution_amount,
                    personal_contribution_percentage: mortgage_market_financial?.personal_contribution_percentage,
                    financed_amount: mortgage_market_financial?.financed_amount,
                    financed_percentage: mortgage_market_financial?.financed_percentage,
                    type: mortgage_market_financial?.type,
                    years: mortgage_market_financial?.years,
                    euribor: mortgage_market_financial?.euribor,
                    interest: mortgage_market_financial?.interest,
                    differential: mortgage_market_financial?.differential,
                    payment: {
                        monthly: mortgage_market_financial?.monthly_payment,
                        yearly: mortgage_market_financial?.yearly_payment
                    },
                    principal_payment: {
                        monthly: mortgage_market_financial?.monthly_principal_payment,
                        yearly: mortgage_market_financial?.yearly_principal_payment
                    },
                    interest_payment: {
                        monthly: mortgage_market_financial?.monthly_interest_payment,
                        yearly: mortgage_market_financial?.yearly_interest_payment
                    }
                },
                roi: {
                    cash_flow: {
                        yearly: {
                            rent: cash_flow_roi_market_financial?.yearly_rent, profit_before_taxes: {
                                optimistic: cash_flow_roi_market_financial?.yearly_profit_before_taxes_optimistic,
                                prudent: cash_flow_roi_market_financial?.yearly_profit_before_taxes_prudent,
                            }, profit_after_taxes: {
                                optimistic: cash_flow_roi_market_financial?.yearly_profit_after_taxes_optimistic,
                                prudent: cash_flow_roi_market_financial?.yearly_profit_after_taxes_prudent,
                            }, value_after_debt_payment: {
                                optimistic: cash_flow_roi_market_financial?.yearly_cash_flow_after_debt_payment_optimistic,
                                prudent: cash_flow_roi_market_financial?.yearly_cash_flow_after_debt_payment_prudent,
                            }

                        }, monthly: {
                            rent: cash_flow_roi_market_financial?.monthly_rent, profit_before_taxes: {
                                optimistic: cash_flow_roi_market_financial?.monthly_profit_before_taxes_optimistic,
                                prudent: cash_flow_roi_market_financial?.monthly_profit_before_taxes_prudent,
                            }, profit_after_taxes: {
                                optimistic: cash_flow_roi_market_financial?.monthly_profit_after_taxes_optimistic,
                                prudent: cash_flow_roi_market_financial?.monthly_profit_after_taxes_prudent,
                            }, value_after_debt_payment: {
                                optimistic: cash_flow_roi_market_financial?.monthly_cash_flow_after_debt_payment_optimistic,
                                prudent: cash_flow_roi_market_financial?.monthly_cash_flow_after_debt_payment_prudent,
                            }
                        },

                    },
                    investment_cost: {
                        itp: investment_cost_roi_market_financial?.itp,
                        property_value: investment_cost_roi_market_financial?.property_value,
                        agency_commission: investment_cost_roi_market_financial?.agency_commission,
                        notary_valuation: investment_cost_roi_market_financial?.notary_valuation,
                        property_renovation: investment_cost_roi_market_financial?.property_renovation,
                        investment_other_cost: investment_cost_roi_market_financial?.investment_other_cost,
                        total_investment_cost: investment_cost_roi_market_financial?.total_investment_cost
                    },
                    property_cost: {
                        yearly: {
                            mortgage_payment: property_cost_roi_market_financial?.yearly_mortgage_payment,
                            maintenance: property_cost_roi_market_financial?.yearly_property_costs.maintenance,
                            empty_period: property_cost_roi_market_financial?.yearly_property_costs.empty_period,
                            unpaid_insurance: property_cost_roi_market_financial?.yearly_property_costs.unpaid_insurance,
                            garbage_tax: property_cost_roi_market_financial?.yearly_property_costs.garbage_tax,
                            home_insurance: property_cost_roi_market_financial?.yearly_property_costs.garbage_tax,
                            life_insurance: property_cost_roi_market_financial?.yearly_property_costs.life_insurance,
                            community_fees: property_cost_roi_market_financial?.yearly_property_costs.community_fees,
                            property_tax_ibi: property_cost_roi_market_financial?.yearly_property_costs.property_tax_ibi,
                            property_other_cost: property_cost_roi_market_financial?.yearly_property_costs.property_other_cost,
                            total_property_cost: property_cost_roi_market_financial?.yearly_total_property_cost,
                            total_property_cost_as_percentage_of_rent: property_cost_roi_market_financial?.total_property_cost_as_percentage_of_rent
                        }, monthly: {
                            mortgage_payment: property_cost_roi_market_financial?.yearly_mortgage_payment,
                            maintenance: property_cost_roi_market_financial?.yearly_property_costs.maintenance,
                            empty_period: property_cost_roi_market_financial?.yearly_property_costs.empty_period,
                            unpaid_insurance: property_cost_roi_market_financial?.yearly_property_costs.unpaid_insurance,
                            garbage_tax: property_cost_roi_market_financial?.yearly_property_costs.garbage_tax,
                            home_insurance: property_cost_roi_market_financial?.yearly_property_costs.garbage_tax,
                            life_insurance: property_cost_roi_market_financial?.yearly_property_costs.life_insurance,
                            community_fees: property_cost_roi_market_financial?.yearly_property_costs.community_fees,
                            property_tax_ibi: property_cost_roi_market_financial?.yearly_property_costs.property_tax_ibi,
                            property_other_cost: property_cost_roi_market_financial?.yearly_property_costs.property_other_cost,
                            total_property_cost: property_cost_roi_market_financial?.yearly_total_property_cost,
                            total_property_cost_as_percentage_of_rent: property_cost_roi_market_financial?.total_property_cost_as_percentage_of_rent
                        }
                    },
                    gross_roi: roi_market_financial?.gross_roi,
                    necessary_cash: roi_market_financial?.necessary_cash,
                    performance: roi_market_financial?.roi_performance,
                    irpf_percentage: roi_market_financial?.irpf_percentage,
                    irpf_amount: {
                        yearly: roi_market_financial?.yearly_irpf_amount,
                        monthly: roi_market_financial?.monthly_irpf_amount
                    },
                    yearly_amortization: roi_market_financial?.yearly_amortization,
                    taxable_income_base_after_bonuses: {
                        yearly: roi_market_financial?.yearly_taxable_income_base_after_bonuses,
                        monthly: roi_market_financial?.monthly_taxable_income_base_after_bonuses
                    },
                    net_roi_before_interest_and_taxes: {
                        optimistic: roi_market_financial?.net_before_interest_and_taxes_optimistic,
                        prudent: roi_market_financial?.net_before_interest_and_taxes_prudent,
                    },
                    net_roi_after_interest_and_taxes: {
                        optimistic: roi_market_financial?.net_after_interest_and_taxes_optimistic,
                        prudent: roi_market_financial?.net_after_interest_and_taxes_prudent
                    },
                    roce: {
                        optimistic: roi_market_financial?.roce_optimistic, prudent: roi_market_financial?.roce_prudent
                    },
                    net_patrimony_created: roi_market_financial?.net_patrimony_created,
                    appreciation_vs_equity: roi_market_financial?.appreciation_vs_equity,
                    years_mortgage_self_paying: roi_market_financial?.years_mortgage_self_paying,
                    minimum_rent_for_self_paying_property: {
                        yearly: roi_market_financial?.yearly_minimum_rent_for_self_paying_property,
                        monthly: roi_market_financial?.monthly_minimum_rent_for_self_paying_property
                    },
                    sale_value_at_retirement: roi_market_financial?.sale_value_at_retirement,
                    accumulated_amortization_at_retirement: roi_market_financial?.accumulated_amortization_at_retirement,
                    capital_gain_at_retirement: roi_market_financial?.capital_gain_at_retirement,
                    roi_diff: market_financial?.roi_diff,
                    patrimony_contribution: market_financial?.patrimony_contribution,
                    cash_flow_contribution: {
                        monthly: {
                            optimistic: market_financial?.cash_flow_monthly_optimistic_contribution,
                            prudent: market_financial?.cash_flow_monthly_prudent_contribution,
                        },
                        yearly: {
                            optimistic: market_financial?.cash_flow_yearly_optimistic_contribution,
                            prudent: market_financial?.cash_flow_yearly_prudent_contribution,
                        }
                    }
                },
            },
            investment_goals: financial.investment_goals,
        },
        parameters: {...validationDetailResponse?.validation_request?.parameters}
    };
}
