import React, {useEffect, useState} from 'react';
import ApexCharts from 'react-apexcharts';
import {Grid, useTheme} from "@mui/material";
import {useTranslation} from "react-i18next";
import {BaseCard} from "./index";
import Typography from "@mui/material/Typography";
import DataField from "../data-field";
import {FormatCurrency} from "../../utils/format-number";

const ExpensesChart = ({data = {}, sx}) => {
    const theme = useTheme();
    const {t} = useTranslation();
    const roi = data?.roi;
    const propertyCost = roi?.property_cost;
    const [chartHeight, setChartHeight] = useState('100px');

    const [legendOptions, setLegendOptions] = useState({
        position: 'right',
        horizontalAlign: 'center',
        formatter: function (seriesName, opts) {
            const sortedOriginalValues = sortedCombinedData.map((data) => data.value);
            const originalValue = sortedOriginalValues[opts.seriesIndex].toFixed(2);
            return `${seriesName}: <strong>€${originalValue}</strong>`;
        },
        itemMargin: {
            vertical: 4
        },
        labels: {
            colors: 'gray',
        },
    });

    const colors = [
        theme.palette.chart.color1,
        theme.palette.chart.color2,
        theme.palette.chart.color3,
        theme.palette.chart.color4,
        theme.palette.chart.color5,
        theme.palette.chart.color6,
        theme.palette.chart.color7,
        theme.palette.chart.color8,
        theme.palette.chart.color9,
        theme.palette.chart.color10,
    ];

    const labelKeys = [
        'charts.holding_cost.maintenance',
        'charts.holding_cost.empty_period',
        'charts.holding_cost.unpaid_insurance',
        'charts.holding_cost.garbage_tax',
        'charts.holding_cost.home_tax',
        'charts.holding_cost.life_insurance',
        'charts.holding_cost.community',
        'charts.holding_cost.IBI',
        'charts.holding_cost.other_cost',
        'charts.holding_cost.mortgage_payment',
    ];

    const originalValues = [
        propertyCost?.maintenance,
        propertyCost?.empty_period,
        propertyCost?.unpaid_insurance,
        propertyCost?.garbage_tax,
        propertyCost?.home_insurance,
        propertyCost?.life_insurance,
        propertyCost?.community_fees,
        propertyCost?.property_tax_ibi,
        propertyCost?.property_other_cost,
        propertyCost?.mortgage_payment,
    ];

    const combinedData = originalValues.map((value, index) => ({
        value: value || 0,
        label: t(labelKeys[index]),
    }));

    const sortedCombinedData = combinedData.sort((a, b) => b.value - a.value);
    const getSeriesData = () => {
        return sortedCombinedData.map(data => Math.log2(data.value || 1));
    };

    useEffect(() => {
        const updateChartSettings = () => {
            if (window.innerWidth < 968) {
                setChartHeight('450px');
                setLegendOptions(prevOptions => ({
                    ...prevOptions,
                    position: 'bottom',
                    horizontalAlign: 'left',
                    fontSize: '10px',
                    markers: {
                        offsetY: 2,
                    },
                    itemMargin: {
                        vertical: 1,
                    },
                }));
            } else {
                setChartHeight('380px');
                setLegendOptions(prevOptions => ({
                    ...prevOptions,
                    position: 'left',
                    horizontalAlign: 'center',
                    fontSize: '12px',
                    itemMargin: {
                        vertical: 6,
                    },
                }));
            }
        };

        window.addEventListener('resize', updateChartSettings);
        updateChartSettings();

        return () => window.removeEventListener('resize', updateChartSettings);
    }, []);

    const sortedLabels = sortedCombinedData.map((data) => data.label);
    const chartData = {
        series: getSeriesData(),
        options: {
            chart: {
                type: 'polarArea',
                toolbar: {
                    show: false
                },
            },
            labels: sortedLabels,
            legend: legendOptions,
            colors: colors,
            tooltip: {
                enabled: true,
                custom: function ({seriesIndex, w}) {
                    return `<div class="tooltip-custom">
                        <span>${w.globals.labels[seriesIndex]}</span>
                    </div>`;
                }
            },
            yaxis: {
                show: false
            },
            xaxis: {
                show: false
            },
            plotOptions: {
                polarArea: {
                    rings: {
                        show: false,
                    },
                    spokes: {
                        show: false,
                    },
                },
            },
        },
    };

    const total = originalValues.reduce((acc, value) => acc + (value || 0), 0);

    return (
        <BaseCard sx={{...sx}} className="tour-oikos-details-expenses-chart">
            <Typography
                variant="h6">{t('charts.holding_cost.title')}</Typography>
            <Grid container spacing={2}>
                <Grid item xs={12} md={9.5} lg={10}>
                    <ApexCharts
                        options={chartData.options}
                        series={chartData.series}
                        type="polarArea"
                        height={chartHeight}
                    />
                </Grid>
                <Grid item xs={12} md={2.5} lg={2}
                      style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <DataField sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}
                               direction="column"
                               label={t('charts.holding_cost.total')} labelVariant="body2"
                               value={FormatCurrency(total)} valueVariant="h4" valueColor="text.primary"
                    />
                </Grid>
            </Grid>
        </BaseCard>
    );
};

export default ExpensesChart;
