export const communities = [{
    "id": "700b0964-a34c-4162-ae29-9352ad73a2bf",
    "gid": 1,
    "name": "Andalucía"
}, {
    "id": "2ae42e73-b3ae-4a89-ab0c-ad8ec0fad980",
    "gid": 2,
    "name": "Aragón"
}, {
    "id": "d5f03bb6-b647-4abd-9285-47af99465489",
    "gid": 3,
    "name": "Principado de Asturias"
}, {
    "id": "834003df-9946-4bcb-99c4-5dc3066545d1",
    "gid": 4,
    "name": "Illes Balears"
}, {
    "id": "65ddcae5-28c6-40c6-a191-23c9dfd60645",
    "gid": 5,
    "name": "Canarias"
}, {
    "id": "4b9ab27d-b583-4cb6-9e57-0f12ad88c6e9",
    "gid": 6,
    "name": "Cantabria"
}, {
    "id": "d1748f0b-571a-478d-bbca-b88d40c8dbf9",
    "gid": 7,
    "name": "Castilla y León"
}, {
    "id": "77033a4e-0a93-4159-a286-b1f808ea678b",
    "gid": 8,
    "name": "Castilla-La Mancha"
}, {
    "id": "049d5e0d-fe2b-4ddf-90ce-9842c6115fac",
    "gid": 9,
    "name": "Cataluña/Catalunya"
}, {
    "id": "fda64c31-25ed-4a61-9bf2-220dae2fc476",
    "gid": 10,
    "name": "Comunitat Valenciana"
}, {
    "id": "b7b79d89-c073-4a8c-a4da-5dacae50ddde",
    "gid": 11,
    "name": "Extremadura"
}, {
    "id": "7cac8760-dc54-42ff-98f5-bcecf880005b",
    "gid": 12,
    "name": "Galicia"
}, {
    "id": "d87d81a7-6350-4fc9-87f7-e5b89461d24e",
    "gid": 13,
    "name": "Comunidad de Madrid"
}, {
    "id": "59ac1c01-5d82-4d98-9803-363771970f22",
    "gid": 14,
    "name": "Región de Murcia"
}, {
    "id": "49faa952-f3d2-4cb9-9731-91af8847b2e1",
    "gid": 17,
    "name": "La Rioja"
}, {
    "id": "5a4c885e-a4bb-4e73-9be8-bba2c64f89b1",
    "gid": 18,
    "name": "Ciudad Autónoma de Ceuta"
}, {
    "id": "06dffaf1-c0a7-4c28-8a0e-8640951f5b95",
    "gid": 19,
    "name": "Ciudad Autónoma de Melilla"
}, {
    "id": "3f75b85f-f4d5-4170-a7f4-71addb82b979",
    "gid": 20,
    "name": "Territorio no asociado a ninguna autonomía"
}, {"id": "e5bb0d37-7edb-45e7-a4cd-056b4407ce1c", "gid": 21, "name": "Territorio no asociado a ninguna autonomía"}]