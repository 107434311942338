import {useTranslation} from "react-i18next";
import {useAuth0} from "@auth0/auth0-react";
import {useQuery} from "react-query";
import {apiService} from "../ApiServiceV2";
import toast from "react-hot-toast";

export function ChecklistDetailer(checklistId) {
    const {t} = useTranslation();
    const auth0Context = useAuth0();

    return useQuery(
        ["checklistDetails", checklistId],
        () => apiService.getChecklistById(auth0Context, checklistId).then(response => response.data),
        {
            refetchOnWindowFocus: false,
            onError: () => {
                toast.error(t("feedback.error.general_api_error"));
            }
        },
    );
}