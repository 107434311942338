import {Tour} from "./tour";
import {useTranslation} from "react-i18next";
import {TourContent} from "./tour-content";


export function ValidationVisualTour() {
    const {t} = useTranslation();

    const tourSteps = [
        {
            target: '.tour_visual_reform',
            content: (
                <TourContent
                    title={t("tour.visual.title")}
                    description={t("tour.visual.reforms")}
                />
            ),
            placement: 'center',
        },
        {
            target: '.tour_visual_cost',
            content: (
                <TourContent
                    title={t("tour.visual.title")}
                    description={t("tour.visual.cost")}
                />
            ),
        },
        {
            target: '.tour_visual_edit',
            content: (
                <TourContent
                    title={t("tour.visual.title")}
                    description={t("tour.visual.edit_visual_validation")}
                />
            ),
        }
    ];

    return <Tour steps={tourSteps} tourKey='validation_visual'/>;
}