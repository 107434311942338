import {useFiltersContext} from "../context";
import {OIKOS_STATUS_AVAILABLE, OIKOS_STATUS_RENTED} from "../../../utils/financial";
import Typography from "@mui/material/Typography";
import {useTranslation} from "react-i18next";
import {FormControl, FormControlLabel, FormGroup, Radio, RadioGroup} from "@mui/material";
import Checkbox from "@mui/material/Checkbox";

const filters = {
    rentStatusRented: {id: "rentStatusRented", fn: (item) => item?.status === OIKOS_STATUS_RENTED},
    rentStatusAvailable: {id: "rentStatusAvailable", fn: (item) => item?.status === OIKOS_STATUS_AVAILABLE}
}

export function OikosFilter() {
    const {t} = useTranslation();
    const filterContext = useFiltersContext();

    const toggleFilter = (event, filterFn) => {
        event.target.checked ? filterContext.onAddFilter(filterFn) : filterContext.onRemoveFilter(filterFn);
    }

    const isChecked = (filterFn) => {
        return filterContext.filters.includes(filterFn)
    }

    const onSortRecentFirst = () => {
        filterContext.onSetDateSort(1);
    }

    const onSortOldestFirst = () => {
        filterContext.onSetDateSort(2);
    }

    return <>
        <Typography variant="subtitle2" color="test.primary">{t("oikos.filters.section_rent_title")}</Typography>
        <FormGroup>
            <FormControlLabel control={<Checkbox onChange={(e) => toggleFilter(e, filters.rentStatusRented)}
                                                 checked={isChecked(filters.rentStatusRented)}/>
            } label={t("oikos.filters.section_rent_rented_label")}/>
            <FormControlLabel control={<Checkbox onChange={(e) => toggleFilter(e, filters.rentStatusAvailable)}
                                                 checked={isChecked(filters.rentStatusAvailable)}/>
            } label={t("oikos.filters.section_rent_available_label")}/>
        </FormGroup>
        <Typography variant="subtitle2" color="test.primary">{t("filter_component.order_by")}:</Typography>
        <FormControl>
            <RadioGroup defaultValue={filterContext.dateSort}>
                <FormControlLabel value={1} control={<Radio onClick={onSortRecentFirst}/>}
                                  label={t("filter_component.recent_first")}/>
                <FormControlLabel value={2} control={<Radio onClick={onSortOldestFirst}/>}
                                  label={t("filter_component.old_first")}/>
            </RadioGroup>
        </FormControl>
    </>
}