export const domain = process.env.REACT_APP_AUTH_DOMAIN;
export const clientId = process.env.REACT_APP_AUTH_CLIENT_ID;
export const audience = process.env.REACT_APP_AUTH_AUDIENCE;

export const authorizationScope = process.env.REACT_APP_AUTHORIZATION_SCOPES;
export const adminScope = "user:admin"
export const scopes = [authorizationScope, adminScope, "profile", "email"]

export function scopesString() {
    return scopes.join(" ");
}
