import {Chip, useTheme} from "@mui/material";
import {colorByStatus, VALIDATION_STATUS_DEMO, VALIDATION_STATUS_DONE} from "../../../utils/financial";
import Box from "@mui/material/Box";
import {useTranslation} from "react-i18next";
import {WLoading, WValidated} from "../../icons/walden-icons";

export function ValidationStatusWidget({status}) {
    const theme = useTheme();
    const {t} = useTranslation();

    const [background, foreground] = colorByStatus(theme, status);
    const label = status === VALIDATION_STATUS_DONE ? t("validation_list.add_validation.validation_done") : t("general.in_process");
    const icon = status === VALIDATION_STATUS_DONE || status === VALIDATION_STATUS_DEMO ?
        <WValidated sx={{marginLeft: 1, color: 'inherit'}}/> :
        <WLoading sx={{marginLeft: 1, color: 'inherit'}}/>

    return (
        <Box
            sx={{
                display: "flex"
            }}
        >
            <Chip
                size="small"
                label={label}
                icon={icon}
                sx={{
                    backgroundColor: background,
                    color: foreground,
                    '&:hover': {
                        bgcolor: background,
                    }
                }}

            />
        </Box>
    );
}