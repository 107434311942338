import React from "react";
import {Grid} from "@mui/material";
import {ValidationParametersContext} from "./ParametersContext";
import {useTranslation} from "react-i18next";
import {SectionTitle} from "../../common/SectionTitle";
import {BaseCard} from "../../components/cards";
import {useResponsive} from "../../hooks/use-responsive";
import Box from "@mui/material/Box";
import {CurrencyField} from "../../components/text_fields";

export const HoldingCostSection = ({showTitle=true}) => {
    const {t} = useTranslation();
    const {validationParameters, setValidationParameters} = React.useContext(ValidationParametersContext);
    const mdUp = useResponsive('up', 'md');
    const CardOrBox = mdUp ? BaseCard : Box;
    const handleMaintenanceChange = (event) => {
        const newMaintenance = event.target.value;
        setValidationParameters({
            ...validationParameters,
            maintenance: newMaintenance
        });
    }
    const handleEmptyPeriodChange = (event) => {
        const newEmptyPeriod = event.target.value;
        setValidationParameters({
            ...validationParameters,
            empty_period: newEmptyPeriod
        });
    }
    const handleUnpaidInsuranceChange = (event) => {
        const newUnpaidInsurance = event.target.value;
        setValidationParameters({
            ...validationParameters,
            unpaid_insurance: newUnpaidInsurance
        });
    }
    const handleGarbageTaxChange = (event) => {
        const newGarbageTax = event.target.value;
        setValidationParameters({
            ...validationParameters,
            garbage_tax: newGarbageTax
        });
    }
    const handleHomeInsuranceChange = (event) => {
        const newHomeInsurance = event.target.value;
        setValidationParameters({
            ...validationParameters,
            home_insurance: newHomeInsurance
        });
    }
    const handleLifeInsuranceChange = (event) => {
        const newLifeInsurance = event.target.value;
        setValidationParameters({
            ...validationParameters,
            life_insurance: newLifeInsurance
        });
    }
    const handleCommunityFeesChange = (event) => {
        const newCommunityFees = event.target.value;
        setValidationParameters({
            ...validationParameters,
            community_fees: newCommunityFees
        });
    }
    const handlePropertyTaxIBIChange = (event) => {
        const newPropertyTaxIBI = event.target.value;
        setValidationParameters({
            ...validationParameters,
            property_tax_ibi: newPropertyTaxIBI
        });
    }
    const handleOtherCostChange = (event) => {
        const newOtherCost = event.target.value;
        setValidationParameters({
            ...validationParameters,
            property_other_cost: newOtherCost
        });
    }

    return (
        <CardOrBox>
            {showTitle ? <SectionTitle
                title={t("parameters.annual_holding_cost.holding_cost_field_label")}
                variant={"h6"}
            /> : null}
            <Grid container elevation={5} spacing={2} alignItems="center">
                <Grid item xs={12} sm={6}>
                    <CurrencyField
                        label={t("parameters.annual_holding_cost.maintenance_field_label")}
                        value={validationParameters.maintenance}
                        onChange={handleMaintenanceChange}
                        fullWidth={true}

                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <CurrencyField
                        label={t("parameters.annual_holding_cost.empty_periods_field_label")}
                        value={validationParameters.empty_period}
                        onChange={handleEmptyPeriodChange}
                        fullWidth={true}

                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <CurrencyField
                        label={t("parameters.annual_holding_cost.unpaid_insurance_field_label")}
                        value={validationParameters.unpaid_insurance}
                        onChange={handleUnpaidInsuranceChange}
                        fullWidth={true}

                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <CurrencyField
                        label={t("parameters.annual_holding_cost.garbage_tax_field_label")}
                        value={validationParameters.garbage_tax}
                        onChange={handleGarbageTaxChange}
                        fullWidth={true}

                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <CurrencyField
                        label={t("parameters.annual_holding_cost.home_insurance_field_label")}
                        value={validationParameters.home_insurance}
                        onChange={handleHomeInsuranceChange}
                        fullWidth={true}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <CurrencyField
                        label={t("parameters.annual_holding_cost.life_insurance_field_label")}
                        value={validationParameters.life_insurance}
                        onChange={handleLifeInsuranceChange}
                        fullWidth={true}

                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <CurrencyField
                        label={t("parameters.annual_holding_cost.community_fees_field_label")}
                        value={validationParameters.community_fees}
                        fullWidth={true}

                        onChange={handleCommunityFeesChange}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <CurrencyField
                        label={t("parameters.annual_holding_cost.property_tax_IBI_field_label")}
                        value={validationParameters.property_tax_ibi}
                        onChange={handlePropertyTaxIBIChange}
                        fullWidth={true}

                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <CurrencyField
                        label={t("parameters.annual_holding_cost.additional_expenses_to_consider_field_label")}
                        value={validationParameters.property_other_cost}
                        onChange={handleOtherCostChange}
                        fullWidth={true}

                    />
                </Grid>
            </Grid>
        </CardOrBox>
    );

}
