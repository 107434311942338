import {styled} from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import React from "react";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import {useResponsive} from "../../hooks/use-responsive";
import {useTranslation} from "react-i18next";

const NoDataContainer = styled(Paper)(({theme}) => ({
    textAlign: 'left',
    backgroundColor: theme.palette.primary.lighter,
    padding: "40px",
    flexDirection: "column",
    alignItems: "left",
    display: "flex",
    height: "300",
    marginTop: "20px"
}));

export function NoData() {
    const {t} = useTranslation();
    const mdDown = useResponsive('down', 'md');
    const direction = mdDown ? "column" : "row";

    return <NoDataContainer>
        <Stack spacing={2} direction={direction} sx={{
            display: "flex",
            alignItems: "center",
        }}>
            <Box
                component="img"
                sx={{
                    width: '207px', height: "375px",
                }}
                src="/assets/illustrations/characters/character_6.png"
            />
            <Typography variant="h4" color="primary.darker">{t("financial.not_enough_data")}</Typography>
        </Stack>
    </NoDataContainer>
}