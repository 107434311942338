import {styled} from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import React from "react";

const EmptyContainer = styled(Paper)(({theme}) => ({
    textAlign: 'center',
    backgroundColor: theme.palette.primary.lighter,
    padding: "40px",
    flexDirection: "column",
    alignItems: "center",
    display: "flex",
    height: "300"
}));

export function EmptyValidationList({imageSrc, children}) {
    return (
        <EmptyContainer>
            <Box
                component="img"
                sx={{
                    width: '100%', height: "250px",
                }}
                src={imageSrc}
            />
            {children}
        </EmptyContainer>
    );
}