import {Card} from "@mui/material";
import {useResponsive} from "../../hooks/use-responsive";

export default function BaseCard({children, sx, ...other}){
    const mdDown = useResponsive('down', 'md');

    return (
        <Card
            sx={{
                display: 'flex',
                padding: mdDown ? '15px' : '24px',
                flexDirection: 'column',
                alignItems: 'flex-start',
                gap: '16px',
                ...sx
            }}
            {...other}
        >
            {children}
        </Card>
    )
}