import React from "react";
import {useTranslation} from "react-i18next";
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";
import BaseCard from "./base-card";
import DataField from "../data-field";
import {FormatPercentage} from "../../utils/format-number";
import Typography from "@mui/material/Typography";

export default function MortgageMetricsCard({data = {}, sx}) {
    const {t} = useTranslation();

    const mortgage = data?.mortgage;

    return (
        <BaseCard sx={sx}>
            <Typography variant="h6">{t("financial.metrics")}</Typography>
            <Stack direction="row" divider={<Divider orientation="vertical" flexItem/>} spacing={2} alignItems="stretch"
                   justifyContent="center" style={{width: '100%'}}>
                <Stack flexGrow={1}>
                    <DataField
                        direction="column"
                        label={t("financial.mortgage.type")}
                        value={mortgage?.type === 1 ? t("financial.mortgage.fixed") : t("financial.mortgage.variable")}
                        valueVariant="h4"
                        valueColor="text.primary"
                    />
                </Stack>
                <Stack flexGrow={1}>
                    <DataField
                        direction="column"
                        label={t("financial.mortgage.years")}
                        value={mortgage?.years}
                        valueVariant="h4"
                        valueColor="text.primary"
                    />
                </Stack>
                {mortgage?.type === 1 && (
                    <Stack flexGrow={1}>
                        <DataField
                            direction="column"
                            label={t("financial.mortgage.interest_rate")}
                            labelVariant="subtitle2"
                            value={FormatPercentage(mortgage?.interest)}
                            valueVariant="h4"
                            valueColor="text.primary"
                        />
                    </Stack>
                )}
                {mortgage?.type === 2 && (
                    <>
                        <Stack flexGrow={1}>
                            <DataField
                                direction="column"
                                label={t("financial.mortgage.euribor")}
                                labelVariant="subtitle2"
                                value={FormatPercentage(mortgage?.euribor)}
                                valueVariant="h4"
                                valueColor="text.primary"
                            />
                        </Stack>
                        <Stack flexGrow={1}>
                            <DataField
                                direction="column"
                                label={t("financial.mortgage.differential")}
                                labelVariant="subtitle2"
                                value={FormatPercentage(mortgage?.differential)}
                                valueVariant="h4"
                                valueColor="text.primary"
                            />
                        </Stack>
                    </>
                )}
            </Stack>
        </BaseCard>
    );
}
