import {useAuth0} from "@auth0/auth0-react";
import {useQuery} from "react-query";
import {apiService} from "../ApiServiceV2";

function mapResponseToDashboardModel(data) {
    return {
        roi: {
            performance: data.roi.performance,
            value: data.roi.value,
        },
        cash_flow: {
            performance: data.cash_flow.performance,
            value: data.cash_flow.value,
        },
        patrimony: {
            performance: data.patrimony.performance,
            value: data.patrimony.value,
        },
        thresholds: {
            has_roi_goal: data.investment_goals.has_roi_goal,
            roi_optimal: data.investment_goals.optimal_roi_threshold,
            roi_mediocre: data.investment_goals.mediocre_roi_threshold,
            roi_low: data.investment_goals.low_roi_threshold,
            has_cash_flow_goal: data.investment_goals.has_cashflow_goal,
            cash_flow_optimal: data.investment_goals.optimal_cashflow_threshold,
            cash_flow_mediocre: data.investment_goals.mediocre_cashflow_threshold,
            cash_flow_low: data.investment_goals.low_cashflow_threshold,
            has_patrimony_goal: data.investment_goals.has_patrimony_goal,
            patrimony_optimal: data.investment_goals.optimal_patrimony_threshold,
            patrimony_mediocre: data.investment_goals.mediocre_patrimony_threshold,
            patrimony_low: data.investment_goals.low_patrimony_threshold,
        },
    };
}

export function useOikosDashboard(onError) {
    const auth0Context = useAuth0();

    return useQuery("oikosDashboard", async () => {
            const response = await apiService.oikosDashboard(auth0Context);
            return mapResponseToDashboardModel(response.data);
        },
        {
            retry: (failureCount, error) => {
                if (error.response.status === 404) {
                    return false
                }
                return failureCount < 3;
            },
            onSuccess: () => {
            },
            onError: (error) => {
                if (onError) {
                    onError(error);
                }
            }
        });
}