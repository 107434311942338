import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import ROUTES from "../../routes";
import Typography from "@mui/material/Typography";
import React from "react";
import {Fab} from "@mui/material";
import {handleAddValidation} from "../validation_add/add-validation-func";

export default function AddOikosFabButton({fetchedProfileData}) {
    const navigate = useNavigate();
    const {t} = useTranslation();

    const handleClick = () => {
        handleAddValidation(fetchedProfileData, navigate, ROUTES.properties.add);
    };

    return <Fab
        variant="extended"
        sx={{
            position: "absolute",
            bottom: 140,
            right: 16
        }}
        onClick={handleClick}
    >
        <Typography variant="button">{t("oikos.add_properties.add_button_label").toUpperCase()}</Typography>
    </Fab>

}