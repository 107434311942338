import {apiService} from "../ApiServiceV2";
import {fDate} from "../../utils/format-time";
import {addressAsString} from "../../utils/financial";
import {useAuth0} from "@auth0/auth0-react";
import {useTranslation} from "react-i18next";
import {useQuery} from "react-query";
import toast from "react-hot-toast";

export function useValidationLister() {
    const auth0Context = useAuth0();
    const {t} = useTranslation();

    return useQuery("validationList", async () => {
            const response = await apiService.listPropertyValidations(auth0Context);
            return response.data.map(item => responseToItemModel(item));

        },
        {
            onError: () => {
                toast.error(t("feedback.error.general_api_error"));
            }
        });
}

function responseToItemModel(item) {
    return {
        id: item.validation_request_id,
        status: item?.status,
        validation_date: fDate(item.validation_date),
        address: addressAsString(item?.address),
        reference: item.reference,

        roi: {
            walden: item?.financial?.financial_estimated_gross_roi,
            market: item?.financial?.financial_actual_gross_roi
        },
        performance: {
            walden: item?.financial?.financial_estimated_performance,
            market: item?.financial?.financial_actual_performance
        },
    };
}
