import Paper from "@mui/material/Paper";
import AppPage from "../../common/AppPage";
import {useTranslation} from "react-i18next";
import React, {useState} from "react";
import {IconButton, Step, Stepper} from "@mui/material";
import Typography from "@mui/material/Typography";
import {ValidationParametersContext} from "../parameters/ParametersContext";
import {
    buildParametersRequest,
    defaultParameters,
    HoldingCostStep,
    InvestmentCostStep,
    MortgageStep
} from "../parameters";
import PageTitle from "../../components/titles";
import {DescribePropertyStep, OikosRentStep, OikosReviewStep} from "./steps";
import ROUTES from "../../routes";
import {useResponsive} from "../../hooks/use-responsive";
import {useOikosStorer} from "../../services/oikos/oikos_storer";
import {useLocation, useNavigate} from "react-router-dom";
import {show_confetti} from "../../components/confetti";
import {PondAwardedDialog} from "../../common/PondAwardedDialog";
import {WClear} from "../../components/icons/walden-icons";
import {ValidationConverter} from "../../services/validationRequest/validation-converter";

export default function AddOikos() {
    const navigate = useNavigate();
    const validationConverterData = useLocation().state;
    const {t} = useTranslation();
    const [cadastre, setCadastre] = useState(validationConverterData?.propertyData?.reference || "");
    const [cadastreIsValid, setCadastreIsValid] = useState(false);
    const [activeStep, setActiveStep] = useState(0);

    const [oikosParameters, setOikosParameters] = useState(validationConverterData?.parameters ?? defaultParameters());
    const [showPondDialog, setShowPondDialog] = useState(false);
    const [pondAmountAwarder, setPondAmountAwarded] = useState(0)
    const lgDown = useResponsive('down', 'lg');

    const goToOikosList = () => {
        navigate(ROUTES.properties.list);
    }

    const {mutate: storeOikos} = useOikosStorer((response) => {
        const awardedAmount = Number(response?.awarded_pond_amount)
        if (awardedAmount > 0) {
            show_confetti();
            setShowPondDialog(true);
            setPondAmountAwarded(awardedAmount);
        } else {
            navigate(ROUTES.properties.list);
        }
    });

    const {mutate: validateAndStoreOikos} = ValidationConverter((response) => {
        const awardedAmount = Number(response?.awarded_pond_amount)
        if (awardedAmount > 0) {
            show_confetti();
            setShowPondDialog(true);
            setPondAmountAwarded(awardedAmount);
        } else {
            navigate(ROUTES.properties.list);
        }
    });

    const buildRequest = () => {
        return buildParametersRequest(oikosParameters, {
            oikos: {
                cadastre_id: cadastre, rent_price: Number(oikosParameters.rent),
            }
        });
    };

    const handleAddButtonClick = () => {
        if (validationConverterData) {
            const oikosData = buildRequest();
            validateAndStoreOikos({
                validationRequestId: validationConverterData.validationRequestID,
                propertyData: {
                    reference: cadastre
                },
                parameters: oikosParameters
            });
        } else {
            const oikosData = buildRequest();
            storeOikos(oikosData);
        }
    };

    const handleNext = () => {
        setActiveStep((prevActiveStep) => ++prevActiveStep);
    };
    const handleCloseButtonClick = () => {
        if (validationConverterData) {
            navigate(ROUTES.validations.list);
        } else {
            navigate(ROUTES.properties.list);
        }
    };
    const handleBack = () => {
        setActiveStep((prevActiveStep) => --prevActiveStep);
    };

    const steps = [<DescribePropertyStep handleNext={handleNext}
                                         cadastre={cadastre}
                                         setCadastre={setCadastre}
                                         cadastreIsValid={cadastreIsValid} setCadastreIsValid={setCadastreIsValid}
                                         isViewMode={!!validationConverterData?.propertyData?.reference}/>,
        <InvestmentCostStep estimatable={false} handleNext={handleNext} handleBack={handleBack}/>,
        <MortgageStep handleNext={handleNext} handleBack={handleBack}/>,
        <HoldingCostStep handleNext={handleNext} handleBack={handleBack}/>,
        <OikosRentStep handleNext={handleNext} handleBack={handleBack} handleAddButtonClick={handleAddButtonClick}/>,
        <OikosReviewStep handleBack={handleBack} setActiveStep={setActiveStep}
                         handleAddButtonClick={handleAddButtonClick} cadastre={cadastre}/>];

    const headerContent = (
        <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%'}}>
            <PageTitle>{t("oikos.add_properties.main_title")}</PageTitle>
            <IconButton onClick={handleCloseButtonClick}>
                <WClear/>
            </IconButton>
        </div>
    );

    return <AppPage topBarContent={headerContent}>
        {!lgDown && <PageTitle>{t("oikos.add_properties.main_title")}</PageTitle>}
        <ValidationParametersContext.Provider
            value={{validationParameters: oikosParameters, setValidationParameters: setOikosParameters}}>
            <Stepper activeStep={activeStep} orientation="vertical">
                {steps.map((step, index) => (<Step key={index}>
                    {step}
                </Step>))}
            </Stepper>
        </ValidationParametersContext.Provider>
        {activeStep === steps.length && (<Paper square elevation={0} sx={{p: 3}}>
            <Typography>{t("oikos.add_properties.step5_success_message")}</Typography>
        </Paper>)}
        <PondAwardedDialog open={showPondDialog} amountAwarded={pondAmountAwarder}
                           tittle={t("oikos.add_properties.pond_awarded_dialog_title")}
                           nextButtonLabel={t("oikos.add_properties.go_to_property_list_cta")}
                           message={t("oikos.add_properties.ponds_awarded_message")}
                           onClick={goToOikosList}/>
    </AppPage>;
}