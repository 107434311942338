import React from "react";
import {Button, Grid, Paper, Typography} from "@mui/material";
import Box from "@mui/material/Box";
import {useProfileConfigStorer} from "../../services/profile-config-storer";
import {useTranslation} from "react-i18next";
import {Refresh as RefreshIcon} from "@mui/icons-material";

export function InvestorProfileConfig() {
    const {mutate: storeProfileConfig} = useProfileConfigStorer();
    const {t} = useTranslation();

    const handleReactivateTour = () => {
        const updatedConfig = {
            config: {
                tour: {
                    dashboard: "pending",
                    oikos_list: "pending",
                    oikos_detail: "pending",
                    validation_list: "pending",
                    validation_summary: "pending",
                    validation_financial: "pending",
                    validation_visual: "pending",
                    validation_administrative: "pending"
                }
            }
        };
        storeProfileConfig(updatedConfig);
    };

    return (
        <Grid item xs={12}>
            <Paper elevation={3}>
                <Box p={3}>
                    <Box display="flex" alignItems="center" mb={2}>
                        <Box flexGrow={1}>
                            <Typography variant="subtitle1">
                                {t("user_profile.tour_settings")}
                            </Typography>
                            <Typography variant="body2" color="textSecondary">
                                {t("user_profile.tour_description")}
                            </Typography>
                        </Box>
                        <Button
                            variant="outlined"
                            color="primary"
                            onClick={handleReactivateTour}
                            startIcon={<RefreshIcon/>}
                        >
                            {t("user_profile.reactivate_tour")}
                        </Button>
                    </Box>
                    <Typography variant="body2" color="textSecondary" align="center" sx={{mt: 4}}>
                        {t("user_profile.more_settings_coming_soon")}
                    </Typography>
                </Box>
            </Paper>
        </Grid>
    );
}