import Typography from "@mui/material/Typography";
import {Stack} from "@mui/material";
import PropTypes from "prop-types";

export default function DataField({
                                      direction = "row", icon, sx,
                                      label, labelColor = "text.disabled", labelVariant = "caption",
                                      value, valueColor = "text.disabled", valueVariant = "caption",
                                      labeledIcon = false
                                  }) {

    const labelAndIcon = labeledIcon ? (
        <Stack direction="row" display="flex" justifyContent='center' spacing={1}>
            {icon}
            <Typography variant={labelVariant} color={labelColor}>{label}</Typography>
        </Stack>
    ) : (<>
        {icon}
        <Typography variant={labelVariant} color={labelColor}>{label}</Typography>
    </>)


    return <Stack direction={direction} spacing={0.5} sx={{...sx}}>
        {labelAndIcon}
        <Typography variant={valueVariant} color={valueColor}>{value}</Typography>
    </Stack>;
}

DataField.propTypes = {
    direction: PropTypes.string,
    icon: PropTypes.element,
    label: PropTypes.string,
    labelColor: PropTypes.string,
    labelVariant: PropTypes.string,
    value: PropTypes.string,
    valueColor: PropTypes.string,
    valueVariant: PropTypes.string
}
