import {Tour} from "./tour";
import {useTranslation} from "react-i18next";
import {TourContent} from "./tour-content";


export function ValidationSummaryTour() {
    const {t} = useTranslation();

    const tourSteps = [{
        target: '.tour-validation-summary-description',
        content: (
            <TourContent
                title={t("tour.summary.title")}
                description={t("tour.summary.description")}
            />
        ),
        placement: 'center',
    }, {
        target: '.tour-validation-summary-convert',
        content: (
            <TourContent
                title={t("tour.summary.title")}
                description={t("tour.summary.convert_to_oikos")}
            />
        ),
        placement: 'left',
    }, {
        target: '.tour-validation-summary-property-summary',
        content: (
            <TourContent
                title={t("tour.summary.title")}
                description={t("tour.summary.summary")}
            />
        ),
    }, {
        target: '.tour-validation-summary-profitability',
        content: (
            <TourContent
                title={t("tour.summary.title")}
                description={t("tour.summary.profitability")}
            />
        ),
    }, {
        target: '.tour-validation-summary-renovation',
        content: (
            <TourContent
                title={t("tour.summary.title")}
                description={t("tour.summary.renovation")}
            />
        ),
    }, {
        target: '.tour-validation-summary-map',
        content: (
            <TourContent
                title={t("tour.summary.title")}
                description={t("tour.summary.map")}
            />
        ),
    }];

    return <Tour steps={tourSteps} tourKey='validation_summary'/>
}

