import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import React, {useState} from "react";
import {Box, ClickAwayListener, Collapse, IconButton} from "@mui/material";
import {useTranslation} from "react-i18next";
import {MIN_SEARCH_LENGTH} from "../search-filter-config";
import {WClear, WSearch} from "../../icons/walden-icons";
import {Mixpanel} from "../../../metrics/mixpanel";
import {useResponsive} from "../../../hooks/use-responsive";

const debounce = (func, delay) => {
    let debounceTimer;
    return function (...args) {
        const context = this;
        clearTimeout(debounceTimer);
        debounceTimer = setTimeout(() => func.apply(context, args), delay);
    }
}

export function PropertySearch({handleSearch, handleClear}) {
    const {t} = useTranslation();
    const [searchInput, setSearchInput] = useState("");
    const [showSearchField, setShowSearchField] = useState(false);
    const smUp = useResponsive("up", "sm");

    const debouncedSearch = debounce((criteria) => {
        handleSearch(criteria.toLowerCase());
    }, 1000);

    const handleChange = (event) => {
        const criteria = event.target.value;
        setSearchInput(criteria);
        debouncedSearch(criteria);
        Mixpanel.track("Property Search Started", {
            "Search Criteria": criteria,
        });
    };

    const onClear = () => {
        setSearchInput("");
        handleClear();
        Mixpanel.track("Property Search Cleared");
    };

    const onBlurHideSearchField = (event) => {
        if (!smUp && searchInput.length === 0) {
            setShowSearchField(false);
        }
    };

    const onClickAwayHideSearchField = (event) => {
        if (
            !event.currentTarget.contains(event.relatedTarget) &&
            searchInput.length === 0
        ) {
            setShowSearchField(false);
        }
    };

    const searchIcon = (
        <IconButton onClick={() => setShowSearchField(true)}>
            <WSearch/>
        </IconButton>
    );

    return (
        <>
            {!showSearchField && searchIcon}
            <Box position="fixed" zIndex={1}>
                <Collapse in={showSearchField} unmountOnExit orientation="horizontal">
                    <ClickAwayListener onClickAway={onClickAwayHideSearchField}>
                        <Box sx={{height: "55px"}}>
                            <TextField
                                id="financial_search_box"
                                label={t("general.search")}
                                fullWidth={true}
                                onBlur={onBlurHideSearchField}
                                sx={{width: smUp ? "400px" : "210px", backgroundColor: "white"}}
                                onChange={handleChange}
                                value={searchInput}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <WSearch/>
                                        </InputAdornment>
                                    ),
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            {searchInput.length > MIN_SEARCH_LENGTH && (
                                                <IconButton>
                                                    <WClear onClick={onClear}/>
                                                </IconButton>
                                            )}
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Box>
                    </ClickAwayListener>
                </Collapse>
            </Box>
        </>
    );
}