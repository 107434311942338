import TextField from "@mui/material/TextField";
import React, {useEffect} from "react";
import {useTranslation} from "react-i18next";

export default function CadastreField({cadastre, setCadastre, cadastreIsValid, setCadastreIsValid}) {
    const {t} = useTranslation();

    const isCadastreValid = (value) => {
        return value.length > 14 && value.length <= 20;
    }

    const handleCadastreChange = (event) => {
        let value = event.target.value;
        value = value.trim().replace(/[^a-zA-Z0-9ñÑ]/g, '').toUpperCase();
        setCadastreIsValid(isCadastreValid(value));
        setCadastre(value);
    }
    useEffect(() => {
        setCadastreIsValid(isCadastreValid(cadastre))
    }, [cadastre]);

    return (
        <TextField
            fullWidth
            label={t("general.cadastre")}
            variant="outlined"
            value={cadastre}
            onChange={handleCadastreChange}
            error={!cadastreIsValid}
            helperText={!cadastreIsValid && t("validation_list.add_validation.invalid_cadastre_message")}
            style={{marginBottom: '20px', width: '100%'}}
        />
    )
}