import React, {useEffect} from "react";
import TextField from "@mui/material/TextField";
import {InputAdornment} from "@mui/material";
import {useTranslation} from "react-i18next";
import {NumericFormat} from "react-number-format";
import PropTypes from "prop-types";

const isInvalidNumber = (val) => isNaN(Number(val)) || String(val).trim() === '';

const NumericFormatCustom = React.forwardRef(function NumericFormatCustom(
    props,
    ref,
) {
    const {onChange, isEuro, ...other} = props;

    return (
        <NumericFormat
            {...other}
            getInputRef={ref}
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value,
                    },
                });
            }}
            thousandSeparator
            valueIsNumericString
            decimalScale={isEuro ? 2 : undefined}
            fixedDecimalScale={isEuro}
        />
    );
});

NumericFormatCustom.propTypes = {
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    isEuro: PropTypes.bool,
};

export default function NumberField({
                                        sign,
                                        less_than,
                                        label,
                                        fullWidth,
                                        value,
                                        onChange,
                                        disabled,
                                        isError,
                                        helperText,
                                        isRequired = true,
                                        variant = "outlined",
                                        isEuro = false,
                                    }) {
    const [error, setError] = React.useState(false);
    const {t} = useTranslation();

    const handleChangeTextFieldValue = (event) => {
        const newValue = (String(event.target.value).trim());
        if (isNaN(newValue) || Number(newValue) > less_than) return;
        setError(isInvalidNumber(newValue));
    };

    useEffect(() => {
        if (value !== null) isRequired = false
        setError(isRequired);
    }, [isRequired, value]);

    return (
        <TextField
            fullWidth={fullWidth}
            label={label}
            variant={variant}
            value={value !== null ? value : ""}
            color={"primary"}
            onChange={onChange}
            onBlur={handleChangeTextFieldValue}
            error={!isRequired ? false : error || isError}
            helperText={error && isRequired ? t("feedback.error.number_field_isInvalid_label") : "" || isError ? helperText : ""}
            InputProps={{
                endAdornment: <InputAdornment position="end">{isEuro ? "€" : sign}</InputAdornment>,
                inputComponent: NumericFormatCustom,
                inputProps: {isEuro},
            }}
            disabled={disabled}
        />
    );
}