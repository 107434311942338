import Typography from "@mui/material/Typography";
import {useResponsive} from "../../hooks/use-responsive";

export default function PageTitle({id, children, sx}) {
    const lgDown = useResponsive('down', 'lg');

    const variant = lgDown ? 'h6' : 'h4';

    return (
        <Typography id={id} variant={variant} sx={{...sx}}>{children}</Typography>
    );
}