import {Tour} from "./tour";
import {useTranslation} from "react-i18next";
import {TourContent} from "./tour-content";

export function ValidationFinancialTour() {
    const {t} = useTranslation();

    const tourSteps = [{
        target: '.tour-validation-financial-description',
        content: (
            <TourContent
                title={t("tour.validation_financial.title")}
                description={t("tour.validation_financial.description")}
            />
        ),
        placement: 'center',
    }, {
        target: '.tour-validation-financial-change',
        content: (
            <TourContent
                title={t("tour.validation_financial.title")}
                description={t("tour.validation_financial.change")}
            />
        ),
    }, {
        target: '.tour-validation-financial-profitability',
        content: (
            <TourContent
                title={t("tour.validation_financial.title")}
                description={t("tour.validation_financial.profitability")}
            />
        ),
    }, {
        target: '.tour-validation-financial-edit-price',
        content: (
            <TourContent
                title={t("tour.validation_financial.title")}
                description={t('tour.validation_financial.edit_price')}
            />
        ),
    }, {
        target: '.tour-validation-financial-cashflow',
        content: (
            <TourContent
                title={t("tour.validation_financial.title")}
                description={t('tour.validation_financial.cashflow')}
            />
        ),
    }, {
        target: '.tour-validation-financial-metrics',
        content: (
            <TourContent
                title={t("tour.validation_financial.title")}
                description={t('tour.validation_financial.metrics')}
            />
        ),
    }, {
        target: '.tour-validation-financial-holding-cost',
        content: (
            <TourContent
                title={t("tour.validation_financial.title")}
                description={t('tour.validation_financial.holding_cost')}
            />
        ),
    }];

    return <Tour steps={tourSteps} tourKey='validation_financial'/>
}
