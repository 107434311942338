import Iconify from "./iconify";
import {MobileMenuIcon} from "./from-svg-icons";
import {AiTooltipIcon} from "./ai_toolltip_icon";

// find icons here: https://icon-sets.iconify.design/
export const ICONS = {
    dashboard: "material-symbols:dashboard-outline",
    my_properties: "material-symbols:holiday-village-outline",
    my_validations: "material-symbols:done-all-rounded",
    profile: "material-symbols:assignment-ind-outline",
    discord: "material-symbols:forum-outline",
    filter: "material-symbols:tune",
    refresh: "ic:round-refresh",
    clear: "ic:round-clear",
    delete: "ic:outline-delete",
    info: "lucide:info",
    search: "material-symbols:search",
    rented: "material-symbols:key",
    non_rented: "material-symbols:key-off",
    validated: "material-symbols:task-alt",
    loading: "line-md:loading-alt-loop",
    location: "mingcute:location-fill",
    download_ns: "material-symbols:sim-card-download-outline-rounded",
    cadastre: "material-symbols:layers-outline",
    area: "material-symbols:aspect-ratio-outline",
    residential: "material-symbols:home-work-outline",
    constructive_state: "material-symbols:roofing",
    bedrooms: "material-symbols:bed-outline",
    bathroom: "material-symbols:bathtub-outline",
    year: "material-symbols:calendar-today-outline-rounded",
    error: "material-symbols:error-outline",
    financial: "material-symbols:currency-exchange",
    validation_summary: "material-symbols:fact-check-outline",
    property_summary: "material-symbols:assignment-outline",
    done: "mingcute:check-circle-line",
    close_menu: "eva:arrow-ios-back-fill",
    certificate: "ph:certificate",
    back: "ion:chevron-back",
    energy_efficiency: "material-symbols:energy-savings-leaf-outline",
    open_menu: "eva:arrow-ios-forward-fill",
    description: "tabler:file-description",
    mobile_menu: "mingcute:menu-fill",
    acceptable: "material-symbols:keyboard-double-arrow-right",
    ideal: "material-symbols:keyboard-double-arrow-up",
    low: "material-symbols:keyboard-double-arrow-down",
    positive: "material-symbols:trending-up",
    negative: "material-symbols:trending-down",
    region: "mingcute:location-2-line",
    country: "material-symbols:flag-outline",
    edit: "material-symbols:page-info-outline",
    neighborhood: "iconoir:neighbourhood",
    pen: "uil:edit",
    home_plus: "mdi:home-plus-outline",
    bank: "ph:bank-bold",
    dollar: "pepicons-pop:dollar",
    credit_card: "mdi:credit-card-outline",
    verified: "material-symbols:verified-outline",
    drop_down: "eva:arrow-ios-downward-fill",
    warning: "material-symbols:warning-rounded",
    settings: "solar:settings-outline",
    //Checklist Icons
    exterior_masonry: "cbi:wall-nyro",
    outer_coating: "game-icons:ancient-columns",
    covers: "material-symbols:roofing",
    carpentry: "fluent-emoji-high-contrast:carpentry-saw",
    finish: "f7:paintbrush",
    inner_lining: "mdi:wall",
    interior_masonry: "game-icons:ladders-platform",
    floors_steps_and_tiling: "carbon:floorplan",
    sanitary_appliances_and_faucets: "mdi:toilet",
    sanitation: "eos-icons:pipeline-outlined",
    plumbing: "mdi:pipe-wrench",
    electricity: "pepicons-pencil:electricity",
    telecommunications: "streamline:satellite-dish",
    ventilation_extraction: "mdi:fan",
    firefighting: "mdi:firewall",
    safety_and_health: "mdi:security-account",
    door: "ph:door-bold",
    repair: "mdi:hammer-wrench",
    modify: "pepicons-pop:pen",
    ai: "mdi:sparkles-outline",
    paint: "maki:paint",
    house_exclamation: "bi:house-exclamation-fill",
    person: "bi:person-fill",
    baby: "ph:baby",
    //------
    usa: "emojione:flag-for-united-states",
    spain: "emojione:flag-for-spain"

}

export default Iconify;
export {MobileMenuIcon, AiTooltipIcon};
