import {createContext} from 'react';

export const InvestorProfileDataContext = createContext({
    profileContext: {
        region: "None",
        country: "None",
        salary: 0,
        community_id: "None",
        optimalROIThreshold: 0,
        mediocreROIThreshold: 0,
        lowROIThreshold: 0,

    },
});
