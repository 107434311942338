import React, {useEffect, useState} from 'react';
import ApexCharts from 'react-apexcharts';
import {useTheme} from '@mui/material/styles';
import Typography from "@mui/material/Typography";
import {useTranslation} from 'react-i18next';
import {BaseCard} from "./index";
import {Stack, Tab, Tabs} from "@mui/material";
import Box from "@mui/material/Box";

function MortgagePaymentChart({data, sx}) {
    const theme = useTheme();
    const {t} = useTranslation();
    const [tabValue, setTabValue] = useState(0);

    const mortgage = data?.mortgage

    const tabData = {
        cuota: mortgage ? [
            mortgage.payment,
            mortgage.interest_payment,
            mortgage.principal_payment,
        ] : [0, 0, 0],
        total: mortgage ? [
            mortgage.total_payment,
            mortgage.total_interest_payment,
            mortgage.total_principal_payment
        ] : [0, 0, 0]
    };

    const [currentData, setCurrentData] = useState(tabData.cuota);
    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };
    useEffect(() => {
        setCurrentData(tabValue === 0 ? tabData.cuota : tabData.total);
    }, [tabValue, data]);

    const series = [
        {name: t('charts.mortgage_payment.total'), data: [currentData[0]]},
        {name: t('charts.mortgage_payment.principal'), data: [currentData[2]]},
        {name: t('charts.mortgage_payment.interest'), data: [currentData[1]]}
    ];


    const options = {
        chart: {
            type: 'bar',
            stacked: false,
            toolbar: {
                show: false
            }
        },
        plotOptions: {
            bar: {
                horizontal: true,
                barHeight: '70%'
            }
        },
        dataLabels: {
            enabled: true,
            style: {
                fontSize: '12px',
                colors: ['#000000']
            },
            offsetX: -4,
            offsetY: 0
        },

        xaxis: {
            categories: ['']
        },
        colors: [
            theme.palette.chart.color1,
            theme.palette.chart.color2,
            theme.palette.chart.color3
        ],
        legend: {
            position: 'bottom'
        },

    };

    return (
        <BaseCard sx={sx}>
            <Box sx={{display: "flex", flexDirection: "column", alignItems: "flex-start"}}>
                <Typography variant="h6" color="text.primary" pb={1}>
                    {t('charts.mortgage_payment.payments')}
                </Typography>
                <Tabs value={tabValue} onChange={handleTabChange}>
                    <Tab label={t('charts.mortgage_payment.quota')}/>
                    <Tab label={t('charts.mortgage_payment.total')}/>
                </Tabs>
            </Box>
            <Stack width="100%" mt={-3}>
                <ApexCharts options={options} series={series} type="bar" width='100%' height={185}/>
            </Stack>
        </BaseCard>
    );

}

export default MortgagePaymentChart;
