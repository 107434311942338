import {Grid, Stack} from "@mui/material";
import AppPage from "../../common/AppPage";
import React, {useEffect, useState} from "react";
import {Mixpanel} from "../../metrics/mixpanel";
import {InvestorProfileEditView} from "./investorProfileEditView";
import ProfileHeader from "./profile-header";
import AboutUser from "./about-user";
import {LoadingScreenBar} from "../../components/loading-screen";
import NewInvestorProfileRanges from "./new-investor-profile-ranges";
import {useProfileDetailer} from "../../services/profile-detailer";
import {useNavigate} from "react-router-dom";
import ROUTES from "../../routes";
import {PricingTables} from "./pricing-tables";
import {useTranslation} from "react-i18next";
import toast from "react-hot-toast";
import {InvestorProfileConfig} from "./investor-profile-config";

function handleError(error, t, toast, navigate) {
    if (error.response.status === 404) {
        navigate(ROUTES.onboarding.profile);
    } else {
        toast.error(t("feedback.error.general_api_error"));
    }
}


export default function InvestorProfile() {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const [isEditMode, setIsEditMode] = React.useState(false);
    const {data: fetchedProfileData, isLoading} = useProfileDetailer(0, (error) => {
        handleError(error, t, toast, navigate);
    });
    const [selectedTab, setSelectedTab] = useState('profile');
    const [profileData, setProfileData] = useState({
        region: "None",
        country: "None",
        salary: 0,
        community_id: "None",
        optimalROIThreshold: 11,
        mediocreROIThreshold: 9,
        lowROIThreshold: 5,
    });
    const [subscriptionData, setSubscriptionData] = useState({})
    const [userConfig, setUserConfig] = useState({});

    useEffect(() => {
        setProfileData(fetchedProfileData?.investor_profile);
        setSubscriptionData(fetchedProfileData?.subscription_detail);
        setUserConfig(fetchedProfileData?.config);
    }, [fetchedProfileData]);

    const handleValueChange = (newValue) => {
        setSelectedTab(newValue);
    };
    const handleEditClick = () => {
        Mixpanel.track('Starting profile Editing');
        setIsEditMode(true);
    };

    const handleCancel = () => {
        Mixpanel.track('Cancel profile Editing');
        if (fetchedProfileData) {
            setProfileData(fetchedProfileData?.investor_profile);
        }
        setIsEditMode(false);
    };



    const profileDetailContent = (<Grid container spacing={2}>
        {(() => {
            switch (selectedTab) {
                case 'subscription':
                    return <PricingTables subscriptionData={subscriptionData}/>;
                case 'settings':
                    return <InvestorProfileConfig/>;
                case 'profile':
                default:
                    return <>
                        <Grid item xs={12} sm={3} md={4}>
                            <AboutUser profileData={profileData}/>
                        </Grid>
                        <Grid item xs={12} sm={9} md={8} textAlign="center">
                            <NewInvestorProfileRanges handleEditClick={handleEditClick}
                                                      profileData={profileData}/>
                        </Grid>
                    </>;
            }
        })()}
    </Grid>);

    const shouldShowEditContent = isEditMode || profileData?.id === null;

    const profileEditContent = (<InvestorProfileEditView
        setIsEditMode={setIsEditMode}
        profileData={profileData}
        setProfileData={setProfileData}
        configData={userConfig}
        handleCancel={handleCancel}
    />);

    const profileContent = <Stack spacing={2}>
        <Grid container spacing={1}>
            {!shouldShowEditContent && (<Grid item xs={12}>
                <ProfileHeader onValueChange={handleValueChange}/>
            </Grid>)}
        </Grid>
        {shouldShowEditContent ? profileEditContent : profileDetailContent}
    </Stack>;

    const renderComponent = () => {
        if (isLoading) {
            return <LoadingScreenBar/>;
        }
        return <AppPage containerComponent="box">{profileContent}</AppPage>;
    }

    return renderComponent();
}

