import React, {useState} from 'react';
import {BaseCard} from "../../components/cards";
import {
    WArea,
    WCertificate,
    WDescription,
    WEnergyEfficiency,
    WLocation,
    WResidential
} from "../../components/icons/walden-icons";
import DataField from "../../components/data-field";
import {useTheme} from "@mui/material";
import {useTranslation} from "react-i18next";
import Box from "@mui/material/Box";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import PageTitle from "../../components/titles";
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import {FormatSquareMeters} from "../../utils/format-number";
import {useResponsive} from "../../hooks/use-responsive";

const PropertyDescription = ({parsedData}) => {
    const theme = useTheme();
    const {t} = useTranslation();
    const [showPopover, setShowPopover] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const maxDescriptionLength = 80;
    const mdDown = useResponsive('down', 'md');

    const getDataFieldStyles = (mdDown) => ({
        ...(mdDown && {
            alignItems: 'flex-start',
            '& .MuiStack-root': {
                justifyContent: 'flex-start',
            },
        }),
    });

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
        setShowPopover(true);
    };

    const handleClose = () => {
        setAnchorEl(null);
        setShowPopover(false);
    };

    const open = Boolean(anchorEl);

    const truncatedDescription = parsedData?.property_description?.slice(0, maxDescriptionLength) + "...";

    return (<>
        <BaseCard sx={{maxWidth: "auto", height: "auto", position: 'relative'}}>
            <PageTitle>Propiedad</PageTitle>

            {parsedData?.property_address?.address && (<DataField
                icon={<WLocation color={theme.palette.primary.main} sx={{minWidth: "20px"}}/>}
                label={t('property_details.address')}
                labelColor="text.primary"
                value={parsedData.property_address.address}
                direction={mdDown ? 'column' : 'row'}
                labeledIcon={mdDown}
                sx={getDataFieldStyles(mdDown)}
            />)}
            <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end'}}>
                {parsedData?.property_description && (
                    <DataField
                        labelColor="text.primary"
                        icon={<WDescription color={theme.palette.text.secondary} sx={{minWidth: "20px"}}/>}
                        label={t('feedback.success.description')}
                        value={truncatedDescription}
                        direction={mdDown ? 'column' : 'row'}
                        labeledIcon={mdDown}
                        sx={getDataFieldStyles(mdDown)}
                    />
                )}

                {parsedData?.property_description?.length > maxDescriptionLength && (<Box
                    onClick={handleClick}
                    sx={{cursor: 'pointer'}}
                >
                    {showPopover ? <KeyboardArrowUpIcon sx={{color: theme.palette.primary.main}}/> :
                        <KeyboardArrowDownIcon sx={{color: theme.palette.primary.main}}/>}
                </Box>)}
            </Box>

            {parsedData?.energy_efficiency && (
                <DataField
                    labelColor="text.primary"
                    icon={<WEnergyEfficiency color={theme.palette.text.secondary} sx={{minWidth: "20px"}}/>}
                    label={t('administrative_validation.energy_efficiency')}
                    value={parsedData.energy_efficiency}
                    direction={mdDown ? 'column' : 'row'}
                    labeledIcon={mdDown}
                    sx={getDataFieldStyles(mdDown)}
                />
            )}
            {parsedData?.property_area?.land_area && (<DataField
                icon={<WArea color={theme.palette.text.secondary}/>}
                labelColor="text.primary"
                label={t('administrative_validation.land_area')}
                value={FormatSquareMeters(parsedData.property_area.land_area)}
                direction={mdDown ? 'column' : 'row'}
                labeledIcon={mdDown}
                sx={getDataFieldStyles(mdDown)}
            />)}
            {parsedData?.property_area?.building_area && (<DataField
                icon={<WResidential color={theme.palette.text.secondary}/>}
                labelColor="text.primary"
                label={t('administrative_validation.building_area')}
                value={FormatSquareMeters(parsedData.property_area.building_area)}
                direction={mdDown ? 'column' : 'row'}
                labeledIcon={mdDown}
                sx={getDataFieldStyles(mdDown)}
            />)}{parsedData?.occupancy_certificate && (<DataField
            icon={<WCertificate color={theme.palette.text.secondary}/>}
            labeledIcon={mdDown}
            direction={mdDown ? 'column' : 'row'}
            labelColor="text.primary"
            label={t('administrative_validation.occupancy_certificate')}
            value={parsedData.occupancy_certificate}
            sx={getDataFieldStyles(mdDown)}
        />)}
        </BaseCard>
        <Popover
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
            sx={{
                '& .MuiPaper-root': {
                    maxWidth: '300px',
                    height: '250px',
                },
            }}
        >
            <Typography sx={{p: 2}}>{parsedData?.property_description}</Typography>
        </Popover>
    </>);
};

export default PropertyDescription;