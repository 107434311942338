import {BaseCard} from "../../components/cards";
import PageTitle from "../../components/titles";
import {useResponsive} from "../../hooks/use-responsive";
import Typography from "@mui/material/Typography";
import {useNavigate} from 'react-router-dom';
import ROUTES from "../../routes";
import {OnboardingPage} from "./onboarding-page";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import {PrimaryButton, TertiaryButton} from "../../components/buttons";
import {useTranslation} from "react-i18next";

export function OnboardingProperty() {
    const mdDown = useResponsive('down', 'md');
    const navigate = useNavigate();
    const {t} = useTranslation();

    const next = () => {
        navigate(ROUTES.properties.add);
    }

    const back = () => {
        navigate(ROUTES.dashboard)
    }


    return <OnboardingPage>
        <BaseCard sx={{
            padding: mdDown ? '-15px' : '-24px',
            margin: "auto",
            minHeight: mdDown ? '320px' : '400x',
            maxWidth: "800px",
            flexDirection: "row",
            display: 'flex',
            backgroundColor: 'rgba(255, 255, 255, 0.6)'
        }}
        >
            {!mdDown ? <Box component="img" sx={{width: '350px', height: '350px'}}
                            src="https://cdn.walden.ai/media/img/app/illustrations/walden_control.svg"/> : null}

            <Box
                sx={{
                    padding: mdDown ? '15px' : '24px',
                    display: "flex",
                    flexDirection: "column",
                    margin: "auto",
                    gap: "1.5rem",
                    flexGrow: 1,
                }}>
                <Box>
                    <PageTitle>{t("onboarding_property.title")}</PageTitle>
                    <Typography mt={2}>{t("onboarding_property.description")}</Typography>
                </Box>
                <Stack direction="column"
                       sx={{display: "flex", alignItems: 'center', gap: '1rem', marginTop: 'auto'}}>
                    <Stack direction="row" sx={{
                        width: '100%',
                        justifyContent: "space-between",
                        alignItems: "center",
                        mt: 3
                    }}>
                        <TertiaryButton onClick={back}>
                            Ahora no
                        </TertiaryButton>
                        <PrimaryButton onClick={next}>
                            Añadir Propiedad
                        </PrimaryButton>
                    </Stack>
                </Stack>
            </Box>

        </BaseCard>
    </OnboardingPage>
}