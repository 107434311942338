import {useAuth0} from "@auth0/auth0-react";
import {useQuery} from "react-query";
import {apiService} from "../ApiServiceV2";

export function useGoalsHistory() {
    const auth0Context = useAuth0();

    return useQuery("goalsHistory", async () => {
        const response = await apiService.goalsHistory(auth0Context);
        return response.data;
    })
}