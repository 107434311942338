import {FormControl, FormControlLabel, FormGroup, Radio, RadioGroup} from "@mui/material";
import Typography from "@mui/material/Typography";
import Checkbox from '@mui/material/Checkbox';
import {useFiltersContext} from "../context";
import {useTranslation} from "react-i18next";
import {
    ROI_PERFORMANCE_LOW,
    ROI_PERFORMANCE_MEDIUM,
    ROI_PERFORMANCE_OPTIMAL,
    VALIDATION_STATUS_DONE,
    VALIDATION_STATUS_PENDING
} from "../../../utils/financial";
import {Mixpanel} from "../../../metrics/mixpanel";

const filters = {
    statusPending: {id: "statusPending", fn: (item) => item?.status === VALIDATION_STATUS_PENDING},
    statusDone: {id: "statusDone", fn: (item) => item?.status === VALIDATION_STATUS_DONE},
    idealPerformance: {id: "idealPerformance", fn: (item) => item?.performance === ROI_PERFORMANCE_OPTIMAL},
    moderatePerformance: {id: "moderatePerformance", fn: (item) => item?.performance === ROI_PERFORMANCE_MEDIUM},
    lowPerformance: {id: "lowPerformance", fn: (item) => item?.performance === ROI_PERFORMANCE_LOW},
}

export function ValidationFilter() {
    const filterContext = useFiltersContext();
    const {t} = useTranslation();

    const toggleFilter = (event, filterFn) => {
        event.target.checked ? filterContext.onAddFilter(filterFn) : filterContext.onRemoveFilter(filterFn);
        Mixpanel.track('Filters from validation: ' + filterFn.id, {
            'Filter': filterFn.id
        });
    }

    const isChecked = (filterFn) => {
        return filterContext.filters.includes(filterFn)
    }

    const onSortRecentFirst = () => {
        filterContext.onSetDateSort(1);
        Mixpanel.track('Filters from validation: Sort Changed', {
            'Sort': 'Recent First'
        });
    }

    const onSortOldestFirst = () => {
        filterContext.onSetDateSort(2);
        Mixpanel.track('Filters from validation: Sort Changed', {
            'Sort': 'Oldest First'
        });
    }

    return <>
        <Typography variant="subtitle2" color="test.primary">{t("filter_component.validation_status")}</Typography>
        <FormGroup>
            <FormControlLabel control={<Checkbox onChange={(e) => toggleFilter(e, filters.statusPending)}
                                                 checked={isChecked(filters.statusPending)}/>
            } label={t("general.pending")}/>
            <FormControlLabel control={<Checkbox onChange={(e) => toggleFilter(e, filters.statusDone)}
                                                 checked={isChecked(filters.statusDone)}/>
            } label={t("general.complete")}/>
        </FormGroup>
        <Typography variant="subtitle2" color="test.primary">{t("quality_levels.performance")}</Typography>
        <FormGroup>
            <FormControlLabel control={<Checkbox onChange={(e) => toggleFilter(e, filters.idealPerformance)}
                                                 checked={isChecked(filters.idealPerformance)}/>
            } label={t("quality_levels.ideal")}/>
            <FormControlLabel control={<Checkbox onChange={(e) => toggleFilter(e, filters.moderatePerformance)}
                                                 checked={isChecked(filters.moderatePerformance)}/>
            } label={t("quality_levels.moderate")}/>
            <FormControlLabel control={<Checkbox onChange={(e) => toggleFilter(e, filters.lowPerformance)}
                                                 checked={isChecked(filters.lowPerformance)}/>
            } label={t("quality_levels.low")}/>
        </FormGroup>
        <Typography variant="subtitle2" color="test.primary">{t("filter_component.order_by")}:</Typography>
        <FormControl>
            <RadioGroup defaultValue={filterContext.dateSort}>
                <FormControlLabel value={1} control={<Radio onClick={onSortRecentFirst}/>}
                                  label={t("filter_component.recent_first")}/>
                <FormControlLabel value={2} control={<Radio onClick={onSortOldestFirst}/>}
                                  label={t("filter_component.old_first")}/>
            </RadioGroup>
        </FormControl>
    </>;
}