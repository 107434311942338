import {useTranslation} from "react-i18next";
import {Stack, useTheme} from "@mui/material";
import {CadastreField} from "../../components/text_fields";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import {StepWrapper} from "../../components/custom-stepper";
import React from "react";
import Typography from "@mui/material/Typography";
import {ValidationParametersContext} from "../parameters/ParametersContext";
import {CustomTimeline} from "../../components/custom-timeline/custom-timeline";
import {FormatCurrency, FormatPercentage} from "../../utils/format-number";
import {WEdit, WHome, WVerified} from "../../components/icons/walden-icons";

export const DescribeValidationStep = ({handleNext, cadastre, setCadastre, cadastreIsValid, setCadastreIsValid}) => {
    const {t} = useTranslation();

    const content = <>
        <Stack spacing={2} mt={1}>
            <CadastreField
                cadastreIsValid={cadastreIsValid}
                setCadastreIsValid={setCadastreIsValid}
                setCadastre={setCadastre}
                cadastre={cadastre}
            />

            <TextField
                fullWidth
                label={t("validation_list.add_validation.address")}
                variant="outlined"
                helperText={t("validation_list.add_validation.readily_available")}
                disabled
            />

            <TextField
                fullWidth
                label={t("general.url")}
                variant="outlined"
                helperText={t("validation_list.add_validation.readily_available")}
                disabled
            />
        </Stack>
        <Box sx={{mb: 2, display: 'flex', justifyContent: 'flex-end'}}>
            <Button
                variant="contained"
                onClick={handleNext}
                disabled={!cadastreIsValid}
                sx={{mt: 1}}
            >
                {t("general.continue")}
            </Button>
        </Box>
    </>

    return <StepWrapper label={t("validation_list.add_validation.title")} stepContent={content} icon={<WHome/>}/>
}

export function ValidationReviewStep({handleNext, handleBack, cadastre, handleStepChange}) {
    const {t} = useTranslation();
    const {validationParameters} = React.useContext(ValidationParametersContext);
    const theme = useTheme();

    const catastre_timeline_content = [
        {
            label: t("validation_list.add_validation.cadastre"),
            value: cadastre
        }
    ];

    const investment_cost_timeline_content = [
        {
            label: t("parameters.investment_expenses.property_value"),
            value: FormatCurrency(validationParameters?.property_value)
        },
        {
            label: t("parameters.investment_expenses.notary_valuation_field_label"),
            value: FormatCurrency(validationParameters?.notary_valuation)
        },
        {
            label: t("parameters.investment_expenses.agency_commission_field_label"),
            value: FormatCurrency(validationParameters?.agency_commission)
        },
        {
            label: t("parameters.investment_expenses.property_renovation_field_label"),
            value: FormatCurrency(validationParameters?.property_renovation)
        },
        {
            label: t("parameters.investment_expenses.additional_expenses_to_consider_field_label"),
            value: FormatCurrency(validationParameters?.property_other_cost)
        },
    ];

    const mortgage_timeline_content = [
        {
            label: t("financial.mortgage.type"),
            value: validationParameters?.type === 1 ? t("financial.mortgage.fixed") : t("financial.mortgage.variable")
        }, {
            label: t("financial.mortgage.years"),
            value: validationParameters?.years
        }, {
            label: t("financial.mortgage.financed_amount"),
            value: FormatPercentage(validationParameters?.financed_percentage)
        }, {
            label: t("financial.mortgage.interest"),
            value: FormatPercentage(validationParameters?.interest)
        }, {
            label: t("financial.mortgage.differential"),
            value: FormatPercentage(validationParameters?.differential)
        }, {
            label: t("financial.mortgage.euribor"),
            value: FormatPercentage(validationParameters?.euribor)
        },
    ]

    const holding_cost_timeline_content = [
        {
            label: t("parameters.annual_holding_cost.maintenance_field_label"),
            value: FormatCurrency(validationParameters?.maintenance)
        }, {
            label: t("parameters.annual_holding_cost.empty_periods_field_label"),
            value: FormatCurrency(validationParameters?.empty_period)
        },
        {
            label: t("parameters.annual_holding_cost.unpaid_insurance_field_label"),
            value: FormatCurrency(validationParameters?.unpaid_insurance)
        }, {
            label: t("parameters.annual_holding_cost.garbage_tax_field_label"),
            value: FormatCurrency(validationParameters?.garbage_tax)
        },
        {
            label: t("parameters.annual_holding_cost.home_insurance_field_label"),
            value: FormatCurrency(validationParameters?.home_insurance)
        }, {
            label: t("parameters.annual_holding_cost.life_insurance_field_label"),
            value: FormatCurrency(validationParameters?.life_insurance)
        },
        {
            label: t("parameters.annual_holding_cost.community_fees_field_label"),
            value: FormatCurrency(validationParameters?.community_fees)
        }, {
            label: t("parameters.annual_holding_cost.property_tax_IBI_field_label"),
            value: FormatCurrency(validationParameters?.property_tax_ibi)
        },
        {
            label: t("parameters.annual_holding_cost.additional_expenses_to_consider_field_label"),
            value: FormatCurrency(validationParameters?.property_other_cost)
        },
    ]

    const timelineSteps = [
        {
            label: t("validation_list.add_validation.identify_the_property"),
            icon: <WEdit cursor="pointer" onClick={() => handleStepChange(0)}/>,
            timeline_content: catastre_timeline_content,
            dotStyle: {backgroundColor: theme.palette.primary.main}
        },
        {
            label: t("validation_list.add_validation.investment_costs"),
            icon: <WEdit cursor="pointer" onClick={() => handleStepChange(1)}/>,
            timeline_content: investment_cost_timeline_content,
            dotStyle: {backgroundColor: theme.palette.primary.main}

        }, {
            label: t("financial.mortgage.mortgage"),
            icon: <WEdit cursor="pointer" onClick={() => handleStepChange(2)}/>,
            timeline_content: mortgage_timeline_content,
            dotStyle: {backgroundColor: theme.palette.primary.main}

        }, {
            label: t("parameters.annual_holding_cost.title"),
            icon: <WEdit cursor="pointer" onClick={() => handleStepChange(3)}/>,
            timeline_content: holding_cost_timeline_content,
            dotStyle: {backgroundColor: theme.palette.primary.main}

        },
    ];

    const content =
        <>
            <Typography variant="body2">{t("validation_list.add_validation.verify_data")}</Typography>
            <CustomTimeline steps={timelineSteps}/>
            <Box sx={{m: 2, display: 'flex', justifyContent: 'flex-end'}}>
                <div>
                    <Button color="secondary" variant="text"
                            onClick={handleBack} sx={{mt: 1, ml: 1}}>
                        {t("general.back")}
                    </Button>
                    <Button
                        color="primary"
                        variant="contained"
                        onClick={handleNext}
                        sx={{mt: 1, ml: 1}}
                    >
                        {t("validation_list.add_validation.request_validation")}
                    </Button>


                </div>
            </Box>
        </>


    return <StepWrapper label={t("validation_list.add_validation.before_push_title")} stepContent={content} icon={<WVerified/>}/>;
}

