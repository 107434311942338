import {Chip, useTheme} from "@mui/material";
import {colorByStatus, OIKOS_STATUS_AVAILABLE, OIKOS_STATUS_RENTED} from "../../../utils/financial";
import Box from "@mui/material/Box";
import {useTranslation} from "react-i18next";
import {WLoading, WNonRented, WRented} from "../../icons/walden-icons";


export function OikosStatusWidget({status}) {
    const {t} = useTranslation();
    const theme = useTheme();

    let [background, foreground] = colorByStatus(theme, status);

    if (status === OIKOS_STATUS_AVAILABLE) {
        foreground = theme.palette.text.primary;
    }
    const props = status === OIKOS_STATUS_RENTED ?
        {
            icon: <WRented sx={{marginLeft: 1, color: 'inherit'}}/>,
            label: t("general.rented")
        } :
        status === OIKOS_STATUS_AVAILABLE ?
            {
                icon: <WNonRented sx={{marginLeft: 1, color: 'text.primary'}}/>,
                label: t("general.available")
            } :
            {
                icon: <WLoading sx={{marginLeft: 1, color: 'inherit'}}/>,
                label: t("general.in_process")
            };

    return (
        <Box
            sx={{
                display: "flex"
            }}
        >
            <Chip
                size="small"
                label={props.label}
                icon={props.icon}
                sx={{
                    backgroundColor: background,
                    color: foreground,
                    '&:hover': {
                        bgcolor: background,
                    }
                }}

            />

        </Box>
    );
}