import React, {useEffect, useMemo, useRef} from "react";
import {Box, Card, Container, useTheme} from "@mui/material";
import Header from "../components/header";
import {useSettingsContext} from "../components/settings";
import {useResponsive} from "../hooks/use-responsive";
import {Toaster} from "react-hot-toast";
import {VerticalNavigation} from "../components/navigation";
import {HEADER} from "../pages/config-layout";
import useServiceWorker from "../useServiceWorker";


export default function AppPage({
                                    children,
                                    containerComponent = "card",
                                    topBarTitle = "",
                                    topBarContent,
                                    ...other
                                }) {
    const theme = useTheme();
    const settings = useSettingsContext();
    const lgUp = useResponsive("up", "lg");
    const {onSetScrolling} = useSettingsContext();
    const ref = useRef(null);
    const ContainerComponent = containerComponent === "box" ? Box : Card;

    const {showReload, reloadPage} = useServiceWorker();


    useEffect(() => {
        if (!lgUp && settings.open) {
            settings.onToggle();
        }
    }, [lgUp, settings.open, settings.onToggle]);

    const mainContentStyles = useMemo(() => ({
        ...{
            position: "fixed",
            display: "flex",
            top: `${HEADER.H_DESKTOP}px`,
            height: "100vh",
            width: "100vw",
            backgroundColor: theme.palette.background.default,
        },
    }), [lgUp]);

    const containerStyles = useMemo(() => ({
        ...{
            overflowY: "auto",
            scrollbarWidth: "none",
            "&::-webkit-scrollbar": {
                display: "none"
            }
        },
    }), []);

    useEffect(() => {
        let scrollTimeout = null;

        const handleScroll = () => {
            clearTimeout(scrollTimeout);
            onSetScrolling(true);

            scrollTimeout = setTimeout(() => {
                onSetScrolling(false);
            }, 90);
        };

        const appPageElement = ref.current;
        if (appPageElement) {
            appPageElement.addEventListener('scroll', handleScroll);
        }

        return () => {
            if (appPageElement) {
                appPageElement.removeEventListener('scroll', handleScroll);
            }
            clearTimeout(scrollTimeout);
        };
    }, [onSetScrolling]);


    return <>
        <Header title={topBarTitle} customContent={topBarContent} showReload={showReload} reloadPage={reloadPage}/>
        <Box
            id="main-content"
            component="main"
            sx={mainContentStyles}
            {...other}
        >
            <VerticalNavigation/>
            <Container
                id="page-container"
                sx={containerStyles}
                ref={ref}
            >
                {lgUp
                    ? <ContainerComponent sx={{
                        m: "15px auto 100px auto",
                        padding: '24px',
                    }}>
                        {children}
                    </ContainerComponent> : <Box sx={{
                        mb: "150px",
                    }}>
                        {children}
                    </Box>}
            </Container>
        </Box>
        <Toaster/>


    </>;
}
