import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {communities} from "./CommunityData";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {FormControl, Grid, InputLabel} from "@mui/material";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import CurrencyField from "../../components/text_fields/currencyField";
import LoadingButton from "@mui/lab/LoadingButton";
import InvestmentGoals from "./investmentGoals";
import NumberField from "../../components/text_fields/numberField";
import {InvestorProfileDataContext} from "./InvestorProfileContext";
import {BaseCard} from "../../components/cards";
import {useProfileStorer} from "../../services/profile-storer";
import PageTitle from "../../components/titles";
import {show_confetti} from "../../components/confetti";
import {PondAwardedDialog} from "../../common/PondAwardedDialog";

function isError(s) {
    return s === undefined || s === null || s === "" || s < 0;
}

function lowROIIsError(profileData) {
    return profileData?.hasROIGoal && (isError(profileData?.lowROIThreshold) || Number(profileData?.lowROIThreshold) < 0);
}

function lowCashflowIsError(profileData) {
    return profileData?.hasCashflowGoal && (isError(profileData?.lowCashflowThreshold) || Number(profileData?.lowCashflowThreshold) < 0);
}

function lowPatrimonyIsError(profileData) {
    return profileData?.hasPatrimonyGoal && (isError(profileData?.lowPatrimonyThreshold) || Number(profileData?.lowPatrimonyThreshold) < 0);
}

function optimalROIIsError(profileData) {
    return profileData?.hasROIGoal && Number(profileData?.optimalROIThreshold) <= Number(profileData?.mediocreROIThreshold);
}

function mediocreROIIsError(profileData) {
    return profileData?.hasROIGoal && Number(profileData?.mediocreROIThreshold) <= Number(profileData?.lowROIThreshold);
}

function optimalCashflowIsError(profileData) {
    return profileData?.hasCashflowGoal && Number(profileData?.optimalCashflowThreshold) <= Number(profileData?.mediocreCashflowThreshold);
}

function mediocreCashflowIsError(profileData) {
    return profileData?.hasCashflowGoal && Number(profileData?.mediocreCashflowThreshold) <= Number(profileData?.lowCashflowThreshold);
}

function optimalPatrimonyIsError(profileData) {
    return profileData?.hasPatrimonyGoal && Number(profileData?.optimalPatrimonyThreshold) <= Number(profileData?.mediocrePatrimonyThreshold);
}

function mediocrePatrimonyIsError(profileData) {
    return profileData?.hasPatrimonyGoal && Number(profileData?.mediocrePatrimonyThreshold) <= Number(profileData?.lowPatrimonyThreshold);
}

const noOp = () => {
}

export const InvestorProfileEditView = ({
                                            profileData,
                                            setProfileData,
                                            configData,
                                            setIsEditMode = noOp,
                                            handleCancel = noOp,
                                            showAboutMe = true,
                                            showInvestmentGoals = true,
                                            readyToSave = false,
                                            notifySubmittable = noOp,
                                            notifyInvestorProfileStoreStatus = noOp
                                        }) => {

    const {t} = useTranslation();
    const [countryDisabled, setCountryDisabled] = useState(true);
    const [communityDisabled, setCommunityDisabled] = useState(true);
    const [submittable, setSubmittable] = useState(false)
    const [isSaving, setIsSaving] = useState(false);
    const showFullComponent = showAboutMe && showInvestmentGoals;
    const [showPondDialog, setShowPondDialog] = useState(false);
    const [pondAmountAwarder, setPondAmountAwarded] = useState(0)
    const {mutate: storeProfile} = useProfileStorer((response) => {
        const awardedAmount = Number(response?.awarded_pond_amount)
        if (awardedAmount > 0) {
            show_confetti();
            setShowPondDialog(true);
            setPondAmountAwarded(awardedAmount);
        } else {
            onClosePondAwardedDialog();
        }
    });

    useEffect(() => {
        if (readyToSave) {
            handleSave();
        }
    }, [readyToSave]);

    const onClosePondAwardedDialog = () => {
        setShowPondDialog(false);
        setIsEditMode(false);
        notifyInvestorProfileStoreStatus();
    }
    useEffect(() => {
        notifySubmittable(submittable)
    }, [submittable]);

    useEffect(() => {
        setCountryDisabled(profileData?.region !== "EU");
        setCommunityDisabled(profileData?.country !== "ES");
        setSubmittable(isDataValid())
    }, [profileData])

    const isDataValid = () => {
        const regionIsValid = profileData?.region !== "None";
        const countryIsValid = (profileData?.region === "EU" && profileData?.country === "ES") || (regionIsValid && profileData?.region !== "EU" && profileData?.country !== "ES");
        const salaryIsValid = profileData?.salary && profileData?.salary !== "" && profileData?.salary !== "0";
        const ageIsValid = profileData?.age && profileData?.age !== "" && profileData?.age !== "0";
        const savedAmountIsValid = profileData?.savedAmount && profileData?.savedAmount !== "" && profileData?.savedAmount !== "0";
        const communityIsValid = (profileData?.country === "ES" && profileData?.community_id !== "None") || (profileData?.country !== "ES" && profileData?.community_id === "None");
        const investmentThresholdIsValid = !lowROIIsError(profileData) && !lowCashflowIsError(profileData) && !lowPatrimonyIsError(profileData) && !optimalROIIsError(profileData) && !mediocreROIIsError(profileData) && !optimalCashflowIsError(profileData) && !mediocreCashflowIsError(profileData) && !optimalPatrimonyIsError(profileData) && !mediocrePatrimonyIsError(profileData);
        return (regionIsValid && countryIsValid && ageIsValid && salaryIsValid && communityIsValid && investmentThresholdIsValid && savedAmountIsValid);
    }


    const handleRegionChange = (event) => {
        const selectedRegion = event.target.value;
        let defaultCountry = profileData?.country;
        let defaultCommunity = profileData?.community_id;
        if (selectedRegion !== "EU") {
            defaultCountry = "None";
            defaultCommunity = "None";
        }
        setProfileData({
            ...profileData, region: selectedRegion, country: defaultCountry, community_id: defaultCommunity
        });
    };


    const buildRequest = (profileDataRaw) => {
        return {
            ID: profileDataRaw.id,
            region: profileDataRaw.region,
            country: profileDataRaw.country,
            has_roi_goal: profileDataRaw.hasROIGoal,
            optimal_roi_threshold: Number(profileDataRaw.optimalROIThreshold),
            mediocre_roi_threshold: Number(profileDataRaw.mediocreROIThreshold),
            low_roi_threshold: Number(profileDataRaw.lowROIThreshold),
            has_cashflow_goal: profileDataRaw.hasCashflowGoal,
            optimal_cashflow_threshold: Number(profileDataRaw.optimalCashflowThreshold),
            mediocre_cashflow_threshold: Number(profileDataRaw.mediocreCashflowThreshold),
            low_cashflow_threshold: Number(profileDataRaw.lowCashflowThreshold),
            has_patrimony_goal: profileDataRaw.hasPatrimonyGoal,
            optimal_patrimony_threshold: Number(profileDataRaw.optimalPatrimonyThreshold),
            mediocre_patrimony_threshold: Number(profileDataRaw.mediocrePatrimonyThreshold),
            low_patrimony_threshold: Number(profileDataRaw.lowPatrimonyThreshold),
            salary: Number(profileDataRaw.salary),
            age: Number(profileDataRaw.age),
            saved_amount: Number(profileDataRaw.savedAmount),
            community_id: profileDataRaw.community_id === "None" ? null : profileDataRaw.community_id,
            config: configData
        };
    }

    const handleCountryChange = (event) => {
        const selectedCountry = event.target.value;
        let defaultSalary = profileData?.salary;
        let defaultCommunity = profileData?.community_id;
        if (selectedCountry !== "ES") {
            defaultCommunity = "None";
        }
        setProfileData({
            ...profileData, country: selectedCountry, salary: defaultSalary, community_id: defaultCommunity
        });
    };

    const handleCommunityChange = (event) => {
        const newValue = event.target.value;
        const communitySelected = communities.filter(c => c.id === newValue)[0];
        setProfileData({...profileData, community_id: newValue, community: communitySelected})
    }

    const handleSave = () => {
        storeProfile(buildRequest(profileData));
        setIsSaving(false);
    };

    const aboutMeSection = <>
        <Grid container spacing={2}>
            <Grid item xs={12} sm={4}>
                <FormControl fullWidth sx={{minWidth: 120}}>
                    <InputLabel
                        id="region-select-label">{t("user_profile.region_select_label")}</InputLabel>
                    <Select
                        labelId="region-select-label"
                        id="region-select"
                        label={t("user_profile.region_select_label")}
                        value={profileData?.region}
                        onChange={handleRegionChange}
                        required={true}
                        sx={{textAlign: 'left'}}
                    >
                        <MenuItem value="EU">{t('location.europe')}</MenuItem>
                        <MenuItem value="Other">{t('general.other')}</MenuItem>
                    </Select>
                </FormControl>
            </Grid>

            <Grid item xs={12} sm={4}>
                <FormControl fullWidth sx={{minWidth: 120}}>
                    <InputLabel
                        id="country-select-label">{t("user_profile.country_select_label")}</InputLabel>
                    <Select
                        labelId="country-select-label"
                        id="country-select"
                        label={t("user_profile.country_select_label")}
                        value={profileData?.country}
                        disabled={countryDisabled}
                        onChange={handleCountryChange}
                        sx={{textAlign: 'left'}}
                    >
                        <MenuItem value={"ES"}>{t('location.spain')}</MenuItem>
                    </Select>
                </FormControl>
            </Grid>

            <Grid item xs={12} sm={4}>
                <FormControl fullWidth sx={{minWidth: 120}}>
                    <InputLabel
                        id="community-select-label">{t("user_profile.community_select_label")}</InputLabel>
                    <Select
                        labelId="community-select-label"
                        id="community-select"
                        label={t("user_profile.community_select_label")}
                        value={profileData?.community_id}
                        disabled={communityDisabled}
                        onChange={handleCommunityChange}
                        sx={{textAlign: 'left'}}
                    >
                        {communities.map((community) => (<MenuItem value={community.id}>{community.name}</MenuItem>))}
                    </Select>
                </FormControl>
            </Grid>

            <Grid item xs={12}>
                <FormControl fullWidth sx={{minWidth: 120}}>
                    <NumberField value={profileData?.age}
                                 label={t("user_profile.age_label")}
                                 onChange={event => setProfileData({...profileData, age: event.target.value})}/>
                </FormControl>
            </Grid>
        </Grid>
        {showFullComponent ? <Typography variant="h6"> {t("user_profile.finance_title")}</Typography> : null}
        <Grid container spacing={2} mt={1}>
            <Grid item xs={12} sm={6}>
                <FormControl fullWidth sx={{minWidth: 120}}>
                    <CurrencyField
                        value={profileData?.salary}
                        label={t("user_profile.salary_label")}
                        onChange={event => setProfileData({...profileData, salary: event.target.value})}
                    />
                </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
                <FormControl fullWidth sx={{minWidth: 120}}>
                    <CurrencyField
                        value={profileData?.savedAmount}
                        label={t("user_profile.saved_amount_label")}
                        onChange={event => setProfileData({...profileData, savedAmount: event.target.value})}
                    />
                </FormControl>
            </Grid>
        </Grid>
    </>

    const investmentGoals = <>
        <InvestorProfileDataContext.Provider
            value={{
                profileData, setProfileData, validations: {
                    optimalROIIsError,
                    mediocreROIIsError,
                    optimalCashflowIsError,
                    mediocreCashflowIsError,
                    optimalPatrimonyIsError,
                    mediocrePatrimonyIsError,
                }
            }}>
            <InvestmentGoals showFullComponent={showFullComponent}/>
        </InvestorProfileDataContext.Provider>
    </>
    const pondAwardedDialog = <PondAwardedDialog open={showPondDialog} amountAwarded={pondAmountAwarder}
                                                 tittle={t("user_profile.pond_awarded_dialog_title")}
                                                 nextButtonLabel={t("general.next")}
                                                 message={t("user_profile.ponds_awarded_message")}
                                                 onClick={onClosePondAwardedDialog}/>

    const fullComponent = <>
        <PageTitle>{t("user_profile.investor_profile_title")}</PageTitle>
        <BaseCard>
            <Typography variant="h6">{t("user_profile.residence_title")}</Typography>
            {showAboutMe ? aboutMeSection : null}
            {showInvestmentGoals ? investmentGoals : null}

            <Box display="flex" justifyContent="flex-end" gap="10px" width="100%">
                <LoadingButton
                    variant="outlined"
                    color="secondary"
                    onClick={handleCancel}
                >
                    {t('user_profile.close_cta')}
                </LoadingButton>

                <LoadingButton
                    loading={isSaving}
                    variant="contained"
                    color="primary"
                    onClick={handleSave}
                    disabled={!submittable}
                >
                    {t('user_profile.save_cta')}
                </LoadingButton>
            </Box>
            {pondAwardedDialog}
        </BaseCard>
    </>

    const partialComponent = <>
        {showAboutMe ? aboutMeSection : null}
        {showInvestmentGoals ? investmentGoals : null}
        {pondAwardedDialog}
    </>

    return <> {showFullComponent ? fullComponent : partialComponent} </>;
}