import {createContext, useContext} from "react";

export const FilterContext = createContext({});

export const useFiltersContext = () => {
    const context = useContext(FilterContext);

    if (!context) throw new Error('useFiltersContext must be use inside FiltersProvider');

    return context;
}