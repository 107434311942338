import Button from "@mui/material/Button";
import React from "react";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {WBack} from "../icons/walden-icons";
import {useResponsive} from "../../hooks/use-responsive";
import {IconButton} from "@mui/material";
import {Mixpanel} from "../../metrics/mixpanel";

export function NavigateBackButton({url}) {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const mdDown = useResponsive('down', 'md');


    const handleBackClick = () => {
        Mixpanel.track(`Navigated Back to ${url}`, {
            'Destination URL': url,
        });
        navigate(url);
    };

    return <>
        {mdDown ? (
            <IconButton onClick={handleBackClick} color="secondary">
                <WBack />
            </IconButton>
        ) : (
            <Button
                variant="text"
                startIcon={<WBack />}
                onClick={handleBackClick}
                color="secondary"
            >
                {t('general.back')}
            </Button>
        )}
    </>
}