export function ValidationItemFiltered(items, filters, dateSort) {
    let nonSearchFilters = filters.filter(f => f.id !== "search");
    let filteredItems = nonSearchFilters.length !== 0 ? items.filter(item => nonSearchFilters.some((f) => f.fn(item))) : items;
    let searchFilters = filters.filter(f => f.id === "search");
    filteredItems = searchFilters.length !== 0 ? filteredItems.filter(item => searchFilters.some((f) => f.fn(item))) : filteredItems;
    if (dateSort === 2) {
        filteredItems.sort((a, b) => new Date(a.validation_date) - new Date(b.validation_date));
    } else {
        filteredItems.sort((a, b) => new Date(b.validation_date) - new Date(a.validation_date));
    }
    return filteredItems;
}