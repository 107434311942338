import React from 'react';
import {useTranslation} from 'react-i18next';
import {Typography, useTheme} from '@mui/material';
import DataField from '../../components/data-field';
import {BaseCard} from "../../components/cards";
import CircleIcon from "@mui/icons-material/Circle";
import PageTitle from "../../components/titles";
import Box from "@mui/material/Box";
import {AiTooltipIcon} from "../../components/icons";

const Comments = ({comments, inSummary}) => {
    const theme = useTheme();
    const {t} = useTranslation();

    if (inSummary && !comments) {
        return null;
    }

    if (!comments) {
        return <BaseCard>
            <Typography>Sin recomendaciones</Typography></BaseCard>
    }

    return (<BaseCard>
        <Box sx={{display: "flex", width: "100%", justifyContent: "space-between", position: "column"}}>
            <PageTitle>
                {inSummary ? t('administrative_validation.comments.about_ns') : t('administrative_validation.comments.title')}
            </PageTitle>
            <AiTooltipIcon/>
        </Box>

        {comments?.length > 0 ? (comments.map((comment, index) => (<DataField
            icon={<CircleIcon sx={{color: theme.palette.primary.main, width: "8px"}}/>}
            key={index}
            labelColor="text.primary"
            value={comment}
            multiline
        />))) : (<DataField
            icon={<CircleIcon sx={{color: theme.palette.primary.main, width: "8px"}}/>}
            labelColor="text.primary"
            value={t('administrative_validation.comments.no_comments')}
            multiline
        />)}
    </BaseCard>);
};

export default Comments;