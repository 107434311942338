import {useResponsive} from "../../../hooks/use-responsive";
import React, {useState} from "react";
import {useFiltersContext} from "../context";
import {useTranslation} from "react-i18next";
import {Button, IconButton, Stack, SwipeableDrawer} from "@mui/material";
import {FILTER} from "../../../pages/config-layout";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import {WFilter, WRefresh} from "../../icons/walden-icons";

export function FilterDrawer({children}) {
    const smDown = useResponsive("down", "sm");
    const [open, setOpen] = useState(false);
    const filterContext = useFiltersContext();
    const {t} = useTranslation();

    const label = smDown ? "" : t("general.filter");

    const toggleDrawer = () => {
        setOpen(prev => !prev)
    }

    const onRemoveFilters = () => {
        filterContext.onRemoveAllFilters();
    }

    return <>
        <Button variant="text" size="medium" onClick={toggleDrawer} endIcon={<WFilter/>}>
            {label}
        </Button>
        <SwipeableDrawer
            anchor="right"
            open={open}
            onClose={() => setOpen(false)}
            onOpen={() => setOpen(true)}
            PaperProps={{
                sx: {width: FILTER.WIDTH},
            }}
        >
            <Stack
                spacing={2}
                padding={4}
            >
                <Stack direction="row" spacing={2} sx={{
                    display: "flex", justifyContent: "space-between", alignItems: "center"
                }}>
                    <Typography variant="h6">{t("general.filter")}</Typography>
                    <IconButton onClick={onRemoveFilters}>
                        <WRefresh/>
                    </IconButton>
                </Stack>
                <Divider/>
                {children}
            </Stack>
        </SwipeableDrawer>
    </>;
}