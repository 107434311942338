import AppPage from "../../common/AppPage";
import {Box, Divider, Stack, Typography} from "@mui/material";
import React, {useEffect, useState} from "react";
import ValidationSummary from "./validation_summary/validation-summary";
import {useLocation, useNavigate} from "react-router-dom";
import FinancialView from "../validation_financial/financial-view";
import ValidationParametersPopup from "../parameters/ValidationParametersPopup";
import {useResponsive} from "../../hooks/use-responsive";
import PageTitle from "../../components/titles";
import ROUTES from "../../routes";
import {useTranslation} from "react-i18next";
import {useSettingsContext} from "../../components/settings";
import responseToData from "./response-mapper";
import {NoData} from "../../components/app-status";
import {LoadingScreenBar} from "../../components/loading-screen";
import {NavigateBackButton} from "../../components/buttons";
import ScrollButtons from "../../components/scrollbar/scroll-buttons";
import {useValidationDetailer} from "../../services/validationRequest/validation-detailer";
import VisualValidation from "../validation_checklist/visual-validation";
import {Mixpanel} from "../../metrics/mixpanel";
import AdministrativeValidation from "../administrative_validation/administrative-validation";
import {
    useParsedAdministrativeValidationData
} from "../../services/validationRequest/administrative-validation-detailer";

export default function ValidationDetail() {
    const validationRequestID = useLocation().state;
    const [selectedTab, setSelectedTab] = useState(1);
    const [isRentPriceAvailable, setIsRentPriceAvailable] = useState(true);
    const [tabContent, setTabContent] = useState(null);
    const {t} = useTranslation();
    const settings = useSettingsContext();
    const lgDown = useResponsive('down', 'lg');
    const mdDown = useResponsive('down', 'md');

    const {
        data: apiResponse,
        isLoading: isLoadingValidationDetailer,
        refetch: refetchValidationDetailer
    } = useValidationDetailer(validationRequestID);

    const navigate = useNavigate();

    const {data: parsedData, isLoading: loadingAdminVali} = useParsedAdministrativeValidationData(validationRequestID);

    const data = React.useMemo(() => {
        if (apiResponse) {
            return responseToData(apiResponse, settings);
        }
    }, [apiResponse, settings.useWaldenPrice, settings.useMonthlyPeriod, settings.useOptimistProfile]);

    const handleNavigateToTab = (tabIndex) => {
        setSelectedTab(tabIndex);
        navigate(`${ROUTES.validations.detail}?id=${validationRequestID}&tab=${tabIndex}`, {
            state: validationRequestID,
        });
    };



    useEffect(() => {
        switch (selectedTab) {
            case 1:
                Mixpanel.track('Validation Detail: Switched to Summarized Validation Tab', {
                    'Validation Request ID': validationRequestID,
                    'Property ID': data?.property?.ID,
                });
                setTabContent(
                    (isLoadingValidationDetailer || loadingAdminVali) ? (
                        <LoadingScreenBar/>
                    ) : (
                        data?.property ? (
                            <ValidationSummary
                                propertyData={data?.property}
                                financialData={data?.financial}
                                parameters={data?.parameters}
                                comments={parsedData?.comments}
                                propertyRenov={data?.parameters?.property_renovation}
                                documentID={""}
                            />
                        ) : (
                            <NoData/>
                        )
                    )
                );
                break;
            case 2:
                Mixpanel.track('Validation Detail: Switched to Financial Validation Tab', {
                    'Validation Request ID': validationRequestID,
                    'Property ID': data?.property?.ID,
                });
                setTabContent(
                    data?.financial ?
                        <FinancialView data={data}/> :
                        <LoadingScreenBar/>
                );
                break;
            case 3:
                Mixpanel.track('Validation Detail: Switched to Visual Validation Tab', {
                    'Validation Request ID': validationRequestID,
                    'Property ID': data?.property?.ID,
                });
                setTabContent(
                    data?.property ?
                        <VisualValidation/> :
                        <LoadingScreenBar/>
                );
                break;
            case 4:
                Mixpanel.track('Validation Detail: Switched to Administrative Validation Tab', {
                    'Validation Request ID': validationRequestID,
                    'Property ID': data?.property?.ID,
                });
                setTabContent(
                    data?.property ?
                        <AdministrativeValidation propertyData={data?.property}/> :
                        <LoadingScreenBar/>
                );
                break;
            default:
                break;
        }
    }, [selectedTab, data, isLoadingValidationDetailer, loadingAdminVali, parsedData]);

    const buttons = [
        {
            label: t('validations.summarized'),
            isActive: selectedTab === 1,
            onClick: () => handleNavigateToTab(1),
        },
        {
            label: t('validations.financial'),
            isActive: selectedTab === 2,
            onClick: () => handleNavigateToTab(2),
        },
        {
            label: t('validations.visual'),
            isActive: selectedTab === 3,
            onClick: () => handleNavigateToTab(3),
        }, {
            label: t('validations.administrative'),
            isActive: selectedTab === 4,
            onClick: () => handleNavigateToTab(4),
        },
    ];

    const title = <PageTitle>{data?.property?.address}</PageTitle>
    const validationRequest = {
        validation_request: {
            ID: data?.validation_request?.ID,
            cadastre_id: data?.validation_request?.cadastre_id,
        }
    }
    const paramsButton = <Box>
        <ValidationParametersPopup refreshParams={refetchValidationDetailer} parameters={data?.parameters}
                                   request={validationRequest} validation/>
    </Box>


    const mobileTitleElement = <Stack spacing={2.5} direction="column">
        <Stack direction="row" justifyContent="space-between">
            {title}
            {paramsButton}
        </Stack>
    </Stack>

    const pageContent = <>

        <ScrollButtons buttons={buttons} sx={{px: mdDown ? 4 : 1}} activeButtonColor="secondary"
                       activeButtonVariant="outlined"/>
        <Divider style={{marginTop: 10}}/>
        {tabContent}
    </>

    const headerContent = <>
        <NavigateBackButton url={ROUTES.validations.list}/>
        <Typography variant="h6" noWrap>
            {data?.property?.address}
        </Typography>
        <ValidationParametersPopup refreshParams={refetchValidationDetailer} parameters={data?.parameters}
                                   request={validationRequest}/>
    </>

    return <AppPage containerComponent="box" topBarContent={headerContent}>

        {!lgDown ? mobileTitleElement : null}
        {isRentPriceAvailable ? pageContent : <NoData/>}

    </AppPage>
}