import * as React from 'react';
import {usePaymentAuthorizer} from "../../services/payment";
import {LoadingScreenBar} from "../loading-screen";

const pricingTableID = process.env.REACT_APP_STRIPE_PRICING_TABLE_ID;
const publishableKey = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY;


export function PricingTable() {
    const {data: secret, isLoading} = usePaymentAuthorizer();

    if (isLoading) {
        return <LoadingScreenBar/>
    }

    //TODO: extract this to something?
    return <>
        <stripe-pricing-table pricing-table-id={pricingTableID}
                              publishable-key={publishableKey}
                              customer-session-client-secret={secret?.customer_secret}
        >
        </stripe-pricing-table>
    </>;
}