import {useMutation} from "react-query";
import toast from "react-hot-toast";
import {useTranslation} from "react-i18next";
import {useAuth0} from "@auth0/auth0-react";
import {apiService} from "../ApiServiceV2";

const empty = () => {
}

export function useOikosStorer(onSuccess = empty) {
    const auth0Context = useAuth0();
    const {t} = useTranslation();

    return useMutation(oikosData => toast.promise(apiService.postNewOikos(auth0Context, oikosData), {
        loading: t("general.saving"), success: t('general.saved'), error: t("feedback.error.api_request_error")
    }), {
        onSuccess: (response) => {
            onSuccess(response.data);
        },
        onError: (error) => {
            if (error?.response?.status === 409) {
                toast.error(t('oikos.add_properties.already_exists_warning_message'));
            }
        },
    });
}
