import React from "react";
import Box from "@mui/material/Box";
import ExpensesChart from "../../components/cards/expenses-chart";
import ProfitabilityCard from "../../components/cards/profitability-card";
import FunnelCashFlow from "../../components/cards/funnel-cash-flow-card";
import FinancialMetricsCard from "../../components/cards/financial-metrics-card";
import {OptionsBar} from "../../components/settings";
import {CardContainer} from "../../components/cards";
import {ValidationPriceFeedback} from "./validation-price-feedback";

export default function ProfitabilityView({data = {}}) {
    const financialData = data?.financial;

    return <>
        <OptionsBar/>
        <CardContainer>
            <ProfitabilityCard
                data={financialData}
                sx={{flexGrow: 1}}
                priceFeedbackComponent={<ValidationPriceFeedback data={data}/>}/>
            <Box sx={{flexGrow: 14}} className="tour-validation-financial-cashflow"><FunnelCashFlow
                data={financialData}/></Box>
            <Box className="tour-validation-financial-metrics"><FinancialMetricsCard data={financialData}/></Box>
            <Box sx={{flexGrow: 1}} className="tour-validation-financial-holding-cost"><ExpensesChart
                data={financialData}/></Box>
        </CardContainer>
    </>;
}
