import {useAuth0} from "@auth0/auth0-react";
import {useQuery} from "react-query";
import {apiService} from "../ApiServiceV2";
import toast from "react-hot-toast";
import {addressAsString} from "../../utils/financial";
import {useTranslation} from "react-i18next";
import {fDate} from "../../utils/format-time";

function mapResponseToDashboardModel(data) {
    if (!data) return {}
    return {
        better_roi: [{
            id: data.roi.validation_request_id,
            validation_date: fDate(data.roi.validation_date),
            address: addressAsString(data.roi.address),
            reference: data.roi.reference,
            status: data.roi.status,
            roi: data.roi.financial.financial_actual_gross_roi,
            performance: data.roi.financial.financial_actual_performance
        }], better_cashflow: [{
            id: data.cash_flow.validation_request_id,
            validation_date: fDate(data.cash_flow.validation_date),
            address: addressAsString(data.cash_flow.address),
            reference: data.cash_flow.reference,
            status: data.cash_flow.status,
            roi: data.cash_flow.financial.financial_actual_gross_roi,
            performance: data.cash_flow.financial.financial_actual_performance
        }], better_patrimony: [{
            id: data.patrimony.validation_request_id,
            validation_date: fDate(data.patrimony.validation_date),
            address: addressAsString(data.patrimony.address),
            reference: data.patrimony.reference,
            status: data.patrimony.status,
            roi: data.patrimony.financial.financial_actual_gross_roi,
            performance: data.patrimony.financial.financial_actual_performance
        }], highlighted: [{
            id: data.mvp.validation_request_id,
            validation_date: fDate(data.mvp.validation_date),
            address: addressAsString(data.mvp.address),
            reference: data.mvp.reference,
            status: data.mvp.status,
            roi: data.mvp.financial.financial_actual_gross_roi,
            performance: data.mvp.financial.financial_actual_performance
        }],
    };
}

export function useValidationReqDashboard() {
    const auth0Context = useAuth0();
    const {t} = useTranslation();

    return useQuery('validationReqDashboard', async () => {
        const response = await apiService.validationReqDashboard(auth0Context);
        const isEmptyResponse = response && Object.keys(response.data).every(key => {
            const item = response.data[key];
            return item.validation_request_id === null && item.validation_date === null && item.reference === "" && item.status === "";
        });

        if (isEmptyResponse) {
            return {};
        }

        if (!response || !response.data) {
            throw new Error("Invalid response");
        }

        return mapResponseToDashboardModel(response.data);
    }, {
        onError: () => {
            toast.error(t("feedback.error.general_api_error"));
        }, retry: (failureCount, error) => {

            if (error.message === "Invalid response") {
                return false;
            }

            return failureCount < 3;
        }
    });
}

