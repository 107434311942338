import {Box, Typography, useTheme} from "@mui/material";
import React from "react";
import {getPerformanceTextColor} from "../../utils/financial";


export const CardTitle = ({level, title}) => {
    const theme = useTheme();

    return (
        <Box sx={{
            display: 'flex',
            width: '100%',
        }}>
            <Typography component="div" style={{
                color: getPerformanceTextColor(theme, level),
                fontSize: '1.2rem',
                fontWeight: 'bold',
                marginRight: level != null ? theme.spacing(1) : 0,
            }}>
                {title}
            </Typography>
            {level != null && (
                <>
                    <Typography component="div" style={{
                        color: getPerformanceTextColor(theme, level),
                        fontSize: '1.2rem',
                        fontWeight: 'bold',
                    }}>
                        {level === 1 ? "Óptima" : level === -1 ? "Baja" : level === 0 ? "Moderada" : ""}
                    </Typography>
                </>
            )}
        </Box>
    );
}