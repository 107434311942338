import {useTranslation} from "react-i18next";
import {useAuth0} from "@auth0/auth0-react";
import {useQuery} from "react-query";
import {apiService} from "../ApiServiceV2";
import toast from "react-hot-toast";

export function useAnalysisDownloader(propertyId, enabled, disableCache = false) {
    const {t} = useTranslation();
    const auth0Context = useAuth0();

    return useQuery(
        ["AnalysisDownloader", propertyId],
        () => apiService.getAnalisysPdf(auth0Context, propertyId).then(response => response.data),
        {
            enabled: enabled,
            cacheTime: disableCache ? 0 : undefined,
            onError: () => {
                toast.error(t("feedback.error.general_api_error"));
            }
        },
    );
}