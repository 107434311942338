import {Box, LinearProgress, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";

const ProductItem = ({product}) => {
    const {t} = useTranslation();

    return (
        <Box key={product.product} sx={{mt: 1.5}}>
            <Box sx={{display: 'flex', justifyContent: 'space-between', flexDirection: 'row'}}>
                <Typography sx={{mr: 2}} color="text.secondary" variant="overline">
                    {product.max === 0 ? `${product.balance} / Ꝏ ` : `${product.balance} / ${product.max}`}
                </Typography>
                <Typography variant="overline">
                    {product.product === "validation-request"
                        ? t("navigation_menu.validations")
                        : product.product === "oikos"
                            ? t("navigation_menu.properties")
                            : product.product}
                </Typography>
            </Box>

            <Box sx={{display: 'flex', justifyContent: 'flex-end', alignItems: 'center', flex: 1}}>
                <LinearProgress
                    variant="determinate"
                    value={product.max === 0 ? 100 : (product.balance / product.max) * 100}
                    sx={{width: '170px'}}
                />
            </Box>
        </Box>
    );
};

export default ProductItem;
