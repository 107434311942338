import {useTranslation} from "react-i18next";
import {
    Timeline,
    TimelineConnector,
    TimelineDot,
    TimelineItem,
    TimelineOppositeContent,
    TimelineSeparator
} from "@mui/lab";
import {Typography} from "@mui/material";
import {WDone, WSearch, WVerified} from "../../components/icons/walden-icons";
import Box from "@mui/material/Box";

export function StepsAdministrativeValidation() {
    const {t} = useTranslation();

    return (
        <Box sx={{my: 3, display: 'flex', flexDirection: 'column', alignContent: "center", justifyContent: "center"}}>
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignContent: "center",
                    maxWidth: "500px",
                    mx: "auto",
                    mt: "30px"
                }}>
                <Timeline>
                    <TimelineItem>
                        <TimelineSeparator>
                            <TimelineDot color="secondary" variant="filled">
                                <WDone/>
                            </TimelineDot>
                            <TimelineConnector/>
                        </TimelineSeparator>
                        <TimelineOppositeContent color="text.secondary">
                            <Typography>{t('administrative_validation.step1')}</Typography>

                        </TimelineOppositeContent>
                    </TimelineItem>
                    <TimelineItem>
                        <TimelineSeparator>
                            <TimelineDot color="primary" variant="filled">
                                <WSearch/>
                            </TimelineDot>
                            <TimelineConnector/>
                        </TimelineSeparator>
                        <TimelineOppositeContent color="text.secondary">
                            <Typography fontWeight="bold">{t('administrative_validation.step2')}</Typography>
                        </TimelineOppositeContent>
                    </TimelineItem>
                    <TimelineItem>
                        <TimelineSeparator>
                            <TimelineDot color="grey" variant="filled">
                                <WVerified/>
                            </TimelineDot>
                        </TimelineSeparator>
                        <TimelineOppositeContent color="text.secondary">
                            {t('administrative_validation.step3')}
                        </TimelineOppositeContent>
                    </TimelineItem>
                </Timeline>
            </Box>

        </Box>
    );
}