import {useTheme} from "@mui/material";
import Typography from "@mui/material/Typography";
import {WBaby, WPerson} from "../../components/icons/walden-icons";
import Box from "@mui/material/Box";
import {useTranslation} from "react-i18next";

export const isNudaPropiedad = (owner) => {
    if (!owner) {
        return false;
    }

    const {type_of_ownership, age} = owner;

    return (typeof type_of_ownership !== 'undefined' &&
            type_of_ownership.toLowerCase().includes('nuda')) ||
        age < 18;
};

function Owner({owner, isError, isWarning, isSuccess, hasNudaPropiedad}) {
    const theme = useTheme();
    const {t} = useTranslation();

    if (!owner) {
        return null;
    }
    const nudaPropiedad = isNudaPropiedad(owner);
    const color = hasNudaPropiedad || nudaPropiedad
        ? theme.palette.error.main
        : isError
            ? theme.palette.error.main
            : isWarning
                ? theme.palette.warning.main
                : isSuccess
                    ? theme.palette.success.main
                    : theme.palette.text.primary;

    return (
        <Box display="flex" flexDirection="column" alignItems="center">
            {nudaPropiedad ? (
                <WBaby sx={{color: color}} width={30}/>
            ) : (
                <WPerson sx={{color: color}} width={30}/>
            )}
            {owner.type_of_ownership && (
                <Typography variant="subtitle1" color="text.primary">
                    {owner.type_of_ownership}
                </Typography>
            )}
            {owner.share ? (
                <Typography variant="body2" color="text.secondary">
                    {`${owner.share}%`}
                </Typography>
            ) : (
                <Typography variant="body2" color="text.secondary">
                    {t("administrative_validation.no_share_from_ns")}
                </Typography>
            )}
        </Box>
    );
}

export default Owner;