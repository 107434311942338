import {Divider, Stack, useTheme} from "@mui/material";
import DataField from "../data-field";
import PropTypes from "prop-types";
import BaseCard from "./base-card";
import {useResponsive} from "../../hooks/use-responsive";
import {useTranslation} from "react-i18next";
import {
    WArea,
    WBathrooms,
    WBedrooms,
    WCadastre,
    WConstructiveState,
    WEnergyEfficiency,
    WLocation,
    WResidential,
    WYear
} from "../icons/walden-icons";
import {CardContainer} from "./card-container";

export default function PropertySummaryCard({data = {}, sx}) {
    const theme = useTheme();
    const smDown = useResponsive("down", "sm");
    const {t} = useTranslation();

    const getDataFieldStyles = (smDown) => ({
        ...(smDown && {
            alignItems: 'flex-start',
            '& .MuiStack-root': {
                justifyContent: 'flex-start',
            },
        }),
    });

    const getConstructionStatus = (status) => {
        if (status >= 1 && status <= 3) {
            return t('property_details.high_quality_of_construction');
        } else if (status >= 4 && status <= 6) {
            return t('property_details.medium_quality_of_construction');
        } else if (status >= 7 && status <= 9) {
            return t('property_details.poor_quality_of_construction');
        }
        return t('property_details.unknown_quality_of_construction');
    }

    return (
        <BaseCard sx={{maxWidth: "570px", ...sx}}>

            <Stack spacing={2} alignSelf="stretch">
                <DataField label={t('property_details.validated_on')} value={data.date}/>

                <DataField icon={<WLocation color={theme.palette.primary.main}/>}
                           label={t('property_details.address')}
                           labelColor="text.primary"
                           value={data.address}
                />

                <DataField icon={<WCadastre color={theme.palette.primary.main}/>}
                           label={t('general.cadastre')} labelColor="text.primary"
                           value={data.reference} valueColor="text.primary"
                />

                <Divider sx={{borderBlockStyle: "dashed"}}/>
            </Stack>
            <CardContainer>
                <DataField icon={<WArea color={theme.palette.text.secondary}/>}
                           direction={smDown ? 'column' : 'row'}
                           labeledIcon={smDown}
                           label={t('property_details.area')}
                           value={data.area}
                           sx={getDataFieldStyles(smDown)}

                />


                <DataField icon={<WYear color={theme.palette.text.secondary}/>}
                           label={t('general.years')}
                           labeledIcon={smDown}
                           value={data.year}
                           sx={getDataFieldStyles(smDown)}

                />

                <DataField icon={<WConstructiveState color={theme.palette.text.secondary}/>}
                           label={t('property_details.quality')}
                           labeledIcon={smDown}
                           value={getConstructionStatus(data.construction_status)}
                           sx={getDataFieldStyles(smDown)}

                />
                <DataField icon={<WResidential color={theme.palette.text.secondary}/>}
                           labeledIcon={smDown}
                           label={data.is_residential ? t('property_details.residential') : t('property_details.commercial')}
                           sx={getDataFieldStyles(smDown)}

                />
                <DataField icon={<WBedrooms color={theme.palette.text.secondary}/>}
                           labeledIcon={smDown}
                           label={t('property_details.rooms')}
                           value={data.rooms}
                           sx={getDataFieldStyles(smDown)}

                />
                <DataField icon={<WBathrooms color={theme.palette.text.secondary}/>}
                           labeledIcon={smDown}
                           label={t('property_details.bathrooms')}
                           value={data.bathrooms}
                           sx={getDataFieldStyles(smDown)}

                />
                <DataField icon={<WEnergyEfficiency color={theme.palette.text.secondary}/>}
                           labeledIcon={smDown}
                           label={t('administrative_validation.energy_efficiency')}
                           value={data.energy_certificate}
                           sx={getDataFieldStyles(smDown)}

                />
            </CardContainer>


        </BaseCard>
    );
}

PropertySummaryCard.protoTypes = {
    data: {
        id: PropTypes.number,
        validation_date: PropTypes.string,
        address: PropTypes.string,
        reference: PropTypes.string,
        area: PropTypes.number,
        year: PropTypes.number,
        construction_status: PropTypes.number,
        is_residential: PropTypes.bool,
        rooms: PropTypes.number,
        bathrooms: PropTypes.number
    }
}