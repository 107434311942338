import {useTranslation} from "react-i18next";
import Paper from "@mui/material/Paper";
import {InvestmentCostSection} from "./InvestmentCostSection";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import {StepWrapper} from "../../components/custom-stepper";
import React from "react";
import {ValidationParametersContext} from "./ParametersContext";
import toast from "react-hot-toast";
import {MortgageSection} from "./MortgageSection";
import {HoldingCostSection} from "./HoldingCostSection";
import {WBank, WDollar, WYear} from "../../components/icons/walden-icons";

export const InvestmentCostStep = ({estimatable, handleNext, handleBack}) => {
    const {t} = useTranslation();

    const content = <Paper sx={{
        borderRadius: '15px', padding: {xs: '1px', lg: '15px'}, margin: 'auto', flexGrow: 1
    }}>
        <InvestmentCostSection showTitle={false} estimatable={estimatable}/>
        <Box sx={{m: 2, display: 'flex', justifyContent: 'flex-end'}}>
            <div>
                <Button
                    onClick={handleBack}
                    sx={{mt: 1, ml: 1}}
                >
                    {t("general.back")}
                </Button>
                <Button
                    variant="contained"
                    onClick={handleNext}
                    sx={{mt: 1, ml: 1}}
                >
                    {t("general.continue")}
                </Button>
            </div>
        </Box>
    </Paper>;
    return <StepWrapper label={t("parameters.investment_expenses.title")} stepContent={content} icon={<WDollar/>}/>;
}

export const MortgageStep = ({handleNext, handleBack}) => {
    const {t} = useTranslation();
    const {validationParameters} = React.useContext(ValidationParametersContext);

    const localHandleNext = (event) => {
        if (!validationParameters.isMortgageValid) {
            toast.error(t("input_and_map.parameters.error.mortgageParamsIsNotValid"))
            return
        }
        handleNext(event);
    }

    const content = <Paper sx={{
        borderRadius: '15px', padding: {xs: '1px', lg: '15px'}, margin: 'auto', flexGrow: 1
    }}>
        <MortgageSection showTitle={false}/>
        <Box sx={{m: 2, display: 'flex', justifyContent: 'flex-end'}}>
            <div>
                <Button
                    onClick={handleBack}
                    sx={{mt: 1, ml: 1}}
                >
                    {t("general.back")}
                </Button>
                <Button
                    variant="contained"
                    onClick={localHandleNext}
                    sx={{mt: 1, ml: 1}}
                >
                    {t("general.continue")}
                </Button>
            </div>
        </Box>
    </Paper>;
    return <StepWrapper label={t("parameters.mortgage.title")} stepContent={content} icon={<WBank/>}/>;
}

export const HoldingCostStep = ({handleNext, handleBack}) => {
    const {t} = useTranslation();

    const content = <Paper sx={{
        borderRadius: '15px', padding: {xs: '1px', lg: '15px'}, margin: 'auto', flexGrow: 1
    }}>
        <HoldingCostSection showTitle={false}/>
        <Box sx={{m: 2, display: 'flex', justifyContent: 'flex-end'}}>
            <div>
                <Button
                    onClick={handleBack}
                    sx={{mt: 1, ml: 1}}
                >
                    {t("general.back")}
                </Button>
                <Button
                    variant="contained"
                    onClick={handleNext}
                    sx={{mt: 1, ml: 1}}
                >
                    {t("general.continue")}
                </Button>
            </div>
        </Box>
    </Paper>;
    return <StepWrapper label={t("parameters.annual_holding_cost.title")} stepContent={content} icon={<WYear/>}/>;
}