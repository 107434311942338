import React from "react";
import LoadingButton from "@mui/lab/LoadingButton";


export const SecondaryButton = ({onClick, children, disabled, loading, sx, className}) => {
    return (
        <LoadingButton
            color="secondary"
            variant="outlined"
            onClick={onClick}
            disabled={disabled}
            loading={loading}
            sx={{...sx}}
            className={className}
        >
            {children}
        </LoadingButton>
    )
}