import {OnboardingPage} from "./onboarding-page";
import {BaseCard} from "../../components/cards";
import Box from "@mui/material/Box";
import PageTitle from "../../components/titles";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import {PrimaryButton} from "../../components/buttons";
import React from "react";
import {useNavigate} from "react-router-dom";
import ROUTES from "../../routes";
import {useResponsive} from "../../hooks/use-responsive";
import {useTranslation} from "react-i18next";

export function OnboardingOutro() {
    const {t} = useTranslation();
    const mdDown = useResponsive('down', 'md');
    const navigate = useNavigate();

    const next = () => {
        navigate(ROUTES.onboarding.profile_setup);
    }

    return <OnboardingPage>
        <BaseCard
            sx={{
                padding: mdDown ? '-15px' : '-24px',
                margin: "auto",
                minHeight: mdDown ? '320px' : '400x',
                maxWidth: "800px",
                flexDirection: "row",
                display: 'flex',
                backgroundColor: 'rgba(255, 255, 255, 0.6)'
            }}
        >
            {!mdDown ? <Box component="img" sx={{width: '350px', height: '350px'}}
                            src="https://cdn.walden.ai/media/img/app/illustrations/walden_control.svg"/> : null}
            <Box sx={{
                padding: mdDown ? '15px' : '24px',
                display: "flex",
                flexDirection: "column",
                margin: "auto",
                gap: "1.5rem",
                flexGrow: 1,
            }}>
                <Box>
                    <PageTitle>{t('onboarding_outro.title')}</PageTitle>
                    <Typography sx={{mt: 2}}>{t('onboarding_outro.description')}</Typography>
                </Box>
                <Stack direction="column"
                       sx={{display: "flex", alignItems: 'center', gap: '1rem', marginTop: 'auto'}}>
                    <Stack direction="row" sx={{
                        width: '100%',
                        justifyContent: "flex-end",
                        alignItems: "center",
                        mt: 3
                    }}>
                        <PrimaryButton
                            onClick={next}
                        >
                            {t('onboarding_outro.button_label')}
                        </PrimaryButton>
                    </Stack>
                </Stack>
            </Box>
        </BaseCard>
    </OnboardingPage>

}