import React from "react";
import {Box, Button, LinearProgress, Skeleton, Typography} from "@mui/material";
import {BaseCard} from "../../components/cards";
import ROUTES from "../../routes";
import {useNavigate} from "react-router-dom";
import DataField from "../../components/data-field";
import {FormatCompactCurrency, FormatPercentage} from "../../utils/format-number";
import {useTranslation} from "react-i18next";
import {ROI_PERFORMANCE_MEDIUM, ROI_PERFORMANCE_OPTIMAL} from "../../utils/financial";
import {useResponsive} from "../../hooks/use-responsive";

function CustomProgressBarLegacy({value, thresholds, performance}) {
    const minValue = thresholds.low;
    const maxValue = thresholds.optimal;
    const clampedValue = Math.min(Math.max(value, minValue), maxValue);
    const normalisedValue = ((clampedValue - minValue) / (maxValue - minValue)) * 100;
    const color = performance === ROI_PERFORMANCE_OPTIMAL ? 'success' : performance === ROI_PERFORMANCE_MEDIUM ? 'warning' : "error";

    return (
        <LinearProgress
            color={color}
            variant="determinate"
            value={normalisedValue}
            sx={{width: '100%', mt: 2, height: 8}}
        />
    );
}


function OikosCard({sectionKey, title, data, isLoading}) {


    const {t} = useTranslation();
    const navigate = useNavigate();
    const mdDown = useResponsive('down', 'md');

    const goalMapping = {
        roi: "has_roi_goal",
        cash_flow: "has_cash_flow_goal",
        patrimony: "has_patrimony_goal",
    };

    const isDataReady = !isLoading && data

    const section = isDataReady ? data[sectionKey] : {};
    const hasGoal = isDataReady && data?.thresholds[goalMapping[sectionKey]];
    const thresholds = isDataReady ? {
        low: data?.thresholds[`${sectionKey}_low`],
        mediocre: data?.thresholds[`${sectionKey}_mediocre`],
        optimal: data?.thresholds[`${sectionKey}_optimal`],
    } : {};

    const formattedValue = sectionKey === 'roi' ? FormatPercentage(section.value) : FormatCompactCurrency(section.value);
    const formattedLow = sectionKey === 'roi' ? FormatPercentage(thresholds.low) : FormatCompactCurrency(thresholds.low);
    const formattedMediocre = sectionKey === 'roi' ? FormatPercentage(thresholds.mediocre) : FormatCompactCurrency(thresholds.mediocre);
    const formattedOptimal = sectionKey === 'roi' ? FormatPercentage(thresholds.optimal) : FormatCompactCurrency(thresholds.optimal);

    const performanceText = section.performance === 3 ? t('quality_levels.ideal') : section.performance === 2 ? t('quality_levels.moderate') : t('quality_levels.low');

    return (
        <BaseCard sx={{flexGrow: 1}}>
            <Typography variant="h6" sx={{alignSelf: "left", mt: mdDown ? -1 : 0}}>{title}</Typography>
            <Box sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                width: "100%",
                pt: mdDown ? 0 : 1
            }}>
                {!isDataReady ? (
                    <>
                        <Box sx={{display: 'flex', justifyContent: 'space-between', width: '100%', mt: 1, mb: 1}}>
                            <Skeleton variant="text" width="20%" height={25}/>
                            <Skeleton variant="text" width="20%" height={25}/>
                        </Box>
                        <Skeleton variant="rectangular" width="100%" height={10} sx={{mt: 1}}/>
                        <Box sx={{display: 'flex', justifyContent: 'space-between', width: '100%', mt: 2}}>
                            <Skeleton variant="rectangular" width="30%" height={30}/>
                            <Skeleton variant="rectangular" width="30%" height={30}/>
                            <Skeleton variant="rectangular" width="30%" height={30}/>
                        </Box>
                    </>
                ) : hasGoal ? (
                    <>
                        <Box sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: "center",
                            width: '100%',
                        }}>
                            <Typography variant="overline">{performanceText}</Typography>
                            <Typography variant="subtitle1">{formattedValue}</Typography>
                        </Box>
                        <CustomProgressBarLegacy value={section.value} thresholds={thresholds}
                                           performance={section.performance}/>
                        <Box sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            width: '100%',
                            mt: 2
                        }}>
                            <DataField
                                labelColor="text.primary"
                                valueColor="text.primary"
                                direction="column"
                                value={formattedLow}
                                label={t('quality_levels.low')}
                                labelVariant="caption"
                                valueVariant="subtitle2"
                            />
                            <DataField
                                labelColor="text.primary"
                                valueColor="text.primary"
                                direction="column"
                                value={formattedMediocre}
                                label={t('quality_levels.moderate')}
                                labelVariant="caption"
                                valueVariant="subtitle2"
                            />
                            <DataField
                                labelColor="text.primary"
                                valueColor="text.primary"
                                direction="column"
                                value={formattedOptimal}
                                label={t('quality_levels.ideal')}
                                labelVariant="caption"
                                valueVariant="subtitle2"
                            />
                        </Box>
                    </>
                ) : (
                    <>
                        <Typography variant="body2" sx={{mb: 2}}>
                            {t('dashboard.no_objectives')}
                        </Typography>
                        <Button variant="outlined" onClick={() => navigate(ROUTES.profile)} color="secondary"
                                size="small" sx={{mt: 2}}>
                            {t('dashboard.add_objective')}
                        </Button>
                    </>
                )}
            </Box>
        </BaseCard>
    );
}

export default OikosCard;





