import {Tour} from "./tour";
import {useTranslation} from "react-i18next";
import {TourContent} from "./tour-content";


export function DashboardTour() {
    const {t} = useTranslation();


    const tourSteps = [

        {
            target: '.tour_dashboard_description',
            content: (
                <TourContent
                    title={t("tour.dashboard.title")}
                    description={t("tour.dashboard.description")}
                />
            ),
            placement: 'center',
        },
        {
            target: '.tour_dashboard_cards',
            content: (
                <TourContent
                    title={t("tour.dashboard.title")}
                    description={t("tour.dashboard.cards")}
                />
            ),
            placement: 'bottom',
        },
        {
            target: '.tour_dashboard_chart',
            content: (
                <TourContent
                    title={t("tour.dashboard.title")}
                    description={t("tour.dashboard.chart")}
                />
            ),
        },
        {
            target: '.tour_dashboard_validations',
            content: (
                <TourContent
                    title={t("tour.dashboard.title")}
                    description={t("tour.dashboard.validation")}
                />
            ),
        },
        {
            target: '.tour_dashboard_benefits',
            content: (
                <TourContent
                    title={t("tour.dashboard.title")}
                    description={t("tour.dashboard.benefits")}
                />
            ),
        }
    ];

    return <Tour steps={tourSteps} tourKey='dashboard'/>
}


