import React from "react";
import ApexChart from "react-apexcharts";
import {useTheme} from '@mui/material/styles';
import {useTranslation} from "react-i18next";
import {
    getPerformanceColor,
    getPerformanceTranslation,
    ROI_PERFORMANCE_MEDIUM,
    ROI_PERFORMANCE_OPTIMAL
} from "../../utils/financial";
import {BaseCard} from "./index";
import Typography from "@mui/material/Typography";
import {useResponsive} from "../../hooks/use-responsive";
import Grid from "@mui/material/Grid";
import DataField from "../data-field";
import {FormatCurrency} from "../../utils/format-number";
import Box from "@mui/material/Box";

const ProfitabilityCard = ({data = {}, sx, priceFeedbackComponent = <></>}) => {
    const roi = data?.roi;
    const performanceLevel = roi?.performance;
    const theme = useTheme();
    const {t} = useTranslation();
    const mdDown = useResponsive('down', 'md');

    const show_chart_horizontal = !mdDown;

    let chartColors;
    switch (performanceLevel) {
        case ROI_PERFORMANCE_MEDIUM:
            chartColors = [theme.palette.chart.color3, theme.palette.chart.color10, theme.palette.chart.color2];
            break;
        case ROI_PERFORMANCE_OPTIMAL:
            chartColors = [theme.palette.chart.color8, theme.palette.chart.color11, theme.palette.chart.color5];
            break;
        default:
            chartColors = [theme.palette.chart.color4, theme.palette.chart.color12, theme.palette.chart.color7];
    }

    const legendOptions = {
        customLegendItems: [
            t('charts.profitability.gross_roi'),
            t('charts.profitability.net_roi_before_interest_and_taxes'),
            t('charts.profitability.net_roi_after_interest_and_taxes'),
        ],
        position: 'bottom', horizontalAlign: 'left', labels: {
            colors: 'gray',
        },
    };

    const goals = [{
        name: t("quality_levels.ideal"),
        value: data.investment_goals.optimal_roi_threshold,
        strokeDashArray: 4,
        strokeColor: theme.palette.success.main
    }, {
        name: t("quality_levels.moderate"),
        value: data.investment_goals.mediocre_roi_threshold,
        strokeDashArray: 4,
        strokeColor: theme.palette.warning.main
    }, {
        name: t("quality_levels.low"),
        value: data.investment_goals.low_roi_threshold,
        strokeDashArray: 4,
        strokeColor: theme.palette.error.main
    }]

    const chartData = {
        series: [{
            name: t('charts.profitability.gross_roi'),
            data: [{
                y: roi?.gross_roi, x: "ROI", goals: goals
            }]
        }, {
            name: t('charts.profitability.net_roi_before_interest_and_taxes'),
            data: [{
                y: roi?.net_roi_before_interest_and_taxes, x: "ROI", goals: goals
            }]
        }, {
            name: t('charts.profitability.net_roi_after_interest_and_taxes'),
            data: [{
                y: roi?.net_roi_after_interest_and_taxes, x: "ROI", goals: goals
            }]
        },], options: {
            chart: {
                type: 'bar', stacked: false, toolbar: {
                    show: false
                }
            }, colors: chartColors, plotOptions: {
                bar: {
                    horizontal: show_chart_horizontal, barHeight: '100%', dataLabels: {
                        position: 'center',
                    }
                }
            }, dataLabels: {
                enabled: true, formatter: function (val) {
                    return `${val}%`;
                }
            }, grid: {
                show: false
            }, xaxis: {
                categories: [""], labels: {
                    show: false
                }, axisBorder: {
                    show: false
                }, axisTicks: {
                    show: false
                }
            }, yaxis: {
                show: false
            }, legend: {
                ...legendOptions, show: true
            }
        }
    };

    const performance_text = getPerformanceTranslation(roi?.performance, t);

    const field_props = {
        alignSelf: "stretch", justifyContent: "space-between",
    }

    return <BaseCard
        className="tour-validation-financial-profitability"
        sx={{
            ...sx,
            backgroundColor: getPerformanceColor(theme, roi?.performance),
            transition: 'background-color 0.5s ease-in-out'
        }}>
        <Typography variant="h6">{`${t('financial.profitability.profitability')} ${performance_text}`}</Typography>

        <div style={{width: '100%'}}>

            <ApexChart
                options={chartData.options}
                series={chartData.series}
                type="bar"
                height={340}
            />
        </div>
        <Grid container spacing={1} direction="column">
            <Grid item>
                <Box sx={{display: "flex", flexDirection: "row", justifyContent: "space-between"}}>

                    <Box sx={{display: "flex", flexDirection: "row", justifyContent: "space-between"}}>

                        <Box className="tour-validation-financial-edit-price" sx={{mt: -0.5, ml: -0.6}}>
                            {priceFeedbackComponent}
                        </Box>

                        <Typography variant="body2" color="text.secondary">
                            {t("financial.profitability.estimated_rent")}
                        </Typography>
                    </Box>

                    <Typography variant="body2" color="text.secondary">
                        {FormatCurrency(roi?.cash_flow?.rent)}
                    </Typography>

                </Box>
            </Grid>
            <Grid item>
                <DataField label={t("financial.profitability.total_investment_cost")} labelVariant="body2"
                           labelColor="text.secondary"
                           value={FormatCurrency(roi?.investment_cost?.total_investment_cost)}
                           valueVariant="body2" valueColor="text.secondary"
                           sx={field_props}
                />
            </Grid>
            <Grid item>
                <DataField label={t("financial.profitability.necessary_cash")} labelVariant="body2"
                           labelColor="text.secondary"
                           value={FormatCurrency(roi?.necessary_cash)}
                           valueVariant="body2" valueColor="text.secondary"
                           sx={field_props}
                />
            </Grid>

            <Grid item>
                <DataField label={t("financial.profitability.taxable_income_base_after_bonuses")} labelVariant="body2"
                           labelColor="text.secondary"
                           value={FormatCurrency(roi?.taxable_income_base_after_bonuses)} valueVariant="body2"
                           valueColor="text.secondary"
                           sx={field_props}
                />
            </Grid>
            <Grid item>
                <DataField label={t("financial.profitability.irpf_amount")} labelVariant="body2"
                           labelColor="text.secondary"
                           value={FormatCurrency(roi?.irpf_amount)} valueVariant="body2"
                           valueColor="text.secondary"
                           sx={field_props}
                />
            </Grid>
        </Grid>
    </BaseCard>;
};

export default ProfitabilityCard;