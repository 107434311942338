import {apiService} from "./ApiServiceV2";
import {useAuth0} from "@auth0/auth0-react";
import {useQuery} from "react-query";


export function useProfileDetailer(cacheTime, onError, onSuccess, refetchOnWindowFocus = true) {
    const auth0Context = useAuth0();

    return useQuery("profileDetailer", async () => {
            const response = await apiService.getProfile(auth0Context);

            return mapProfileData(response.data);
        },
        {
            retry: (failureCount, error) => {
                if (error?.response?.status === 404) {
                    return false
                }
                return failureCount < 3;
            },
            cacheTime: cacheTime,
            onSuccess: (response) => {
                if (onSuccess) {
                    onSuccess(response);
                }
            },
            onError: (error) => {
                if (onError) {
                    onError(error);
                }
            },
            refetchOnWindowFocus: refetchOnWindowFocus
        });
}

function mapProfileData(data) {
    const investorProfile = data?.investor_profile;
    const subscriptionDetail = data?.subscription_detail;
    const config = investorProfile?.config;

    return {
        investor_profile: {
            id: investorProfile?.ID || null,
            region: investorProfile?.region,
            country: investorProfile?.country,
            salary: investorProfile?.salary,
            age: investorProfile?.age,
            savedAmount: investorProfile?.saved_amount,
            hasROIGoal: investorProfile?.has_roi_goal,
            optimalROIThreshold: investorProfile?.optimal_roi_threshold,
            mediocreROIThreshold: investorProfile?.mediocre_roi_threshold,
            lowROIThreshold: investorProfile?.low_roi_threshold,
            hasCashflowGoal: investorProfile?.has_cashflow_goal,
            optimalCashflowThreshold: investorProfile?.optimal_cashflow_threshold,
            mediocreCashflowThreshold: investorProfile?.mediocre_cashflow_threshold,
            lowCashflowThreshold: investorProfile?.low_cashflow_threshold,
            hasPatrimonyGoal: investorProfile?.has_patrimony_goal,
            optimalPatrimonyThreshold: investorProfile?.optimal_patrimony_threshold,
            mediocrePatrimonyThreshold: investorProfile?.mediocre_patrimony_threshold,
            lowPatrimonyThreshold: investorProfile?.low_patrimony_threshold,
            community: investorProfile?.community,
            community_id: investorProfile?.community_id || "None",
        },
        subscription_detail: {
            tier_name: subscriptionDetail?.tier_name,
            start_date: subscriptionDetail?.start_date,
            expires_at: subscriptionDetail?.expires_at,
            period: subscriptionDetail?.period,
            is_active: subscriptionDetail?.is_active,
            status: subscriptionDetail?.status,
            subscription_limits: subscriptionDetail?.subscription_limits,
        },
        config: config || {},
    };
}