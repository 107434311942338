import React, {useState} from "react";
import {FormControlLabel, Grid, Radio, Switch,} from "@mui/material";
import {useTranslation} from "react-i18next";
import {ValidationParametersContext} from "./ParametersContext";
import NumberField from "../../components/text_fields/numberField";
import Typography from "@mui/material/Typography";
import {BaseCard} from "../../components/cards";
import {useResponsive} from "../../hooks/use-responsive";
import Box from "@mui/material/Box";
import {PercentageField} from "../../components/text_fields";

export const MortgageSection = ({showTitle = true}) => {
    const mortgageTypeFixed = 1;
    const mortgageTypeVariable = 2;
    const {t} = useTranslation();
    const {validationParameters, setValidationParameters} = React.useContext(ValidationParametersContext);
    const [useFinancing, setUseFinancing] = useState(validationParameters.financed_percentage !== 0);
    const mdUp = useResponsive('up', 'md');
    const CardOrBox = mdUp ? BaseCard : Box;
    const areParamsValid = (params) => {
        const isYearAndAmountValid = params.years !== 0 && params.financed_percentage !== 0;
        const isFixedValid = params.type === mortgageTypeFixed && params.interest !== 0;
        const isVariableValid = params.type === mortgageTypeVariable && params.euribor !== 0;
        return isYearAndAmountValid && (isFixedValid || isVariableValid)
    }

    const handleTypeChange = (event) => {
        const newType = Number(event.target.value);
        setValidationParameters((prev) => {
            const newParams = {...prev, type: newType};

            if (newType === mortgageTypeFixed) {
                newParams.euribor = 0;
                newParams.differential = 0;
            }

            if (newType === mortgageTypeVariable) {
                newParams.interest = 0;
            }

            return {...newParams, isMortgageValid: areParamsValid(newParams)};
        });
    };
    const handleFinancedAmountChange = (event) => {
        const newFinancedAmount = Number(event.target.value)
        setValidationParameters((prev) => {
            const newParams = {...prev, financed_percentage: newFinancedAmount}
            return {...newParams, isMortgageValid: areParamsValid(newParams)}
        });
    }

    const handleYearsChange = (event) => {
        const newYears = Number(event.target.value)
        setValidationParameters((prev) => {
            const newParams = {...prev, years: newYears}
            return {...newParams, isMortgageValid: areParamsValid(newParams)}
        });
    }

    const handleInterestChange = (event) => {
        const newInterest = Number(event.target.value)
        setValidationParameters((prev) => {
            const newParams = {...prev, interest: newInterest}
            return {...newParams, isMortgageValid: areParamsValid(newParams)}
        });

    }
    const handleEuriborChange = (event) => {
        const newEuribor = Number(event.target.value)
        setValidationParameters((prev) => {
            const newParams = {...prev, euribor: newEuribor}
            return {...newParams, isMortgageValid: areParamsValid(newParams)}
        });
    }

    const handleDifferentialChange = (event) => {
        const newDifferential = Number(event.target.value)
        setValidationParameters((prev) => {
            const newParams = {...prev, differential: newDifferential}
            return {...newParams, isMortgageValid: areParamsValid(newParams)}
        });
    }

    const handleUseFinancingChange = (event) => {
        const checked = event.target.checked;
        setUseFinancing((prevState) => {
            if (checked && checked !== prevState) {
                setValidationParameters({
                    ...validationParameters,
                    years: 30,
                    financed_percentage: 80,
                    type: 1,
                    interest: 3,
                    differential: 0,
                    euribor: 0,
                    isMortgageValid: true
                });
            } else {
                setValidationParameters({
                    ...validationParameters,
                    years: 0,
                    financed_percentage: 0,
                    type: 0,
                    interest: 0,
                    differential: 0,
                    euribor: 0,
                    isMortgageValid: true
                })
            }
            return checked
        });
    }

    return (
        <CardOrBox>
            {showTitle ? <Typography variant={"h6"}>{t("financial.mortgage.mortgage")}</Typography> : null}
            <FormControlLabel
                sx={{ marginTop: 1 }}
                control={<Switch defaultChecked checked={useFinancing} onChange={handleUseFinancingChange} />}
                label={t("parameters.mortgage.usage")}
            />

            {useFinancing ? (
                <Grid container elevation={5} spacing={2} direction="column">
                    {/* Botones de radio para Fixed y Variable */}
                    <Grid item container xs={12}>
                        <Grid item xs={6}>
                            <FormControlLabel
                                value={1}
                                control={<Radio />}
                                label={t("financial.mortgage.fixed")}
                                checked={validationParameters.type === 1}
                                onChange={handleTypeChange}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <FormControlLabel
                                value={2}
                                control={<Radio />}
                                label={t("financial.mortgage.variable")}
                                checked={validationParameters.type === 2}
                                onChange={handleTypeChange}
                            />
                        </Grid>
                    </Grid>

                    {/* Campos de Interest, Euribor y Differential */}
                    <Grid item container xs={12} spacing={2}>
                        <Grid item xs={12} sm={4}>
                            <PercentageField
                                fullWidth={true}
                                label={t("financial.mortgage.interest")}
                                value={validationParameters.interest}
                                onChange={handleInterestChange}
                                disabled={validationParameters.type !== 1}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <PercentageField
                                fullWidth={true}
                                label={t("financial.mortgage.euribor")}
                                value={validationParameters.euribor}
                                onChange={handleEuriborChange}
                                disabled={validationParameters.type !== 2}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <PercentageField
                                fullWidth={true}
                                label={t("financial.mortgage.differential")}
                                value={validationParameters.differential}
                                onChange={handleDifferentialChange}
                                disabled={validationParameters.type !== 2}
                            />
                        </Grid>
                    </Grid>

                    {/* Otros campos */}
                    <Grid item xs={12} md={6}>
                        <NumberField
                            fullWidth={true}
                            label={t("financial.mortgage.years")}
                            value={validationParameters.years}
                            onChange={handleYearsChange}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <PercentageField
                            fullWidth={true}
                            label={t("parameters.mortgage.amount_financed")}
                            value={validationParameters.financed_percentage}
                            onChange={handleFinancedAmountChange}
                        />
                    </Grid>
                </Grid>
            ) : (
                <Typography variant={"body"}>
                    {t("parameters.mortgage.mortgage_ignore_financing_message")}
                </Typography>
            )}
        </CardOrBox>
    );
};

