import Box from "@mui/material/Box";
import {Logo} from "../../components/logo";
import React from "react";
import {Container} from "@mui/material";
import {Toaster} from "react-hot-toast";

export function OnboardingPage({children}) {
    return <Container
        id="onboarding-container"
        sx={{
            display: "flex", flexDirection: "column", minHeight: '100vh', minWidth: '100vw', gap: "1rem",
            '&::before': {
                content: '""',
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                backgroundImage: "url('https://cdn.walden.ai/media/img/app/background/overlay_2.webp')",
                backgroundSize: 'cover',
                filter: 'blur(15px) opacity(0.15)',
                zIndex: -1,
            }
        }}
    >
        <Box sx={{marginTop: "1rem",}}>
            <Logo sx={{height: "24px"}}/>
        </Box>
        <Box sx={{display: "flex", flexGrow: 1, justifyContent: "center", alignItems: "center"}}>
            {children}
        </Box>
        <Toaster/>
    </Container>
}