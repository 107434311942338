import {getPerformanceColor, OIKOS_STATUS_PENDING, VALIDATION_STATUS_DONE} from "../../utils/financial";
import {BaseCard} from "../../components/cards";
import {Paper, Stack, useTheme} from "@mui/material";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import DataField from "../../components/data-field";
import Typography from "@mui/material/Typography";
import {FormatCompactCurrency, FormatCurrency} from "../../utils/format-number";
import {useTranslation} from "react-i18next";
import React from "react";
import {useNavigate} from "react-router-dom";
import ROUTES from "../../routes";
import {OikosStatusWidget, RoiIndicatorWidget} from "../../components/cards/widgets";
import {Mixpanel} from "../../metrics/mixpanel";

export default function OikosListItem({data}) {
    const theme = useTheme();
    const navigate = useNavigate();
    const {t} = useTranslation();

    const isReady = Boolean(data?.address)

    const handleOpenOikos = () => {
        Mixpanel.track('Oikos Opened', {
            'Oikos ID': data.id,
        });
        navigate(`${ROUTES.properties.detail}?id=${data.id}`, {state: data.id});
    }

    const paperStyle = {
        padding: theme.spacing(3),
        backgroundColor: getPerformanceColor(theme, data?.financial?.performance),
        transition: 'background-color 0.7s ease-in-out',
        color: theme.palette.text.primary,
        textAlign: 'center',
        margin: "auto",
        alignItems: "center",
        justifyContent: "center",
        display: "flex",

        minHeight: data.status === VALIDATION_STATUS_DONE ? 'auto' : '118px'
    };

    return <BaseCard onClick={handleOpenOikos}>
        <Grid container spacing={1}>
            <Grid item xs={12} md={8} lg={8} xl={9}>
                <Stack sx={{flexGrow: 1}} spacing={1}>
                    <Stack spacing={0.5} direction="row">
                        <OikosStatusWidget status={isReady ? data.status : OIKOS_STATUS_PENDING}/>
                        <DataField value={data.creation_date}/>
                    </Stack>
                    {isReady ? <>
                        <Typography variant="caption"
                                    color="text.disabled">{t("property_details.address")}</Typography>
                        <Typography variant="h6" color="text.primary">{data.address}</Typography>
                    </> : null}
                    <DataField
                        label={`${t("general.cadastre")}:`}
                        labelColor="text.primary"
                        value={data.reference}
                        valueColor="text.secondary"
                    />
                </Stack>
            </Grid>
            {isReady ?
                <Grid item xs={12} md={4} lg={4} xl={3}>
                    <Paper sx={paperStyle}>
                        <RoiIndicatorWidget data={{
                            roi: data?.financial?.gross_roi,
                            performance: data?.financial?.performance
                        }}/>
                        {data.status === 'pending' ? <Typography variant="h5">
                            {t("general.in_process_dots")}
                        </Typography> : null}
                    </Paper>
                </Grid> : null}
        </Grid>
        {isReady ?
            <Grid item container spacing={2} marginTop="0.5em">
                <Grid item xs={12} md={6} sx={{display: "flex", justifyContent: "flex-start"}}>
                    {data?.has_patrimony_goal ? <div>
                        <DataField
                            direction="column"
                            label={t("oikos.patrimony_weight_title")}
                            labelVariant="caption"
                            labelColor="text.primary"
                            value={`${FormatCurrency(data?.financial?.patrimony)} ${t("oikos.out_of")} 
                                    ${FormatCompactCurrency(data?.patrimony_goal)}`}
                            valueVariant="h4"
                            valueColor="text.primary"
                        />
                    </div> : null}
                </Grid>
                <Grid item xs={12} md={6}>
                    {data?.has_cash_flow_goal ? <div>
                        <DataField
                            direction="column"
                            label={t("oikos.cash_flow_weight_title")}
                            labelVariant="caption"
                            labelColor="text.primary"
                            value={`${FormatCurrency(data.financial.cash_flow)} ${t("oikos.out_of")} 
                                    ${FormatCompactCurrency(data?.cash_flow_goal)}`}
                            valueVariant="h4"
                            valueColor="text.primary"
                        />
                    </div> : null}
                </Grid>
            </Grid> : null}
    </BaseCard>
}

OikosListItem.protoTypes = {
    data: {
        id: PropTypes.number,
        address:
        PropTypes.string,
        reference:
        PropTypes.string,
        status:
        PropTypes.string,
        patrimony_goal:
        PropTypes.number,
        patrimony:
        PropTypes.number,
        cash_flow_goal:
        PropTypes.number,
        cash_flow:
        PropTypes.number,
        performance:
        PropTypes.number,
        creation_date:
        PropTypes.string
    }
}