import PropTypes from 'prop-types';
import {forwardRef} from 'react';

import Box from '@mui/material/Box';
import Link from '@mui/material/Link';

// ----------------------------------------------------------------------

export const Logo = forwardRef(({disabledLink = false, sx}, ref) => {
    const logo = (
        <Box
            component="img"
            src="https://cdn.walden.ai/logo_walden.svg"
            sx={{display: 'flex', flexShrink: 0, cursor: 'pointer', ...sx}}
        />
    );

    if (disabledLink) {
        return logo;
    }

    return (
        <Link href="/" sx={{display: 'contents'}}>
            {logo}
        </Link>
    );
});

Logo.propTypes = {
    disabledLink: PropTypes.bool,
    sx: PropTypes.object,
};

