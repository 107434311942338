import {useAuth0} from "@auth0/auth0-react";
import {apiService} from "./ApiServiceV2";
import {useMutation} from "react-query";
import {useTranslation} from "react-i18next";
import toast from "react-hot-toast";

export function useRequestDemo(onSuccess = () => {
}, showToast = true) {
    const auth0Context = useAuth0();
    const {t} = useTranslation();

    const mutationFn = async () => {
        if (showToast) {
            return toast.promise(
                apiService.requestDemo(auth0Context),
                {
                    loading: t("general.saving"),
                    success: t('general.saved'),
                    error: t("feedback.error.api_request_error")
                }
            );
        } else {
            return apiService.requestDemo(auth0Context);
        }
    };

    return useMutation(mutationFn, {
        onSuccess: (response) => {
            onSuccess(response.data);
        }
    });
}